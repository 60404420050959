// import React, { useState, useEffect } from "react";
// import Sidenavbar from "../Sidenavbar";
// import Topbar from "../Topbar";
// import "../../styles/CommonStyle.scss";
// import "../../styles/Clients.scss";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Col, Row } from "react-bootstrap";
// import AddClientModel from "./AddClientModel";
// import { baseURL } from "../BaseUrl";
// import AddBulkClient from "./AddBulkClient";
// import ClientHistory from "./ClientHistory";
// import { exportExcel } from "../DownloadFile";
// import Select from "react-select";
// import { optionsStatusList } from "../DropdownsData";
// import { loggedInRole } from "../LoginRole";
// import AccessDenied from "../../AccessDenied";
// import MappedToUsers from "./MappedToUsers";
// import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
// import ViewClient from "./ViewClient";
// import {
//   loadClientCategoryDrop,
//   loadClientStageGrop,
//   loadCompanyData,
//   loadCompanyReportingData,
//   loadFilterCompanyData,
//   loadGetSingleClient,
//   loadPageBreadCome,
// } from "../../redux/action";
// import { useDispatch, useSelector } from "react-redux";
// import ClientTable from "./ClientTable";
// import SbCancelBtn from "../SbButtons/SbCancelBtn";
// import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
// import AllClientsInMap from "./AllClientsInMap";
// import Footer from "../Footer";
// import ReportingTo from "./ReportingTo";
// import AddClientData from "./AddClientData";
// import AddBulkClientData from "./AddBulkClientData";

// function Clients() {
//   let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
//   const travelizeAuth = {
//     "Content-Type": "application/json",
//     Travelize_Authentication: userDetails,
//   };

//   let dispatch = useDispatch();
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const category = queryParams.get("category");

//   const { companyData } = useSelector((state) => state.data);
//   const { categoryDropdown } = useSelector((state) => state.data);
//   const { stageDropdown } = useSelector((state) => state.data);
//   const { companyReportingData } = useSelector((state) => state.data);

//   const [menuCollapse, setMenuCollapse] = useState(false);
//   const [editclientmodel, setEditclientmodel] = useState(false);
//   const [stateList, setStateList] = useState([]);
//   const [userList, setUserList] = useState([]);
//   const [activeStatus, setActiveStatus] = useState(2);
//   const [selectCategory, setSelectCategory] = useState("");
//   const [selectStage, setSelectStage] = useState("");
//   const [filterUserId, setFilterUserId] = useState("");
//   const [managerId, setManagerId] = useState("");
//   const [stateName, setStateName] = useState({ value: 0, label: "" });
//   const [searchval, setSearchval] = useState("");
//   const [clientId, setClientId] = useState(0);
//   const [toggleshift, setToggleshift] = useState({ style: "" });
//   const [addClientmodelShow, setAddClientmodelShow] = useState(false);
//   const [addBulkClientModel, setAddBulkClientModel] = useState(false);
//   const [historyclientmodel, setHistoryclientmodel] = useState(false);
//   const [viewModal, setViewModal] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const [showMappedTo, setShowMappedTo] = useState(false);
//   const [clientsLocations, setClientsLocations] = useState([]);

//   let tableHeader = [
//     { name: "CUSTOMER NAME", align: "left", width: "250px" },
//     { name: "CONTACT PERSON", align: "left", width: "150px" },
//     { name: "MOBILE / EMAIL", align: "left", width: "150px" },
//     { name: "CATEGORY / STAGE", align: "left", width: "200px" },
//     { name: "LOCATION", align: "left", width: "300px" },
//     { name: "ADDED BY", align: "left", width: "200px" },
//     { name: "REPORTING TO", align: "left", width: "200px" },
//     { name: "STATUS", align: "center", width: "100px" },
//     { name: "ACTION", align: "center", width: "150px" },
//   ];

//   const [totalCounts, setTotalCounts] = useState([]);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     dispatch(loadClientCategoryDrop());
//     dispatch(loadClientStageGrop());
//   }, []);

//   const toggleMenu = () => {
//     if (menuCollapse) {
//       setMenuCollapse(false);
//       setToggleshift({ style: "" });
//     } else {
//       setMenuCollapse(true);
//       setToggleshift({ style: "toggle-shift-left" });
//     }
//   };

//   const handleCloseBulkClient = () => {
//     setAddBulkClientModel(!addBulkClientModel);
//   };

//   // ReportingTo
//   const [showReportingTo, setShowReporting] = useState(false);
//   // ReportingTo

//   const handleReportData = (clintid) => {
//     setClientId(clintid);
//     setShowReporting(!showReportingTo);
//   };

//   useEffect(() => {
//     if (clientId) {
//       dispatch(loadCompanyReportingData(clientId));
//     }
//   }, [clientId]);

//   const handleClose = () => {
//     setAddClientmodelShow(false);
//     setEditclientmodel(false);
//     setShowMappedTo(false);
//     setShowReporting(false);
//   };

//   const openAddModal = () => {
//     setAddClientmodelShow(true);
//   };

//   // PAGINATION START
//   const [PageSize, setPageSize] = useState(20);
//   const [pageCount, setPageCount] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageDataSize, setPageDataSize] = useState(0);
//   const [searchValue, setSearchValue] = useState("");
//   const [filterArray, setFilterArray] = useState([]);

//   const setSearchData = (value) => {
//     let list;
//     if (filterArray.length !== 0) {
//       list = filterArray.filter((item) => item.key !== "Text");
//     } else {
//       list = [];
//     }
//     if (value) {
//       list.push({ key: "Text", value: value });
//     } else {
//       list = filterArray.filter((item) => item.key !== "Text");
//     }
//     setSearchValue(value);
//     setFilterArray(list);
//   };

//   const setSelectSearchDrop = (data, type) => {
//     let list;
//     if (type === "user") {
//       if (filterArray.length !== 0) {
//         list = filterArray.filter((item) => item.key !== "UserID");
//       } else {
//         list = [];
//       }
//       list.push({ key: "UserID", value: data.value });
//       setFilterUserId(data);
//     } else if (type === "state") {
//       if (filterArray.length !== 0) {
//         list = filterArray.filter((item) => item.key !== "Stateid");
//       } else {
//         list = [];
//       }
//       list.push({ key: "Stateid", value: data.value });
//       setStateName(data);
//     } else if (type === "status") {
//       if (filterArray.length !== 0) {
//         list = filterArray.filter((item) => item.key !== "EnabledStatus");
//       } else {
//         list = [];
//       }
//       list.push({ key: "EnabledStatus", value: data.value });
//       setActiveStatus(data);
//     } else if (type === "category") {
//       if (filterArray.length !== 0) {
//         list = filterArray.filter((item) => item.key !== "CategoryID");
//       } else {
//         list = [];
//       }
//       list.push({ key: "CategoryID", value: data.value });
//       setSelectCategory(data);
//     } else if (type === "stage") {
//       if (filterArray.length !== 0) {
//         list = filterArray.filter((item) => item.key !== "StageID");
//       } else {
//         list = [];
//       }
//       list.push({ key: "StageID", value: data.value });
//       setSelectStage(data);
//     }
//     setFilterArray(list);
//   };

//   // PAGINATION END
//   const [editClientId, setEditClientId] = useState("");

//   const editClientDetails = (clientId) => {
//     dispatch(loadGetSingleClient(clientId));
//     setEditClientId(clientId);
//     setClientId(clientId);
//     setAddClientmodelShow(!addClientmodelShow);
//   };

//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//     let breadCome = { mainpage: "Company" };
//     dispatch(loadPageBreadCome(breadCome));
//     fetchDropdownData();
//     fetchClientData(filterArray, PageSize, currentPage);
//   }, [filterArray, PageSize, currentPage]);

//   useEffect(() => {
//     if (category && category === "Connect") {
//       // let categoryDataList = [...categoryDropdown];
//       let filterCategory = categoryDropdown.find((categoryItem) => {
//         return categoryItem.label === "Connect";
//       });

//       if (filterCategory) {
//         filterConnectData(filterCategory);
//       }
//     }
//   }, [category, categoryDropdown]);

//   const filterConnectData = (filterCategory) => {
//     // if(selectCategory){
//     let listFilter = [...filterArray];
//     listFilter.push({ key: "CategoryID", value: filterCategory.value });
//     setFilterArray(listFilter);
//     setSelectCategory(filterCategory);
//     // }
//   };

//   const fetchClientData = () => {
//     if (filterArray.length === 0) {
//       dispatch(
//         loadCompanyData(
//           PageSize,
//           currentPage,
//           setPageCount,
//           setTotalCounts,
//           setIsLoading,
//           setLoading
//         )
//       );
//     } else {
//       setIsLoading(true);
//       dispatch(
//         loadFilterCompanyData(
//           filterArray,
//           currentPage,
//           PageSize,
//           setPageCount,
//           setTotalCounts,
//           setIsLoading,
//           setLoading
//         )
//       );
//     }
//   };

//   const fetchDropdownData = () => {
//     let requestOptionZone = {
//       method: "GET",
//       mode: "cors",
//       headers: travelizeAuth,
//     };
//     fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
//       .then((res) => res.json())
//       .then((res) => {
//         if (res.success) {
//           setUserList(
//             res.result.map((data) => {
//               return { value: data.userId, label: data.userFullName };
//             })
//           );
//         } else {
//           setUserList([]);
//         }
//       });
//     fetch(`${baseURL}/api/Zone/GetStates`, requestOptionZone)
//       .then((res) => res.json())
//       .then((res) => {
//         if (res.success) {
//           setStateList(
//             res.result.map((data) => {
//               return { value: data.stateId, label: data.stateName };
//             })
//           );
//         } else {
//           setStateList([]);
//         }
//       });
//   };

//   const [showModal, setShowModal] = useState(false);
//   const [openClientsInMap, setOpenClientsInMap] = useState(false);

//   const handleView = (clntId) => {
//     setShowModal(true);
//     setClientId(clntId);
//   };

//   const goBacktoClients = () => {
//     setHistoryclientmodel(!historyclientmodel);
//   };

//   const showAllClientsInMapModal = () => {
//     setOpenClientsInMap(!openClientsInMap);
//   };

//   const clearSerachBySelect = () => {
//     setFilterArray([]);
//     setSearchval("");
//     setFilterUserId("");
//     setStateName("");
//     setActiveStatus(2);
//     setSelectCategory("");
//     setSelectStage("");
//   };

//   // console.log("USERSIS:::::", filterUserId);
//   // console.log("SEARCHVAL:::::", searchValue);

//   const exportExcelClient = () => {
//     exportExcel(
//       `${baseURL}/api/Export/ClientExcelExport?UserID=${filterUserId.value ?? ""
//       }&Text=${searchValue}&CategoryID=${selectCategory.value ?? 0}&StageID=${selectStage.value ?? 0
//       }&EnabledStatus=${activeStatus.value ?? 2}&Stateid=${stateName.value ?? 0
//       }&PageSize=${PageSize}&CurrentPage=${currentPage}`,
//       "Clients.xlsx"
//     );
//   };

//   // Page navigation
//   let navigate = useNavigate();
//   const goToSettingPage = () => {
//     navigate(-1);
//   };
//   // page navigation

//   // Open Filter div
//   const [filter, setFilter] = useState(false);
//   // Open Filter div

//   return (
//     <div id="main-page">
//       {/* view details modal */}
//       {showModal && (
//         <ViewClient
//           showModal={showModal}
//           setShowModal={setShowModal}
//           clientId={clientId}
//         />
//       )}
//       {/* view details modal */}

//       {/* {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? ( */}
//       {!addClientmodelShow && !addBulkClientModel && (
//         <>
//           {/* SIDEBAR START */}
//           <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
//           {/* SIDEBAR END */}

//           {/* TOPBAR START */}
//           <Topbar
//             toggleMenu={toggleMenu}
//             menuCollapse={menuCollapse}
//             toggleshift={toggleshift}
//           />
//           {/* TOPBAR END */}

//           {/* MAIN BODY START */}
//           <div className="page-wrapper">
//             {/* PAGE CONTENT START */}
//             {!historyclientmodel && !viewModal ? (
//               <div className={`main-body ${toggleshift.style}`}>
//                 <div className="page-content">
//                   {/* USER TABLE START */}
//                   <div className="clients-table">
//                     <Row className="search-row">
//                       <Col md={3} className="cat-col">
//                         <div className="page-header-text-div">
//                           <div className="back-btn" onClick={goToSettingPage}>
//                             <img
//                               src="../../../../images/DefaultImages/back-btn-arrow.svg"
//                               alt=""
//                             />
//                           </div>
//                           <h6 className="page-header-text">
//                             CUSTOMER ({totalCounts[0]})
//                           </h6>
//                           &nbsp;
//                           <Link
//                             to="#"
//                             className="see-all-check-ins"
//                             title="View on Map"
//                             onClick={showAllClientsInMapModal}
//                           >
//                             <span
//                               className="material-symbols-outlined"
//                               id="growing-animation"
//                             >
//                               person_pin_circle
//                             </span>
//                           </Link>
//                         </div>
//                       </Col>
//                       <Col md={5} className="cat-col">
//                         <div className="input-div">
//                           <input
//                             type="search"
//                             id="metricInfo_search_bar"
//                             placeholder="Search Customer By Name / Mobile number / Code"
//                             className="form-control setting-input"
//                             value={searchValue}
//                             onChange={(e) => setSearchData(e.target.value)}
//                           />
//                           <i className="fa fa-search search-icon" />
//                         </div>
//                       </Col>
//                       <Col md={4} className="cat-col">
//                         <div
//                           className="search-bar-header"
//                           style={{ float: "right" }}
//                         >
//                           <div className="page-top-bar-dropdowns page-top-Filters">
//                             <div
//                               className="header-filter-btn"
//                               onClick={() => setFilter(!filter)}
//                             >
//                               <p className="card-head">
//                                 <i className="bi bi-sliders" />
//                                 &nbsp;&nbsp;Filters
//                               </p>
//                             </div>
//                             <SbDropDownBtn
//                               openAddModal={openAddModal}
//                               // pageName="Client"
//                               addBulkModel={handleCloseBulkClient}
//                             />
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>

//                     {filter && (
//                       <div className="page-filter-section">
//                         <div style={{ width: "100%" }}>
//                           <Row>
//                             <Col md={10}>
//                               <div className="clients-filter-input-boxs filter-box-div">
//                                 <div
//                                   style={{ width: "100%", padding: "0px 5px" }}
//                                 >
//                                   <Select
//                                     className="react-select-container-list"
//                                     classNamePrefix="react-select-list"
//                                     options={userList}
//                                     placeholder="Select Employee"
//                                     value={filterUserId !== "" && filterUserId}
//                                     onInputChange={userList.label}
//                                     onChange={(data) =>
//                                       setSelectSearchDrop(data, "user")
//                                     }
//                                     isSearchable={true}
//                                   />
//                                 </div>
//                                 <div
//                                   style={{ width: "100%", padding: "0px 5px" }}
//                                 >
//                                   <Select
//                                     className="react-select-container-list"
//                                     classNamePrefix="react-select-list"
//                                     options={stateList}
//                                     placeholder="Select State"
//                                     value={stateName}
//                                     onInputChange={stateList.label}
//                                     onChange={(data) =>
//                                       setSelectSearchDrop(data, "state")
//                                     }
//                                     isSearchable={true}
//                                   />
//                                 </div>
//                                 <div
//                                   style={{ width: "100%", padding: "0px 5px" }}
//                                 >
//                                   <Select
//                                     className="react-select-container-list"
//                                     classNamePrefix="react-select-list"
//                                     // defaultValue={optionsRoleList[0].label}
//                                     options={optionsStatusList}
//                                     placeholder="Select Status"
//                                     value={activeStatus && activeStatus}
//                                     onInputChange={optionsStatusList.label}
//                                     onChange={(data) =>
//                                       setSelectSearchDrop(data, "status")
//                                     }
//                                     isSearchable={true}
//                                   />
//                                 </div>
//                                 <div
//                                   style={{ width: "100%", padding: "0px 5px" }}
//                                 >
//                                   <Select
//                                     className="react-select-container-list"
//                                     classNamePrefix="react-select-list"
//                                     // defaultValue={optionsRoleList[0].label}
//                                     options={categoryDropdown}
//                                     placeholder="Select Category"
//                                     value={selectCategory && selectCategory}
//                                     onInputChange={categoryDropdown.label}
//                                     onChange={(data) =>
//                                       setSelectSearchDrop(data, "category")
//                                     }
//                                     isSearchable={true}
//                                   />
//                                 </div>
//                                 <div
//                                   style={{ width: "100%", padding: "0px 5px" }}
//                                 >
//                                   <Select
//                                     className="react-select-container-list"
//                                     classNamePrefix="react-select-list"
//                                     // defaultValue={optionsRoleList[0].label}
//                                     options={stageDropdown}
//                                     placeholder="Select Stage"
//                                     value={selectStage && selectStage}
//                                     onInputChange={stageDropdown.label}
//                                     onChange={(data) =>
//                                       setSelectSearchDrop(data, "stage")
//                                     }
//                                     isSearchable={true}
//                                   />
//                                 </div>
//                               </div>
//                             </Col>
//                             <Col md={2}>
//                               <div className="clients-filter-input-boxs filter-box-div">
//                                 <SbCancelBtn
//                                   onClickEffect={clearSerachBySelect}
//                                   btnName="Clear"
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                         </div>
//                       </div>
//                     )}

//                     <ClientTable
//                       tableHeader={tableHeader}
//                       clientData={companyData}
//                       isLoading={isLoading}
//                       handleView={handleView}
//                       setShowMappedTo={setShowMappedTo}
//                       showMappedTo={showMappedTo}
//                       setClientId={setClientId}
//                       setEditclientmodel={setEditclientmodel}
//                       editclientmodel={editclientmodel}
//                       setHistoryclientmodel={setHistoryclientmodel}
//                       historyclientmodel={historyclientmodel}
//                       editClientDetails={editClientDetails}
//                       loading={loading}
//                       setPageSize={setPageSize}
//                       PageSize={PageSize}
//                       setLoading={setLoading}
//                       setShowReporting={setShowReporting}
//                       handleReportData={handleReportData}
//                     />
//                   </div>
//                   {/* USER TABLE END */}
//                   <div className="download-and-pagination">
//                     <SbDownloadBtn
//                       btnName="Download"
//                       onClickEffect={exportExcelClient}
//                     />
//                   </div>

//                   {/* ADD CLIENT MODEL START */}
//                   {/* {addClientmodelShow && (
//                     <AddClientModel
//                       addClientmodelShow={addClientmodelShow}
//                       isLoading={isLoading}
//                       setIsLoading={setIsLoading}
//                       handleClose={handleClose}
//                       fetchClientData={fetchClientData}
//                       editClientId={editClientId}
//                       setEditClientId={setEditClientId}
//                       companyReportingData={companyReportingData}
//                     />
//                   )} */}

//                   {/* ADD CLIENT MODEL END */}

//                   {/* ADD BULK CLIENT MODEL START */}
//                   {/* {addBulkClientModel && (
//                     <AddBulkClient
//                       show={addBulkClientModel}
//                       handleCloseBulkClient={handleCloseBulkClient}
//                     />
//                   )} */}

//                   {/* ADD BULK CLIENT MODEL END */}

//                   {/* EDIT CLIENT MODEL START */}
//                   {/* {editclientmodel && (
//                     <EditclientsModel
//                       editclientmodel={editclientmodel}
//                       handleClose={handleClose}
//                       clientId={clientId}
//                       fetchClientDetails={fetchClientDetails}
//                     />
//                   )} */}
//                   {/* EDIT CLIENT MODEL END */}

//                   {/* MAPPEDTO USER MODEL START */}
//                   {showMappedTo && (
//                     <MappedToUsers
//                       showMappedTo={showMappedTo}
//                       handleClose={handleClose}
//                       clientId={clientId}
//                       filterUserId={filterUserId}
//                     />
//                   )}
//                   {/* MAPPEDTO USER MODEL END */}

//                   {/* ReportingTo Modal */}
//                   {showReportingTo && (
//                     <ReportingTo
//                       showReportingTo={showReportingTo}
//                       clientId={clientId}
//                       handleClose={handleClose}
//                     />
//                   )}
//                   {/* ReportingTo Modal */}

//                   {/* PAGE CONTENT END*/}
//                 </div>
//               </div>
//             ) : (
//               <>
//                 {historyclientmodel && (
//                   <ClientHistory
//                     historyclientmodel={historyclientmodel}
//                     toggleshift={toggleshift}
//                     goBacktoClients={goBacktoClients}
//                     clientId={clientId}
//                   // fetchClientDetails={fetchClientDetails}
//                   />
//                 )}
//               </>
//             )}
//           </div>
//           <>
//             {openClientsInMap && (
//               <AllClientsInMap
//                 openClientsInMap={openClientsInMap}
//                 showAllClientsInMapModal={showAllClientsInMapModal}
//               />
//             )}
//           </>

//           {/* MAIN BODY END */}
//           <Footer />
//         </>
//       )}
//       {/* // ) : ( */}
//       {/* // <AccessDenied /> */}
//       {addClientmodelShow && (
//         <AddClientData
//           addClientmodelShow={addClientmodelShow}
//           isLoading={isLoading}
//           setIsLoading={setIsLoading}
//           handleClose={handleClose}
//           fetchClientData={fetchClientData}
//           editClientId={editClientId}
//           setEditClientId={setEditClientId}
//           companyReportingData={companyReportingData}
//         />
//       )}
//       {addBulkClientModel && (
//         <AddBulkClientData
//           show={addBulkClientModel}
//           handleCloseBulkClient={handleCloseBulkClient}
//         />
//       )}
//       {/* // ) */}
//       {/* } */}
//     </div>
//   );
// }

// export default Clients;







import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Clients.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import ClientHistory from "./ClientHistory";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import MappedToUsers from "./MappedToUsers";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import ViewClient from "./ViewClient";
import {
  loadClientCategoryDrop,
  loadClientStageGrop,
  loadCompanyData,
  loadCompanyReportingData,
  loadFilterCompanyData,
  loadGetSingleClient,
  loadPageBreadCome,
  loadStateList,
  loadZoneList,
  loadCityList,
  loadManagerDropdown,
  loadUserDropdownByManager,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import ClientTable from "./ClientTable";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { MiCustomBtn, SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { MultiSelect } from "react-multi-select-component";
import AllClientsInMap from "./AllClientsInMap";
import Footer from "../Footer";
import ReportingTo from "./ReportingTo";
import AddClientData from "./AddClientData";
import AddBulkClientData from "./AddBulkClientData";
import ReAssignModel from "./ReAssignModel";
import ClientCard from "./ClientCard";
import { loggedInId } from "../LoginRole";

function Clients() {
  const { id } = useParams();

  let dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");

  const { managerDropdown } = useSelector((state) => state.data);
  const { userDropdownByManager } = useSelector((state) => state.data);

  const { companyData } = useSelector((state) => state.data);
  const { categoryDropdown } = useSelector((state) => state.data);
  const { stageDropdown } = useSelector((state) => state.data);
  const { companyReportingData } = useSelector((state) => state.data);

  const { zoneList } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editclientmodel, setEditclientmodel] = useState(false);
  const [activeStatus, setActiveStatus] = useState({
    value: 1,
    label: "Active",
  });
  const [selectCategory, setSelectCategory] = useState({
    value: id,
    label: "",
  });
  const [selectStage, setSelectStage] = useState("");
  const [filterUserId, setFilterUserId] = useState([]);
  const [managerId, setManagerId] = useState({
    value: loggedInId,
    label: "Select Manager",
  });
  const [clientId, setClientId] = useState(0);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [addClientmodelShow, setAddClientmodelShow] = useState(false);
  const [addBulkClientModel, setAddBulkClientModel] = useState(false);
  const [historyclientmodel, setHistoryclientmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMappedTo, setShowMappedTo] = useState(false);
  const [zoneID, setZoneID] = useState({ value: 0, label: "Select Zone" });
  const [stateID, setStateID] = useState({ value: 0, label: "Select State" });
  const [cityID, setCityID] = useState({ value: 0, label: "Select City" });
  const [totalCounts, setTotalCounts] = useState([]);
  const [reAssignEnable, setReAssignEnable] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [reAssignData, setReAssignData] = useState(null);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const currentPage = 1;
  const [searchValue, setSearchValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);

  let tableHeader = [
    { name: "COMPANY NAME", align: "left", width: "250px" },
    { name: "CONTACT PERSON", align: "left", width: "150px" },
    { name: "MOBILE / EMAIL", align: "left", width: "150px" },
    { name: "CATEGORY / STAGE", align: "left", width: "200px" },
    { name: "LOCATION", align: "left", width: "300px" },
    { name: "ADDED BY", align: "left", width: "200px" },
    { name: "REPORTING TO", align: "left", width: "200px" },
    { name: "CUSTOM FIELDS", align: "left", width: "200px" },
    { name: "STATUS", align: "center", width: "100px" },
    { name: "ACTION", align: "center", width: "150px" },
  ];

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleCloseBulkClient = () => {
    setAddBulkClientModel(!addBulkClientModel);
  };

  // ReportingTo
  const [showReportingTo, setShowReporting] = useState(false);
  const [showCustomeField, setShowCustomeField] = useState(false);
  // ReportingTo

  const handleReportData = (clintid) => {
    setClientId(clintid);
    setShowReporting(!showReportingTo);
  };
  const handleCustomeFiledsData = (clintid) => {
    setClientId(clintid);
    setShowCustomeField(!showCustomeField);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "Company" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadManagerDropdown());
    dispatch(loadClientCategoryDrop());
    dispatch(loadClientStageGrop());
    dispatch(loadZoneList(0));
  }, []);

  useEffect(() => {
    dispatch(loadUserDropdownByManager(managerId.value));
  }, [managerId]);

  useEffect(() => {
    dispatch(loadStateList(zoneID.value));
    dispatch(loadCityList(stateID.value));
  }, [zoneID, stateID]);

  useEffect(() => {
    if (clientId) {
      dispatch(loadCompanyReportingData(clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const handleClose = () => {
    setAddClientmodelShow(false);
    setEditclientmodel(false);
    setShowMappedTo(false);
    setShowReporting(false);
  };

  const openAddModal = () => {
    setAddClientmodelShow(true);
  };

  const openAssignToUserModal = () => {
    setOpenReassignModal(!openReassignModal);
  };

  // useEffect(() => {
  //   let list;
  //   if (id) {
  //     if (filterArray.length !== 0) {
  //       list = filterArray.filter((item) => item.key !== "CategoryID");
  //     } else {
  //       list = [];
  //     }
  //     list.push({ key: "CategoryID", value: id });
  //     setFilterArray(list);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  useEffect(() => {
    let list;
    if (userDropdownByManager?.length > 0) {
      if (filterArray?.length !== 0) {
        list = filterArray.filter(
          (item) => item.key !== "SubOrdinates" && item.key !== "UserID"
        );
      } else {
        list = [];
      }
      if (userDropdownByManager?.length > 1) {
        if (managerId.value === loggedInId) {
          list.push({
            key: "SubOrdinates",
            value: `${userDropdownByManager
              .map((d) => d.value)
              .toString()},${loggedInId}`,
          });
        } else {
          list.push({
            key: "SubOrdinates",
            value: `${userDropdownByManager.map((d) => d.value).toString()}`,
          });
        }
      } else if (userDropdownByManager?.length === 1) {
        list.push({ key: "UserID", value: userDropdownByManager[0]?.value });
      }
      setFilterUserId(userDropdownByManager);
      setFilterArray(list);
    }
  }, [userDropdownByManager]);

  const setSearchData = (value) => {
    let list;
    if (filterArray?.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Text");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "Text", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "Text");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list;
    if (type === "manager") {
      if (filterArray?.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ManagerID");
      } else {
        list = [];
      }
      list.push({ key: "ManagerID", value: data.value });
      setManagerId(data);
      // setUsersByManager();
    } else if (type === "user") {
      if (filterArray?.length !== 0) {
        list = filterArray.filter(
          (item) => item.key !== "SubOrdinates" && item.key !== "UserID"
        );
      } else {
        list = [];
      }
      if (data.length === 1) {
        list.splice(
          list.findIndex((a) => a.key == "SubOrdinates"),
          1
        );
        list.push({ key: "UserID", value: data[0]?.value });
      } else if (data.length > 1) {
        list.splice(
          list.findIndex((a) => a.key == "UserID"),
          1
        );
        list.push({
          key: "SubOrdinates",
          value: `${data.map((d) => d.value).toString()}`,
        });
      }
      setFilterUserId(data);
    } else if (type === "state") {
      if (filterArray?.length !== 0) {
        list = filterArray.filter((item) => item.key !== "Stateid");
      } else {
        list = [];
      }
      list.push({ key: "Stateid", value: data.value });
      setStateID(data);
    } else if (type === "city") {
      if (filterArray?.length !== 0) {
        list = filterArray.filter((item) => item.key !== "CityID");
      } else {
        list = [];
      }
      list.push({ key: "CityID", value: data.value });
      setCityID(data);
    } else if (type === "status") {
      if (filterArray?.length !== 0) {
        list = filterArray.filter((item) => item.key !== "EnabledStatus");
      } else {
        list = [];
      }
      list.push({ key: "EnabledStatus", value: data.value });
      setActiveStatus(data);
      // } else if (type === "category") {
      //   if (filterArray.length !== 0) {
      //     list = filterArray.filter((item) => item.key !== "CategoryID");
      //   } else {
      //     list = [];
      //   }
      //   list.push({ key: "CategoryID", value: data.value });
      //   setSelectCategory(data);
    } else if (type === "stage") {
      if (filterArray?.length !== 0) {
        list = filterArray.filter((item) => item.key !== "StageID");
      } else {
        list = [];
      }
      list.push({ key: "StageID", value: data.value });
      setSelectStage(data);
    }
    setFilterArray(list);
  };

  const handleDropdownItemClick = (data, type) => {
    if (type === "stage") {
      setSelectSearchDrop(data, type);
    }
  };

  const [editClientId, setEditClientId] = useState("");

  const editClientDetails = (clientId) => {
    dispatch(loadGetSingleClient(clientId));
    setEditClientId(clientId);
    setClientId(clientId);
    setAddClientmodelShow(!addClientmodelShow);
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchClientData();
  }, [filterArray, PageSize, currentPage, id]);

  useEffect(() => {
    if (category && category === "Connect") {
      // let categoryDataList = [...categoryDropdown];
      let filterCategory = categoryDropdown.find((categoryItem) => {
        return categoryItem.label === "Connect";
      });

      if (filterCategory) {
        filterConnectData(filterCategory);
      }
    }
  }, [category, categoryDropdown]);

  const filterConnectData = (filterCategory) => {
    // if(selectCategory){
    let listFilter = [...filterArray];
    listFilter.push({ key: "CategoryID", value: filterCategory.value });
    setFilterArray(listFilter);
    setSelectCategory(filterCategory);
    // }
  };

  const fetchClientData = () => {
    if (filterArray?.length === 0) {
      dispatch(
        loadCompanyData(
          PageSize,
          currentPage,
          setTotalCounts,
          setIsLoading,
          setLoading,
          loggedInId,
          id
        )
      );
    } else {
      dispatch(
        loadFilterCompanyData(
          filterArray,
          currentPage,
          PageSize,
          setTotalCounts,
          setIsLoading,
          setLoading,
          id
        )
      );
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [openClientsInMap, setOpenClientsInMap] = useState(false);

  const handleView = (clntId) => {
    setShowModal(true);
    setClientId(clntId);
  };

  const goBacktoClients = () => {
    setHistoryclientmodel(!historyclientmodel);
  };

  const showAllClientsInMapModal = () => {
    setOpenClientsInMap(!openClientsInMap);
  };

  const clearSerachBySelect = () => {
    setFilterArray([]);
    setFilterUserId([]);
    setManagerId({
      value: loggedInId,
      label: "Select Manager",
    });
    setActiveStatus({
      value: 1,
      label: "Active",
    });
    setSelectCategory({
      value: id,
      label: "",
    });
    setSelectStage("");
    setZoneID({ value: 0, label: "Select Zone" });
    setStateID({ value: 0, label: "Select State" });
    setCityID({ value: 0, label: "Select City" });
  };

  const exportExcelClient = () => {
    exportExcel(
      `${baseURL}/api/Export/ClientExcelExport?UserID=${filterUserId.value ?? ""
      }&Text=${searchValue}&CategoryID=${id ?? 0}&StageID=${selectStage.value ?? 0
      }&EnabledStatus=${activeStatus.value ?? 2}&Stateid=${stateID.value ?? 0
      }&CityID=${cityID.value ?? 0
      }&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Clients.xlsx"
    );
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    if (showCustomeField) {
      setShowCustomeField(false);
    } else {
      navigate(-1);
    }
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  const [pageGridView, setPageGridView] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {/* view details modal */}
      {showModal && (
        <ViewClient
          showModal={showModal}
          setShowModal={setShowModal}
          clientId={clientId}
        />
      )}
      {/* view details modal */}

      {/* {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? ( */}
      {!addClientmodelShow && !addBulkClientModel && (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            {!historyclientmodel ? (
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* USER TABLE START */}
                  <div className="clients-table">
                    <Row className="search-row">
                      <Col md={3} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToSettingPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">
                            COMPANY / CUSTOMER ({totalCounts[0]})
                          </h6>
                          &nbsp;
                          <Link
                            to="#"
                            className="see-all-check-ins"
                            title="View on Map"
                            onClick={showAllClientsInMapModal}
                          >
                            <span
                              className="material-symbols-outlined"
                              id="growing-animation"
                            >
                              person_pin_circle
                            </span>
                          </Link>
                        </div>
                      </Col>
                      <Col md={4} className="cat-col">
                        <div className="input-div">
                          <input
                            type="search"
                            id="metricInfo_search_bar"
                            placeholder="Search Customer By Name / Mobile / Code"
                            className="form-control setting-input"
                            value={searchValue}
                            onChange={(e) => setSearchData(e.target.value)}
                          />
                          <i className="fa fa-search search-icon" />
                        </div>
                      </Col>
                      <Col md={5} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div className="header-filter-btn dflex-j-between mrx-pdg-5">
                              <p
                                className="card-head p-2"
                                onClick={() => setPageGridView(!pageGridView)}
                              >
                                {pageGridView ? (
                                  <i className="fa fa-th" />
                                ) : (
                                  <i className="fa fa-th-list" />
                                )}
                                &nbsp; &nbsp;
                              </p>
                              <p
                                className="card-head color-green"
                                onClick={() =>
                                  setReAssignEnable(!reAssignEnable)
                                }
                              >
                                <i className="fa fa-retweet" />
                                &nbsp;&nbsp;Re-Assign
                              </p>
                            </div>
                            {reAssignEnable && (
                              <div className="header-filter-btn mrx-pdg-5">
                                <MiCustomBtn
                                  btnName="Assign To"
                                  onClickEffect={openAssignToUserModal}
                                  iconName={"fa fa-user"}
                                  btnColor="primary"
                                  fontSize={"fz13"}
                                  disableProp={
                                    reAssignData?.length > 0 ? false : true
                                  }
                                />
                              </div>
                            )}
                            <div
                              className="header-filter-btn mrx-pdg-5"
                              onClick={() => setFilter(!filter)}
                            >
                              <p className="card-head">
                                <i className="bi bi-sliders" />
                                &nbsp;&nbsp;Filters
                              </p>
                            </div>
                            <SbDropDownBtn
                              openAddModal={openAddModal}
                              pageName="Client"
                              addBulkModel={handleCloseBulkClient}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter && (
                      <div className="page-filter-section">
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Col md={12}>
                              <div className="clients-filter-input-boxs dflex-j-start flex-wrap">
                                <div
                                  style={{ width: "auto", padding: "5px 5px" }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    // defaultValue={optionsRoleList[0].label}
                                    options={managerDropdown}
                                    placeholder="Select Manager"
                                    value={managerId && managerId}
                                    onInputChange={managerDropdown.label}
                                    // onChange={(data) =>
                                    //   setSelectSearchDrop(data, "manager")
                                    // }
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "manager")
                                    }
                                    isSearchable={true}
                                  />
                                </div>
                                <div
                                  style={{ width: "auto", padding: "5px 5px" }}
                                >
                                  <MultiSelect
                                    className="multiselect-comp filter-dropdown"
                                    options={userDropdownByManager}
                                    value={filterUserId}
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "user")
                                    }
                                    labelledBy={"Select"}
                                    overrideStrings={"Select Users"}
                                  />
                                </div>
                                <div
                                  style={{ width: "auto", padding: "5px 5px" }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    // defaultValue={optionsRoleList[0].label}
                                    options={optionsStatusList}
                                    placeholder="Select Status"
                                    value={activeStatus && activeStatus}
                                    onInputChange={optionsStatusList.label}
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "status")
                                    }
                                    isSearchable={true}
                                  />
                                </div>
                                {/* <div
                                  style={{ width: "100%", padding: "0px 5px" }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    // defaultValue={optionsRoleList[0].label}
                                    options={categoryDropdown}
                                    placeholder="Select Category"
                                    value={selectCategory && selectCategory}
                                    onInputChange={categoryDropdown.label}
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "category")
                                    }
                                    isSearchable={true}
                                  />
                                </div> */}
                                {/* <div
                                  style={{ width: "100%", padding: "0px 5px" }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    // defaultValue={optionsRoleList[0].label}
                                    options={stageDropdown}
                                    placeholder="Select Stage"
                                    value={selectStage && selectStage}
                                    onInputChange={stageDropdown.label}
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "stage")
                                    }
                                    isSearchable={true}
                                  />
                                </div> */}
                                <div
                                  style={{
                                    width: "auto",
                                    padding: "5px 5px",
                                  }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={zoneList}
                                    placeholder="Select Zone"
                                    value={zoneID}
                                    onInputChange={zoneList.label}
                                    onChange={(data) => setZoneID(data)}
                                    // onChange={(data) =>
                                    //   setSelectSearchDrop(data, "zone")
                                    // }
                                    isSearchable={true}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "auto",
                                    padding: "5px 5px",
                                  }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={stateList}
                                    placeholder="Select State"
                                    value={stateID}
                                    onInputChange={stateList.label}
                                    // onChange={(data) => setStateID(data)}
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "state")
                                    }
                                    isSearchable={true}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "auto",
                                    padding: "5px 5px",
                                  }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={cityList}
                                    placeholder="Select City"
                                    value={cityID}
                                    onInputChange={cityList.label}
                                    // onChange={(data) => setCityID(data)}
                                    onChange={(data) =>
                                      setSelectSearchDrop(data, "city")
                                    }
                                    isSearchable={true}
                                  />
                                </div>
                                <SbCancelBtn
                                  onClickEffect={clearSerachBySelect}
                                  btnName="Clear"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="customer-dropdown-cont">
                                {stageDropdown.map((item, index) => (
                                  <b
                                    key={index}
                                    className={`customer-dropdown-item ${item.label === selectStage?.label
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      handleDropdownItemClick(item, "stage")
                                    }
                                  >
                                    {item.label}
                                  </b>
                                ))}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}

                    {/* {!showCustomeField ? ( */}
                    <>
                      {pageGridView && companyData.length > 0 ? (
                        <ClientTable
                          tableHeader={tableHeader}
                          clientData={companyData}
                          isLoading={isLoading}
                          handleView={handleView}
                          setShowMappedTo={setShowMappedTo}
                          showMappedTo={showMappedTo}
                          setClientId={setClientId}
                          setEditclientmodel={setEditclientmodel}
                          editclientmodel={editclientmodel}
                          setHistoryclientmodel={setHistoryclientmodel}
                          historyclientmodel={historyclientmodel}
                          editClientDetails={editClientDetails}
                          loading={loading}
                          setPageSize={setPageSize}
                          PageSize={PageSize}
                          setLoading={setLoading}
                          setShowReporting={setShowReporting}
                          handleReportData={handleReportData}
                          handleCustomeFiledsData={handleCustomeFiledsData}
                          reAssignEnable={reAssignEnable}
                          setReAssignData={setReAssignData}
                        />
                      ) : (
                        <ClientCard
                          isLoading={isLoading}
                          clientData={companyData}
                          editClientDetails={editClientDetails}
                          historyclientmodel={historyclientmodel}
                          setClientId={setClientId}
                          handleView={handleView}
                          showMappedTo={showMappedTo}
                          setShowMappedTo={setShowMappedTo}
                          handleReportData={handleReportData}
                          handleCustomeFiledsData={handleCustomeFiledsData}
                          setHistoryclientmodel={setHistoryclientmodel}
                          loading={loading}
                          setPageSize={setPageSize}
                          PageSize={PageSize}
                          setLoading={setLoading}
                          setShowReporting={setShowReporting}
                          reAssignEnable={reAssignEnable}
                          setReAssignData={setReAssignData}
                        />
                      )}
                    </>
                  </div>

                  {/* USER TABLE END */}
                  <div className="download-and-pagination">
                    <SbDownloadBtn
                      btnName="Download"
                      onClickEffect={exportExcelClient}
                    />
                  </div>

                  {/* MAPPEDTO USER MODEL START */}
                  {showMappedTo && (
                    <MappedToUsers
                      showMappedTo={showMappedTo}
                      handleClose={handleClose}
                      clientId={clientId}
                      filterUserId={filterUserId}
                    />
                  )}
                  {/* MAPPEDTO USER MODEL END */}

                  {/* ReportingTo Modal */}
                  {showReportingTo && (
                    <ReportingTo
                      showReportingTo={showReportingTo}
                      clientId={clientId}
                      handleClose={handleClose}
                    />
                  )}
                  {/* ReportingTo Modal */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            ) : (
              <>
                {historyclientmodel && (
                  <ClientHistory
                    historyclientmodel={historyclientmodel}
                    toggleshift={toggleshift}
                    goBacktoClients={goBacktoClients}
                    clientId={clientId}
                  />
                )}
              </>
            )}
          </div>

          <>
            {openClientsInMap && (
              <AllClientsInMap
                openClientsInMap={openClientsInMap}
                showAllClientsInMapModal={showAllClientsInMapModal}
              />
            )}

            {openReassignModal && (
              <ReAssignModel
                openReassignModal={openReassignModal}
                openAssignToUserModal={openAssignToUserModal}
                reAssignData={reAssignData}
                userDropdown={userDropdownByManager}
                totalCounts={totalCounts[0]}
                fetchClientData={fetchClientData}
              />
            )}
          </>

          {/* MAIN BODY END */}
          <Footer />
        </>
      )}
      {addClientmodelShow && (
        <AddClientData
          addClientmodelShow={addClientmodelShow}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleClose={handleClose}
          fetchClientData={fetchClientData}
          editClientId={editClientId}
          setEditClientId={setEditClientId}
          companyReportingData={companyReportingData}
        />
      )}
      {addBulkClientModel && (
        <AddBulkClientData
          show={addBulkClientModel}
          handleCloseBulkClient={handleCloseBulkClient}
        />
      )}
    </div>
  );
}

export default Clients;
