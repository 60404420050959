import { useEffect, useState } from 'react';
import '../../styles/AddUserModel.scss'
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Col, FloatingLabel, Row } from 'react-bootstrap';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import * as HeaderData from "../HeaderAuthentication";
import { postAmcStatusData } from '../../redux/action';

const AnnualContractStatus = (props) => {

    let dispatch = useDispatch();
    const singleClientCompaint = props.singleClientCompaint;
    const [status, setStatus] = useState('Approved');
    const [remark, setRemark] = useState("")

    const handleStatusChange = (e) => {
        // setStatus(e.target.value);
        const newStatus = e.target.value;
        setStatus(newStatus);
        if (newStatus === 'Approved') {
            setRemark('Accepted');
        }
        //  else {
        //     setRemark(singleClientCompaint.remark || '');
        // }
    };
    const closeModel = () => {
        props.handleShowStatus();
    };
    const handleAmcStatus = (e) => {
        e.preventDefault();
        const formattedDate = moment().format('DD/MM/YYYY');
        const data = {
            "clientComplaintsID": props.singleClientCompaint.clientComplaintsID,
            "remark": remark,
            "amc": props.singleClientCompaint.amc,
            "amC_app_rej_ID": status,
            "amC_app_rej_Date": formattedDate,
        }
        var postRequest = {
            method: "POST",
            mode: "cors",
            headers: HeaderData.postTravelizeAuth,
            body: JSON.stringify(data),
        };
        dispatch(postAmcStatusData(postRequest, closeModel, singleClientCompaint.clientComplaintsID));
    }

    const [machineCode, setMachineCode] = useState("");
    const [machineName, setMachineName] = useState("");
    const [category, setCategory] = useState("");
    const [contractStart, setContractStart] = useState("");
    const [contractEnd, setContractEnd] = useState("");
    const [stampingNo, setStampingNo] = useState("");
    const [warrantyStart, setWarranty] = useState("");
    const [warrantyEnd, setWarrantyEnd] = useState("");
    const [amcStatus, setAmcStatus] = useState("");
    const [defaultTime, setDefaultTime] = useState("");
    const [capacity, setCapacity] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountStatus, setAccountstatus] = useState("");
    const [stampingDate, setStampingDate] = useState("");
    const [installationEngg, setInstallationEngg] = useState("");
    const [billingAddress, setBillingAddress] = useState("");

    useEffect(() => {
        if (props.getSingleRowFormData !== 0) {
            let foundMachineCode = "";
            let foundMachineName = "";
            let foundCategory = "";
            let foundContractstart = "";
            let foundContractEnd = "";
            let foundStampingNo = "";
            let foundWarrantyStart = "";
            let foundWarrantyEnd = "";
            let foundAmcStatus = "";
            let foundDefaultTime = "";
            let foundCapacity = "";
            let foundAccountName = "";
            let foundAccountStatus = "";
            let foundStampingDate = "";
            let foundInstallationEngg = "";
            let foundBillingAddress = "";

            if (Array.isArray(props.getSingleRowFormData)) {
                props.getSingleRowFormData.forEach(innerArray => {
                    innerArray.forEach(item => {
                        if (item.data) {
                            item.data.forEach(data => {
                                switch (data.iForm_FieldName) {
                                    case "Machine Number":
                                        foundMachineCode = data.iForm_FieldValue;
                                        break;
                                    case "Product":
                                        foundMachineName = data.iForm_FieldValue;
                                        break;
                                    case "Category":
                                        foundCategory = data.iForm_FieldValue;
                                        break;
                                    case "Contract Start Date":
                                        foundContractstart = data.iForm_FieldValue;
                                        break;
                                    case "Contract End Date":
                                        foundContractEnd = data.iForm_FieldValue;
                                        break;
                                    case "Stamping CFT No":
                                        foundStampingNo = data.iForm_FieldValue;
                                        break;
                                    case "Warranty Start Date":
                                        foundWarrantyStart = data.iForm_FieldValue;
                                        break;
                                    case "Warranty End Date":
                                        foundWarrantyEnd = data.iForm_FieldValue;
                                        break;
                                    case "AMC Status":
                                        foundAmcStatus = data.iForm_FieldValue;
                                        break;
                                    case "Default Service Time":
                                        foundDefaultTime = data.iForm_FieldValue;
                                        break;
                                    case "Capacity":
                                        foundCapacity = data.iForm_FieldValue;
                                        break;
                                    case "Account Name":
                                        foundAccountName = data.iForm_FieldValue;
                                        break;
                                    case "Account Status":
                                        foundAccountStatus = data.iForm_FieldValue;
                                        break;
                                    case "Stamping Date":
                                        foundStampingDate = data.iForm_FieldValue;
                                        break;
                                    case "Installation Engineer":
                                        foundInstallationEngg = data.iForm_FieldValue;
                                        break;
                                    case "Billing Address":
                                        foundBillingAddress = data.iForm_FieldValue;
                                        break;
                                    default:
                                        break;
                                }
                            });
                        }
                    });
                })
            };

            setMachineCode(foundMachineCode);
            setMachineName(foundMachineName);
            setCategory(foundCategory);
            setContractStart(foundContractstart);
            setContractEnd(foundContractEnd);
            setStampingNo(foundStampingNo);
            setWarranty(foundWarrantyStart);
            setWarrantyEnd(foundWarrantyEnd);
            setAmcStatus(foundAmcStatus);
            setDefaultTime(foundDefaultTime);
            setCapacity(foundCapacity);
            setAccountName(foundAccountName);
            setAccountstatus(foundAccountStatus);
            setStampingDate(foundStampingDate);
            setInstallationEngg(foundInstallationEngg);
            setBillingAddress(foundBillingAddress);
        }
    }, [props.getSingleRowFormData]);

    useEffect(() => {
        if (singleClientCompaint) {
            setStatus(singleClientCompaint.amC_app_rej_ID)
            setRemark(singleClientCompaint.remark || '')
        }
    }, [singleClientCompaint])

    return (
        <div>
            {/* Button to trigger the modal */}

            {/* Bootstrap Modal */}
            <div
                show={props.showStatus}
                onHide={props.handleShowStatus}
            >
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header add-user-title">
                            <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                            <h4 className="modal-title">AMC STATUS</h4>
                            {/* <button type="button" className="btn-close" aria-label="Close" onClick={closeModel}></button> */}
                        </div>
                        <div className="px-0 modal-body">
                            <div className="container-fluid add-user-container">
                                <div className="clients-table">
                                    <Row className='search-row order-details-row'>
                                        <Col md={4} className='cat-col'>
                                            <ul className="order-detail-list">
                                                <li><div className="amc-span1">Complaint </div> &nbsp;&nbsp;<div className="amc-span2"><b style={{ color: "#5842bc" }}>#{singleClientCompaint.clientComplaintsID}</b></div></li>
                                                <li><div className="amc-span1">Company</div> &nbsp;&nbsp;<div className="amc-span2"><b>{singleClientCompaint.clientName ? singleClientCompaint.clientName : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Machine Code</div> &nbsp;&nbsp;<div className="amc-span2"><b>{machineCode ? machineCode : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Machine Name</div> &nbsp;&nbsp;<div className="amc-span2"><b>{machineName ? machineName : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Category</div> &nbsp;&nbsp;<div className="amc-span2"><b>{category ? category : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Contract Start Date</div> &nbsp;&nbsp;<div className="amc-span2"><b>{contractStart ? contractStart : "NA"}</b></div></li>
                                            </ul>
                                        </Col>

                                        <Col md={4} className='cat-col' >
                                            <ul className="order-detail-list" >
                                                <li><div className="amc-span1">Contract End Date</div>&nbsp;&nbsp;<div className="amc-span2"><b>{contractEnd ? contractEnd : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Stamping CFT No</div>&nbsp;&nbsp;<div className="amc-span2"><b>{stampingNo ? stampingNo : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Warranty Start Date</div>&nbsp;&nbsp;<div className="amc-span2"><b>{warrantyStart ? warrantyStart : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Warranty End Date</div>&nbsp;&nbsp;<div className="amc-span2"><b>{warrantyEnd ? warrantyEnd : "NA"}</b></div></li>
                                                <li><div className="amc-span1">AMC Status</div> &nbsp;&nbsp;<div className="amc-span2"><b style={{ color: "#ff847f" }}>{amcStatus ? amcStatus : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Default Service Time</div>&nbsp;&nbsp;<div className="amc-span2"><b style={{ color: "#5842bc" }}>{defaultTime ? defaultTime : "NA"}</b></div></li>
                                            </ul>
                                        </Col>
                                        <Col md={4} className='cat-col' >
                                            <ul className="order-detail-list">
                                                <li><div className="amc-span1">Account Name</div> &nbsp;&nbsp;<div className="amc-span2"><b>{accountName ? accountName : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Account Status</div> &nbsp;&nbsp;<div className="amc-span2"><b>{accountStatus ? accountStatus : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Stamping Date</div> &nbsp;&nbsp;<div className="amc-span2"><b>{stampingDate ? stampingDate : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Installation Engineer</div> &nbsp;&nbsp;<div className="amc-span2"><b>{installationEngg ? installationEngg : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Billing Address</div> &nbsp;&nbsp;<div className="amc-span2"><b>{billingAddress ? billingAddress : "NA"}</b></div></li>
                                                <li><div className="amc-span1">Capacity</div> &nbsp;&nbsp;<div className="amc-span2"><b>{capacity ? capacity : "NA"}</b></div></li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-3">
                                    <span className="enable-status d-flex">
                                        <h6>Enable</h6>
                                        <span className="enable-radio d-flex">
                                            <div className="form-check enable-radio-status">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    id="approved"
                                                    value="Approved"
                                                    checked={status === 'Approved'}
                                                    onChange={handleStatusChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault1"
                                                >
                                                    Approved
                                                </label>
                                            </div>
                                            <div className="form-check enable-radio-status">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    id="rejected"
                                                    value="Rejected"
                                                    checked={status === 'Rejected'}
                                                    onChange={handleStatusChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault2"
                                                >
                                                    Rejected
                                                </label>
                                            </div>


                                        </span>

                                    </span>
                                    {status === "Rejected" && <div className="row add-user-image-container-div">
                                        <div className="col-lg-4 col">
                                            <div className="mb-3 mt-3">
                                                <FloatingLabel
                                                    label="Remark"
                                                    className={`${remark
                                                        ? "float-input-visible"
                                                        : "float-hidden float-input"
                                                        }`}
                                                >
                                                    <textarea
                                                        rows="3"
                                                        cols="20"
                                                        name="instruction"
                                                        wrap="hard"
                                                        className="form-control form-textarea add-product-input"
                                                        id="descriptionArea"
                                                        placeholder="Remark"
                                                        value={remark}
                                                        onChange={(e) => { setRemark(e.target.value) }}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="btns-save-cancel">
                                    <SBSaveUpdateBtn btnName="Save" onClickEffect={handleAmcStatus} />
                                    <SbCancelBtn
                                        btnName="Cancel"
                                        onClickEffect={() => {
                                            // props.handleClose();
                                            closeModel()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AnnualContractStatus;

