import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Footer from "../Footer";
import SrOverDue from "./SrOverDue";

function NewEscalation() {

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Page navigation
    let navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };
    // page navigation

    const [activeTab, setActiveTab] = useState('level1');

    return (
        <div id="main-page">
            <>
                {/* SIDEBAR START */}
                <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                {/* SIDEBAR END */}

                {/* TOPBAR START */}
                <Topbar
                    toggleMenu={toggleMenu}
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />
                {/* TOPBAR END */}

                {/* MAIN BODY START */}
                <div className="page-wrapper">
                    {/* PAGE CONTENT START */}
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            {/* Filter and Header Section */}
                            <div className="user-table">
                                {/* <Col md={3} className="cat-col"> */}
                                <Tabs
                                    activeKey={activeTab}
                                    id="uncontrolled-tab-example"
                                    className="local-claim-nav-tab"
                                    onSelect={(k) => setActiveTab(k)}
                                >
                                    <Tab
                                        eventKey="back"
                                        title={
                                            <div className="back-btn" onClick={goToBackPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                        }
                                    >
                                        Back
                                    </Tab>
                                    <Tab eventKey="level1" title="Escalation Level 1">
                                        {activeTab === 'level1' && <SrOverDue escalationLevel={1} />}
                                    </Tab>
                                    <Tab eventKey="level2" title="Escalation Level 2">
                                        {activeTab === 'level2' && <SrOverDue escalationLevel={2} />}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </>
        </div>
    );
}

export default NewEscalation;
