import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import * as HeaderData from "../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleClientCompalint, loadUserDropdown, updateComplaintStatus, updatePriorityData } from "../../redux/action";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";
import { priorityStatus } from "../DropdownsData";

export default function UpdatePriority(props) {

    let dispatch = useDispatch();
    const { singleClientCompaint } = useSelector((state) => state.data);

    const [selectedPriority, setSelectedPriority] = useState({})

    const closePriorityModal = () => {
        props.setShowPriority(false);
        // props.setCompaintId("")
        // props.fetchComplaintdata()
    };

    // const [updateData, setUpdateData] = useState({
    //     clientComplaintsID: 0, subscriptionID: "", userID: "", userFullName: "",
    //     addedByID: "", addedByUserFullName: "", addedOn: "", status: 0,
    //     clientIDFk: 0, date: "", productIDFK: 0, clientEmail: "", clientPhone: 0,
    //     issue: "", description: "", issueImage: "", inWarranty: 0, address: "",
    //     priority: 0, assingnedTo: "", adttenedByID: "", adttenedOn: "", solution: "",
    //     solutionImage: "",
    // });

    // useEffect(() => {
    //     if (singleClientCompaint) {
    //         setUpdateData(singleClientCompaint);
    //     }
    //     dispatch(loadUserDropdown())
    // }, [singleClientCompaint])

    useEffect(() => {
        if (singleClientCompaint) {
            priorityStatus.map(drop => {
                if (drop.value === singleClientCompaint.priority) {
                    setSelectedPriority(drop)
                }
            })
        }
    }, [singleClientCompaint, priorityStatus])

    const updateComplaints = (e) => {
        e.preventDefault();
        const data = {
            "clientComplaints_IDPK": singleClientCompaint.clientComplaintsID.toString(),
            "clientComplaints_Priority": selectedPriority.value.toString(),
            "clientComplaints_updatetoflag": "priority",
        }
        var postRequest = {
            method: "POST",
            mode: "cors",
            headers: HeaderData.postTravelizeAuth,
            body: JSON.stringify(data),
        };
        dispatch(updatePriorityData(postRequest, closePriorityModal, singleClientCompaint.clientComplaintsID));
    };

    return (
        <div>
            <div>
                <Modal
                    show={props.showPriority}
                    onHide={closePriorityModal}
                    className="category-modal-div"
                >
                    <Row className="modale-header-div">
                        <Col md={6} xs={9} className="cat-col">
                            <h6 className="page-header-text">Update Priority</h6>
                        </Col>
                        <Col md={6} xs={3} className="cat-col" align="right">
                            <button
                                onClick={closePriorityModal}
                                className="bi bi-x close-popup"
                            ></button>
                        </Col>
                    </Row>
                    <hr />
                    <div className="cat-col">
                        <Form>
                            <div className="mb-3">
                                {/* <Form.Label>Select User To Re-Assign</Form.Label> */}
                                <Select
                                    className="select-add-meeting-model"
                                    classNamePrefix="model-select-salesbee"
                                    options={priorityStatus}
                                    placeholder="Select Priority"
                                    value={selectedPriority.value ? selectedPriority : priorityStatus.value}
                                    onInputChange={priorityStatus.label}
                                    onChange={(data) => setSelectedPriority(data)}
                                    isSearchable={true}
                                />
                            </div>
                            <div className="sb-btn-section">
                                <SBSaveUpdateBtn onClickEffect={updateComplaints} btnName="Update Priority" />
                                <SbCancelBtn btnName="Cancel" onClickEffect={closePriorityModal} />
                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        </div>
    );
}
