import React, { useEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Accountsettings.scss";
import Topbar from "../../Topbar";
import { baseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";
import { loggedInRole } from "../../LoginRole";
import { Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import Footer from "../../Footer";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import { useDispatch } from "react-redux";
import { AddCheckInData, AddDirectVisitData } from "../../../redux/action";
import { AddCheckOutData } from "../../../redux/action";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

function AccountSettings() {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [mappingType, setMappingType] = useState(
    resPassData?.subscription?.clientMapType
  );
  const [meetingRange, setMeetingRange] = useState(resPassData?.allowMeetings);
  const [kilometerUpdateMessage, setKilometerUpdateMessage] = useState("");
  const [twoLevelClaimRes, setTwoLevelClaimRes] = useState("");
  const [mappingSettingRes, setMappingSettingRes] = useState("");

  const [twoLevelClaim, setTwoLevelClaim] = useState(
    resPassData?.subscription?.twoLevelClaim
  );

  let navigate = useNavigate();

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const setRangeforMeetingSubmit = () => {
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify({ status: meetingRange }),
    };
    fetch(`${baseURL}/api/Subscription/UpdateAllowMeeting`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setKilometerUpdateMessage(res.message);
          getLoginUserDetails();
        } else {
          setKilometerUpdateMessage(res.errorMessage);
        }
      });
    setTwoLevelClaimRes("");
  };

  const onValueChange = (e) => {
    setMeetingRange(e.target.value);
  };

  const onTwoLevelClaimChange = (e) => {
    setTwoLevelClaim(e.target.value);
  };

  const onMappingChange = (e) => {
    setMappingType(e.target.value);
  };

  const twoLevelClaimApprove = () => {
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify({ status: twoLevelClaim }),
    };
    fetch(
      `${baseURL}/api/Subscription/UpdateTwoLevelClaimStatus`,
      requestoption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("TWO-LEVEL-CLAIM-RES::", res);
        if (res.success) {
          setTwoLevelClaimRes(res.message);
          getLoginUserDetails();
        } else {
          setTwoLevelClaimRes(res.errorMessage);
        }
      });
    setKilometerUpdateMessage("");
  };

  const mappingTypeSetting = () => {
    let mapType = {
      subscriptionID: resPassData.subscriptionId,
      clientMapType: JSON.parse(mappingType),
    };
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(mapType),
    };
    fetch(`${baseURL}/api/Subscription/UpdateClientMapType`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("MAPPINGTYPE-SETTINGS-RES::", res);
        if (res.success) {
          setMappingSettingRes(res.message);
          getLoginUserDetails();
        } else {
          setMappingSettingRes(res.errorMessage);
        }
      });
    setKilometerUpdateMessage("");
    setTwoLevelClaimRes("");
  };

  let dispatch = useDispatch();

  const [baseLocationRestriction, setBaseLocationRestriction] = useState(resPassData.checkInOut_RadiusRestriction == 0 ? 'No' : 'Yes');
  const [baseLocationRestrictionForCheckOut, setBaseLocationRestrictionForCheckOut] = useState(resPassData.checkOut_RadiusRestriction == 0 ? 'No' : 'Yes');
  const [checkInRadius, setCheckInRadius] = useState(resPassData.checkInOut_RadiusRestriction);
  const [showConfirmationModalForCheckIn, setShowConfirmationModalForCheckIn] = useState(false);
  const [checkOutRadius, setCheckOutRadius] = useState(resPassData.checkOut_RadiusRestriction);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [chechInUpdate, setChechInUpdate] = useState("");
  const [chechOutUpdate, setChechOutUpdate] = useState("");
  const [chechOutManualEntryUpdate, setChechOutManualEntryUpdate] = useState("");
  const [directVisit, setDirectVisit] = useState(resPassData.directVisitRestrictions_InTime);
  const [showManualEntryModal, setShowManualEntryModal] = useState(false);

  const handleManualEntry = (e) => {
    console.log(e.target.value, 'setDirectVisit')
    setDirectVisit(+e.target.value)
  }
  // Function to handle "Update" button click
  const handleUpdateDirectVisit = () => {
    setShowManualEntryModal(true); // Show the confirmation modal
  };
  const handleYesUpdateDirectVisit = (e) => {
    updateManualEntryForInTime(e); // Call the updateCheckOutRadius function
    setShowManualEntryModal(false); // Close the confirmation modal
  };
  // Function to handle "No" button click in the confirmation modal
  const handleNoUpdateDirectVisit = () => {
    setShowManualEntryModal(false); // Close the confirmation modal
  };

  const updateManualEntryForInTime = (e) => {
    e.preventDefault();
    let data = {
      "subscriptionID": resPassData.subscriptionId,
      "status": directVisit.toString()
    }
    let request = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(data)
    }
    dispatch(AddDirectVisitData(request, setChechOutManualEntryUpdate))
    getLoginUserDetails();
  }


  // const handleBaseLocationRestrictionForCheckIn = (e) => {
  //   setBaseLocationRestriction(e.target.value)
  // }
  const handleBaseLocationRestrictionForCheckIn = (event) => {
    const { value } = event.target;
    setBaseLocationRestriction(value);

    if (value === "No") {
      setCheckInRadius(0); // Set the checkInRadius to zero when "No" is selected
    } else {
      setCheckInRadius(resPassData.checkInOut_RadiusRestriction)
    }
  };


  // Function to handle "Update" button click
  const handleUpdateCheckIn = () => {
    setShowConfirmationModalForCheckIn(true); // Show the confirmation modal
  };
  const handleYesUpdateCheckIn = (e) => {
    updateCheckInRadius(e); // Call the updateCheckOutRadius function
    setShowConfirmationModalForCheckIn(false); // Close the confirmation modal
    // setBaseLocationRestriction(false);
    // setCheckInRadius(0);
  };
  // Function to handle "No" button click in the confirmation modal
  const handleNoUpdateCheckIn = () => {
    setShowConfirmationModalForCheckIn(false); // Close the confirmation modal
  };
  const updateCheckInRadius = (e) => {
    e.preventDefault();
    let data = {
      "subscriptionID": resPassData.subscriptionId,
      "distanceRadius": parseFloat(checkInRadius)
    }
    let request = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(data)
    }
    dispatch(AddCheckInData(request, setChechInUpdate))
    getLoginUserDetails();
  }

  // Function to handle "Update" button click
  const handleUpdateClick = () => {
    setShowConfirmationModal(true); // Show the confirmation modal
  };
  const handleYesClick = (e) => {
    updateCheckOutRadius(e); // Call the updateCheckOutRadius function
    setShowConfirmationModal(false); // Close the confirmation modal
    // setBaseLocationRestrictionForCheckOut(false)
    // setCheckOutRadius(0);
  };
  // Function to handle "No" button click in the confirmation modal
  const handleNoClick = () => {
    setShowConfirmationModal(false); // Close the confirmation modal
  };


  const handleBaseRestrictionForCheckout = (event) => {
    const { value } = event.target;
    setBaseLocationRestrictionForCheckOut(value);

    if (value === "No") {
      setCheckOutRadius(0); // Set the setCheckOutRadius to zero when "No" is selected
    } else {
      setCheckOutRadius(resPassData.checkOut_RadiusRestriction);
    }
  };

  const updateCheckOutRadius = (e) => {
    e.preventDefault();
    let data = {
      subscriptionID: resPassData.subscriptionId,
      "distanceRadius": parseFloat(checkOutRadius)
    }
    let request = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(data)
    }
    dispatch(AddCheckOutData(request, setChechOutUpdate))
    getLoginUserDetails();
  }

  const getLoginUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/DashBoard/GetLoginUserDetails`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("USER-LOGIN-DETAILS::", res.result);
        const userLoginDetails = res.result;
        sessionStorage.setItem("usrlgndtls", JSON.stringify(userLoginDetails));
      });
    setTimeout(() => setKilometerUpdateMessage(""), 5000);
    setTimeout(() => setTwoLevelClaimRes(""), 5000);
    setTimeout(() => setMappingSettingRes(""), 5000);
    setTimeout(() => setChechInUpdate(""), 5000);
    setTimeout(() => setChechOutUpdate(""), 5000);
    setTimeout(() => setChechOutManualEntryUpdate(""), 5000);

    navigate("/settings/accountsettings");
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // useEffect(() => {
  //   if (chechInUpdate) {
  //     setTimeout(() => setChechInUpdate(""), 5000);
  //   }
  //   else if (chechOutUpdate) {
  //     setTimeout(() => setChechOutUpdate(""), 5000);
  //   } else if (chechOutManualEntryUpdate) {
  //     setTimeout(() => setChechOutManualEntryUpdate(""), 5000);
  //   }
  // }, [chechInUpdate, chechOutUpdate, chechOutManualEntryUpdate])

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}

        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">ACCOUNT SETTINGS</h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col"></Col>
              </Row>
            </div>
            {/* USER TABLE START */}
            <div className="account-settings-page">
              <div className="account-settings-page-body">
                <div className="mapping-page-added-list">
                  {loggedInRole !== 2 ? (
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 meeting-range-part mt-3">
                        <div className="meeting-range-head">
                          <h6>Meeting Range</h6>
                        </div>
                        <div className="mt-2">
                          <span className="fz13">
                            Allow the users to submit a meeting from range?
                          </span>
                          <span className="allow-meeting-range-select-radio d-flex">
                            <div className="form-check meeting-range-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="meeting-range-outside-area"
                                id="meetRange500"
                                value={0.6}
                                onChange={onValueChange}
                                checked={meetingRange == 0.6 ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="meetRange500"
                              >
                                500 Meter
                              </label>
                            </div>
                            <div className="form-check meeting-range-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="meeting-range-outside-area"
                                id="meetRange1km"
                                value={1}
                                onChange={onValueChange}
                                checked={meetingRange == 1 ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="meetRange1km"
                              >
                                1 KM
                              </label>
                            </div>
                            <div className="form-check meeting-range-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="meeting-range-outside-area"
                                id="meetRange2km"
                                value={2}
                                onChange={onValueChange}
                                checked={meetingRange == 2 ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="meetRange2km"
                              >
                                2 KM
                              </label>
                            </div>
                            <div className="form-check meeting-range-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="meeting-range-outside-area"
                                id="norestriction"
                                value={0}
                                onChange={onValueChange}
                                checked={meetingRange == 0 ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="norestriction"
                              >
                                No Restriction
                              </label>
                            </div>
                          </span>
                          <div className="input-group mt-3 mb-3">
                            <button
                              className="btn btn-success meeting-range-update"
                              onClick={setRangeforMeetingSubmit}
                            >
                              Update Range
                            </button>
                            {kilometerUpdateMessage != "" && (
                              <small
                                id="kilometerMsgSuccess"
                                className="form-text text-muted"
                              >
                                {kilometerUpdateMessage}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 claim-two-level-approval-part mt-3">
                        <div className="claim-two-aprroval">
                          <h6>Claim-Two Level Approval</h6>
                        </div>
                        <div className="mt-2">
                          <span className="fz13">
                            {" "}
                            Do you need claim approval for Admin level?
                          </span>
                          <span className="claim-two-level approval-enable-radio d-flex">
                            <div className="form-check approval-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="claim-two-approve"
                                id="claimadminYes"
                                value="Yes"
                                onChange={(e) => onTwoLevelClaimChange(e)}
                                checked={twoLevelClaim == "Yes" ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="claimadminYes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check approval-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="claim-two-approve"
                                id="claimadminNo"
                                value="No"
                                onChange={(e) => onTwoLevelClaimChange(e)}
                                checked={twoLevelClaim == "No" ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="claimadminNo"
                              >
                                No
                              </label>
                            </div>
                          </span>
                          <div className="input-group mt-3 mb-3">
                            <button
                              className="btn btn-success claim-approval-update"
                              onClick={twoLevelClaimApprove}
                            >
                              Update
                            </button>
                            {twoLevelClaimRes !== "" && (
                              <small
                                id="twolevelclaimMsgSuccess"
                                className="form-text text-muted"
                              >
                                {twoLevelClaimRes}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 set-client-mapping-settings mt-5">
                      <div className="client-mapping-setting-title">
                        <h6>Client Mapping</h6>
                      </div>
                      <div className="mt-2">
                        <span className="fz13">
                          {" "}
                          Please choose below option for client mapping type{" "}
                        </span>
                        <span className="allow-meeting-range-select-radio d-flex">
                          <div className="form-check meeting-range-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="use-mapping-type"
                              id="mappingYes"
                              value={-1}
                              onChange={onMappingChange}
                              checked={mappingType == -1 ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mappingYes"
                            >
                              Use Mapping
                            </label>
                          </div>
                          <div className="form-check meeting-range-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="use-mapping-type"
                              id="addedbyUser"
                              value={0}
                              onChange={onMappingChange}
                              checked={mappingType == 0 ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="addedbyUser"
                            >
                              Added by User
                            </label>
                          </div>

                          <div className="form-check meeting-range-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="use-mapping-type"
                              id="noRule"
                              value={6}
                              onChange={onMappingChange}
                              checked={mappingType == 6 ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="noRule"
                            >
                              No Rule <br />
                              <small className="fz12">
                                (All Company to All Users)
                              </small>
                            </label>
                          </div>
                        </span>
                        <div className="input-group mt-3 mb-3">
                          <button
                            className="btn btn-success mappingtype-update"
                            onClick={mappingTypeSetting}
                          >
                            Update
                          </button>
                          {mappingSettingRes !== "" && (
                            <small
                              id="twolevelclaimMsgSuccess"
                              className="form-text text-muted"
                            >
                              {mappingSettingRes}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 claim-two-level-approval-part mt-5">
                      {showManualEntryModal && (
                        <Modal
                          show={showManualEntryModal}
                          onHide={handleNoUpdateDirectVisit}
                          className="logout-modal-div"
                        >
                          <div className="cat-col">
                            <div>
                              <div className="log-out-div">
                                <h6>Are you sure want to update account settings?</h6>
                              </div>
                              {/* <hr /> */}
                              <div className="sb-logout-btn">
                                <SBSaveUpdateBtn onClickEffect={handleYesUpdateDirectVisit} btnName="Yes" />
                                <SbCancelBtn btnName="No" onClickEffect={handleNoUpdateDirectVisit} />
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}
                      <div className="claim-two-aprroval">
                        <h6>Direct Visit</h6>
                      </div>
                      <div className="mt-2">
                        <span className="fz13">
                          {" "}
                          Do you want to allow the manual entry for in-time?
                        </span>
                        <span className="claim-two-level approval-enable-radio d-flex">
                          <div className="form-check approval-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="manualEntry"
                              id="manualEntryYes"
                              value={1}
                              onChange={handleManualEntry}
                              checked={directVisit === 1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="manualEntryYes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check approval-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="manualEntry"
                              id="manualEntryNo"
                              value={0}
                              onChange={handleManualEntry}
                              checked={directVisit === 0}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="manualEntryNo"
                            >
                              No
                            </label>
                          </div>
                        </span>
                        <div className="input-group mt-3 mb-3">
                          <button
                            className="btn btn-success claim-approval-update"
                            onClick={handleUpdateDirectVisit}
                          >
                            Update
                          </button>
                          {chechOutManualEntryUpdate !== "" && (
                            <small
                              id="twolevelclaimMsgSuccess"
                              className="form-text text-muted"
                            >
                              {chechOutManualEntryUpdate}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 claim-two-level-approval-part mt-5">
                      {showConfirmationModalForCheckIn && (
                        <Modal
                          show={showConfirmationModalForCheckIn}
                          onHide={handleNoUpdateCheckIn}
                          className="logout-modal-div"
                        >
                          <div className="cat-col">
                            <div>
                              <div className="log-out-div">
                                <h6>Are you sure want to update account settings?</h6>
                              </div>
                              {/* <hr /> */}
                              <div className="sb-logout-btn">
                                <SBSaveUpdateBtn onClickEffect={handleYesUpdateCheckIn} btnName="Yes" />
                                <SbCancelBtn btnName="No" onClickEffect={handleNoUpdateCheckIn} />
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}
                      <div className="claim-two-aprroval">
                        <h6>Base Location Restriction for Check-In</h6>
                      </div>
                      <div className="mt-2">
                        <span className="fz13">
                          {" "}
                          Do you need to restrict the base location for check-in?
                        </span>
                        <span className="claim-two-level approval-enable-radio d-flex">
                          <div className="form-check approval-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="baseLocationRestriction"
                              id="baseLocationRestrictionForCheckInYes"
                              value="Yes"
                              onChange={handleBaseLocationRestrictionForCheckIn}
                              checked={baseLocationRestriction === "Yes"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="baseLocationRestrictionForCheckInYes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check approval-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="baseLocationRestriction"
                              id="baseLocationRestrictionForCheckInNo"
                              value="No"
                              onChange={handleBaseLocationRestrictionForCheckIn}
                              checked={baseLocationRestriction === "No"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="baseLocationRestrictionForCheckInNo"
                            >
                              No
                            </label>
                          </div>
                        </span>
                        {baseLocationRestriction === "Yes" && (<div className="col-lg-6 col">
                          <div className="mb-3 mt-2">
                            <FloatingLabel
                              label="Define Radius for Check-In (km's)"
                              className={`${checkInRadius ? "float-input-visible" : "float-hidden float-input"
                                }`}
                            >
                              <input
                                type="number"
                                className="form-control add-user-input"
                                placeholder="Enter check-in radius (km's)"
                                value={checkInRadius}
                                onChange={(event) => setCheckInRadius(event.target.value)}
                              />
                            </FloatingLabel>
                          </div>
                        </div>)}
                        <div className="input-group mt-3 mb-3">
                          <button
                            className="btn btn-success claim-approval-update"
                            onClick={handleUpdateCheckIn}
                          >
                            Update
                          </button>
                          {chechInUpdate !== "" && (
                            <small
                              id="twolevelclaimMsgSuccess"
                              className="form-text text-muted"
                            >
                              {chechInUpdate}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 claim-two-level-approval-part mt-5">
                      {showConfirmationModal && (
                        <Modal
                          show={showConfirmationModal}
                          onHide={handleNoClick}
                          className="logout-modal-div"
                        >
                          <div className="cat-col">
                            <div>
                              <div className="log-out-div">
                                <h6>Are you sure want to update account settings?</h6>
                              </div>
                              {/* <hr /> */}
                              <div className="sb-logout-btn">
                                <SBSaveUpdateBtn onClickEffect={handleYesClick} btnName="Yes" />
                                <SbCancelBtn btnName="No" onClickEffect={handleNoClick} />
                              </div>
                            </div>
                          </div>
                        </Modal>
                      )}
                      <div className="claim-two-aprroval">
                        <h6>Base Location Restriction for Check-Out</h6>
                      </div>
                      <div className="mt-2">
                        <span className="fz13">
                          {" "}
                          Do you need to restrict the base location for check-out?
                        </span>
                        <span className="claim-two-level approval-enable-radio d-flex">
                          <div className="form-check approval-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="baseLocationRestrictionCheckOut"
                              id="baseLocationRestrictionForCheckOutYes"
                              value="Yes"
                              checked={baseLocationRestrictionForCheckOut === "Yes"}
                              onChange={handleBaseRestrictionForCheckout}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="baseLocationRestrictionForCheckOutYes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check approval-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="baseLocationRestrictionCheckOut"
                              id="baseLocationRestrictionForCheckOutNo"
                              value="No"
                              checked={baseLocationRestrictionForCheckOut === "No"}
                              onChange={handleBaseRestrictionForCheckout}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="baseLocationRestrictionForCheckOutNo"
                            >
                              No
                            </label>
                          </div>
                        </span>
                        {baseLocationRestrictionForCheckOut === "Yes" && (<div className="col-lg-6 col">
                          <div className="mb-3 mt-2">
                            <FloatingLabel
                              label="Define Radius for Check-Out (km's)"
                              className={`${checkOutRadius ? "float-input-visible" : "float-hidden float-input"
                                }`}
                            >
                              <input
                                type="number"
                                className="form-control add-user-input"
                                placeholder="Define check-out radius (km's)"
                                value={checkOutRadius}
                                onChange={(event) => setCheckOutRadius(event.target.value)}
                              />
                            </FloatingLabel>
                          </div>
                        </div>)}
                        <div className="input-group mt-3 mb-3">
                          <button
                            className="btn btn-success claim-approval-update"
                            onClick={handleUpdateClick}
                          >
                            Update
                          </button>
                          {chechOutUpdate !== "" && (
                            <small
                              id="twolevelclaimMsgSuccess"
                              className="form-text text-muted"
                            >
                              {chechOutUpdate}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* USER TABLE END */}

            {/* PAGE CONTENT END*/}
          </div>
        </div>
      </div>
      {/* MAIN BODY END */}
      <Footer />
    </div >
  );
}

export default AccountSettings;
