import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useRef, useState } from "react";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { getRandomColor } from "../FormattedAmount";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { baseURL } from "../BaseUrl";

function CustomerFeedBackTable(props) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tootTipId, setTooltipId] = useState(null);
    const buttonRef = useRef(null);

    const renderTooltip = (text) => (
        <Tooltip id="tooltip">
            {text}
        </Tooltip>
    );

    const handleClick = (id) => {
        setShowTooltip(!showTooltip);
        setTooltipId(id);
    };
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className="table-main-div"
            >
                <TableContainer
                    sx={{ maxHeight: 440 }}
                // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="basefare-table"
                    >
                        <TableHead className="custom-table-header">
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    <TableCell
                                        key={i}
                                        align={name.align}
                                        className={`thead-cell-products`}
                                        style={{ minWidth: name.width }}
                                    >
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                            <TableBody>
                                {props.tableHeader.map((name, i) => (
                                    <SkeletonTableData key={i} />
                                ))}
                            </TableBody>
                        ) : (
                            <>
                                {props.getCustomerFeedback && props.getCustomerFeedback.length === 0 ? (
                                    <TableBody>
                                        <TableRow align="center">
                                            <TableCell
                                                align="center"
                                                colSpan={props.tableHeader.length}
                                            >
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img
                                                        src="../../../../images/DefaultImages/NodataFound.jpg"
                                                        alt=""
                                                        style={{ width: "10%" }}
                                                    />
                                                    <br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {props.getCustomerFeedback &&
                                            props.getCustomerFeedback.map((data, i) => (
                                                <TableRow
                                                    align="center"
                                                    key={i}
                                                    className="client-list-row-data"
                                                >
                                                    <TableCell>
                                                        <div className="d-flex">
                                                            <img
                                                                className="table-data-users-imgages company-logo"
                                                                src="https://accounts.metricinfo.com/static/media/Default_company.1b81cea453b5127f3cae.jpg"
                                                                alt="client-profile"
                                                            />
                                                            <div onClick={props.viewFeedback} style={{ cursor: "pointer" }}>
                                                                <b>{data.mobile_user_name ?? "NA"}</b>
                                                                <br />
                                                                {data.Phone_number}
                                                                <br />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {data.date}
                                                    </TableCell>
                                                    <TableCell>
                                                        {data.issuetype}
                                                    </TableCell>
                                                    <TableCell>
                                                        {data.product}
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>
                                                            {data.issue ? (
                                                                data.issue.length > 100
                                                                    ? `${data.issue.substring(0, 100)}...`
                                                                    : data.issue
                                                            ) : (
                                                                "NA"
                                                            )}
                                                            &nbsp;&nbsp;
                                                            {data.issue && data.issue.length > 100 && (
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={renderTooltip(data.issue)}
                                                                    show={showTooltip && tootTipId === data.id}
                                                                >
                                                                    <i
                                                                        ref={buttonRef}
                                                                        id="info"
                                                                        className="fa fa-info-circle details-icon"
                                                                        onClick={() => handleClick(data.id)}
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </OverlayTrigger>
                                                            )}
                                                        </b>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        {/* {props.loading &&
                                              <OnScrollLoading />
                                          } */}
                                    </TableBody>
                                )}
                            </>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </div >
    );
}

export default CustomerFeedBackTable;
