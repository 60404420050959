import { useEffect, useRef, useState } from "react";
import "../../styles/AddUserDetails.scss";
import { FloatingLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import "../../styles/AddUserModel.scss";
import { Link } from "react-router-dom";
import SalesBee from "../../images/MatricLogo.svg";
import Multiselect from "multiselect-react-dropdown";
import profileimg from "../../images/profile-1.png";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { baseURL } from "../BaseUrl";
import { salesbeeImgAuth, travelizeAuth } from "../HeaderAuthentication";
import { loadCityList, loadStateList, loadUserRegion } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import * as swal from "../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Geocode from "react-geocode";
import { React_Google_API_Key } from "../../GoogleApiKey";
import { textNameValidation } from "../FormattedAmount";
import { NumberValidation } from "../ValidationFormat";
import { optionsMapByListModel } from "../DropdownsData";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
Geocode.setApiKey(React_Google_API_Key);

const AddUsersData = (props) => {
  let dispatch = useDispatch();

  const { userRegionResult } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);

  const [fullname, setFullname] = useState("");
  const [usercode, setUserCode] = useState("");
  const [emailaddress, setEmailAddress] = useState("");
  const [mobilenum, setMobileNum] = useState("");
  const [location, setLocation] = useState("");
  const [countryCode, setCountryCode] = useState({
    label: "+91",
    value: "+91",
  });
  const [manager, setManager] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);
  const [imgFile, setImgFile] = useState("");
  const [country, setCountry] = useState({ label: "India", value: "IND" });
  const [localImage, setLocalImage] = useState("");
  const [role, setRole] = useState(0);
  const [stateForHoliday, setStateForHoliday] = useState({ label: "", value: "" });
  const [shift, setShift] = useState(null);
  const [imgFileName, setImgFileName] = useState("");
  const [zone, setZone] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateListDropdown, setStateListDropdown] = useState([]);
  const [cityLists, setCityList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [zoneIds, setZoneIds] = useState([]);
  const [stateIds, setStateIds] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [searchVal, setSearchVal] = useState(null);
  const [address, setAddress] = useState(null);
  const [mapById, setMapById] = useState(3);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });
  const [designationList, setDesignationList] = useState([]);
  const [reportDesignation, setReportDesignation] = useState(0);
  const [minMeetingRange, setMinMeetingRange] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });
  const [branch, setBranch] = useState({ value: "", label: "" })

  //   const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
  //   const [showMachineDetailsHeader, setShowMachineDetailsHeader] =
  //     useState(true);
  //   const [activeMenu, setActiveMenu] = useState(1);

  //   const toggleMachineDetails = () => {
  //     setShowMachineDetailsForm(true);
  //     setShowMachineDetailsHeader(true);
  //     setActiveMenu(1);
  //   };

  /*  GET LIVE LOCATION BY SYSTEM START  */
  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported for your browser");
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLan({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    }
  };
  /*  GET LIVE LOCATION BY SYSTEM END  */

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
    setAddress("");
  };

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    dispatch(loadUserRegion());
    dispatch(loadStateList(0));
  }, []);

  useEffect(() => {
    if (Object.keys(userRegionResult).length > 0) {
      setCountryCodeList(
        userRegionResult?.allCountryCode.map((data) => {
          return { value: data?.code, label: data?.code };
        })
      );
    }
    fetchDropdownData();
  }, [reportDesignation, userRegionResult]);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Zone/GetUserRegionsResult`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setCountryList(
            res.result?.allCountry.map((data) => {
              return {
                value: data.countryID,
                label: data.countryName,
              };
            })
          );
          setZoneList(res.result?.allZones);
        } else {
          setZoneList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });

    // SHIFT LIST API CALL
    fetch(`${baseURL}/api/Zone/BindShiftDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShiftList(
            res.result.map((data) => {
              return { value: data.shiftID, label: data.shiftName };
            })
          );
        } else {
          setShiftList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    // SHIFT LIST API CALL

    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result
              .filter((desig) => desig.designationLevel !== 1)
              .map((data) => {
                return {
                  value: data.designationID,
                  label: data.designationName,
                };
              })
          );
        } else {
          setDesignationList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result.map((data) => {
              return { value: data.roleID, label: data.roleName };
            })
          );
        } else {
          setRoleList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Department/BindDepartmentDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDepartmentList(
            res.result.map((data) => {
              return { value: data.departmentID, label: data.departmentName };
            })
          );
        } else {
          setDepartmentList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(
      `${baseURL}/api/User/BindTopManagerDropDown/${reportDesignation}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  useEffect(() => {
    fetchDropdownByZone();
  }, [zoneIds, stateIds]);

  const fetchDropdownByZone = () => {
    let stateResponse = userRegionResult?.allStates;
    setStateListDropdown(
      stateResponse
        ?.filter((z) => zoneIds.includes(z.zoneId))
        ?.map((item) => item) ?? []
    );
    let cityResponse = userRegionResult?.allCities;
    setCityList(
      cityResponse
        ?.filter((s) => stateIds.includes(s.stateId))
        ?.map((item) => item) ?? []
    );
  };
  //* FETCH DROPDOWN DATA END *//

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const [errorMessageRange, setErrorMessageRange] = useState();
  const handleChangeRange = (e) => {
    const inputValue = e.target.value;
    if (parseFloat(inputValue) < 0.5) {
      setErrorMessageRange("Minimum range should be 0.5 or above");
    } else {
      setErrorMessageRange("");
      setMinMeetingRange(inputValue);
    }
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  //*  ADDING PROFILE IMAGE BY USER START *//
  const addProfileImg = (e) => {
    e.preventDefault();
    if (imgFile !== null && imgFile !== "") {
      const formData = new FormData();
      formData.append("image", imgFile);

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Upload/UploadProfile?directory=Profile`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          console.log("IMAGE-USER-RES::", res);
          if (res.success) {
            var imgUrl = res.result[0];
            addUser(imgUrl);
          } else {
            setLocalImage("");
            setImgFileName(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
        });
    } else {
      addUser();
    }
  };
  //*  ADDING PROFILE IMAGE BY USER END  *//

  //*  ADDING USER START  *//
  const addUser = (imgUrl) => {
    let currentYear = new Date().getFullYear();
    if (
      countryCode !== "" &&
      mobilenum.toString().length == 10 &&
      role !== "" &&
      location !== "" &&
      country !== "" &&
      stateIds !== "" &&
      stateIds.length > 0 &&
      zoneIds !== "" &&
      zoneIds.length > 0
    ) {
      const addUserDetails = {
        desingation: {
          designationID: reportDesignation,
        },
        fullName: fullname,
        email: emailaddress,
        profilePic: imgUrl,
        countryCode: countryCode.value,
        contact: JSON.parse(mobilenum),
        password: `MetricInfo_${currentYear}`,
        code: usercode,
        activeStatus: 1,
        enableStatus: enableStatus,
        // allowMeetings: "YES",
        managerId: manager,
        shiftID: shift,
        mapType: mapById,
        allowMeetings: minMeetingRange,
        baseLoc: {
          latitude: JSON.stringify(latLan.lat),
          longitude: JSON.stringify(latLan.lng),
          location: location,
        },
        stateSpecificHoliday: stateForHoliday.label,
        branch: branch.label,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(addUserDetails),
      };

      fetch(`${baseURL}/api/User/AddUser`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            let resUserId = res.result.replace("Success|", "");
            addZoneStateCity(resUserId);
            props.handleClose();
            props.getUserDetails();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            // props.handleClose();
            // swal.showAlert("Fail", `${res.errorMessage}`, "warning");
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          // console.log("ERROR :", error);
          // swal.showAlert("Fail", `${error}`, "warning");
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required !",
      });
    }
  };
  //*  ADDING USER END  *//

  //  ADDING ZONE STATE AND CITY BY USER START
  const addZoneStateCity = (resUserId) => {
    const zoneStateCity = city.map((item) => {
      return {
        ...item,
        userId: resUserId,
      };
    });
    state.forEach((s_state) => {
      if (
        zoneStateCity.filter((zsc) => zsc.stateId == s_state.stateId).length ==
        0
      ) {
        zoneStateCity.push({ ...s_state, userId: resUserId });
      }
    });
    zone.forEach((z_Zone) => {
      if (
        zoneStateCity.filter((zsc) => zsc.zoneId == z_Zone.zoneId).length == 0
      ) {
        zoneStateCity.push({ ...z_Zone, userId: resUserId });
      }
    });

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(zoneStateCity),
    };
    fetch(`${baseURL}/api/Zone/AddUserRegions`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        console.log("ZONEADDED-RES::", res);
      });
  };
  //  ADDING ZONE STATE AND CITY BY USER END

  // MULTI SELECT DROPDOWN FUNCTION START
  const handleSelectZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };
  const handleRemoveZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };
  const handleSelectState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };
  const handleRemoveState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };
  const handleSelectCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };
  const handleRemoveCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };
  // MULTI SELECT DROPDOWN FUNCTION END

  // GOOGLE MAPS LOCATION START
  useEffect(() => {
    if (searchVal) {
      Geocode.fromAddress(searchVal).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setLatLan({
            lat: lat,
            lng: lng,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [searchVal]);

  const onMarkerDragEnd = (e) => {
    setLatLan({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const searchLocation = (event) => {
    setSearchVal(event.target.value);
  };

  useEffect(() => {
    if (latLan) {
      Geocode.fromLatLng(latLan.lat, latLan.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // console.log("GOOGLE-ADDRESS-PLACEHOLDER:", address);
          setAddress(response.results[0].formatted_address);
          setLocation(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      addressFromGoogle();
    }
  }, [latLan]);

  const addressFromGoogle = () => {
    openGoogleMaps();
  };
  // GOOGLE MAPS LOCATION END

  useEffect(() => {
    dispatch(loadCityList())
  }, [])

  const closeModel = () => {
    props.handleClose();
  };

  return (
    <div show={props.addusermodelshow} onHide={props.handleClose}>
      <div className="add-user-details-container">
        <div className="add-user-details-first-div">
          <div className="machines-logo-div">
            <div className="logotext">
              <div className="logo profile-mini">
                <Link className="travelize_logo" to="/dashboard">
                  <img
                    src={SalesBee}
                    alt="product-title-img"
                    className="salesbee-logo"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="add-user-details-menus"
          // onClick={toggleMachineDetails}
          >
            <div
              className="add-user-details-menu-numbers active"
            //   ${
            // activeMenu === 1 ? "active" : "inactive"
            //   }`}
            >
              <p className="add-user-details-menu-number-one">
                <span>01</span>
              </p>
              <div className="add-user-details-menu-name"> Employee Details</div>
            </div>
          </div>
        </div>
        <div className="add-user-details-second-div">
          <div className="add-user-details-second-div-header">
            <div className="back-btn" onClick={closeModel}>
              <img
                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                alt=""
              />
            </div>
            <div>
              <h4>Employee Details</h4>
              <p>Please enter employee infomation and proceed to the next step.</p>
            </div>
          </div>

          <div style={{ position: " relative" }} className="amc-single-image">
            <form onSubmit={addProfileImg}>
              <div className="row">
                <div className="col-lg-3 col">
                  <div className="add-user-main-logo-image">
                    <img
                      className="add-user-images"
                      src={localImage ? localImage : profileimg}
                      alt="Profile Images"
                    />
                    <div>
                      <button
                        type="button"
                        className="btn upload-logo"
                        onClick={uploadClick}
                      >
                        <i className="bi bi-camera-fill" />
                      </button>
                      <input
                        id="getFile"
                        type="file"
                        name="filetobase64"
                        accept="image/*"
                        className="btn-primary upload"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col">
                  <div>
                    <div className="row add-user-image-container-div">
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Full Name *"
                            className={`${fullname
                              ? "float-input-visible"
                              : "float-hidden float-input"
                              }`}
                          >
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control add-user-input"
                              id="exampleFormControlInput1"
                              placeholder="Full Name *"
                              value={fullname}
                              onChange={(e) => {
                                setFullname(e.target.value);
                              }}
                              onKeyDown={(e) => textNameValidation(e)}
                            // required
                            />
                          </FloatingLabel>
                          {fieldReq && (fullname == null || fullname == "") ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Employee Code *"
                            className={`${usercode
                              ? "float-input-visible"
                              : "float-hidden float-input"
                              }`}
                          >
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control add-user-input"
                              id="exampleFormControlInput1"
                              placeholder="Employee Code *"
                              value={usercode}
                              onChange={(e) => setUserCode(e.target.value)}
                            />
                          </FloatingLabel>
                          {fieldReq && (usercode == null || usercode == "") ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="row add-user-image-container-div"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="E-Mail Address *"
                            className={`${emailaddress
                              ? "float-input-visible"
                              : "float-hidden float-input"
                              }`}
                          >
                            <input
                              type="email"
                              autoComplete="off"
                              className="form-control add-user-input"
                              id="exampleFormControlInput1"
                              placeholder="E-Mail Address *"
                              value={emailaddress}
                              onChange={(e) => setEmailAddress(e.target.value)}
                              required
                            />
                          </FloatingLabel>
                          {fieldReq &&
                            (emailaddress == null || emailaddress == "") ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <div className="input-mobile-field d-flex">
                            <div className="country-code">
                              <FloatingLabel
                                label="Code *"
                                className={`${countryCode
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                                  }`}
                              >
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={countryCodeList}
                                  placeholder="Code *"
                                  value={countryCodeList.find((option) => {
                                    return option.label === countryCode.label;
                                  })}
                                  onInputChange={countryCodeList.label}
                                  onChange={(data) =>
                                    setCountryCode(data.value)
                                  }
                                  isSearchable={true}
                                // required
                                />
                              </FloatingLabel>
                              {fieldReq &&
                                (countryCode == null || countryCode == "") ? (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}&nbsp;
                                  <i className="fa fa-exclamation-circle" />
                                </small>
                              ) : (
                                ""
                              )}
                            </div>

                            <div
                              className="mobile-num"
                              style={{ width: "100%" }}
                            >
                              <FloatingLabel
                                label="Mobile *"
                                className={`${mobilenum
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <input
                                  type="tel"
                                  autoComplete="off"
                                  className="form-control add-user-input"
                                  id="exampleFormControlInput1"
                                  placeholder="Mobile *"
                                  maxLength={10}
                                  onChange={(e) => setMobileNum(e.target.value)}
                                  onKeyDown={NumberValidation}
                                />
                              </FloatingLabel>
                              {fieldReq && mobilenum.length !== 10 ? (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  {`${alertMessage.message} - 10 Numbers`}&nbsp;
                                  <i className="fa fa-exclamation-circle" />
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row add-user-image-container-div">
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Designation *"
                            className={`${reportDesignation
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              defaultValue={designationList[null]}
                              options={designationList}
                              placeholder="Designation *"
                              value={designationList.value}
                              onInputChange={designationList.label}
                              onChange={(data) =>
                                setReportDesignation(data.value)
                              }
                              isSearchable={true}
                            // required
                            />
                          </FloatingLabel>
                          {(fieldReq && reportDesignation === "") ||
                            (fieldReq && reportDesignation === 0) ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Reporting *"
                            className={`${manager
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              options={managerList}
                              placeholder="Select Reporting *"
                              value={managerList.value}
                              onInputChange={managerList.label}
                              onChange={(data) => setManager(data.value)}
                              isSearchable={true}
                              autoComplete={false}
                            // required
                            />
                          </FloatingLabel>
                          {(fieldReq && manager === "") ||
                            (fieldReq && manager === 0) ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row add-user-image-container-div">
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Country *"
                            className={`${country
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              options={countryList}
                              placeholder="Select Country *"
                              value={countryList.find(
                                (option) => option.label === country.label
                              )}
                              onInputChange={countryList.label}
                              onChange={(data) => setCountry(data)}
                              isSearchable={true}
                            // required
                            />
                          </FloatingLabel>
                          {fieldReq && country === "" ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Zone *"
                            className={`${zone.length !== 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Multiselect
                              className="select-add-user-model zone model-select"
                              options={zoneList} // Options to display in the dropdown
                              selectedValues={zone} // Preselected value to persist in dropdown
                              onSelect={handleSelectZone} // Function will trigger on select event
                              onRemove={handleRemoveZone} // Function will trigger on remove event
                              showCheckbox={true}
                              displayValue="zoneName" // Property name to display in the dropdown options
                              placeholder={"Select Zone *"}
                              emptyRecordMsg="No options available"
                              avoidHighlightFirstOption={true}
                              closeIcon="cancel"
                              showArrow={true}
                              selectionLimit={3}
                              closeOnSelect={true}
                              hidePlaceholder={true}
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                    </div>
                    <div className="row add-user-image-container-div">
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select States *"
                            className={`${state.length !== 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Multiselect
                              className="select-add-user-model"
                              options={stateListDropdown} // Options to display in the dropdown
                              selectedValues={state} // Preselected value to persist in dropdown
                              onSelect={handleSelectState} // Function will trigger on select event
                              onRemove={handleRemoveState} // Function will trigger on remove event
                              showCheckbox={true}
                              displayValue="stateName" // Property name to display in the dropdown options
                              placeholder={"Select States *"}
                              emptyRecordMsg="No options available"
                              avoidHighlightFirstOption={true}
                              closeIcon="cancel"
                              groupBy="zoneName"
                              showArrow={true}
                              hidePlaceholder={true}
                            />
                          </FloatingLabel>
                          {fieldReq && stateIds.length <= 0 ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Cities *"
                            className={`${city.length !== 0
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Multiselect
                              className="select-add-user-model"
                              options={cityLists} // Options to display in the dropdown
                              selectedValues={city} // Preselected value to persist in dropdown
                              onSelect={handleSelectCity} // Function will trigger on select event
                              onRemove={handleRemoveCity} // Function will trigger on remove event
                              showCheckbox={true}
                              displayValue="cityName" // Property name to display in the dropdown options
                              placeholder={"Select Cities *"}
                              emptyRecordMsg="No options available"
                              avoidHighlightFirstOption={true}
                              closeIcon="cancel"
                              groupBy="stateName"
                              showArrow={true}
                              hidePlaceholder={true}
                            />
                          </FloatingLabel>
                          {fieldReq && cityIds.length <= 0 ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row add-user-image-container-div">
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Shift *"
                            className={`${shift
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              defaultValue={shiftList[null]}
                              options={shiftList}
                              placeholder="Select Shift"
                              value={shiftList.value}
                              onInputChange={shiftList.label}
                              onChange={(data) => setShift(data.value)}
                              isSearchable={true}
                            />
                          </FloatingLabel>
                          {fieldReq && (shift == null || shift == "") ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Map By Method *"
                            className={`${mapById
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              defaultValue={optionsMapByListModel[2]}
                              options={optionsMapByListModel}
                              placeholder="Map User By :"
                              value={optionsMapByListModel.value}
                              onInputChange={optionsMapByListModel.label}
                              onChange={(data) => setMapById(data.value)}
                              isSearchable={true}
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                    </div>
                    <div className="row add-user-image-container-div">
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="State-Specific Holiday *"
                            className={`${stateForHoliday.value !== ""
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              options={stateList}
                              placeholder="Select State-Specific Holiday"
                              value={stateForHoliday.value && stateForHoliday}
                              onInputChange={stateList.label}
                              onChange={(data) =>
                                setStateForHoliday(data)
                              }
                              isSearchable={true}
                              autoComplete={false}
                            // required
                            />
                          </FloatingLabel>
                          {/* {(fieldReq && stateForHoliday === "") ||
                            (fieldReq && stateForHoliday === 0) ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </div>
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Minimum Range For Meetings(KMs)"
                            className={`${minMeetingRange
                              ? "float-input-visible"
                              : "float-hidden float-input"
                              }`}
                          >
                            <input
                              type="text"
                              className="form-control add-user-input"
                              id="exampleFormControlInput1"
                              placeholder="Minimum Range For Meetings(KMs)"
                              value={minMeetingRange}
                              onChange={handleChangeRange}
                            />
                          </FloatingLabel>
                          {errorMessageRange && (
                            <div style={{ color: "red" }}>
                              {errorMessageRange}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row add-user-image-container-div">
                      <div className="col-lg-6 col">
                        <div className="mb-3 input-address-location">
                          <FloatingLabel
                            label="Location *"
                            className={`${location
                              ? "float-input-visible"
                              : "float-hidden float-input"
                              }`}
                          >
                            <textarea
                              rows="3"
                              cols="20"
                              name="locationtxt"
                              wrap="hard"
                              className="form-control form-textarea add-user-input"
                              id="exampleFormControlInput1"
                              placeholder="Location (Please click the icon for location)"
                              value={location}
                              readOnly={true}
                            // required
                            // onChange={(e) => setLocation(e)}
                            />
                          </FloatingLabel>
                          {fieldReq && location === "" ? (
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}&nbsp;
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          ) : (
                            ""
                          )}
                          <OverlayTrigger
                            placement={"right"}
                            delay={{ show: 250, hide: 300 }}
                            overlay={
                              <Tooltip id={`tooltip-googlemap`}>
                                Google Maps
                              </Tooltip>
                            }
                          >
                            <div>
                              <i
                                className="bi bi-geo-alt"
                                onClick={openGoogleMaps}
                              />
                            </div>
                          </OverlayTrigger>
                        </div>

                      </div>
                      <div className="col-lg-6 col">
                        <div className="mb-3">
                          <FloatingLabel
                            label="Select Branch *"
                            className={`${branch.value !== ""
                              ? "float-visible-select"
                              : "float-hidden float-select"
                              }`}
                          >
                            <Select
                              className="react-select-container-list model-select"
                              classNamePrefix="react-select-list"
                              options={cityList}
                              placeholder="Select Branch"
                              value={branch.value && branch}
                              onInputChange={cityList.label}
                              onChange={(data) =>
                                setBranch(data)
                              }
                              isSearchable={true}
                              autoComplete={false}
                            // required
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-page-save-cancel">
                <SBSaveUpdateBtn btnName="Save" />
                <SbCancelBtn
                  btnName="Cancel"
                  onClickEffect={() => {
                    props.handleClose();
                    setLocation("");
                  }}
                />
              </div>
            </form>
            {googleMapsModel && (
              // <LoadScript googleMapsApiKey={React_Google_API_Key}>
              <GoogleMapAddressModel
                googleMapsModel={googleMapsModel}
                addressFromGoogle={addressFromGoogle}
                openGoogleMaps={openGoogleMaps}
                latLan={latLan}
                searchLocation={searchLocation}
                searchVal={searchVal}
                onMarkerDragEnd={onMarkerDragEnd}
                address={address}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUsersData;
