import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { format } from "date-fns";
import moment from "moment";
import React from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";

function ElementSetting(props) {
  let selectedField = props.selectedField;

  const hasActiveCountryCode = (field) => {
    if (field.field_type !== "composite") {
      return false;
    }
    return field.components.some(
      (comp) => comp.name === "countryCode" && comp.active
    );
  };
  console.log(selectedField, 'selectedField')
  return (
    <div className="sub-tab-div">
      <div className="form-elements-header">
        <h5>{selectedField.content} Properties</h5>
      </div>
      <div style={{ height: `94%` }}>
        <Tabs
          defaultActiveKey="General"
          id="justify-tab-example"
          className="edit-field-tabs"
          justify
        >
          <Tab eventKey="General" className="field-edit-tabs" title="General">
            <div>
              <div className="item-edit-section">
                <Form.Group>
                  <Form.Label>
                    {selectedField.field_type === "header"
                      ? "Heading Text"
                      : selectedField.field_type === "input_split"
                        ? "Field Labels"
                        : "Field Label"}
                  </Form.Label>
                  {selectedField.field_type === "input_split" ? (
                    selectedField.subLabels.map((subLabels) => (
                      <div className="mb-3">
                        <Form.Control
                          inline
                          value={subLabels.label || ""}
                          id={subLabels.id}
                          name={subLabels.label}
                          type={subLabels.type}
                          onChange={(e) =>
                            props.updateFieldValue(e, selectedField, "sublabel")
                          }
                          disabled={selectedField.editable === true}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="mb-3">
                      <Form.Control
                        inline
                        value={selectedField.Label || ""}
                        name="Label"
                        type="text"
                        onChange={(e) =>
                          props.updateFieldValue(e, selectedField)
                        }
                        disabled={selectedField.editable === true}
                      />
                    </div>
                  )}
                </Form.Group>
              </div>
              {selectedField.subheading && (
                <div className="item-edit-section">
                  <Form.Group>
                    <Form.Label>Sub heading text</Form.Label>
                    <div className="mb-3">
                      <Form.Control
                        inline
                        value={selectedField.subheading || ""}
                        name="subheading"
                        type="text"
                        onChange={(e) =>
                          props.updateFieldValue(e, selectedField)
                        }
                        disabled={selectedField.editable === true}
                      />
                    </div>
                    <p>Add smaller text below the heading</p>
                  </Form.Group>
                </div>
              )}
              {selectedField.field_type === "header" && (
                <div className="item-edit-section">
                  <Form.Group>
                    <Form.Label>Heading Size</Form.Label>
                    <div className="mb-3">
                      <ButtonToolbar>
                        <ToggleButtonGroup
                          type="radio"
                          name="fontSize"
                          defaultValue={selectedField.fontSize || ""}
                          className="align-btn"
                          onChange={(val) =>
                            props.updateSizeAlignValue(
                              val,
                              selectedField,
                              "fontSize"
                            )
                          }
                        >
                          <ToggleButton
                            id="toggle-default"
                            className={`align-label ${selectedField.fontSize === "default"
                                ? "active"
                                : ""
                              }`}
                            value="default"
                          >
                            Default
                          </ToggleButton>
                          <ToggleButton
                            id="toggle-large"
                            className={`align-label ${selectedField.fontSize === "large" ? "active" : ""
                              }`}
                            value="large"
                          >
                            Large
                          </ToggleButton>
                          <ToggleButton
                            id="toggle-small"
                            className={`align-label ${selectedField.fontSize === "small" ? "active" : ""
                              }`}
                            value="small"
                          >
                            Small
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    </div>
                    <p>Select how the heading is aligned horizontally</p>
                  </Form.Group>
                </div>
              )}
              <div className="item-edit-section">
                <Form.Group>
                  <Form.Label>
                    {selectedField.field_type === "header"
                      ? "Text"
                      : selectedField.field_type === "button"
                        ? "Button"
                        : "Label"}{" "}
                    Alignment
                  </Form.Label>
                  <div className="mb-3">
                    <ButtonToolbar>
                      <ToggleButtonGroup
                        type="radio"
                        name="alignment"
                        defaultValue={selectedField.alignment || ""}
                        className="align-btn"
                        onChange={(val) =>
                          props.updateSizeAlignValue(
                            val,
                            selectedField,
                            "alignment"
                          )
                        } // Set the onChange handler here
                      >
                        <ToggleButton
                          id="toggle-left"
                          className={`align-label ${selectedField.alignment === "left" ? "active" : ""
                            }`}
                          value="left"
                          disabled={selectedField.editable === true}
                        >
                          Left
                        </ToggleButton>
                        <ToggleButton
                          id="toggle-center"
                          className={`align-label ${selectedField.alignment === "center" ||
                              selectedField.alignment === "top"
                              ? "active"
                              : ""
                            }`}
                          value={
                            selectedField.field_type === "header"
                              ? "center"
                              : "top"
                          }
                          disabled={selectedField.editable === true}
                        >
                          {selectedField.field_type === "header"
                            ? "Center"
                            : "Top"}
                        </ToggleButton>
                        <ToggleButton
                          id="toggle-right"
                          className={`align-label ${selectedField.alignment === "right" ? "active" : ""
                            }`}
                          value="right"
                          disabled={selectedField.editable === true}
                        >
                          Right
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </div>
                  <p>Select how the heading is aligned horizontally</p>
                </Form.Group>
              </div>
              <div className="item-edit-section">
                <Form.Group>
                  <Form.Label>Required</Form.Label>
                  <div className="mb-3" disabled={selectedField.editable === true}>
                    <BootstrapSwitchButton
                      checked={selectedField.validation.required}
                      onChange={(checked) =>
                        props.updateSizeAlignValue(
                          checked,
                          selectedField,
                          "required"
                        )
                      }
                      style={{
                        backgroundColor: selectedField.required
                          ? "green"
                          : "red",
                      }}
                      className="required-toggle"
                      size="sm"
                    />
                  </div>
                  <p>Prevent submission if this field is empty</p>
                </Form.Group>
              </div>
              {selectedField.subLabels && (
                <div className="item-edit-section">
                  <Form.Group>
                    <Form.Label>Sublabels</Form.Label>
                    {selectedField.subLabels.map((subLabels, i) => (
                      <>
                        {subLabels.active && (
                          <div className="mb-3" key={i}>
                            <Form.Control
                              inline
                              value={subLabels.label || ""}
                              id={subLabels.id}
                              name={subLabels.label}
                              type="text"
                              onChange={(e) =>
                                props.updateFieldValue(
                                  e,
                                  selectedField,
                                  "sublabel"
                                )
                              }
                            />
                          </div>
                        )}
                      </>
                    ))}
                  </Form.Group>
                </div>
              )}
              <div className="item-edit-section">
                <Form.Group>
                  <Form.Label>Dublicate Field</Form.Label>
                  <div className="mb-3">
                    <Button
                      className="btn btn-field-dublicate"
                      onClick={() => props.duplicateItem(selectedField)}
                      disabled={selectedField.editable === true}
                    >
                      <i className="fa fa-clone"></i> Duplicate In same row
                    </Button>
                  </div>
                  <div className="mb-3">
                    <Button
                      className="btn btn-field-dublicate"
                      onClick={() => props.duplicateItemNewRow(selectedField)}
                      disabled={selectedField.editable === true}
                    >
                      <i className="fa fa-clone"></i> Duplicate in new Row
                    </Button>
                  </div>
                  <p>Duplicate this field with all saved settings</p>
                </Form.Group>
              </div>
            </div>
          </Tab>
          {selectedField.field_type !== "header" && (
            <Tab eventKey="Options" title="Options" className="field-edit-tabs">
              <div>
                {(selectedField.placeholder ||
                  selectedField.placeholder === "") && (
                    <div className="item-edit-section">
                      <Form.Group>
                        <Form.Label>Placeholder</Form.Label>
                        <div className="mb-3">
                          <Form.Control
                            inline
                            value={selectedField.placeholder || ""}
                            name="placeholder"
                            type="text"
                            onChange={(e) =>
                              props.updateFieldValue(e, selectedField)
                            }
                            disabled={selectedField.editable === true}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {selectedField.field_type === "input_split" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Placeholder</Form.Label>
                      {selectedField.subLabels.map((item, index) => (
                        <div className="mb-3" key={index}>
                          <Form.Control
                            inline
                            value={item.placeholder || ""}
                            name="placeholder"
                            id={item.id}
                            type="text"
                            onChange={(e) =>
                              props.updateFieldValue(
                                e,
                                selectedField,
                                "sublabel",
                                "placeholder"
                              )
                            }
                          />
                        </div>
                      ))}
                    </Form.Group>
                  </div>
                )}
                {selectedField.field_type === "composite" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Placeholder</Form.Label>
                      {selectedField.components.map(
                        (item, index) =>
                          item.active && (
                            <div className="mb-3" key={index}>
                              <Form.Control
                                inline
                                value={item.placeholder || ""}
                                name="placeholder"
                                id={item.id}
                                type="text"
                                onChange={(e) =>
                                  props.updateFieldValue(
                                    e,
                                    selectedField,
                                    "components"
                                  )
                                }
                              />
                            </div>
                          )
                      )}
                    </Form.Group>
                  </div>
                )}
                {selectedField.field_type === "input_group" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Middle Name</Form.Label>
                      <div className="mb-3">
                        {selectedField.subLabels.map((subLabels, i) => (
                          <React.Fragment key={i}>
                            {subLabels.name === "mname" && (
                              <BootstrapSwitchButton
                                checked={subLabels.active}
                                onChange={(checked) =>
                                  props.updateSizeAlignValue(
                                    checked,
                                    selectedField,
                                    "sublabels",
                                    subLabels.id
                                  )
                                }
                                className="required-toggle"
                                size="sm"
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                      <p>Let users enter a middle name</p>
                    </Form.Group>
                  </div>
                )}
                {selectedField.field_type === "composite" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Country Code</Form.Label>
                      <div className="mb-3">
                        {selectedField.components.map(
                          (comp, i) =>
                            comp.type === "select" && (
                              <BootstrapSwitchButton
                                key={i}
                                checked={comp.active}
                                onChange={(checked) =>
                                  props.updateSizeAlignValue(
                                    checked,
                                    selectedField,
                                    "composite",
                                    comp.id
                                  )
                                }
                                className="required-toggle"
                                size="sm"
                              />
                            )
                        )}
                      </div>
                      <p>Required country code with mobile number</p>
                    </Form.Group>
                  </div>
                )}
                {selectedField.field_type === "input_group" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Placeholder</Form.Label>
                      {selectedField.subLabels.map((subLabels, i) => (
                        <>
                          {subLabels.active && (
                            <div className="mb-3" key={i}>
                              <Form.Control
                                inline
                                value={subLabels.placeholder || ""}
                                id={subLabels.id}
                                name={subLabels.placeholder}
                                placeholder={`placeholder for ${subLabels.label}`}
                                type="text"
                                onChange={(e) =>
                                  props.updateFieldValue(
                                    e,
                                    selectedField,
                                    "sublabel",
                                    "placeholder"
                                  )
                                }
                              />
                            </div>
                          )}
                        </>
                      ))}
                    </Form.Group>
                  </div>
                )}
                {selectedField.field_type === "date_picker" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Date Format</Form.Label>
                      <div className="mb-3">
                        <ButtonToolbar>
                          <ToggleButtonGroup
                            type="radio"
                            name="config"
                            defaultValue={selectedField.config.dateFormat || ""}
                            className="align-btn"
                            onChange={(val) =>
                              props.updateSizeAlignValue(
                                val,
                                selectedField,
                                "dateFormat"
                              )
                            } // Set the onChange handler here
                          >
                            <ToggleButton
                              id="toggle-date1"
                              className={`align-label ${selectedField.config.dateFormat === "MM/dd/yyyy"
                                  ? "active"
                                  : ""
                                }`}
                              value="MM/dd/yyyy"
                            >
                              MM/DD/YYYY
                            </ToggleButton>
                            <ToggleButton
                              id="toggle-date2"
                              className={`align-label ${selectedField.config.dateFormat === "dd/MM/yyyy"
                                  ? "active"
                                  : ""
                                }`}
                              value="dd/MM/yyyy"
                            >
                              DD/MM/YYYY
                            </ToggleButton>
                            <ToggleButton
                              id="toggle-date3"
                              className={`align-label ${selectedField.config.dateFormat === "yyyy/MM/dd"
                                  ? "active"
                                  : ""
                                }`}
                              value="yyyy/MM/dd"
                            >
                              YYYY/MM/DD
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                      </div>
                      <p>Select the date format</p>
                    </Form.Group>
                  </div>
                )}
                {selectedField.field_type === "date_picker" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Default Date</Form.Label>
                      <div className="mb-3">
                        <ButtonToolbar>
                          <ToggleButtonGroup
                            type="radio"
                            name="config"
                            defaultValue={
                              selectedField.config.defaultDate || ""
                            }
                            className="align-btn"
                            onChange={(val) =>
                              props.updateSizeAlignValue(
                                val,
                                selectedField,
                                "defaultDate"
                              )
                            } // Set the onChange handler here
                          >
                            <ToggleButton
                              id="toggle-defaultdate1"
                              className={`align-label ${!props.customTab &&
                                  selectedField.config.defaultDate === ""
                                  ? "active"
                                  : ""
                                }`}
                              value=""
                            >
                              NONE
                            </ToggleButton>
                            <ToggleButton
                              id="toggle-defaultdate2"
                              className={`align-label ${!props.customTab &&
                                  moment(
                                    new Date(selectedField.config.defaultDate)
                                  ).format(selectedField.config.dateFormat) ===
                                  moment(new Date()).format(
                                    selectedField.config.dateFormat
                                  )
                                  ? "active"
                                  : ""
                                }`}
                              value={new Date()}
                            >
                              CURRENT
                            </ToggleButton>
                            <ToggleButton
                              id="toggle-defaultdate3"
                              className={`align-label ${props.customTab ? "active" : ""
                                }`}
                              value="custom"
                            >
                              CUSTOM
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                      </div>
                      {props.customTab && (
                        <div className="meeting-date-picker-with-icon">
                          <DatePicker
                            selected={selectedField.config.defaultDate}
                            className="select-add-meeting-model meeting-date model-input"
                            dateFormat={selectedField.config.dateFormat}
                            onChange={(date) =>
                              props.setCustomDate(date, selectedField)
                            }
                          />
                          <i className="fa fa-calendar-o calander-icon" />
                        </div>
                      )}
                      <p>Select the default date</p>
                    </Form.Group>
                  </div>
                )}
                {selectedField.field_type === "file" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>File Types</Form.Label>
                      <div className="mb-3">
                        <textarea
                          rows={3}
                          cols={4}
                          type="text"
                          name="accept"
                          value={selectedField.validation.accept || ""}
                          className="form-control form-textarea add-product-input"
                          id="validation"
                          placeholder={""}
                          onChange={(e) =>
                            props.updateFieldValue(e, selectedField)
                          }
                        />
                      </div>
                      <p>
                        Enter the file types you wish to accept. Separate each
                        file type with a comma.
                      </p>
                    </Form.Group>
                  </div>
                )}
                {selectedField.field_type === "file" && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Limit number of files</Form.Label>
                      <div className="mb-3">
                        <BootstrapSwitchButton
                          checked={selectedField.validation.limitFile}
                          onChange={(checked) =>
                            props.updateSizeAlignValue(
                              checked,
                              selectedField,
                              "limitFile"
                            )
                          }
                          className="required-toggle"
                          size="sm"
                        />
                      </div>
                      {selectedField.validation.limitFile && (
                        <div className="mb-3">
                          <Form.Control
                            inline
                            value={selectedField.validation.maxFile || ""}
                            name="maxFile"
                            id="validation"
                            type="text"
                            onChange={(e) =>
                              props.updateFieldValue(e, selectedField)
                            }
                          />
                        </div>
                      )}
                      <p>Limit the no of files in sigle upload</p>
                    </Form.Group>
                  </div>
                )}

                {selectedField.options && (
                  <div className="item-edit-section">
                    <Form.Group>
                      <Form.Label>Options</Form.Label>
                      <div className="opt-value-div">
                        {selectedField.options.map((opt, i) => (
                          <div className="mb-3" key={i}>
                            <Form.Control
                              inline
                              value={opt.label || ""}
                              name={opt.name}
                              id={opt.id}
                              type="text"
                              onChange={(e) =>
                                props.updateFieldValue(
                                  e,
                                  selectedField,
                                  "options"
                                )
                              }
                            />
                            <div className="rmv-default">
                              <div>
                                <Form.Check
                                  inline
                                  label="Make as selected"
                                  name={opt.name}
                                  type="checkbox"
                                  id={`check-${i + 1}`}
                                  checked={opt.checked}
                                  onChange={(e) =>
                                    props.makeSelected(e, selectedField, opt.id)
                                  }
                                  style={{
                                    visibility:
                                      selectedField.field_type === "select" &&
                                      "hidden",
                                  }}
                                />
                              </div>
                              <div
                                onClick={() =>
                                  props.removeOptions(selectedField, opt.id)
                                }
                              >
                                Remove
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className="add-options"
                        onClick={() => props.addOptions(selectedField)}
                      >
                        <i className="fa fa-plus"></i> Add options
                      </div>
                      <p>Give options for users to select from</p>
                    </Form.Group>
                  </div>
                )}
                <div className="item-edit-section">
                  <Form.Group>
                    <Form.Label>Read Only</Form.Label>
                    <div className="mb-3" disabled={selectedField.editable === true}>
                      <BootstrapSwitchButton
                        checked={selectedField.validation.readOnly}
                        onChange={(checked) =>
                          props.updateSizeAlignValue(
                            checked,
                            selectedField,
                            "readOnly"
                          )
                        }
                        className="required-toggle"
                        size="sm"
                      />
                    </div>
                    <p>Prevent entry in this field</p>
                  </Form.Group>
                </div>
              </div>
            </Tab>
          )}
          {/* {selectedField.field_type !== "header" && <Tab eventKey="Advanced" title="Advanced" className="field-edit-tabs">Advanced</Tab>} */}
        </Tabs>
      </div>
    </div>
  );
}

export default ElementSetting;
