import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useEffect } from "react";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import { loadForeignKeyDropdownData } from "../../../redux/action";

function SettingsTab(props) {
  const formName = props.formName;

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const moduleData = resPassData?.subscription?.modules.map((menus) => {
    return { label: menus.moduleName, value: menus.moduleName };
  });

  let dispatch = useDispatch();
  const { getForeignKeyData } = useSelector((state) => state.data);
  useEffect(() => {
    dispatch(loadForeignKeyDropdownData())
  }, [])

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="setting" style={{ height: "100%" }}>
      <Row className="custom-form-row">
        <Col sm={3} className="form-element-col">
          <div className="form-setting-box">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="setting">Form Setting</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="emails">Emails</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="thank">Thank You Page</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </div>
        </Col>
        <Col sm={9} className="form-edit-col">
          <Tab.Content>
            <Tab.Pane eventKey="setting">
              <div className="form-box">
                <div className="form-setting-element">
                  <Form.Group>
                    <h6>Form Status</h6>
                    <p className="mb-2">
                      Enable, disable, or conditionally enable your form
                    </p>

                    <div className="mb-3">
                      <BootstrapSwitchButton
                        checked={formName.validation.active}
                        onChange={(checked) =>
                          props.updateFormSettings(checked, "boolian")
                        }
                        className="required-toggle"
                        size="sm"
                      />{" "}
                      {formName.validation.active ? (
                        <span className="enable">Enable</span>
                      ) : (
                        <span className="disable">Disable</span>
                      )}
                    </div>
                  </Form.Group>
                </div>
                <div className="form-setting-element">
                  <Form.Group>
                    <h6>Select Module</h6>
                    <p className="mb-2">
                      Select module where you want to add this form
                    </p>

                    <div className="form-setting-control">
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={moduleData}
                        name="moduleName"
                        value={
                          moduleData.find(
                            (option) => option.value === formName.moduleName
                          ) || ""
                        }
                        placeholder={"Select Module"}
                        onChange={(data) =>
                          props.updateFormSettings(data.value, "moduleName")
                        }
                        readOnly={true}
                      />
                      {props.submitError && !formName.moduleName && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please Select Module Name
                        </small>
                      )}
                    </div>
                  </Form.Group>
                </div>
                <Row className="form-setting-inner-div">
                  <Col md={6}>
                    <Form.Group>
                      <h6>Select Sub Module</h6>
                      <p className="mb-2">
                        Select sub module where you want to add this form
                      </p>

                      <div className="form-setting-control">
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={moduleData}
                          name="subModuleName"
                          value={
                            moduleData.find(
                              (option) => option.value === formName.subModuleName
                            ) || ""
                          }
                          placeholder={"Select Sub Module"}
                          onChange={(data) =>
                            props.updateFormSettings(data.value, "subModuleName")
                          }
                          readOnly={true}
                        />
                        {props.submitError && !formName.subModuleName && (
                          <small id={`Error`} className="form-text text-muted ">
                            Please Select Sub Module Name
                          </small>
                        )}
                      </div>
                    </Form.Group>

                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <h6>Select Foreign Key</h6>
                      <p className="mb-2">
                        Select Foreign key where you want to add this form
                      </p>

                      <div className="form-setting-control">
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={getForeignKeyData}
                          name="moduleName"
                          value={
                            getForeignKeyData.find(
                              (option) => option.value === formName.foreignKey
                            ) || ""
                          }
                          placeholder={"Select Foreign Key"}
                          onChange={(data) =>
                            props.updateFormSettings(data.value, "foreignKey")
                          }
                          readOnly={true}
                        />
                        {props.submitError && !formName.foreignKey && (
                          <small id={`Error`} className="form-text text-muted ">
                            Please Select Foreign Key
                          </small>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="emails">
              <div className="form-box">
                <div className="form-setting-element">
                  <Form.Group>
                    <h6>Form Status</h6>
                    <p className="mb-2">
                      Enable, disable, or conditionally enable your form
                    </p>

                    <div className="mb-3">
                      <BootstrapSwitchButton
                        checked={formName.validation.active}
                        onChange={(checked) =>
                          props.updateFormSettings(checked, "boolian")
                        }
                        className="required-toggle"
                        size="sm"
                      />{" "}
                      {formName.validation.active ? (
                        <span className="enable">Enable</span>
                      ) : (
                        <span className="disable">Disable</span>
                      )}
                    </div>
                  </Form.Group>
                </div>
                <div className="form-setting-element">
                  <Form.Group>
                    <h6>Select Module</h6>
                    <p className="mb-2">
                      Select module where you want to add this form
                    </p>

                    <div className="form-setting-control">
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={moduleData}
                        name="moduleName"
                        value={
                          moduleData.find(
                            (option) => option.value === formName.moduleName
                          ) || ""
                        }
                        placeholder={"Select Modules"}
                        onChange={(data) =>
                          props.updateFormSettings(data.value, "moduleName")
                        }
                        readOnly={true}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default SettingsTab;
