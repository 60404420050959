import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const DecryptionComponent = () => {
    const [decryptedData, setDecryptedData] = useState('');

    useEffect(() => {
        const encryptedData = 'U2FsdGVkX1+XXwDubMU9SkZFIBN+nUzKoyjV1MhzzfSEs7QRjAaHAkR4+fvYNfTILdAABw6adU5BIBdi37CXKA==';
        const key = 'yourSecretKey123';

        console.log("Encrypted Data:", encryptedData);
        console.log("Key:", key);

        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, key);
            console.log("Decrypted Bytes:", bytes);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            console.log("Decrypted Data:", decryptedData);
            setDecryptedData(decryptedData);
        } catch (error) {
            console.error("Decryption Error:", error);
        }
    }, []);

    // const dataToEncrypt = 'id=USR400&name=Karan&email=karan@lobotus.co.in';

    // // Encryption key (replace with your own)
    // const key = 'yourSecretKey123';

    // // Encrypt the data using AES encryption
    // const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, key).toString();

    // console.log('Encrypted Data:', encryptedData);
    // console.log('Key:', key);


    return (
        <div>
            <h2>Decrypted Data:</h2>
            <p>{decryptedData}</p>
        </div>
    );
};

export default DecryptionComponent;
