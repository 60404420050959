import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import SbCancelBtn from '../../SbButtons/SbCancelBtn';
import moment from 'moment';
import { SBSaveUpdateBtn } from '../../SbButtons/SbAddBtn';
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";

function SubscriptionDetailsTable(props) {

    const getTextColor = (subscriptionName) => {
        switch (subscriptionName) {
            case 'Elite':
                return 'green';
            case 'End Subscription':
                return 'red';
            case 'Trial':
                return 'orange';
            case 'Tier':
            case 'One Month':
            case 'One week':
                return 'blue';
            default:
                return 'black'; // Default color if none of the conditions match
        }
    };

    const getExpiryDateColor = (expiryDate) => {
        const daysLeft = moment(expiryDate).diff(moment(), 'days');
        return daysLeft <= 7 ? 'red' : 'green';
    };

    const getDaysLeftColor = (daysLeft) => {
        return daysLeft <= 7 ? 'red' : 'green'; 
    };

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }}
                    onScroll={(e) => handleScroll(e, props.allSubscriptionDetails, props.totalData, props.setLoading, props.setPageSize, props.PageSize)}
                >
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} className={`thead-cell-products`}
                                        align={name.align} style={{ minWidth: name.width }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <>{props.tableHeader.map((name, i) => (
                                <SkeletonMeetPurpose key={i} />
                            ))}
                            </>
                            :
                            <>
                                {props.allSubscriptionDetails && props.allSubscriptionDetails.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell align="center" colSpan={props.tableHeader.length}>
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.allSubscriptionDetails && props.allSubscriptionDetails.map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data'>
                                                <TableCell>
                                                    <p style={{ color: getTextColor(data?.subscriptionType?.subscriptionName) }}><b>
                                                        {data?.subscriptionType?.subscriptionName}
                                                    </b></p>
                                                    <p>Cycle : <b>{data?.subscriptionType?.cycle}</b></p>
                                                    <p>
                                                        User Limit :{" "}
                                                        <b>{data?.subscriptionType?.userLimit}</b>
                                                    </p>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="color-green"><b>{data?.company?.companyName}</b></span>
                                                    <p>{data?.company?.clientName}</p>
                                                    <p>{data?.company?.email}</p>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="color-yellow"><b>{data?.adminFullName}</b> </span>
                                                    <p>{data?.adminEmail}</p>
                                                    <p>{data?.adminCountryCode} {data?.adminContact}</p>
                                                </TableCell>
                                                <TableCell>
                                                    <p>Subscribed Date :{" "}
                                                        <b>{moment(data?.subscribedDate).format(
                                                            "DD/MM/YYYY"
                                                        )}</b></p>
                                                    <p>Start Date :{" "}
                                                        <b>{moment(data?.startdate).format("DD/MM/YYYY")}</b></p>
                                                    <p>Expiry Date :{" "}
                                                        <b style={{ color: getExpiryDateColor(data?.expirydate) }}>{moment(data?.expirydate).format("DD/MM/YYYY")}</b></p>
                                                    <p>Max User Count : <b>{data?.maxUserCount}</b></p>
                                                </TableCell>
                                                <TableCell>
                                                    Subscription Days :
                                                    <b>{data?.subscriptionPeriod}</b>
                                                    <br />
                                                    Subscription Days Complete :
                                                    <b>{data?.daysComplete}</b>
                                                    <br />
                                                    Subscription Days Left :
                                                    <b style={{ color: getDaysLeftColor(data?.daysleft) }}>{data?.daysleft}</b>
                                                    <br />
                                                    Subscription Expired :
                                                    <b>NA</b>
                                                </TableCell>
                                                <TableCell>
                                                    <SBSaveUpdateBtn btnName='Upgrade' onClickEffect={() => props.openModal(data.subscriptionId)} />
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                        {props.loading &&
                                            <OnScrollLoading />
                                        }
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default SubscriptionDetailsTable;
