import { Modal } from "react-bootstrap";

const ViewIssueImages = ({ showImageModal, handleCloseModal, singleClientCompaint, decodeBase64Image, modalImage, modalTitle, }) => {

    return (
        <Modal show={showImageModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={decodeBase64Image(modalImage)} alt={modalTitle} style={{ width: "100%", height: "auto" }} />
            </Modal.Body>
        </Modal>
    );
}

export default ViewIssueImages;