import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import assignToIcon from "../../images/icons/icons8-complaint.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";

function ComplaintTable(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tootTipId, setTootTipId] = useState("");
  const buttonRef = useRef(null);
  const { totalCount } = useSelector((state) => state.data);

  const handleClick = (id) => {
    setShowTooltip(!showTooltip);
    setTootTipId(id);
  };

  const handleClickState = (event) => {
    if (event.target.id !== "info") {
      setShowTooltip(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickState);
    return () => {
      document.removeEventListener("click", handleClickState);
    };
  }, []);

  let currentTime = moment(new Date()).format("DD/MM/YYYY");

  const renderTooltip = (dynamicValue) => (
    // <Tooltip id="tooltip" className="custom-tool-tip">
    <Tooltip id={`tooltip-dashboard`}>
      {dynamicValue} {/* Replace dynamicValue with your actual dynamic value */}
    </Tooltip>
  );

  // console.log(props.clientComplaints, "props.clientComplaints")

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.clientComplaints,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="three-header-sticky"
          >
            <TableHead className="custom-table-header">
              <TableRow className="table-row">
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    className={`thead-cell-products`}
                    style={{ minWidth: `${name.width}` }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <TableBody>
                {props.tableHeader.map((name, i) => (
                  <SkeletonTableData key={i} />
                ))}
              </TableBody>
            ) : (
              <>
                {!props.clientComplaints ||
                props.clientComplaints.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>No Data Found...!</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody style={{ position: "relative" }}>
                    {props.clientComplaints.map((data, i) => (
                      <TableRow
                        align="center"
                        key={i}
                        className="client-list-row-data table-row"
                      >
                        <TableCell align="center">
                          {data.clientComplaintsID}
                        </TableCell>
                        <TableCell>
                          {moment(data.date, "DD/MM/YYYY").format(
                            "DD MMM YYYY"
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            <b>{data?.clientName}</b>
                          </div>
                          <div>{data?.addedByUserFullName}</div>
                        </TableCell>
                        {/* <TableCell></TableCell> */}
                        <TableCell>
                          <div>{data.clientEmail}</div>
                          <div className="user-mobile">{data.clientPhone}</div>
                        </TableCell>
                        <TableCell>
                          <Link to="/products" style={{ color: "#76797e" }}>
                            <b>{data.productName}</b>
                          </Link>
                        </TableCell>
                        <TableCell>{data.issue}</TableCell>
                        <TableCell align="center">
                          {data.priority === 3 ? (
                            <span className="pending-status status-badge">
                              High
                            </span>
                          ) : data.priority === 2 ? (
                            <span className="follow-status status-badge">
                              Medium
                            </span>
                          ) : (
                            data.priority === 1 && (
                              <span className="complete-status status-badge">
                                Low
                              </span>
                            )
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {data.inWarranty == 1 ? (
                            <span className="color-green">Yes</span>
                          ) : (
                            <span className="color-yellow">No</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {data.status === 1 ? (
                            <span className="pending-status status-badge">
                              Pending
                            </span>
                          ) : data.status === 2 ? (
                            <span className="follow-status status-badge">
                              Follow Up
                            </span>
                          ) : data.status === 3 ? (
                            <span className="complete-status status-badge">
                              Resolved
                            </span>
                          ) : (
                            data.status === 4 && (
                              <span className="complete-status status-badge">
                                Closed
                              </span>
                            )
                          )}
                        </TableCell>
                        <TableCell>{data.assingnedToName}</TableCell>
                        <TableCell>
                          {data.adttenedByName == " "
                            ? "-"
                            : data.adttenedByName}
                        </TableCell>
                        <TableCell align="center">
                          {moment(currentTime, "DD/MM/YYYY").diff(
                            moment(data.date, "DD/MM/YYYY"),
                            "days"
                          )}{" "}
                          days
                        </TableCell>
                        <TableCell>
                          {data.description.length > 20
                            ? data.description.substring(0, 20) + "..."
                            : data.description}
                          &nbsp;&nbsp;
                          {data.description.length > 20 && (
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip(data.description)}
                              show={
                                showTooltip &&
                                tootTipId === data.clientComplaintsID
                              }
                            >
                              <i
                                ref={buttonRef}
                                id="info"
                                className="fa fa-info-circle details-icon"
                                onClick={(e) =>
                                  handleClick(data.clientComplaintsID)
                                }
                                aria-hidden="true"
                              ></i>
                            </OverlayTrigger>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <OverlayTrigger
                            placement={"top"}
                            delay={{ show: 250, hide: 300 }}
                            overlay={
                              <Tooltip id={`tooltip-dashboard`}>
                                Update Status
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={(e) =>
                                props.handleStatus(data?.clientComplaintsID)
                              }
                              className="btn client-history-link"
                            >
                              <i className="fa fa-tasks" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={"top"}
                            delay={{ show: 250, hide: 300 }}
                            overlay={
                              <Tooltip id={`tooltip-dashboard`}>
                                Re Assign
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() =>
                                props.handleAssign(data?.clientComplaintsID)
                              }
                              className="btn client-asign-btn"
                            >
                              {/* <Image src={assignToIcon} alt="assign-to-icon" /> */}
                              <span className="material-symbols-outlined">
                                quick_phrases
                              </span>
                            </button>
                          </OverlayTrigger>
                          {(data.status === 1 || data.status === 2) && (
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 250, hide: 300 }}
                              overlay={
                                <Tooltip id={`tooltip-dashboard`}>
                                  Edit Complaint
                                </Tooltip>
                              }
                            >
                              <button
                                onClick={(e) =>
                                  props.handleEditCompleint(
                                    data?.clientComplaintsID
                                  )
                                }
                                className="btn client-history-link"
                              >
                                <i className="fa fa-edit" />
                              </button>
                            </OverlayTrigger>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default ComplaintTable;
