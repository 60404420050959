import Select from "react-select";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DraggableItem = ({ formItem}) => {

  const renderInput = () => (
    <Form.Group
      key={formItem.id}
      className={`control-form-group ${formItem.alignment}`}
    >
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        <Form.Control
          type={formItem.type}
          placeholder={formItem.placeholder || ""}
          readOnly
        />
      </div>
    </Form.Group>
  );

  const renderInputGroup = () => (
    <Form.Group
      key={formItem.id}
      className={`control-form-group ${formItem.alignment}`}
    >
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        {formItem.subLabels.map((subLabels, i) => (
          <>
            {subLabels.active && (
              <div key={i} className="sublabel-container">
                <Form.Control
                  type={formItem.type}
                  placeholder={subLabels.placeholder || ""}
                  readOnly
                />
                <div className="form-sublabel">{subLabels.label}</div>
              </div>
            )}
          </>
        ))}
      </div>
    </Form.Group>
  );

  const renderSplitInput = () => (
    <Form.Group
      key={formItem.id}
      className={`control-form-group ${formItem.alignment}`}
    >
      <div className="fullname-form-control">
        {formItem.subLabels.map((subLabels, i) => (
          <div key={i} className="sublabel-container">
            <div className={`control-form-group ${formItem.alignment}`}>
              <Form.Label className="control-form-label">{subLabels.label}</Form.Label>
              <Form.Control
                type={subLabels.type}
                placeholder={subLabels.placeholder || ""}
                readOnly
              />
            </div>
          </div>
        ))}
      </div>
    </Form.Group>
  );

  const renderComposite = () => (
    <Form.Group
      key={formItem.id}
      className={`control-form-group ${formItem.alignment}`}
    >
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        {formItem.components.map((component, i) => (
          <React.Fragment key={i}>
            {component.type === "select" && component.active && (
              <div className="country-code">
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={component.options}
                  placeholder={component.placeholder || ""}
                  readOnly={true}
                />
              </div>
            )}

            {component.type === "tel" && (
              <div className="sublabel-container">
                <Form.Control
                  type={formItem.type}
                  placeholder={component.placeholder || ""}
                  readOnly
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </Form.Group>
  );

  const renderDate = () => (
    <Form.Group
      key={formItem.id}
      className={`control-form-group ${formItem.alignment}`}
    >
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        <div className="meeting-date-picker-with-icon">
          <DatePicker
            selected={formItem.config.defaultDate}
            className="form-control select-add-meeting-model meeting-date model-input"
            dateFormat={formItem.config.dateFormat}
          />
          {formItem.config.defaultDate === '' && <span className="datepicker-placeholder">
            {formItem.config.dateFormat}
          </span>}
          <i className="fa fa-calendar-o calander-icon" />
        </div>
      </div>
    </Form.Group>
  );

  const renderTime = () => (
    <Form.Group
      key={formItem.id}
      className={`control-form-group ${formItem.alignment}`}
    >
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            // label="Time"
            value={formItem.config.default ? formItem.config.default : null}
            className="form-control select-add-meeting-model"
            renderInput={(params) => <TextField {...params}
              placeholder={formItem.config.default === "" ? "Select Time" : ""} />}
            // minTime={
            //   isCurrentDate
            //     ? dayjs(`2023-01-30T${isTime}`)
            //     : dayjs("2023-01-30T09:00")
            // }
            readOnly={formItem.validation.readOnly}
            InputProps={{ readOnly: true }}
            inputFormat={formItem.config.timeFormat}
            // maxTime={dayjs("2023-01-31T22:00")}
            minutesStep={15}
          />
        </LocalizationProvider>
      </div>
    </Form.Group>
  );

  const rendertTextarea = () => (
    <Form.Group
      key={formItem.id}
      className={`control-form-group ${formItem.alignment}`}
    >
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        <textarea
          rows={formItem.attributes.rows}
          cols={formItem.attributes.cols}
          type={formItem.type}
          className="form-control form-textarea add-product-input"
          id="descriptionArea"
          placeholder={formItem.placeholder || ""}
          readOnly
        />
      </div>
    </Form.Group>
  );

  const rendertFile = () => (
    <Form.Group
      key={formItem.id}
      className={`control-form-group ${formItem.alignment}`}
    >
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        <div className="drop-area">
          <img src="../../../images/DefaultImages/UploadImages.svg" alt="" />
          <p>Drag and drop images here</p>
          <p>OR</p>
          <h6>Browse Images</h6>
        </div>
      </div>
    </Form.Group>
  );

  const renderCheckboxOrRadio = (type) => (
    <Form.Group key={formItem.id} className={`control-form-group ${formItem.alignment}`}>
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        <div>
          {formItem.options.map((opt, i) => (
            <Form.Check
              inline
              key={i}
              label={opt.label}
              name={formItem.Label}
              checked={opt.checked}
              type={type}
              id={`${type}-${formItem.id}-${i + 1}`}
              readOnly
            />
          ))}
        </div>
      </div>
    </Form.Group>
  );

  const renderSelect = () => (
    <Form.Group key={formItem.id} className={`control-form-group ${formItem.alignment}`}>
      <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
      <div className="fullname-form-control">
        <Select
          className="react-select-container-list model-select"
          classNamePrefix="react-select-list"
          options={formItem.options}
          placeholder={formItem.placeholder || ""}
          readOnly={true}
        />
      </div>
    </Form.Group>
  );

  switch (formItem.field_type) {
    case "input":
      return renderInput();
    case "input_group":
      return renderInputGroup();
    case "input_split":
      return renderSplitInput();
    case "composite":
      return renderComposite();
    case "date_picker":
      return renderDate();
    case "time_picker":
      return renderTime();
    case "textarea":
      return rendertTextarea();
    case "file":
      return rendertFile();
    case "checkbox_group":
      return renderCheckboxOrRadio("checkbox");
    case "radio_group":
      return renderCheckboxOrRadio("radio");
    case "select":
      return renderSelect();
    case "header":
      return (
        <>
          <Form.Control
            type="text"
            value={formItem.Label}
            style={{ textAlign: `${formItem.alignment}` }}
            className={`custom-form-control font-${formItem.fontSize}`}
            readOnly
          />
          <p style={{ textAlign: `${formItem.alignment}` }}>
            {formItem.subheading}
          </p>
        </>
      );
    case "button":
      return (
        <Form.Group key={formItem.id}
          className={`control-btn ${formItem.alignment}`}>
          <Button type={formItem.type} className="custom-form-btn" >{formItem.Label}</Button>
        </Form.Group>
      );
    case "devider":
      return (
        <Form.Group key={formItem.id}
          className={`control-divider ${formItem.alignment}`}>
          <hr />
        </Form.Group>
      );
    default:
      return null;
  }
};

export default DraggableItem;
