import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PassBookHistoryTable from "./PassbookHistoryTable";
import { loadUserPassbookDetails, loadUserTransactionDetails } from "../../redux/action";

function PassbookHistory() {
  let dispatch = useDispatch();
  const location = useLocation();
  const { userPassbookDetails } = useSelector((state) => state.data);
  const { userTransactionDetails } = useSelector((state) => state.data);
  console.log(userPassbookDetails, 'userPassbookDetails')
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    if (location.state) {
      const { summaryId } = location.state;
      dispatch(loadUserPassbookDetails(summaryId))
      dispatch(loadUserTransactionDetails(summaryId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])


  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };

  return (
    <div id="main-page">

      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">PASSBOOK HISTORY</h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col">
                  <div
                    className="search-bar-header"
                    style={{ float: "right" }}
                  ></div>
                </Col>
              </Row>
            </div>
            <div className="clients-table">
              <Row className="search-row order-details-row">
                <Col md={4} className='cat-col'>
                  <ul className="order-detail-list">
                    <li><div className="span1">Client Name:</div> &nbsp;&nbsp;<div className="span2"><b>{userPassbookDetails[0]?.custName}</b></div></li>
                    <li><div className="span1">Email:</div> &nbsp;&nbsp;<div className="span2"><b>{userPassbookDetails[0]?.custEmail}</b></div></li>
                    <li><div className="span1">Phone:</div> &nbsp;&nbsp;<div className="span2"><b>{userPassbookDetails[0]?.custMobile}</b></div></li>
                    <li><div className="span1">Address:</div> &nbsp;&nbsp;<div className="span2">{userPassbookDetails[0]?.custAddress}</div></li>
                  </ul>
                </Col>
                <Col md={4} className='cat-col'>
                </Col>
                <Col className="cat-col">
                  <ul className="order-detail-list" style={{ float: "right" }}>
                    <li>
                      Order #: &nbsp;&nbsp;<b>{userPassbookDetails[0]?.orderID}</b>
                    </li>
                    <li>
                      Order Date:&nbsp;&nbsp;<b>{userPassbookDetails[0]?.orderDate}</b>
                    </li>
                    <li>
                      Order Status &nbsp;&nbsp;<b>{userPassbookDetails[0]?.orderStatus}</b>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <PassBookHistoryTable
              userPassbookDetails={userPassbookDetails}
              userTransactionDetails={userTransactionDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassbookHistory;
