import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ProSidebar, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "../styles/Sidenavbar.scss";
import "../styles/livechat.scss";
import miniLogo from "../images/metric-favicon.svg";
import SalesBee from "../images/MatricLogo.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ORModal from "./ORModal";
import MenuFormat from "./MenuFormat";
import { baseURL } from "./BaseUrl";
import { travelizeAuth } from "./HeaderAuthentication";
import { useDispatch } from "react-redux";
import { loadClientCategoryDrop } from "../redux/action";
import { useSelector } from "react-redux";

function Sidenavbar(props) {
  const [open, setOpen] = useState(false);
  const [subId, setSubId] = useState("");
  let dispatch = useDispatch();
  const { categoryDropdown } = useSelector((state) => state.data);

  useEffect(() => {
    dispatch(loadClientCategoryDrop());
  }, [])

  const menuCollapse = props.menuCollapse;

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const menuItems = MenuFormat(resPassData?.subscription?.modules);

  const subMenuOpen = (e) => {
    setSubId(e.target.id);
    setOpen(!open);
  };

  const [openQrModal, setOpenQrModal] = useState(false);
  const openAppQrCode = () => {
    setOpenQrModal(true);
  };

  const closeQrModal = () => {
    setOpenQrModal(false);
  };

  const [essaeMenuItems, setEssaeMenuItems] = useState([])

  const getUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Essae/GetALLDForm`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        setEssaeMenuItems(res.result)
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };
  useEffect(() => {
    getUserDetails()
  }, [])

  const [openSubMenuId, setOpenSubMenuId] = useState(null);

  // Function to handle submenu toggle
  const toggleSubMenu = (subMenuId) => {
    setOpenSubMenuId(subMenuId === openSubMenuId ? null : subMenuId);
  };

  const formPathMapping = {
    machines: '/Machines',
    amc: '/annualContract',
    spares: '/spares',
    // Add more mappings as needed
  };

  // const navigate = useNavigate();
  // const navigatePage = (page, pageId) => {
  //   navigate(`/${page}`, { state: { pageId: pageId } });
  // }

  let clientCatId = window.location.hash.split("/").pop();

  return (
    <div>
      {openQrModal && (
        <ORModal openQrModal={openQrModal} closeQrModal={closeQrModal} />
      )}
      <div id="header">
        <ProSidebar
          collapsed={menuCollapse}
          className={`pro-side-bar ${menuCollapse ? "collapsed-bar" : "expanded-bar"
            }`}
        >
          <SidebarHeader className="side_bar_header_main">
            <div className="logotext">
              <div className="logo profile-mini">
                {menuCollapse ? ( // If menuCollapse is true, render this block
                  <div className="profile-image">
                    <img src={miniLogo} alt="Profile-Img" />
                  </div>
                ) : // If menuCollapse is false, render this block
                  resPassData.subscriptionId === "SUB0000000091" ? (
                    <Link className="travelize_logo" to="/dashboard">
                      <img
                        src="../images/Client-Logo.svg"
                        alt="product-title-img"
                        className="salesbee-logo"
                        style={{ width: "150px", height: "60px" }}
                      />
                    </Link>
                  ) : (
                    <Link className="travelize_logo" to="/dashboard">
                      <img
                        src={SalesBee}
                        alt="product-title-img"
                        className="salesbee-logo"
                      />
                    </Link>
                  )}
              </div>
            </div>
          </SidebarHeader>

          {/* {!menuCollapse &&  */}
          <div className="profile-image-sidebar-big">
            {/* <div className="profile-controls">
              <div className="beta-tag-inside">
                <h6>Beta</h6>
              </div>
            </div> */}
          </div>
          {/* } */}
          <SidebarContent
            className="sidebar_content sidenavbar-scroll"
            id="sidenavAccordion"
          >
            {/* START NAV LINK ITEMS */}
            {!menuCollapse ? (
              <div>
                {menuItems &&
                  menuItems.map((menuItems, i) => (
                    <div className="expanded-nav-bar" key={i}>
                      {menuItems.name === "Dashboard" && (
                        <Link to="/dashboard">
                          <div
                            className={
                              window.location.hash === `#/dashboard` ||
                                window.location.hash === ""
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  {/* <span className="fa fa-desktop"></span> */}
                                  <img
                                    src="../images/SideBar/dashboard.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                {/* <span className="xn-text">DASHBOARD</span> */}
                                <span className="xn-text">Dashboard</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {/* {menuItems.name === "Users" && (
                        <Link to="/users">
                          <div
                            className={
                              window.location.hash === `#/users`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/users.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Employees</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )} */}

                      {menuItems.name === "Human Resource" && (
                        <div
                          className={
                            (subId === "Human Resource" && open) ||
                              window.location.hash === `#/HR/Users` ||
                              window.location.hash === `#/HR/Monthly` ||
                              window.location.hash === `#/HR/Leaves` ||
                              window.location.hash === `#/HR/Fullday`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="HUMANRESOURCE"
                            id="Human Resource"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "Human Resource" && open) ||
                                window.location.hash === `#/HR/Users` ||
                                window.location.hash === `#/HR/Monthly` ||
                                window.location.hash === `#/HR/Leaves` ||
                                window.location.hash === `#/HR/Fullday`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="Human Resource">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/users.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="HUMANRESOURCE"
                                id="Human Resource"
                              >
                                Human Resource
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="Human Resource"
                              />
                            </div>
                          </li>
                          {(subId === "Human Resource") ||
                            window.location.hash === `#/HR/Users` ||
                            window.location.hash === `#/HR/Monthly` ||
                            window.location.hash === `#/HR/Leaves` ||
                            window.location.hash === `#/HR/Fullday` ? (
                            <>
                              {menuItems.subNames.map((subMenu, index) => (
                                <div key={index}>
                                  {/* {subMenu.name === "Monthly" && ( */}
                                  <Link to={`/HR/${subMenu.name}`}>
                                    <div
                                      className={
                                        window.location.hash ===
                                          `#/HR/${subMenu.name}`
                                          ? `sidebar-content sub-active`
                                          : `sidebar-content`
                                      }
                                    >
                                      <div className="nav-link-item">
                                        {/* <span className="bi bi-check-square-fill"></span> */}
                                        <div className="sub-icon-div">
                                          <img
                                            src={`../images/SideBar/${subMenu.icon}`}
                                            alt=""
                                            width="100%"
                                          /><span className="xn-text">
                                            {subMenu.name}
                                          </span>
                                        </div>

                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      {menuItems.name === "Attendance" && (
                        <div
                          className={
                            (subId === "attendance" && open) ||
                              window.location.hash === `#/attendance/monthly` ||
                              window.location.hash === `#/attendance/fullday`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="ATTENDANCE"
                            id="attendance"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "attendance" && open) ||
                                window.location.hash === `#/attendance/monthly` ||
                                window.location.hash === `#/attendance/fullday`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="attendance">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/attendance.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="ATTENDANCE"
                                id="attendance"
                              >
                                Attendance
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="attendance"
                              />
                            </div>
                          </li>
                          {(subId === "attendance" && open) ||
                            window.location.hash === `#/attendance/monthly` ||
                            window.location.hash === `#/attendance/fullday` ? (
                            <>
                              {menuItems.subNames.map((subMenu, index) => (
                                <div key={index}>
                                  {subMenu.name === "Monthly" && (
                                    <Link to="/attendance/monthly">
                                      <div
                                        className={
                                          window.location.hash ===
                                            `#/attendance/monthly`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            Monthly
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {subMenu.name === "Fullday" && (
                                    <Link to="/attendance/fullday">
                                      <div
                                        className={
                                          window.location.hash ===
                                            `#/attendance/fullday`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            Full Day
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      {menuItems.name === "Client Complaint" && (
                        <Link to="/complaints">
                          <div
                            className={
                              window.location.hash === `#/complaints`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/complaints.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Complaints</span>
                                <i
                                  className="bi-chevron-right toggle-btn"
                                  style={{ visibility: "hidden" }}
                                ></i>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Leaves" && (
                        <Link to="/leaves">
                          <div
                            className={
                              window.location.hash === `#/leaves`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/leaves.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Leaves</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Meetings" && (
                        <Link to="/meetings">
                          <div
                            className={
                              window.location.hash === `#/meetings`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/meetings.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Meetings</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {/* {menuItems.name === "Clients" && (
                        <Link to="/company">
                          <div
                            className={
                              window.location.hash === `#/company`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/company.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Customer</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )} */}
                      {menuItems.name === "Clients" && (
                        <div
                          className={
                            (subId === "Clients" && open) ||
                              window.location.hash === `#/company/${clientCatId}`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <Link to="/company/0">
                            <li
                              name="CLIENTS"
                              id="Clients"
                              onClick={(e) => subMenuOpen(e)}
                              className={
                                (subId === "Clients" && open) ||
                                  window.location.hash ===
                                  `#/company/${clientCatId}`
                                  ? "nav-link-item drop-down-item active"
                                  : "nav-link-item drop-down-item drop-main"
                              }
                            >
                              <div className="sidebar-title" id="Clients">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/company.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span
                                  className="xn-text"
                                  name="CLIENTS"
                                  id="Clients"
                                >
                                  Customer
                                </span>
                                <i
                                  className="bi-chevron-right toggle-btn"
                                  id="Clients"
                                />
                              </div>
                            </li>
                          </Link>

                          {(subId === "Clients" && open) ||
                            (window.location.hash ===
                              `#/company/${clientCatId}` && (
                                <div
                                  style={{ maxHeight: "250px", overflow: "auto" }}
                                >
                                  {categoryDropdown.map((subMenu) => (
                                    <div>
                                      <Link to={`/company/${subMenu.value}`}>
                                        <div
                                          className={
                                            window.location.hash ===
                                              `#/company/${subMenu.value}`
                                              ? `sidebar-content sub-active`
                                              : `sidebar-content`
                                          }
                                        >
                                          <div className="nav-link-item">
                                            <span className="bi bi-check-square-fill"></span>

                                            <span className="xn-text">
                                              {subMenu.label}
                                            </span>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              ))}
                        </div>
                      )}
                      {menuItems.name === "Orders" && (
                        <Link to="/orders">
                          <div
                            className={
                              window.location.hash === `#/orders`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/products.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">
                                  {menuItems.name}
                                </span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Products" && (
                        <Link to="/products">
                          <div
                            className={
                              window.location.hash === `#/products`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/products.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Products</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {/* ESSE CUSTOMISATION PART START */}
                      {/* {menuItems.name === "Machines" && (
                        <Link to="/machines">
                          <div
                            className={
                              window.location.hash === `#/machines`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/newMachine.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Machines</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )} */}
                      {/* {menuItems.name === "Amc" && (
                        <Link to="/annualContract">
                          <div
                            className={
                              window.location.hash === `#/annualContract`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/newAmc.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">AMC</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )} */}
                      {menuItems.name === "Service Request" && (
                        <Link to="/serviceRequest">
                          <div
                            className={
                              window.location.hash === `#/serviceRequest`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/newServiceRequest.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Service Request</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {/* ESSE CUSTOMISATION PART END */}

                      {menuItems.name === "Routes" && (
                        <Link to="/routes">
                          <div
                            className={
                              window.location.hash === `#/routes`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/routes.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Routes</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Forms" && (
                        <Link to="/forms">
                          <div
                            className={
                              window.location.hash === `#/forms`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/forms.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Forms</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {menuItems.name === "Spares" && (
                        <Link to="/spares">
                          <div
                            className={
                              window.location.hash === `#/spares`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li>
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/spares.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Spares</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Claims" && (
                        <div
                          className={
                            (subId === "claims" && open) ||
                              window.location.hash === `#/localclaim` ||
                              window.location.hash === `#/outstationclaim` ||
                              window.location.hash === `#/claimsummary`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="CLAIMS"
                            id="claims"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "claims" && open) ||
                                window.location.hash === `#/localclaim` ||
                                window.location.hash === `#/outstationclaim` ||
                                window.location.hash === `#/claimsummary`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="claims">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/claims.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="CLAIMS"
                                id="claims"
                              >
                                Claims
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="claims"
                              />
                            </div>
                          </li>

                          {(subId === "claims" && open) ||
                            window.location.hash === `#/localclaim` ||
                            window.location.hash === `#/outstationclaim` ||
                            window.location.hash === `#/claimsummary` ? (
                            <>
                              {menuItems.subNames.map((subMenu) => (
                                <div>
                                  {subMenu.name === "Local Claims" && (
                                    <Link to="/localclaim">
                                      <div
                                        className={
                                          window.location.hash === `#/localclaim`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Local Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Outstation Claims" && (
                                    <Link to="/outstationclaim">
                                      <div
                                        className={
                                          window.location.hash ===
                                            `#/outstationclaim`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Outstation Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Summary Claims" && (
                                    <Link to="/claimsummary">
                                      <div
                                        className={
                                          window.location.hash ===
                                            `#/claimsummary`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Summary Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      {menuItems.name === "Connector" && (
                        <div
                          className={
                            (subId === "connect" && open) ||
                              window.location.hash === `#/user/referrals` ||
                              window.location.hash === `#/referenceInfo` ||
                              window.location.hash === `#/commission` ||
                              window.location.hash === `#/payment`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="CONNECTOR"
                            id="connector"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "connect" && open) ||
                                window.location.hash === `#/user/referrals` ||
                                window.location.hash === `#/referenceInfo` ||
                                window.location.hash === `#/commission` ||
                                window.location.hash === `#/payment`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="connect">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/connector.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="CONNECT"
                                id="connect"
                              >
                                Connector
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="connect"
                              />
                            </div>
                          </li>
                          {(subId === "connect" && open) ||
                            window.location.hash === `#/user/referrals` ||
                            window.location.hash === `#/referenceInfo` ||
                            window.location.hash === `#/commission` ||
                            window.location.hash === `#/payment` ? (
                            <>
                              {menuItems.subNames.map((subMenu, index) => (
                                <div key={index}>
                                  {subMenu.name === "Ref User" && (
                                    <Link to="/user/referrals">
                                      <div
                                        className={
                                          window.location.hash ===
                                            `#/user/referrals`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            User Profile
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Reference Info" && (
                                    <Link to="/referenceInfo">
                                      <div
                                        className={
                                          window.location.hash ===
                                            `#/referenceInfo`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            Reference Info
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Commission" && (
                                    <Link to="/commission">
                                      <div
                                        className={
                                          window.location.hash ===
                                            `#/commission`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            Commission
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Payment" && (
                                    <Link to="/payment">
                                      <div
                                        className={
                                          window.location.hash === `#/payment`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Payment
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  ))}

                <div className="expanded-nav-bar">
                  <Link to="">
                    <div
                      className={
                        window.location.hash === ``
                          ? `sidebar-item open`
                          : `sidebar-item`
                      }
                    >
                      <li onClick={() => toggleSubMenu('main')} className={
                        (openSubMenuId === 'main') ||
                          window.location.hash === '/'
                          ? "nav-link-item drop-down-item active"
                          : "nav-link-item drop-down-item drop-main"
                      }>
                        <div className="sidebar-title">
                          <div className="fa-icon-div">
                            <img
                              src="../images/SideBar/spares.svg"
                              alt=""
                              width="100%"
                            />
                          </div>
                          <span className="xn-text">Form Repository</span>
                          <i className={`bi bi-chevron-${openSubMenuId === 'main' ? 'down' : 'right'} toggle-btn`} />
                        </div>
                      </li>
                      {openSubMenuId === 'main' && (
                        <div className="submenu">
                          {essaeMenuItems.map(menuItem => (
                            <div key={menuItem.dForm_ID}>
                              {menuItem.dForm_ModuleName && (
                                <div className="sidebar-item plain">
                                  <li>
                                    <div className="nav-link-item" style={{ padding: "0px 0px 0px 30px" }}>
                                      <span className="bi bi-check-square-fill"></span>
                                      <Link
                                        to={{
                                          // pathname: `${formPathMapping[menuItem.dForm_ModuleName.toLowerCase()]}/${menuItem.dForm_ID}`,
                                          pathname: `/${menuItem.dForm_ModuleName}/${menuItem.dForm_ID}`,
                                          state: { dForm_ID: menuItem.dForm_ID }
                                        }}
                                      >
                                        <span className="xn-text">{menuItem.dForm_ModuleName}</span>
                                      </Link>
                                    </div>
                                  </li>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
                <hr />
                <div className="expanded-nav-bar">
                  <div onClick={openAppQrCode}>
                    <div className={`sidebar-item plain`}>
                      <li>
                        <div className="nav-link-item d-flex">
                          <div className="fa-icon-div">
                            <img
                              src="../images/SideBar/downloadApp.svg"
                              alt=""
                              width="100%"
                            />
                          </div>
                          <span className="xn-text">Download App</span>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="expanded-nav-bar">
                  <div className={`sidebar-item plain`}>
                    <div className="powered-by-div">
                      <span>Powered By</span>
                      <div>
                        <img
                          src={SalesBee}
                          alt="product-title-img"
                          width="50%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              //  COLLAPSED NAV BAR START
              <div>
                {menuItems &&
                  menuItems.map((menuItems, i) => (
                    <div className="collapsed-nav-bar" key={i}>
                      {menuItems.name === "Dashboard" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-dashboard`}>
                              Dashboard
                            </Tooltip>
                          }
                        >
                          <li id="#dashboard">
                            <NavLink to="/dashboard">
                              <img
                                src="../images/SideBar/dashboard.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-desktop"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Human Resource" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-dashboard`}>
                              Human Resource
                            </Tooltip>
                          }
                        >
                          <li id="#dashboard">
                            <NavLink to="">
                              <img
                                src="../images/SideBar/users.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-desktop"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Users" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-users`}>Users</Tooltip>
                          }
                        >
                          <li id="#users">
                            <NavLink to="/users">
                              <img
                                src="../images/SideBar/users.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-group"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Attendance" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-meetings`}>
                              Attendance
                            </Tooltip>
                          }
                        >
                          <li id="#attendance" className="xn-openable">
                            <NavLink to="/attendance/monthly">
                              <img
                                src="../images/SideBar/attendance.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-calendar-check-o"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Client Complaint" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-meetings`}>
                              Complaints
                            </Tooltip>
                          }
                        >
                          <li id="leaves">
                            <NavLink to="/leaves">
                              <img
                                src="../images/SideBar/complaints.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="bi bi-ui-checks fa"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Leaves" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-leaves`}>Leaves</Tooltip>
                          }
                        >
                          <li id="leaves">
                            <NavLink to="/leaves">
                              <img
                                src="../images/SideBar/leaves.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-handshake-o"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Meetings" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-meetings`}>Meetings</Tooltip>
                          }
                        >
                          <li id="meetings">
                            <NavLink to="/meetings">
                              <img
                                src="../images/SideBar/meetings.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-handshake-o"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Clients" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-company`}>Company</Tooltip>
                          }
                        >
                          <li id="company" className="xn-openable">
                            <NavLink to="/company">
                              <img
                                src="../images/SideBar/company.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-user-circle"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Orders" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-orders`}>Orders</Tooltip>
                          }
                        >
                          <li id="orders">
                            <NavLink to="/orders">
                              <img
                                src="../images/SideBar/products.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-handshake-o"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Products" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-products`}>Products</Tooltip>
                          }
                        >
                          <li id="products" className="xn-openable">
                            <NavLink to="/products">
                              <img
                                src="../images/SideBar/products.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-cube"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Service Request" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-products`}>Service Request</Tooltip>
                          }
                        >
                          <li id="products" className="xn-openable">
                            <NavLink to="/serviceRequest">
                              <img
                                src="../images/SideBar/newServiceRequest.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-cube"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                      {menuItems.name === "Spares" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-spares`}>Spares</Tooltip>
                          }
                        >
                          <li id="#spares">
                            <NavLink to="/spares">
                              <img
                                src="../images/SideBar/spares.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-cogs"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Claims" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-meetings`}>Claim</Tooltip>
                          }
                        >
                          <li id="claims" className="xn-openable">
                            <NavLink to="/localclaim">
                              <img
                                src="../images/SideBar/claims.svg"
                                alt=""
                                width="20px"
                              />
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Forms" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-forms`}>Forms</Tooltip>
                          }
                        >
                          <li id="forms">
                            <NavLink to="/forms">
                              <img
                                src="../images/SideBar/forms.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-handshake-o"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Connector" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-connector`}>
                              Connector
                            </Tooltip>
                          }
                        >
                          <li id="connector">
                            <NavLink to="/refferal">
                              <img
                                src="../images/SideBar/connector.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-handshake-o"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}

                      {menuItems.name === "Routes" && (
                        <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-routes`}>Routes</Tooltip>
                          }
                        >
                          <li id="routes">
                            <NavLink to="/routes">
                              <img
                                src="../images/SideBar/routes.svg"
                                alt=""
                                width="20px"
                              />
                              {/* <span className="fa fa-handshake-o"></span> */}
                            </NavLink>
                          </li>
                        </OverlayTrigger>
                      )}
                    </div>
                  ))}
                <hr />
                <div className="collapsed-nav-bar">
                  <OverlayTrigger
                    placement={"right"}
                    delay={{ show: 250, hide: 300 }}
                    overlay={
                      <Tooltip id={`tooltip-meetings`}>Download App</Tooltip>
                    }
                  >
                    <li id="claims" className="xn-openable">
                      <div
                        onClick={openAppQrCode}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="../images/SideBar/downloadApp.svg"
                          alt=""
                          width="20px"
                        />
                      </div>
                    </li>
                  </OverlayTrigger>
                </div>
              </div>
              //  COLLAPSED NAV BAR END
            )}

            {/* END NAV LINK ITEMS */}
          </SidebarContent>
        </ProSidebar>
      </div>
    </div>
  );
}

export default Sidenavbar;
