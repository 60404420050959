import '../../styles/AddUserDetails.scss'
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import '../../styles/AddUserModel.scss'
import { useState, useEffect, useRef } from "react";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SalesBee from "../../images/MatricLogo.svg";
import DatePicker from "react-datepicker";
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import {
    loadCityList,
    loadClientDropDown,
    loadGetSingleClient,
    loadMachineDropdownData,
    loadOtherContacts,
    loadPostClientComplaint,
    loadProductsDropDown,
    loadUploadComplaintImages,
    loadUserByClient,
    updateComplaintStatus,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../BaseUrl";
import { priorityStatus, warrantyStatus } from "../DropdownsData";
import moment from 'moment';
import { formatDateToIST } from '../FormattedAmount';
import Geocode from "react-geocode";
import GoogleMapAddressModel from '../GoogleMapAddressModel';

const defaultServicetime = [
    { value: "24", label: "24" },
    { value: "36", label: "36" },
    { value: "48", label: "48" },
]

const AddServiceRequestData = (props) => {

    let compaintId = props.compaintId;
    let dispatch = useDispatch();
    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

    const { productDropdown } = useSelector((state) => state.data);
    const { clientDropdown } = useSelector((state) => state.data);
    const { contactByCompanyDrop } = useSelector((state) => state.data);
    const { userDropdownByCompany } = useSelector((state) => state.data);
    const { singleClientCompaint } = useSelector((state) => state.data);
    const { singleClient } = useSelector((state) => state.data);
    const { cityList } = useSelector((state) => state.data);
    const { getMachineNames } = useSelector((state) => state.data);
    console.log(getMachineNames, 'getMachineNames')

    useEffect(() => {
        dispatch(loadMachineDropdownData())
    }, [])

    const [localImage, setLocalImage] = useState("");
    const [imgFileName, setImgFileName] = useState("");
    const [imgFile, setImgFile] = useState("");
    const [amc, seAmc] = useState("");
    const [companyData, setCompanyData] = useState({ value: "", label: "" });
    const [reportedData, setReportedData] = useState({ value: "", label: "" });
    const [complaintDate, setComplaintDate] = useState(new Date());
    const [selectProduct, setSelectProduct] = useState({ value: "", label: "" });
    const [selectWarranty, setSelectWarranty] = useState({ value: 1, label: "Yes" });
    const [selectPriority, setSelectPriority] = useState({ value: 1, label: "Low" });
    const [selectAssign, setSelectAssign] = useState({ value: "", label: "" });
    const [selectBranch, setSelectBranch] = useState({ value: "", label: "" });
    const [selectDefaultServiceTime, setSelectDefaultServiceTime] = useState({ value: "", label: "" });

    const [isError, setIsError] = useState(false)
    const [phoneLength, setPhoneLength] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    useEffect(() => {
        dispatch(loadProductsDropDown());
        dispatch(loadClientDropDown());
        dispatch(loadCityList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hiddenFileInput = useRef(null);
    const [clientLocation, setClientLocation] = useState({
        latitude: "",
        longitude: "",
        location: "",
    });
    const [complaintData, setComplaintData] = useState({
        subscriptionID: resPassData.subscriptionId,
        userID: resPassData.userId, userFullName: resPassData.fullName, addedByID: "", addedByUserFullName: "",
        addedOn: "", status: 0, clientIDFk: 0, date: moment(new Date()).format("DD/MM/YYYY"), productName: "",
        productIDFK: "", clientEmail: "", clientPhone: "", issue: "", description: "",
        issueImage: "", inWarranty: 1, address: "", priority: 1,
        assingnedTo: "", adttenedByID: "", reportedBy: '', adttenedOn: "", solution: "", solutionImage: "",
        clientComplaints_Warranty_Till: "", amC_Number: "", amC_Status: "", default_Service_Time: "", clientComplaintsID: 0
    });

    const {
        issue, description, clientEmail, clientPhone,
    } = complaintData;

    useEffect(() => {
        if (compaintId && singleClientCompaint) {
            setComplaintData(singleClientCompaint)
            if (singleClientCompaint.date) {
                let comDate = formatDateToIST(singleClientCompaint.date);
                setComplaintDate(new Date(comDate));
                getMachineNames?.map(prod => {
                    if (prod.label === singleClientCompaint.productName) {
                        setSelectProduct(prod)
                    }
                })
                clientDropdown.map(comp => {
                    if (comp.value === singleClientCompaint.clientIDFk) {
                        dispatch(loadOtherContacts(comp.value));
                        dispatch(loadUserByClient(comp.value));
                        setCompanyData(comp)
                    }
                })
                warrantyStatus.map(warranty => {
                    if (warranty.value === singleClientCompaint.inWarranty) {
                        setSelectWarranty(warranty)
                    }
                })
                priorityStatus.map(prior => {
                    if (prior.value === singleClientCompaint.priority) {
                        setSelectPriority(prior)
                    }
                })
                // userDropdownByCompany?.map(assign => {
                //     if (assign.value === singleClientCompaint?.assingnedTo) {
                //         setSelectAssign(assign)
                //     }
                // })
                cityList?.map(branch => {
                    if (branch.label === singleClientCompaint?.branch) {
                        setSelectBranch(branch)
                    }
                })
                defaultServicetime?.map(time => {
                    if (time.value === singleClientCompaint?.default_Service_Time) {
                        setSelectDefaultServiceTime(time)
                    }
                })
            }
        }
    }, [compaintId, singleClientCompaint])

    useEffect(() => {
        if (singleClient && complaintData.clientIDFk) {
            setComplaintData({
                ...complaintData,
                clientEmail: singleClient?.email,
                clientPhone: singleClient?.mobile,
                // address: singleClient?.clientLoc?.location
            })
        }
    }, [singleClient])

    useEffect(() => {
        if (compaintId && singleClientCompaint && contactByCompanyDrop) {
            contactByCompanyDrop.map(contact => {
                if (contact.value == singleClientCompaint.addedByID) {
                    setReportedData(contact)
                }
            })
        }
    }, [compaintId, singleClientCompaint, contactByCompanyDrop])

    useEffect(() => {
        if (compaintId && singleClientCompaint && userDropdownByCompany) {
            userDropdownByCompany.forEach(assign => {
                if (assign.value === singleClientCompaint.assingnedTo) {
                    setSelectAssign(assign)
                }
            })
        }
    }, [compaintId, singleClientCompaint, userDropdownByCompany])

    const handleDropDown = (data, type) => {
        let list = complaintData;
        if (type === "company") {
            dispatch(loadGetSingleClient(data.value));
            dispatch(loadOtherContacts(data.value));
            dispatch(loadUserByClient(data.value));
            setCompanyData(data);
            setReportedData({ value: "", label: "" });
            setSelectAssign({ value: "", label: "" });
            list.clientIDFk = parseInt(data.value);
        } else if (type === "report") {
            setReportedData(data);
            list.addedByID = data.value.toString();
            list.reportedBy = data.label;
        } else if (type === "ComplaintDate") {
            setComplaintDate(data)
            list.date = moment(new Date(data)).format("DD/MM/YYYY")
        } else if (type === "product") {
            setSelectProduct(data)
            list.productName = data.label
            list.productCode = data.value
            list.description = data.description
            list.clientComplaints_Warranty_Till = data.warrantyDate || ""
            list.amC_Number = data.amcNumber || ""
            list.amC_Status = data.amcStatus || ""
            list.default_Service_Time = data.defaultServiceTime || ""
            list.productIDFK = parseInt(data.formName) || ""
        } else if (type === "warranty") {
            setSelectWarranty(data)
            list.inWarranty = parseInt(data.value)
        } else if (type === "priority") {
            setSelectPriority(data)
            list.priority = parseInt(data.value)
        } else if (type === "assign") {
            setSelectAssign(data)
            list.assingnedTo = data.value
        } else if (type === "branch") {
            setSelectBranch(data)
            list.branch = data.label
        }
        // else if (type === "defaultTime") {
        //     setSelectDefaultServiceTime(data)
        //     list.default_Service_Time = data.label
        // }
        setComplaintData(list)
    };

    const uploadClick = (e) => {
        e.preventDefault();
        hiddenFileInput.current.click();
    };

    const handleChange = (event) => {
        if (event.target.files.length !== 0) {
            const fileUploaded = event.target.files[0];
            setImgFile(fileUploaded);
            setImgFileName(event.target.files[0].name);

            const reader = new FileReader();
            reader.readAsDataURL(fileUploaded);
            reader.onloadend = () => {
                complaintData.issueImage = reader.result;
                setLocalImage(reader.result);
            };
        }
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        if (name === "clientPhone") {
            setComplaintData({ ...complaintData, [name]: parseInt(value) });
        } else {
            setComplaintData({ ...complaintData, [name]: value });
        }
    };

    const modleClose = () => {
        // props.setCompaintId("")
        props.handleClose();
        props.fetchComplaintdata()
    };

    const saveComplaints = (e) => {
        e.preventDefault();

        if (!complaintData.date || !complaintData.productCode || !complaintData.addedByID ||
            !issue || !complaintData.priority || !clientEmail || !clientPhone ||
            !complaintData.assingnedTo
        ) {
            setIsError(true)
            setErrorMessage("Required")
        } else {
            if (clientPhone && clientPhone.toString().length < 10) {
                setPhoneLength(true)

            } else {
                if (imgFile) {
                    const formData = new FormData();
                    if (imgFile) {
                        formData.append("Files", imgFile);
                    }
                    formData.append("Directory", "Complaint");
                    formData.append("FileNamePrefix", "Complaint" + imgFile.name);
                    var imgurlPost = {
                        method: "POST",
                        mode: "cors",
                        headers: HeaderAuthentication.salesbeeImgAuth,
                        body: formData,
                    };
                    dispatch(loadUploadComplaintImages(
                        imgurlPost,
                        complaintData,
                        modleClose,
                        compaintId
                    ))
                } else {
                    var postRequest = {
                        method: "POST",
                        mode: "cors",
                        headers: HeaderAuthentication.postTravelizeAuth,
                        body: JSON.stringify(complaintData),
                    };
                    if (compaintId) {
                        dispatch(updateComplaintStatus(postRequest, modleClose, "update"))
                    } else {
                        dispatch(loadPostClientComplaint(postRequest, modleClose))
                    }
                }
            }
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length !== 0) {
            const fileUploaded = files[0];
            setImgFile(fileUploaded);
            setImgFileName(files[0].name);

            const reader = new FileReader();
            reader.readAsDataURL(fileUploaded);
            reader.onloadend = () => {
                complaintData.issueImage = reader.result;
                setLocalImage(reader.result);
            };
        }
    };

    const [activeKey, setActiveKey] = useState('');

    const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
    const [showMachineDimensionsForm, setShowMachineDimensionsForm] = useState(false);
    const [showMachineImagesForm, setShowMachineImagesForm] = useState(false);
    const [showMachineDetails, setShowMachineDetails] = useState(false);
    const [showMachineDimensions, setShowMachineDimensions] = useState(false);
    const [showAmcDetails, setShowAmcDetails] = useState(false);
    const [showAmcDetailsForm, setShowAmcDetailsForm] = useState(false);
    const [showMachineDetailsHeader, setShowMachineDetailsHeader] = useState(true);
    const [amcDetailsHeader, setShowAmcDetailsHeader] = useState(false);
    const [showMachineDimensionHeader, setShowMachineDimensionHeader] = useState(false);
    const [showMachineImageHeader, setShowMachineImageHeader] = useState(false);
    const [activeMenu, setActiveMenu] = useState(1);

    const toggleMachineDetails = () => {
        setShowMachineDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowAmcDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(false)
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowMachineDetailsHeader(true)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
        setActiveMenu(1);
    }
    const toggleAmcDetails = () => {
        if (!complaintData.date || !complaintData.productCode || !complaintData.addedByID ||
            !complaintData.clientEmail || !complaintData.clientPhone ||
            !complaintData.assingnedTo
        ) {
            setIsError(true);
            setErrorMessage("Required");
            return;
        }
        setShowAmcDetailsForm(true)
        setShowMachineDimensionsForm(false);
        setShowMachineDetailsForm(false)
        setShowMachineImagesForm(false);
        setShowMachineDetails(true);
        setShowAmcDetails(false)
        setShowMachineDimensions(false)
        setShowAmcDetailsHeader(true)
        setActiveMenu(2);
        setActiveKey('0')

        setShowMachineDetailsHeader(false)
        setShowMachineDimensionHeader(false)
        setShowMachineImageHeader(false)
    };
    const toggleMachineImages = () => {
        if (!complaintData.date || !complaintData.productCode || !complaintData.addedByID ||
            !complaintData.clientEmail || !complaintData.clientPhone ||
            !complaintData.assingnedTo
        ) {
            setIsError(true);
            setErrorMessage("Required");
            return;
        }
        setShowMachineImagesForm(true);
        setShowMachineDetailsForm(false);
        setShowMachineDimensionsForm(false);
        setShowMachineDetails(true)
        setShowMachineDimensions(true)
        setShowAmcDetails(true);
        setShowAmcDetailsForm(false)
        setShowMachineImageHeader(true)
        setActiveMenu(4);
        // setActiveKey('0')
        // setActiveKey('1')
        setActiveKey(['0', '1']);

        setShowMachineDetailsHeader(false)
        setShowAmcDetailsHeader(false)
        setShowMachineDimensionHeader(false)
    };

    const closeModel = () => {
        props.handleClose();
    };

    const [location, setLocation] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [googleMapsModel, setGoogleMapsModel] = useState(false);
    const [address, setAddress] = useState("");
    const [latLan, setLatLan] = useState({
        lat: null,
        lng: null,
    });
    useEffect(() => {
        if (searchVal) {
            Geocode.fromAddress(searchVal).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;

                    setLatLan({
                        lat: lat,
                        lng: lng,
                    });
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    }, [searchVal]);

    const onMarkerDragEnd = (e) => {
        setLatLan({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        });
    };

    const searchLocation = (event) => {
        setSearchVal(event.target.value);
    };

    useEffect(() => {
        if (latLan.lat !== null && latLan.lat !== "") {
            Geocode.fromLatLng(latLan.lat, latLan.lng).then(
                (response) => {
                    const address = response.results[0].formatted_address;

                    setAddress(address);
                    setLocation(address);
                },
                (error) => {
                    console.error(error);
                }
            );
        } else {
            addressFromGoogle();
        }
    }, [latLan]);

    const addressFromGoogle = () => {
        if (googleMapsModel) {
            openGoogleMaps();
        }
    };
    const openGoogleMaps = () => {
        setGoogleMapsModel(!googleMapsModel);
        setAddress("");
    };
    useEffect(() => {
        if (latLan.lat == null && latLan.lng == null) {
            getLocation();
        } else {
            const newClientLocation = {
                latitude: JSON.stringify(latLan.lat),
                longitude: JSON.stringify(latLan.lng),
                location: location,
            };
            setClientLocation(newClientLocation);
        }
    }, [latLan, location]);

    useEffect(() => {
        setComplaintData((prevData) => ({
            ...prevData,
            address: clientLocation.location
        }));
    }, [clientLocation]);


    const getLocation = () => {
        if (!navigator.geolocation) {
            alert("Geolocation is not supported for your browser");
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                setLatLan({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            });
        }
    };

    return (
        <div
            show={props.show}
            onHide={props.handleClose}
        >
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className='machines-logo-div'>
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineDetails}>
                        <div className={`add-user-details-menu-numbers ${activeMenu === 1 ? 'active' : 'inactive'}`} >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name">Service Request Details</div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleAmcDetails}>
                        <div className="add-user-details-menus">
                            <div
                                // className="add-user-details-menu-numbers active"
                                className={`add-user-details-menu-numbers ${activeMenu === 2 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>02</span>
                                </p>
                                <div className="add-user-details-menu-name">Issue Details</div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineImages}>
                        <div className="add-user-details-menus">
                            <div
                                //  className="add-user-details-menu-numbers active"
                                className={`add-user-details-menu-numbers ${activeMenu === 3 ? 'active' : 'inactive'}`}
                            >
                                <p className="add-user-details-menu-number-one">
                                    <span>03</span>
                                </p>
                                <div className="add-user-details-menu-name">Images</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="add-user-details-second-div">
                    {showMachineDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Service Request Details</h4>
                            <p>Please enter infomation and proceed to the next step.</p>
                        </div>
                    </div>}
                    {amcDetailsHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleMachineDetails} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Issue Details</h4>
                            <p>Please enter issue details and proceed to the next step.</p>
                        </div>
                    </div>}
                    {showMachineImageHeader && <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={toggleAmcDetails} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Images</h4>
                            <p>Please upload images and proceed to the next step.</p>
                        </div>
                    </div>}

                    <div style={{ position: " relative" }} className="amc-single-image">

                        {showMachineDetailsForm && <form>
                            {/* <div className="row"> */}

                            <div className="col-lg-10 col">
                                <div>
                                    <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Service Request Date' className={`${complaintDate ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <DatePicker
                                                        selected={complaintDate}
                                                        className={`form-control form add-input meeting-date`}
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholder="Service Request Date"
                                                        onChange={(date) => handleDropDown(date, "ComplaintDate")}
                                                        maxDate={new Date()}
                                                        readOnly={compaintId}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Customer Name' className={`${companyData.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={clientDropdown}
                                                        placeholder="Customer Name"
                                                        value={companyData.value && companyData}
                                                        onInputChange={clientDropdown.label}
                                                        onChange={(data) => handleDropDown(data, "company")}
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                                {isError && !complaintData.clientIDFk && (
                                                    <small
                                                        id={`Error`}
                                                        className="form-text text-muted "
                                                    >
                                                        Company Name is {errorMessage}
                                                    </small>
                                                )}                                          </div>
                                        </div>
                                    </div>

                                    <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Customer Email' className={`${clientEmail ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <input
                                                        type="email"
                                                        name="clientEmail"
                                                        className="form-control form add-input"
                                                        placeholder="Customer Email"
                                                        value={clientEmail}
                                                        onChange={handleInput}
                                                    />
                                                </FloatingLabel>
                                                {isError && clientEmail == "" && (
                                                    <small
                                                        id={`Error`}
                                                        className="form-text text-muted "
                                                    >
                                                        Please Enter your email
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Phone Number' className={`${clientPhone ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <input
                                                        type="tel"
                                                        name="clientPhone"
                                                        className="form-control form add-input"
                                                        placeholder="Phone Number"
                                                        maxLength={10}
                                                        value={clientPhone || ''}
                                                        onChange={handleInput}
                                                    />
                                                </FloatingLabel>
                                                {isError && clientPhone == "" && (
                                                    <small
                                                        id={`Error`}
                                                        className="form-text text-muted "
                                                    >
                                                        Please Enter phone number
                                                    </small>
                                                )}
                                                {phoneLength && clientPhone.toString().length < 10 && (
                                                    <small
                                                        id={`Error`}
                                                        className="form-text text-muted "
                                                    >
                                                        Please Enter Valid Mobile Number
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row add-user-image-container-div">
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Reported By' className={`${reportedData.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={contactByCompanyDrop}
                                                        placeholder="Reported By"
                                                        value={reportedData.value && reportedData}
                                                        onInputChange={contactByCompanyDrop?.label}
                                                        onChange={(data) => handleDropDown(data, "report")}
                                                        isSearchable={true}
                                                        isDisabled={!companyData.value}
                                                    />
                                                </FloatingLabel>
                                                {isError && !complaintData.addedByID && (
                                                    <small
                                                        id={`Error`}
                                                        className="form-text text-muted "
                                                    >
                                                        Please select reported by
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Machine Name' className={`${selectProduct.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={getMachineNames}
                                                        placeholder="Machine Name"
                                                        value={selectProduct.value && selectProduct}
                                                        onInputChange={getMachineNames?.label}
                                                        onChange={(data) => handleDropDown(data, "product")}
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                                {isError && !complaintData.productCode && (
                                                    <small
                                                        id={`Error`}
                                                        className="form-text text-muted "
                                                    >
                                                        Please select Machine name
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row add-user-image-container-div">
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Machine Code' className={`${complaintData.productCode ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <input
                                                        type="text"
                                                        className="form-control form add-input"
                                                        placeholder="Machine Code"
                                                        readOnly={true}
                                                        value={complaintData.productCode}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Branch' className={`${selectBranch.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={cityList}
                                                        placeholder="Branch"
                                                        value={selectBranch.value && selectBranch}
                                                        onInputChange={cityList.label}
                                                        onChange={(data) => handleDropDown(data, "branch")}
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row add-user-image-container-div">
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Assign To' className={`${selectAssign.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={userDropdownByCompany}
                                                        placeholder="Assign To"
                                                        value={selectAssign.value && selectAssign}
                                                        onInputChange={userDropdownByCompany?.label}
                                                        onChange={(data) => handleDropDown(data, "assign")}
                                                        isSearchable={true}
                                                    />
                                                </FloatingLabel>
                                                {isError && !complaintData.assingnedTo && (
                                                    <small
                                                        id={`Error`}
                                                        className="form-text text-muted "
                                                    >
                                                        Please select Assigned to Name
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Default Service Time' className={`${complaintData.default_Service_Time !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="Default Service Time"
                                                        value={
                                                            complaintData.default_Service_Time
                                                                ? { label: complaintData.default_Service_Time, value: complaintData.default_Service_Time }
                                                                : null
                                                        }
                                                        isSearchable={true}
                                                        isDisabled
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row add-user-image-container-div">
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='AMC Number' className={`${complaintData.amC_Number ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <input
                                                        type="text"
                                                        className="form-control form add-input"
                                                        placeholder="AMC Number"
                                                        value={complaintData.amC_Number}
                                                        readOnly={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='AMC Status' className={`${complaintData.amC_Status !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        placeholder="AMC Status"
                                                        value={
                                                            complaintData.amC_Status
                                                                ? { label: complaintData.amC_Status, value: complaintData.amC_Status }
                                                                : null
                                                        }
                                                        readOnly={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                        <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Warranty Till' className={`${complaintData.clientComplaints_Warranty_Till !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <DatePicker
                                                        // selected={complaintData.clientComplaints_Warranty_Till}
                                                        value={complaintData.clientComplaints_Warranty_Till}
                                                        className={`form-control form add-input meeting-date`}
                                                        // dateFormat="dd/MM/yyyy"
                                                        placeholderText={"Warranty Till"}
                                                        readOnly={true}
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col">
                                            <div className="mb-3">
                                                <FloatingLabel label='Description' className={`${complaintData.description ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <textarea
                                                        rows="3"
                                                        name="issue"
                                                        wrap="hard"
                                                        className="form-control form-textarea add-product-input"
                                                        id="descriptionArea"
                                                        placeholder="Description"
                                                        value={complaintData.description}
                                                        readOnly={true}
                                                    />
                                                </FloatingLabel>                                       </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='machines-next-arrow-button'>
                                    <span className="material-symbols-outlined" onClick={toggleAmcDetails}>
                                        arrow_circle_right
                                    </span>
                                </div>
                            </div>
                            {/* </div> */}

                        </form>}

                        <div className="d-flex justify-content-between" style={{ padding: "10px 25px" }}>

                            <div className="col-lg-5 col">
                                {showAmcDetailsForm && <form>
                                    <div className="mb-3">
                                        <FloatingLabel label='Priority' className={`${selectPriority.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                            <Select
                                                className="react-select-container-list model-select"
                                                classNamePrefix="react-select-list"
                                                options={priorityStatus}
                                                placeholder="Priority"
                                                value={selectPriority.value && selectPriority}
                                                onInputChange={priorityStatus.label}
                                                onChange={(data) => handleDropDown(data, "priority")}
                                                isSearchable={true}
                                            />
                                        </FloatingLabel>
                                        {isError && !complaintData.priority && (
                                            <small
                                                id={`Error`}
                                                className="form-text text-muted "
                                            >
                                                Please select priority
                                            </small>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel label='Issue' className={`${issue ? "float-input-visible" : "float-hidden float-input"}`}>
                                            <textarea
                                                rows="4"
                                                name="issue"
                                                wrap="hard"
                                                className="form-control form-textarea add-product-input"
                                                id="descriptionArea"
                                                placeholder="Issue"
                                                value={issue || ''}
                                                onChange={handleInput}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className="mb-3 client-location-by-map">
                                        <FloatingLabel label='Location' className={`${location ? "float-input-visible" : "float-hidden float-input"}`}>
                                            <textarea
                                                rows="4"
                                                name="address"
                                                wrap="hard"
                                                className="form-control form-textarea add-product-input"
                                                id="descriptionArea"
                                                placeholder="Location"
                                                value={location}
                                                // onChange={handleInput}
                                                onChange={(e) => setLocation(e)}
                                                readOnly={true}
                                            />
                                        </FloatingLabel>
                                        <i
                                            className="bi bi-geo-alt"
                                            title="Google Location"
                                            onClick={openGoogleMaps}
                                        />
                                    </div>
                                    <div className='machines-next-arrow-button'>
                                        <span className="material-symbols-outlined" onClick={toggleMachineImages}>
                                            arrow_circle_right
                                        </span>
                                    </div>
                                </form>}

                                {showMachineImagesForm && <form>
                                    <div className="mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-add-product-img"
                                        >
                                            <span
                                                style={{
                                                    color: "#04A96D",
                                                    fontSize: "13px",
                                                    fontWeight: "700",
                                                }} >

                                                <div className="drop-area"
                                                    onDragOver={(e) => e.preventDefault()}
                                                    onDrop={handleDrop} >
                                                    {(!localImage && !singleClientCompaint?.issueImage) ? <>
                                                        <h6>Upload Issue Attachment</h6>
                                                        <p>Drag and drop images here</p>
                                                        <p>OR</p>
                                                    </>
                                                        :
                                                        <>
                                                            {!localImage &&
                                                                Object.keys(singleClientCompaint).length !== 0 &&
                                                                singleClientCompaint?.issueImage ?
                                                                <img
                                                                    className="complaint-img"
                                                                    src={`${baseURL}/${singleClientCompaint?.issueImage}`}
                                                                    alt="Product Images"
                                                                />
                                                                :
                                                                <img
                                                                    className="complaint-img"
                                                                    src={localImage}
                                                                    alt="Product Images"
                                                                />
                                                            }
                                                        </>
                                                    }
                                                    <SbCancelBtn btnName="Browse" onClickEffect={uploadClick} />
                                                    <p style={{ fontSize: "11px" }}>Supports Only JPG, JPEG, PNG</p>
                                                </div>

                                            </span>
                                        </button>
                                        <input
                                            id="getFile"
                                            type="file"
                                            multiple
                                            name="filetobase64"
                                            accept="image/*"
                                            className="btn-primary upload"
                                            style={{ display: "none" }}
                                            ref={hiddenFileInput}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="add-page-save-cancel">
                                        <SBSaveUpdateBtn btnName={compaintId ? "Update" : "Save"} onClickEffect={saveComplaints} />
                                        <SbCancelBtn
                                            btnName="Cancel"
                                            // onClickEffect={() => {
                                            //     props.handleClose();
                                            // }}
                                            onClickEffect={closeModel}
                                        />
                                    </div>
                                </form >}
                            </div>
                            {/* <AccordianPage
                            showMachineDetails={showMachineDetails}
                            showAmcDetails={showAmcDetails}
                            showMachineDimensions={showMachineDimensions}
                            activeKey={activeKey} /> */}

                            <div className="col-lg-6 col machine-details-cards">
                                <Accordion defaultActiveKey="0" activeKey={activeKey} flush>
                                    <Card>
                                        {showMachineDetails && (
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header >
                                                    Service Request Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div className="amc-second-container-labels">
                                                            <span>
                                                                <p>Service Request Date</p>
                                                                : <b>{moment(complaintDate).format('DD/MM/YYYY')}</b>
                                                            </span>
                                                            <span>
                                                                <p>Customer Name</p>
                                                                : <b>{companyData.label}</b>
                                                            </span>
                                                            <span>
                                                                <p>Customer Email</p>
                                                                : <b>{clientEmail}</b>
                                                            </span>
                                                            <span>
                                                                <p>Customer Phone Number</p>
                                                                : <b>{clientPhone}</b>
                                                            </span>
                                                            <span>
                                                                <p>Reported By</p>
                                                                : <b>{reportedData.label}</b>
                                                            </span>
                                                            <span>
                                                                <p>Machine Name</p>
                                                                : <b>{selectProduct.label}</b>
                                                            </span>
                                                            <span>
                                                                <p>Machine Code</p>
                                                                : <b>{complaintData.productCode}</b>
                                                            </span>
                                                            <span>
                                                                <p>Branch</p>
                                                                : <b>{selectBranch.label}</b>
                                                            </span>
                                                            <span>
                                                                <p>Assigned To</p>
                                                                : <b>{selectAssign.label}</b>
                                                            </span>
                                                            <span>
                                                                <p>Default Service Time</p>
                                                                : <b>{selectDefaultServiceTime.value}</b>
                                                            </span>
                                                            <span>
                                                                <p>AMC Number</p>
                                                                : <b>{complaintData.amC_Number}</b>
                                                            </span>
                                                            <span>
                                                                <p>AMC Status</p>
                                                                : <b>{complaintData.amC_Status}</b>
                                                            </span>
                                                            <span>
                                                                <p>Warranty Till</p>
                                                                : <b>{complaintData.clientComplaints_Warranty_Till}</b>
                                                            </span>
                                                            <span>
                                                                <p>Description</p>
                                                                : <b>{complaintData.description}</b>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                        {showAmcDetails && (
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header >
                                                    Issue Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="amc-second-container">
                                                        <div>
                                                            <div className="amc-second-container-labels">
                                                                <span>
                                                                    <p>Priority</p>
                                                                    : <b>{selectPriority.label}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Issue</p>
                                                                    : <b>{issue}</b>
                                                                </span>
                                                                <span>
                                                                    <p>Location</p>
                                                                    : <b>{location}</b>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}

                                    </Card>
                                </Accordion>
                            </div>

                        </div>

                    </div>
                </div >

            </div >
            {googleMapsModel === true && (
                // <LoadScript googleMapsApiKey={React_Google_API_Key}>
                <GoogleMapAddressModel
                    googleMapsModel={googleMapsModel}
                    addressFromGoogle={addressFromGoogle}
                    openGoogleMaps={openGoogleMaps}
                    latLan={latLan}
                    searchLocation={searchLocation}
                    searchVal={searchVal}
                    onMarkerDragEnd={onMarkerDragEnd}
                    address={address}
                />
            )}
        </div >
    );
}

export default AddServiceRequestData;