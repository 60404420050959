import React, { useEffect, useState } from "react";
import "../../styles/Login.scss";
import "../../styles/CommonStyle.scss";
import { Button, Form, Alert, Image, Row, Col } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import travelizeSalesBeeLogo from "../../images/MatricLogo.svg";
import travelize from "../../images/metric-favicon.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { NumberValidation } from "../ValidationFormat";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCountryCodes,
  loadReferredByDropdowndata,
} from "../../redux/action";
import Select from "react-select";
import CryptoJS from 'crypto-js';

function CreateSubscription() {
  let themeColor = localStorage.getItem("theme");
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { getReferredByDropdownData } = useSelector((state) => state.data);
  const { countryCodes } = useSelector((state) => state.data);

  useEffect(() => {
    dispatch(loadReferredByDropdowndata());
    dispatch(loadCountryCodes());
  }, []);

  const location = useLocation();
  const [locationPathNames, setLocationPathNames] = useState([]);
  const [showReferredByDropdown, setShowReferredByDropdown] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const nameParam = searchParams.get("name");
    setLocationPathNames(location.pathname.split("/"));

    if (nameParam) {
      setShowReferredByDropdown(false);
    } else {
      setShowReferredByDropdown(true);
    }
  }, [location]);

  // function getUrlParameter(name) {
  //   const urlParams = new URLSearchParams(
  //     window.location.search + window.location.hash
  //   );
  //   return urlParams.get(name);
  // }

  // // Get the 'name' parameter from the URL
  // const nameFromUrl = getUrlParameter("name");

  const pathname = location.pathname;

  function getUrlParameter(name) {
    const encryptedParam = window.location.hash.split('?')[1];
    if (encryptedParam) {
      try {
        // Decrypt the encrypted parameter value
        const bytes = CryptoJS.AES.decrypt(encryptedParam, 'yourSecretKey123');
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        console.log("Decrypted Value:", decryptedValue);

        // Extract the 'name' and 'email' parameters from the decrypted value
        const paramsArray = decryptedValue.split('&');
        let nameValue = null;
        let emailValue = null;
        paramsArray.forEach(param => {
          const [key, value] = param.split('=');
          if (key === 'name') {
            nameValue = decodeURIComponent(value);
          } else if (key === 'email') {
            emailValue = decodeURIComponent(value);
          }
        });

        // Return the 'name' and 'email' parameter values
        return { name: nameValue, email: emailValue };
      } catch (error) {
        console.error("Decryption Error:", error);
        return null;
      }
    }
    return null;
  }

  // Get the 'name' and 'email' parameters from the decrypted URL
  const { name: nameFromUrl, email: emailFromUrl } = getUrlParameter();

  console.log("Name from URL:", nameFromUrl);
  console.log("Email from URL:", emailFromUrl);

  // Define placeholder text based on the pathname
  let placeholderText = "";
  switch (pathname) {
    case "/Trial":
      placeholderText = "Source";
      break;
    case "/Trial/BDE":
      placeholderText = "Referred By";
      break;
    // Add more cases for other pathnames
    default:
      placeholderText = "Source";
  }

  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Update dropdown options based on the pathname
    const pathname = location.pathname;

    switch (pathname) {
      case "/Trial":
        const trialOptions = [
          { value: "Website", label: "Website" },
          { value: "Email", label: "Email" },
          { value: "BusinessTeam", label: "Business Team" },
          { value: "SocialMedia", label: "Social Media" },
        ];
        setOptions(trialOptions);
        break;
      case "/Trial/BDE":
        // Use options from the state data
        setOptions(getReferredByDropdownData);
        break;
      // Add more cases for other pathnames
      default:
        // Use the default options
        setOptions([]);
    }
  }, [location.pathname, getReferredByDropdownData]);

  const [companyName, setCompanyName] = useState("");
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [contactNo, setContactNo] = useState(null);
  const [subscriptionResMessage, setSubscriptionResMessage] = useState({
    success: null,
    message: null,
  });
  const [referredBy, setReferredBy] = useState({
    value: "",
    label: "",
  });
  const [captchaVerification, setCaptchaVerification] = useState(true);

  let currentYear = new Date().getFullYear();

  const onChange = (value) => {
    // console.log("CAPTCHA:", value);
    if (value !== "" && value !== null) {
      setCaptchaVerification(true);
    }
  };

  const handleReferredByChange = (selectedOption) => {
    setReferredBy(selectedOption.label);
  };

  const createSubscription = (e) => {
    if (captchaVerification) {
      if (email && companyName && contactNo && countryCode) {
        const subscriptionData = {
          subscriptionTypeID: 0,
          companyName: companyName,
          clientName: clientName,
          email: email + (emailFromUrl ? ',' + emailFromUrl : ''),
          countryCode: countryCode,
          contact: contactNo,
          createdByUserName: nameFromUrl || referredBy,
          password: `MetricInfo_${currentYear}`, // Password for New Subscriptions
        };
        let requestoption = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(subscriptionData),
        };
        
        console.log(subscriptionData,'subscriptionData')
        fetch(`${baseURL}/api/Subscription/AddSubscription`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            console.log(res, "res");
            if (res.success) {
              setSubscriptionResMessage({
                success: res.success,
                message: "Your Subscription has Created Successfully",
              });
              console.log(locationPathNames, "locationPathNames");
              if (locationPathNames.includes("BDE")) {
                const userNamePassword = {
                  UserName: email,
                  Password: `MetricInfo_${currentYear}`,
                };
                let requestoption = {
                  method: "POST",
                  mode: "cors",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(userNamePassword),
                };
                fetch(`${baseURL}/api/Authentication/Login`, requestoption)
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.success) {
                      sessionStorage.setItem(
                        "usdtls",
                        JSON.stringify(res.result)
                      );

                      //// CHECK SUBSCRIPTION EXPIRY
                      let requestOptionS = {
                        method: "GET",
                        mode: "cors",
                        headers: {
                          "Content-Type": "application/json",
                          Accept: "application/json",
                          Travelize_Authentication: res.result,
                        },
                      };
                      fetch(
                        `${baseURL}/api/DashBoard/GetLoginUserDetails`,
                        requestOptionS
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          const userLoginDetails = res.result;
                          navigate("/signupSubscription", {
                            state: userLoginDetails,
                          });
                          window.location.reload();
                        })
                        .catch((error) => {
                          console.log("ERROR :", error);
                        });
                    } else {
                      console.log(res, "res.errorMessage");
                    }
                  })
                  .catch((error) => {
                    console.log("ERROR :", error);
                  });
              } else {
                // resetAll();
              }
            } else {
              setSubscriptionResMessage({
                success: res.success,
                message: res.errorMessage,
              });
            }
          });
      } else {
        setSubscriptionResMessage({
          success: false,
          message: "Please fill all the fields",
        });
      }
    } else {
      setSubscriptionResMessage({
        success: false,
        message: "Captcha is Required",
      });
    }
    e.preventDefault();
  };

  const resetAll = () => {
    setTimeout(
      () =>
        setSubscriptionResMessage({
          success: null,
          message: null,
        }),
      5000
    );
    window.location.reload();
  };

  const [trailFlag, setTrailFlag] = useState(false);
  useEffect(() => {
    let url = window.location.href;
    if (url.includes("Trail")) {
      setTrailFlag(true);
    }
  }, [window.location.href]);

  return (
    <div>
      <div>
        <div>
          <div
            className="main-body"
            style={{ backgroundImage: 'url("images/Login_Page/bg4.jpg")' }}
          >
            <div className="login-page-body subscription-box">
              <Row
                className="sb-login-row create-subscription"
                style={{ position: "relative" }}
              >
                {trailFlag && (
                  <div className="beta-tag-trail">
                    <h6>Trail</h6>
                  </div>
                )}
                <Col xl={5} lg={5} md={5} className="sb-gif-col col-left">
                  <div className="w-100">
                    <div className="sb-login-logo-div create-subscription">
                      <Image
                        src={travelizeSalesBeeLogo}
                        alt="MetricInfo"
                        className="sb-login-logo metricinfo"
                      />
                    </div>
                    <div className="pt-2">
                      <h6 className="login-head-text create-subscription">
                        Sign Up
                      </h6>
                    </div>
                    <div className="sb-form-section">
                      <form
                        onSubmit={createSubscription}
                        className="login-form"
                      >
                        <div className="userName-input">
                          <input
                            type="text"
                            className="form-control create-subscription-input"
                            id="cpname"
                            placeholder="Company Name"
                            onChange={(e) => setCompanyName(e.target.value)}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="userName-input">
                          <input
                            type="text"
                            className="form-control create-subscription-input"
                            id="clname"
                            placeholder="Contact Person"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="userName-input">
                          <input
                            type="email"
                            className="form-control create-subscription-input"
                            id="subemail"
                            placeholder="Email ID"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                            required
                          />
                        </div>
                        {/* <div className="userName-input d-flex"> */}
                        <div className="contact-input sub-inputs d-flex">
                          <Form.Select
                            aria-label="Default select example"
                            name="countrycode"
                            className="cntry-code"
                            value={countryCode}
                            onChange={(event) =>
                              setCountryCode(event.target.value)
                            }
                          >
                            {countryCodes.map((data, i) => (
                              <option key={i}>{data?.value}</option>
                            ))}
                          </Form.Select>
                          <input
                            type="tel"
                            className="form-control "
                            id="contact"
                            placeholder="Mobile Number"
                            maxLength={10}
                            value={contactNo}
                            onChange={(e) => setContactNo(e.target.value)}
                            onKeyDown={NumberValidation}
                            required
                            autoComplete="off"
                          />
                        </div>
                        <div className="referred-by">
                          {showReferredByDropdown && (
                            <Select
                              className="react-select-container-list model-select"
                              styles={{ margin: "0px" }}
                              classNamePrefix="react-select-list"
                              // options={getReferredByDropdownData}
                              options={options}
                              // placeholder="Referred By"
                              placeholder={placeholderText}
                              value={referredBy.value && referredBy}
                              onChange={handleReferredByChange}
                              isSearchable={true}
                            />
                          )}
                        </div>
                        {/* <div className="recaptcha-part">
                          <ReCAPTCHA
                            sitekey="6LcC984nAAAAAA3eYGW7xlxb12ooQRUCu1TkFHum"
                            onChange={onChange}
                            size={"normal"}
                            style={{
                              transform: "scale(0.7)",
                              transformOrigin: "1 0",
                            }}
                          />
                        </div> */}
                        <Row>
                          <Col md={12} className="p-0">
                            <div className="forget-pass-login-button">
                              <Button
                                type="submit"
                                className="btn btn-login-button create-subscription"
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <div className="pt-1" style={{ textAlign: "center" }}>
                          <p style={{ fontWeight: "600" }}>
                            Do you have an account ?{" "}
                            <a href="#/login" className="login-signup-text">
                              Sign In
                            </a>
                          </p>
                        </div>
                      </form>
                      <div className="subscription-res-message">
                        {subscriptionResMessage.success ? (
                          <Alert variant="success">
                            {subscriptionResMessage.message}
                          </Alert>
                        ) : subscriptionResMessage.success == false ? (
                          <Alert variant="danger">
                            {subscriptionResMessage.message}
                          </Alert>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={2} md={2} className="col-right">
                  <div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                    <div className="travelize-log">
                      <Image
                        src={travelize}
                        alt="Now Travelize is SalesBee"
                        className="sb-trv-logo"
                      />
                    </div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                  </div>
                </Col>
                <Col xl={5} lg={5} md={5} className="sb-gif-col col-right">
                  <div>
                    <div className="sb-login-bgimg">
                      <Image
                        src={`../images/Login/laptop-motion-${themeColor ? themeColor : "light"
                          }.gif`}
                        height="350"
                        alt="Now Travelize is SalesBee"
                        className="w-100"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSubscription;
