import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Image, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../../styles/ProductsViewPage.scss";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { loadPageBreadCome, loadSingleMachine, loadSingleProduct } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../BaseUrl";
import { FormattedAmount } from "../FormattedAmount";
import Skeleton from "react-loading-skeleton";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function MachinesViewPage() {
  let navigate = useNavigate();
  let { machineId } = useParams();
  let dispatch = useDispatch();
  // const { singleProduct } = useSelector((state) => state.data);
  const { getSingleMachineData } = useSelector((state) => state.data);
  console.log(getSingleMachineData, 'getSingleMachineData')

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // useEffect(() => {
  //   let breadCome = { mainpage: "Products" };
  //   dispatch(loadPageBreadCome(breadCome));
  //   if (prodId) {
  //     dispatch(loadSingleProduct(prodId, setIsLoading));
  //   }
  // }, [prodId]);

  useEffect(() => {
    if (machineId) {
      dispatch(loadSingleMachine(machineId))
    }
  }, [machineId])

  const [galleryImages, setGalleryImages] = useState([]);
  useEffect(() => {
    if (Object.keys(getSingleMachineData).length !== 0) {
      let spareImgs = [];
      if (getSingleMachineData[0].machineGalaryImage) {
        spareImgs.push(getSingleMachineData[0].machineGalaryImage.split(","));
        spareImgs.unshift(getSingleMachineData[0].machineImage);
      } else if (!getSingleMachineData[0].machineGalaryImage && getSingleMachineData[0].machineImage) {
        spareImgs.push(getSingleMachineData[0].machineImage);
      }
      console.log(spareImgs.flat(), "spareImgs.flat()");
      setGalleryImages(spareImgs.flat());
    }
  }, [getSingleMachineData]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">MACHINE DETAILS</h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col"></Col>
              </Row>
            </div>

            <div className="product-details-model">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="product-detail-left-part">
                    <div className="product-big-img">
                      {isLoading ? (
                        <div style={{ padding: "20px 0px" }}>
                          <Skeleton />
                        </div>
                      ) : (
                        <Swiper
                          spaceBetween={10}
                          navigation={false}
                          thumbs={{ swiper: thumbsSwiper }}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper2"
                        >
                          {galleryImages.length != 0 &&
                            galleryImages.map((data, i) => (
                              <SwiperSlide>
                                <Image
                                  src={`${baseURL}/${data}`}
                                  alt="attachment-photo"
                                  className="product-img"
                                />
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      )}
                    </div>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <div className="carousel-sliding-part">
                        <Swiper
                          style={{
                            "--swiper-navigation-color": "#000000",
                            "--swiper-pagination-color": "#000000",
                            "--swiper-navigation-size": "13px",
                            "--swiper-navigation-sides-offset": "0px",
                          }}
                          onSwiper={setThumbsSwiper}
                          spaceBetween={5}
                          slidesPerView={4}
                          // slidesPerGroup={1}
                          loadSingleProduct
                          navigation={true}
                          freeMode={true}
                          watchSlidesProgress={true}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper"
                        >
                          {galleryImages.length != 0 &&
                            galleryImages.map((data, i) => (
                              <SwiperSlide key={i}>
                                <div className="product-small-images-row">
                                  <div className="product-small-image">
                                    <Image
                                      src={`${baseURL}/${data}`}
                                      alt="attachment-photo"
                                      className="product-img"
                                    />
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-8 col-sm-12">
                  {isLoading ? (
                    <div className="product-detail-right-part">
                      <div className="product-name">
                        <Skeleton />
                      </div>
                      <div className="product-price">
                        <Skeleton />
                      </div>
                      <div className="description-cont">
                        <Skeleton />
                      </div>
                      <div className="description-cont">
                        <Skeleton />
                      </div>
                      <div className="specifications">
                        <Skeleton />
                        <div className="products-specifications">
                          <Skeleton />
                        </div>
                        <div className="products-specifications">
                          <Skeleton />
                        </div>
                        <div className="products-specifications">
                          <Skeleton />
                        </div>
                        <div className="products-specifications">
                          <Skeleton />
                        </div>
                        <div className="products-specifications">
                          <Skeleton />
                        </div>
                        <div className="products-specifications">
                          <Skeleton />
                        </div>
                        <div className="products-specifications">
                          <Skeleton />
                        </div>
                        <div className="products-specifications">
                          <Skeleton />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="product-detail-right-part">
                      <div className="product-name">
                        <h1>
                          <span>{getSingleMachineData[0]?.machineName}</span>
                        </h1>
                      </div>
                      <div className="product-price">
                        <div className="product-discount-price">
                          ₹ {FormattedAmount(getSingleMachineData[0]?.retailPrice)}
                        </div>
                        <div className="product-mrp-price">
                          ₹ {FormattedAmount(getSingleMachineData[0]?.mrp)}
                        </div>
                      </div>
                      <div className="specifications">
                        <div className="sub-header">Description </div>
                        <p className="description-about-product">
                          {getSingleMachineData[0]?.description}{" "}
                        </p>
                      </div>
                      <div className="specifications">
                        <div className="sub-header">Instruction </div>
                        <p className="description-about-product">
                          {getSingleMachineData[0]?.instruction}{" "}
                        </p>
                      </div>
                      <div className="specifications">
                        <div className="sub-header">Specifications</div>
                        <div className="machine-specification-container">
                          <div>
                            {getSingleMachineData[0]?.machineName && (
                              <div className="machines-specifications">
                                <span>Product/Machine Name </span>
                                <b>:</b>
                                <b>{getSingleMachineData[0]?.machineName}</b>
                              </div>
                            )}
                            {getSingleMachineData[0]?.machineCode && (
                              <div className="machines-specifications">
                                <span>Product/Machine Code </span>
                                <b>:</b>
                                <b>{getSingleMachineData[0]?.machineCode}</b>
                              </div>
                            )}
                            {getSingleMachineData[0]?.category && (
                              <div className="machines-specifications">
                                <span>Category </span>
                                <b>:</b>
                                <b>{getSingleMachineData[0]?.category}</b>
                              </div>
                            )}
                            {getSingleMachineData[0]?.subCategory && (
                              <div className="machines-specifications">
                                <span>Sub-Category </span>
                                <b>:</b>
                                <b>{getSingleMachineData[0]?.subCategory}</b>
                              </div>
                            )}
                            {getSingleMachineData[0]?.brand && (
                              <div className="machines-specifications">
                                <span>Brand </span>
                                <b>:</b>
                                <b>{getSingleMachineData[0]?.brand}</b>
                              </div>
                            )}
                            {/* {getSingleMachineData?.shape && (
                              <div className="machines-specifications">
                                <span>Shape </span>
                                <b>:</b>
                                <b>{getSingleMachineData?.shape}</b>
                              </div>
                            )} */}
                            {/* {getSingleMachineData?.color && (
                              <div className="machines-specifications">
                                <span>Color </span>
                                <b>:</b>
                                <b>{getSingleMachineData?.color}</b>
                              </div>
                            )} */}
                            {/* {getSingleMachineData?.size && (
                              <div className="machines-specifications">
                                <span>Size </span>
                                <b>:</b>
                                <b>{getSingleMachineData?.size}</b>
                              </div>
                            )} */}
                          </div>
                          <div>
                            {getSingleMachineData[0]?.height *
                              getSingleMachineData[0]?.width *
                              getSingleMachineData[0]?.length !==
                              0 && (
                                <div className="machines-specifications">
                                  <span>Dimension(H*W*L) </span>
                                  <b>:</b>
                                  <b>
                                    {getSingleMachineData[0]?.height} * {getSingleMachineData[0]?.width} *{" "}
                                    {getSingleMachineData[0]?.length}
                                  </b>
                                </div>
                              )}
                            {getSingleMachineData[0]?.weight && (
                              <div className="machines-specifications">
                                <span>Weight </span>
                                <b>:</b>
                                <b>{getSingleMachineData[0]?.weight}</b>
                              </div>
                            )}
                            {getSingleMachineData?.mrp && (
                              <div className="machines-specifications">
                                <span>MRP </span>
                                <b>:</b>
                                <b>
                                  <i className="fa fa-inr" /> {getSingleMachineData[0]?.mrp}
                                </b>
                              </div>
                            )}
                            <div className="machines-specifications">
                              <span>Capacity </span>
                              <b>:</b>
                              <b>
                                {getSingleMachineData[0]?.capacity}
                              </b>
                            </div>
                            <div className="machines-specifications">
                              <span>Location </span>
                              <b>:</b>
                              <b>
                                {getSingleMachineData[0]?.location}
                              </b>
                            </div>
                            <div className="machines-specifications">
                              <span>Warranty Status </span>
                              <b>:</b>
                              <b>
                                {getSingleMachineData[0]?.warrentyStatus}
                              </b>
                            </div>
                            <div className="machines-specifications">
                              <span>AMC Status </span>
                              <b>:</b>
                              <b>
                                {getSingleMachineData[0]?.awcStatus ? getSingleMachineData[0]?.awcStatus : "NA"}
                              </b>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
