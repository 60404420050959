import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loadAllCreatedCustomeForms } from "../../../redux/action";
import Footer from "../../Footer";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SkeletonTableData from "../../Skeleton Loading/SkeletonTableData";
import PrintForm from "../DragDropForm/PrintForm";

const DisplayAllForms = () => {

    let tableHeader = [
        { name: "MODULE NAME", align: "left", width: "200px" },
        { name: "FORM NAME", align: "left", width: "200px" },
        { name: "ACTION", align: "left", width: "200px" },
    ];

    let dispatch = useDispatch();
    const { dForm_ModuleName, dForm_ID } = useParams();
    const { getAllCustomeFrom } = useSelector((state) => state.data);

    const [isLoading, setIsLoading] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [menuCollapse, setMenuCollapse] = useState(false);

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const [parsedTableData, setParsedTableData] = useState([])

    useEffect(() => {
        dispatch(loadAllCreatedCustomeForms());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    let navigate = useNavigate();
    const handleOpenCustomForm = () => {
        navigate(`/settings/DForm`)
    }

    const goToBackPage = () => {
        navigate(-1);
    };

    const [showCreatedForm, setShowCreatedForm] = useState(false)
    const [dFormId, setDeformId] = useState("")
    const handleEditDynamicForm = (formId) => {
        setShowCreatedForm(true)
        setDeformId(formId)
        // navigate(`/createdform/${dForm_ID}`, { state: { rowId: id } })
        navigate(`/settings/DForm`, { state: { dFormId: formId } })
    }

    return (
        <div id="main-page">
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        <div className="clients-table">
                            <Row className="search-row">
                                <Col md={3} className="cat-col">
                                    <div className="page-header-text-div">
                                        <div className="back-btn" onClick={goToBackPage}>
                                            <img
                                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="page-header-text">
                                            ALL DYNAMIC FORMS
                                        </h6>
                                    </div>
                                </Col>
                                {/* <Col md={5} className="cat-col">
                                    <div className="input-div">
                                        <input
                                            type="search"
                                            id="metricInfo_search_bar"
                                            placeholder="Search..."
                                            className="form-control setting-input"
                                        // value={searchValue}
                                        // onChange={(e) => setSearchData(e.target.value)}
                                        />
                                        <i className="fa fa-search search-icon" />
                                    </div>
                                </Col> */}
                                <Col md={9} className="cat-col">
                                    <div
                                        className="search-bar-header"
                                        style={{ float: "right" }}
                                    >
                                        <div className="page-top-bar-dropdowns page-top-Filters">
                                            {/* <div
                                                className="header-filter-btn"
                                                onClick={() => setFilter(!filter)}
                                            >
                                                <p className="card-head">
                                                    <i className="bi bi-sliders" />
                                                    &nbsp;&nbsp;Filters
                                                </p>
                                            </div> */}
                                            <SBSaveUpdateBtn onClickEffect={handleOpenCustomForm} btnName="Create Form" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <div className="sb-table-div sb-table-setion">
                                <Paper
                                    sx={{ width: "100%", overflow: "hidden" }}
                                    className="table-main-div"
                                >
                                    <TableContainer
                                        sx={{ maxHeight: 440 }}
                                    // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                                    >
                                        <Table
                                            stickyHeader
                                            aria-label="sticky table table-responsive"
                                            className="basefare-table"
                                        >
                                            <TableHead className="custom-table-header">
                                                <TableRow>
                                                    {tableHeader.map((name, i) => (
                                                        <TableCell
                                                            key={i}
                                                            align={name.align}
                                                            className={`thead-cell-products`}
                                                            style={{ minWidth: name.width }}
                                                        >
                                                            {name.name}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            {isLoading ? (
                                                <TableBody>
                                                    {tableHeader.map((name, i) => (
                                                        <SkeletonTableData key={i} />
                                                    ))}
                                                </TableBody>
                                            ) : (
                                                <>
                                                    {getAllCustomeFrom && getAllCustomeFrom.length === 0 ? (
                                                        <TableBody>
                                                            <TableRow align="center">
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={tableHeader.length}
                                                                >
                                                                    <div style={{ padding: "20px 20px" }}>
                                                                        <img
                                                                            src="../../../../images/DefaultImages/NodataFound.jpg"
                                                                            alt=""
                                                                            style={{ width: "10%" }}
                                                                        />
                                                                        <br />
                                                                        <span>No Data Found...!</span>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    ) : (
                                                        <TableBody>
                                                            {getAllCustomeFrom &&
                                                                getAllCustomeFrom.map((data, i) => (
                                                                    <TableRow
                                                                        align="center"
                                                                        key={i}
                                                                        className="client-list-row-data"
                                                                    >
                                                                        <TableCell>{data.dForm_ModuleName}</TableCell>
                                                                        <TableCell>{data.dForm_FormName}</TableCell>
                                                                        <TableCell>
                                                                            <button className="btn client-edit-link" onClick={() => { handleEditDynamicForm(data.dForm_ID) }}>
                                                                                <i class="bi bi-pencil-fill"></i>&nbsp; Edit
                                                                            </button>
                                                                        </TableCell>
                                                                    </TableRow>))}
                                                        </TableBody>
                                                    )}
                                                </>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </div>
                    </div >
                </div >
                <Footer />
            </div >
            {showCreatedForm && <PrintForm />}
        </div >
    );
}

export default DisplayAllForms;