import React from "react";

function OnScrollLoading(props) {
  return (
    <div id="preloader">
      <div id="loading-wrapper" className="show">
        <div id="loading-text">
          <img
            src="../../images/DefaultImages/loading-logo.svg"
            alt="metricinfo-logo"
          />
        </div>
        <div id="loading-content"></div>
      </div>
    </div>
  );
}

export default OnScrollLoading;

export const handleScroll = (
  event,
  clientComplaints,
  totalCount,
  setLoading,
  setPageSize,
  PageSize
) => {
  if (clientComplaints) {
    if (clientComplaints.length == totalCount) {
      event.preventDefault();
    } else {
      const element = event.target;
      // Do something when the element is scrolled
      const isCloseToEnd =
        element.scrollTop + element.clientHeight >= element.scrollHeight - 1;
      if (!isCloseToEnd) {
        event.preventDefault();
      } else {
        if (isCloseToEnd) {
          event.preventDefault();
          if (clientComplaints.length < totalCount) {
            event.preventDefault();
            setLoading(true);
            setPageSize(PageSize + 50);
          }
        }
      }
    }
  }
};
