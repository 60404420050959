import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DisplayForm from "./DisplayForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { travelizeAuth } from "../../HeaderAuthentication";
import { baseURL } from "../../BaseUrl";
import { useDispatch } from "react-redux";
import { loadSingleRowFormData } from "../../../redux/action";
import { useSelector } from "react-redux";

const DisplayCreatedForm = () => {

    

    useEffect(() => {
        getUserForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [parsedData, setParsedData] = useState({})

    const { dForm_ID } = useParams();

    const getUserForm = () => {
        let requestOption = {
            method: "GET",
            mode: "cors",
            headers: travelizeAuth,
        };

        fetch(
            `${baseURL}/api/Essae/GetDForm/${dForm_ID}`,
            requestOption
        )
            .then((res) => res.json())
            .then((res) => {
                setParsedData(JSON.parse(res.result.dForm_JsonUI));
            })
            .catch((error) => {
                console.log("ERROR :", error);
            });
    };
    

    const { getSingleRowFormData } = useSelector((state) => state.data);

    const location = useLocation();
    const rowId = location.state?.rowId;
    const dispatch = useDispatch()
    useEffect(() => {
        if (rowId) {
            dispatch(loadSingleRowFormData(rowId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [singleMachineData, setSingleMachineData] = useState([]);
    
    useEffect(() => {
        if (rowId && getSingleRowFormData && getSingleRowFormData.length > 0) {
            setSingleMachineData(getSingleRowFormData[0][0].data);
        } else {
            // let formFieldsData = []
            if(Object.keys(parsedData).length !== 0){
                const extractedFields = extractFieldsData(parsedData);
            
            setSingleMachineData(extractedFields);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedData]);

    const extractFieldsData = (data) => {
        const extractedFieldsData = [];
        data.fields.forEach(item => {
            item.rowData.forEach(field => {
                    extractedFieldsData.push({
                        "dForm_ID": parseInt(dForm_ID),
                        "iForm_ModuleName": data.moduleName,
                        "iForm_FieldValue": field.value ? field.value : "",
                        "iForm_FieldName": field.Label,
                    });
            });
        });
        return extractedFieldsData;
    };

    

    let navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };

    return (
        <div className="main-without-sidebar">
            <div className="custom-form-row">
                <div className="form-edit-col">
                    <div className="form-box">
                        <div className="form-header-card">
                            <div className="form-header" style={{ alignItems: "center" }}>
                                <div className="back-btn" onClick={goToBackPage}>
                                    <img
                                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                        alt=""
                                    />
                                </div>
                                <div className={`header-item`}>
                                    <Form.Control
                                        type="text"
                                        value={parsedData?.Label}
                                        style={{ textAlign: `${parsedData?.alignment}` }}
                                        className={`custom-form-control font-${parsedData?.fontSize}`}
                                        readOnly
                                    />
                                    <p style={{ textAlign: `${parsedData?.alignment}` }}>
                                        {parsedData?.subheading}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: "5px",
                                height: "80%",
                                overflow: "auto",
                            }}
                        >
                            {Object.keys(parsedData).length !== 0 && parsedData.fields.length !== 0 &&
                                parsedData.fields.map((field, index) => (
                                    <div className="form-header" tabIndex={0}>
                                        <Row className="header-item field-item-card">
                                            {field.rowData.map((rowData, row_i) => (
                                                <Col key={row_i} className={`item-col-data`} >
                                                    <DisplayForm formItem={rowData} fieldId={field.id} formId={parsedData.id}
                                                        parsedData={parsedData} setParsedData={setParsedData}
                                                        setSingleMachineData={setSingleMachineData} singleMachineData={singleMachineData} />
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DisplayCreatedForm;
