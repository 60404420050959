import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Leaves.scss";
import "../../styles/CommonStyle.scss";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

function UpdateManagerModel(props) {
  const [managerList, setManagerList] = useState([]);
  const [managerID, setManagerID] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });
  const [designationList, setDesignationList] = useState([]);
  const [reportDesignation, setReportDesignation] = useState(0);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchDropdown();
  }, [reportDesignation]);

  const fetchDropdown = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      });
    fetch(
      `${baseURL}/api/User/BindTopManagerDropDown/${reportDesignation}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("Manager-List::", res.result);
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      });
  };

  const [fieldReq, setFieldReq] = useState(false);
  const updateManager = () => {
    if (!reportDesignation || !managerList) {
      setFieldReq(true);
    } else {
      const updateManagerDetails = {
        managerId: managerID,
        userId: props.updateUserId,
        desingation: {
          designationID: reportDesignation,
        },
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(updateManagerDetails),
      };
      fetch(`${baseURL}/api/User/UpdateManager`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("UPDATE-MANAGER-RES::", res);
          if (res.success) {
            props.handleClose();
            props.getManagerSubordinates();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            props.handleClose();
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
          // props.getManagerSubordinates();
        });
    }
  };

  return (
    <div>
      <Modal
        show={props.updateManagerModel}
        onHide={props.handleClose}
        className="main_model"
      >
        <div className="update-leavestatus-heading">
          <h6 className="page-header-text">ASSIGN TO</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="row model-add-user-body">
              <div className="mb-3">
                <FloatingLabel
                  label="Designation"
                  className={`${
                    reportDesignation != 0
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={designationList[null]}
                    options={designationList}
                    placeholder="Designation"
                    value={designationList.value}
                    onInputChange={designationList.label}
                    onChange={(data) => setReportDesignation(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && !reportDesignation && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Designation
                  </small>
                )}
              </div>
              <div className="mb-1">
                <FloatingLabel
                  label="Select Manager"
                  className={`${
                    managerID
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={managerList}
                    placeholder="Select Manager"
                    value={managerList.value}
                    onInputChange={managerList.label}
                    onChange={(data) => setManagerID(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && !managerID && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Manager
                  </small>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={updateManager} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
      <></>
    </div>
  );
}

export default UpdateManagerModel;
