import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import "../../styles/AddPage.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import Footer from "../Footer";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import AnnualContractTable from "./AnnualContractTable";
import AnnualContractAttachments from "./AnnualContractAttachments";
import AddBulkAnnualContract from "./AddBulkAnnualContract";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import DatePicker from "react-datepicker";
import Select from "react-select";
import EditAnnualContract from "./EditAnnualContract";
import moment from "moment";
import SbAddBtn, { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import AddAnnualContractData from "./AddAnnualContractData";
import AddAnnualContract from "./AddAnnualContract";
import { useDispatch, useSelector } from "react-redux";
import { loadAmcData, loadClientDropDown, loadFormData, loadProductsDropDown, loadSingleAmcData } from "../../redux/action";


const amcTypeOptions = [
  { value: "Comprehensive AMC", label: "Comprehensive AMC" },
  { value: "Non-Comprehensive AMC", label: "Non-Comprehensive AMC" },
]

const expiryOptions = [
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Semi Annually", label: "Semi Annually" },
  { value: "Annually", label: "Annually" },
];


function AnnualContract() {
  const { dForm_ID } = useParams();
  console.log('dForm_ID:', dForm_ID);
  let tableHeader = [
    { name: "AMC DETAILS", align: "left", width: "200px" },
    { name: "PRODUCT DETAILS", align: "left", width: "200px" },
    { name: "COMPANY DETAILS", align: "left", width: "200px" },
    { name: "ACTION", align: "left", width: "200px" },
  ];

  let dispatch = useDispatch();

  const { getAmcData } = useSelector((state) => state.data);
  const { clientDropdown } = useSelector((state) => state.data);
  const { productDropdown } = useSelector((state) => state.data);
  const { getFromData } = useSelector((state) => state.data);

  const amcData = [
    {
      number: "AMC-12345",
      type: "Premium",
      invoiceDate: "14-02-2024",
      startDate: "14-02-2024",
      endDate: "14-02-2025",
      freeService: "365 days",
      productName: "Mobile",
      code: "M-001",
      serialNo: "SL0012345",
      modalName: "Galaxy S3",
      modalNo: "GS-01",
      companyName: "Cargo Electronics",
      contactPerson: "Dummy",
      email: "cargoelectronics@gmail.com",
      phone: "9848488488",
      address:
        "1st Floor,Mallikarjun Avenue,Koppikar Road,Hubli-580023,D|| Dharwad, Karnataka",
      lat: "12.9813217,77.57657",
      long: "12.9813217,77.57657",
    },
  ];

  let navigate = useNavigate();
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [showBulk, setShowBulk] = useState(false);

  const [menuCollapse, setMenuCollapse] = useState(false);

  const [amcType, setAmcType] = useState({ value: "", label: "" });
  const [filterClientName, setFilterClientName] = useState({
    value: "",
    label: "",
  });
  const [filterProductName, setFilterProductName] = useState({
    value: "",
    label: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [PageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const [expiry, setExpiry] = useState({ value: "", label: "" })

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [pageDataSize, setPageDataSize] = useState(0);
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const handleClose = () => {
    setShow(!show);
  };

  const handleCloseBulk = () => {
    setShowBulk(!showBulk);
  };

  const [viewAttachments, setViewAttachments] = useState("");

  const handleViewAttachments = (amcId) => {
    setAmcNum(amcId)
    navigate("/annualContractAttachments", { state: { amcId: amcId } });
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  //* EXPORT FILE START *//

  const [showEdit, setShowEdit] = useState(false)
  const [amcNum, setAmcNum] = useState("")
  const [filteredAmc, setFilteredAmc] = useState([]);
  const EditAmc = (amcId) => {
    setAmcNum(amcId)
    setShow(!show);
    // const filteredAmc = getAmcData.filter((data) => { return data.amcNumber === amcId })
    // console.log(filteredAmc, 'filteredAmc')
    // setFilteredAmc(filteredAmc)
  }
  const handleCloseEdit = () => {
    setShowEdit(false)
  }
  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "ClientName") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "CompanyName");
      }
      list.push({ key: "CompanyName", value: data.label });
      setFilterClientName(data);
    } else if (type === "productName") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ProductName");
      }
      list.push({ key: "ProductName", value: data.label });
      setFilterProductName(data);
    }
    else if (type === "amc") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "AMCType");
      }
      list.push({ key: "AMCType", value: data.value });
      setAmcType(data);
    }
    else if (type === "expiry") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "expiry");
      }
      list.push({ key: "expiry", value: data.value });
      setExpiry(data);
    }

    setFilterArray(list);
  };

  useEffect(() => {
    // dispatch(loadAmcData());
    dispatch(loadClientDropDown());
    dispatch(loadProductsDropDown());
  }, [])

  useEffect(() => {
    fetchAMCData(filterArray, PageSize, currentPage);
  }, [filterArray, PageSize, currentPage]);

  const fetchAMCData = () => {
    dispatch(
      loadAmcData(
        filterArray,
        currentPage,
        PageSize,
        setIsLoading,
        setLoading
      )
    );
  }

  const clearSerachBySelect = () => {
    setAmcType({ value: "", label: "" });
    setFilterClientName({ value: "", label: "" });
    setFilterProductName({ value: "", label: "" });
    setExpiry({ value: "", label: "" });
    setSearchValue("");
    setFilterArray([]);
  };

  const handleOpenAddForm = ()=>{
    navigate(`/createdform/${dForm_ID}`)
  }

  const [parsedData, setParsedData] = useState([])
  console.log(parsedData,'parsedData')

  useEffect(() => {
    dispatch(loadFormData());
  }, [])

  useEffect(() => {
    if (getFromData && getFromData.length !== 0) {
      // console.log(getFromData, 'getFromData');
      let parsedDataArray = getFromData.map(item => {
        if (item.iForm_FieldValue) {
          let parsedArray = JSON.parse(item.iForm_FieldValue);
          item.iForm_FieldValue = parsedArray;
        }
        // let parsedArray = JSON.parse(item.iForm_FieldValue);

        // item.iForm_FieldValue = parsedArray;
        return item;
      });
      setParsedData(parsedDataArray);
      console.log(parsedDataArray, 'parsedDataArray')

    }
  }, [getFromData]);

  return (
    <div id="main-page">
      {!show && !showBulk && !viewAttachments && !showEdit && (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div className="clients-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          ANNUAL MAINTENANCE CONTRACT
                        </h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          id="metricInfo_search_bar"
                          placeholder="Search"
                          className="form-control setting-input"
                          value={searchValue}
                        //   onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon" />
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={handleOpenAddForm} />

                          {/* <SbDropDownBtn
                            openAddModal={() => setShow(!show)}
                            // openAddModal={handleClose}
                            addBulkModel={handleCloseBulk}
                          /> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {filter && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={12}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={clientDropdown}
                                  placeholder="Select Company"
                                  value={filterClientName.value && filterClientName}
                                  onInputChange={clientDropdown.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "ClientName")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={productDropdown}
                                  placeholder="Product Name"
                                  value={filterProductName.value && filterProductName}
                                  onInputChange={productDropdown.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "productName")
                                  }
                                  isSearchable={true}
                                />
                              </div>

                              <div style={{ width: "100%", padding: "0px 5px" }}>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={amcTypeOptions}
                                  placeholder="Amc Type"
                                  value={amcType.value && amcType}
                                  onInputChange={amcTypeOptions.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "amc")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={expiryOptions}
                                  placeholder="Expiry"
                                  value={expiry.value && expiry}
                                  onInputChange={expiryOptions.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "expiry")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                                className="input-group from-to-date-select"
                              >
                                <SbCancelBtn
                                  onClickEffect={clearSerachBySelect}
                                  btnName="Clear"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                <AnnualContractTable
                  tableHeader={tableHeader}
                  getAmcData={getAmcData}
                  handleViewAttachments={handleViewAttachments}
                  EditAmc={EditAmc}
                  amcNum={amcNum}
                  parsedData={parsedData}
                />
              </div>
              <div className="download-and-pagination">
                <SbDownloadBtn
                  btnName="Download"
                // onClickEffect={exportComplaintDetails}
                />
              </div>
            </div>

            <Footer />
          </div>
        </>
      )}
      {/* {show && <AddAnnualContract show={show} handleClose={handleClose} />} */}
      {show && <AddAnnualContractData show={show} handleClose={handleClose} amcNum={amcNum} setAmcNum={setAmcNum} />}
      {showBulk && (
        <AddBulkAnnualContract
          showBulk={showBulk}
          handleCloseBulk={handleCloseBulk}
        />
      )}
      {showEdit && <EditAnnualContract showEdit={showEdit} handleCloseEdit={handleCloseEdit} />}
      {viewAttachments && <AnnualContractAttachments />}
    </div>
  );
}

export default AnnualContract;
