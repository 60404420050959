import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import bikeicon from "../../images/bike.svg";
import caricon from "../../images/car.svg";
import busicon from "../../images/bus.svg";
import autoicon from "../../images/auto.svg";
import railicon from "../../images/railway.svg";
import metroicon from "../../images/metro.png";
import taxiicon from "../../images/taxiicon.png";
import moticon from "../../images/mot.svg";

import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { dateFormatLocal } from "../FormattedAmount";
import ShowCustomeFieldData from "./ShowCustomeFieldData";
import { Link } from "react-router-dom";

function MeetingTable(props) {

  const [travelledTimes, setTravelledTimes] = useState([]);

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    // Calculate travelledTime for each meeting
    if (Array.isArray(props.meetingDetails)) {
      const calculatedTimes = props.meetingDetails.map((data) => {
        const dateTimeString = data?.meetingStatusUpDate ?? '';
        const [datePart, timePart] = dateTimeString.split(' ');

        const inTimeStr = data?.inTime || '';
        const meetingDate = new Date(datePart);

        const [inTimeHours, inTimeMinutes] = inTimeStr.replace(/[^0-9:]/g, '').split(':').map(Number);
        meetingDate.setHours(inTimeHours, inTimeMinutes);

        if (!isNaN(meetingDate.getTime())) {
          const meetingDateTimeString = `${datePart} ${timePart}`;
          const meetingDateTime = new Date(meetingDateTimeString);

          if (!isNaN(meetingDateTime.getTime())) {
            const travelledTime = (meetingDateTime.getTime() - meetingDate.getTime()) / 1000;
            data.travelledTimes = formatTime(travelledTime);
          } else {
            data.travelledTimes = "";
          }

        } else {
          console.log('Invalid inTime format', data);
        }

        return data;
      });

      setTravelledTimes(calculatedTimes);
    }
  }, [props.meetingDetails]);



  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.meetingDetails,
              props.totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table stickyHeader aria-label="sticky table table-responsive">
            <TableHead className="custom-table-header">
              <TableRow className="table-row">
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    className={`thead-cell-products`}
                    style={{ minWidth: `${name.width}` }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <TableBody>
                {props.tableHeader.map((name, i) => (
                  <SkeletonTableData key={i} />
                ))}
              </TableBody>
            ) : (
              <>
                {!props.meetingDetails || props.meetingDetails.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>No Data Found...!</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody style={{ position: "relative" }}>
                    {props.meetingDetails.map((data, i) => (
                      <TableRow
                        align="center"
                        key={i}
                        className="client-list-row-data table-row"
                      >
                        <TableCell>
                          <p className="table-row-head-Text">
                            {data?.fullName}
                          </p>
                          <div className="table-row-sub-text">
                            {data?.userCode}
                          </div>
                          <p>{data?.stateName}</p>
                        </TableCell>
                        <TableCell>
                          <span className="client-meet d-flex">
                            <p>
                              <b className="color-green">
                                {data?.clientName} -{" "}
                              </b>
                            </p>
                            &nbsp;
                            <p className="client-meet-status">
                              {data?.meetingType}
                            </p>
                          </span>
                          <i className="fa fa-user mtdtls-icon" />
                          &nbsp;
                          {data?.contactPersonName ??
                            data?.contactPerson ??
                            data?.clientName}
                          <br />
                          <i className="fa fa-mobile mtdtls-icon" /> &nbsp;
                          {data?.contactPersonNumber ?? data?.contact}
                          <br />
                          <i className="fa fa-thumb-tack mtdtls-icon" /> &nbsp;
                          {data?.clientName === "Check-Out"
                            ? data?.endLoc?.location
                            : data?.clientLoc?.location}
                          <span className="client-meet-location">
                            <p className="client-meet-lat">
                              <span className="color-yellow">
                                <i className="bi bi-geo-alt" />
                              </span>
                              {data?.startLoc?.latitude ?? "NA"},
                              {data?.startLoc?.longitude ?? "NA"}
                            </p>
                            <p className="client-meet-lan">
                              <span className="color-yellow">
                                <i className="bi bi-geo-alt" />
                              </span>
                              {data?.endLoc?.latitude ?? "NA"},
                              {data?.endLoc?.longitude ?? "NA"}
                            </p>
                          </span>
                        </TableCell>
                        <TableCell>
                          <p>
                            {dateFormatLocal(data?.meetingDate)},{" "}
                            {data?.meetingTime}
                          </p>
                        </TableCell>
                        <TableCell>
                          <span className="client-meet-purpose d-flex">
                            <p>
                              <b>Purpose :</b>
                              <span className="client-meet-purpose-status">
                                &nbsp;{data?.purpose ?? "NA"}
                              </span>
                            </p>
                          </span>
                          {/* <span className="client-meet-purpose d-flex">
                            <p>
                              <b>Travelled Time :</b>
                              <span className="client-meet-purpose-status">
                                &nbsp;{data.travelledTimes ?? "NA"}
                              </span>
                            </p>
                          </span> */}
                          <span className="client-meet-purpose d-flex">
                            {data?.meetingType === "Scheduled" && (
                              <p>
                                <b>Travelled Time :</b>
                                <span className="client-meet-purpose-status">
                                  &nbsp;{data.travelledTimes ?? "NA"}
                                </span>
                              </p>
                            )}
                          </span>
                          <p>
                            In Time :{" "}
                            {data?.inTime === null || data?.inTime === ""
                              ? "NA"
                              : data?.inTime}
                          </p>
                          <p>
                            Out Time :{" "}
                            {data?.outTime === null || data?.outTime === ""
                              ? "NA"
                              : data?.outTime}
                          </p>
                          <p>
                            <b>Duration : </b> &nbsp;{data?.timeSpent}
                          </p>
                          <p>
                            <b>Distance Travelled : </b>
                            &nbsp;
                            {data?.distanceTravelled === null ||
                              data?.distanceTravelled === ""
                              ? 0
                              : data?.distanceTravelled}
                          </p>
                          <p>
                            <b>Manual Route : </b> &nbsp;
                            {data?.setRootStatus ?? "NA"}
                          </p>
                        </TableCell>
                        <TableCell>
                          <div style={{ textAlign: "left" }}>
                            <div>
                              <Image
                                style={{ marginLeft: "2px" }}
                                src={
                                  data?.mot == "Bike" || data?.mot == "bike"
                                    ? bikeicon
                                    : data?.mot == "Car" || data?.mot == "car"
                                      ? caricon
                                      : data?.mot == "Bus" || data?.mot == "bus"
                                        ? busicon
                                        : data?.mot == "Auto" || data?.mot == "auto"
                                          ? autoicon
                                          : data?.mot == "Train" ||
                                            data?.mot == "train"
                                            ? railicon
                                            : data?.mot == "Taxi" ||
                                              data?.mot == "taxi" ||
                                              data?.mot == "Cab" ||
                                              data?.mot == "cab"
                                              ? taxiicon
                                              : data?.mot == "Metro" ||
                                                data?.mot == "metro"
                                                ? metroicon
                                                : data?.mot == "NA" || data?.mot == null
                                                  ? ""
                                                  : moticon
                                }
                                className={
                                  data?.mot == "Bus" ||
                                    data?.mot == "bus" ||
                                    data?.mot == "bus" ||
                                    data?.mot == "Taxi" ||
                                    data?.mot == "taxi" ||
                                    data?.mot == "Cab" ||
                                    data?.mot == "cab" ||
                                    data?.mot == "Metro" ||
                                    data?.mot == "metro" ||
                                    data?.mot == "NA" ||
                                    data?.mot == null
                                    ? "mot-icon-sml"
                                    : "mot-icon"
                                }
                              />
                              <br />
                              {data?.mot}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {data?.lessRemark === "NA" ? (
                            data?.lessRemark
                          ) : (
                            <OverlayTrigger
                              placement={"bottom"}
                              delay={{ show: 250, hide: 300 }}
                              overlay={
                                <Tooltip id={`tooltip-remarks`}>
                                  {data?.remark}
                                </Tooltip>
                              }
                            >
                              <div>{data?.lessRemark}</div>
                            </OverlayTrigger>
                          )}
                        </TableCell>
                        <TableCell><ShowCustomeFieldData meetingId={data?.meetingID} /></TableCell>
                        <TableCell align="center">
                          <p className={`status-badge ${data?.status}`}>
                            {data?.status === "PCancelled"
                              ? "Pre-Cancelled"
                              : data?.status == "Re-Schedule" ||
                                data?.status == "Re-schedule"
                                ? "Re-Scheduled"
                                : data.status ?? "-"}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <span
                            className="meeting-detail-action-td"
                            style={{
                              display:
                                data.meetingType === "Check-Out" ||
                                  data.status === "Check-Out" ||
                                  data.status === "PCancelled" ||
                                  data.status === "Pcancelled" ||
                                  data.status === "Pending" ||
                                  data.status === "pending" ||
                                  data.status === "Re-Schedule" ||
                                  data.status === "Re-schedule" ||
                                  data.status === "Expired" ||
                                  data.status === "expired"
                                  ? "none"
                                  : "block",
                            }}
                          >
                            <button
                              onClick={() => {
                                props.setMeetingattachmentmodel(
                                  !props.meetingattachmentmodel
                                );
                                props.setMeetingId(data?.meetingID);
                              }}
                              className="meeting-details-view"
                            >
                              <i className="fa fa-paperclip" /> View
                            </button>
                            {/* <button
                              className="meetingdetail-viewroute-link"
                              onClick={() => {
                                props.setViewonuserroute(
                                  !props.viewonuserroute
                                );
                                props.setMeetingId(data?.meetingID);
                              }}
                            >
                              <i className="bi bi-geo-alt-fill"></i> View Route
                            </button> */}
                          </span>
                          <button
                            onClick={() => {
                              const startLatitude = data?.startLoc?.latitude ?? "NA";
                              const startLongitude = data?.startLoc?.longitude ?? "NA";
                              const endLatitude = data?.endLoc?.latitude ?? "NA";
                              const endLongitude = data?.endLoc?.longitude ?? "NA";

                              const mapUrl = `https://www.google.com/maps/dir/${startLatitude},${startLongitude}/${endLatitude},${endLongitude}`;
                              window.open(mapUrl, "_blank");
                            }}
                            className="meetingdetail-viewroute-link">

                            <i className="bi bi-geo-alt-fill"></i> View Route
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default MeetingTable;
