import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import AddBaseFareModel from "./AddBaseFareModel";
import { baseURL } from "../../BaseUrl";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import {
  optionsMOTTypes,
  optionsMeetStatusList,
} from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import BaseFareTable from "./BaseFareTable";
import {
  getSingleBasefare,
  loadBaseFare,
  loadDepartmentDrop,
  loadDesignationDrop,
  loadMotDrop,
  loadPageBreadCome,
  loadSingleBaseFare,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import Footer from "../../Footer";

function BaseFare() {
  let tableHeader = [
    // { name: "NAME", align: "left", width: "150px" },
    { name: "DESIGNATION", align: "left", width: "300px" },
    { name: "MOT TYPE", align: "left", width: "150px" },
    { name: "BASEFARE", align: "left", width: "150px" },
    { name: "UNIT", align: "left", width: "150px" },
    { name: "LIMIT PER DAY", align: "left", width: "160px" },
    { name: "ADDED BY", align: "left", width: "150px" },
    { name: "STATUS", align: "center", width: "150px" },
    { name: "ACTION", align: "center", width: "200px" },
  ];

  let dispatch = useDispatch();
  const { departmentDrop } = useSelector((state) => state.data);
  const { desigationdrop } = useSelector((state) => state.data);
  const { baseFareList } = useSelector((state) => state.data);
  console.log(baseFareList, "baseFareList");
  const { motDrop } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [basefareId, setBasefareId] = useState("");
  const [EditmotID, setEditMotID] = useState("");
  const [clickType, setClickType] = useState("");
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(20);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [filterArray, setFilterArray] = useState([]);
  const [selectMot, setSelectMot] = useState({ value: "", label: "" });
  const [selectMotType, setSelectMotType] = useState({ value: "", label: "" });
  const [selectStatus, setSelectStatus] = useState({ value: "", label: "" });
  const [selectDepartment, setSelectDepartment] = useState({
    value: "",
    label: "",
  });
  const [selectDesignation, setSelectDesignation] = useState({
    value: "",
    label: "",
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "motID") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "MotID");
      } else {
        list = [];
      }
      list.push({ key: "MotID", value: data.value });
      setSelectMot(data);
    } else if (type === "motType") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "MotType");
      } else {
        list = [];
      }
      list.push({ key: "MotType", value: data.value });
      setSelectMotType(data);
    } else if (type === "Status") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "Status");
      } else {
        list = [];
      }
      list.push({ key: "Status", value: data.value });
      console.log(data);
      setSelectStatus(data);
    } else if (type === "Department") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "DepartmentID");
      } else {
        list = [];
      }
      list.push({ key: "DepartmentID", value: data.value });
      setSelectDepartment(data);
    } else if (type === "Designation") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "DesignationID");
      } else {
        list = [];
      }
      list.push({ key: "DesignationID", value: data.value });
      setSelectDesignation(data);
    }
    setFilterArray(list);
  };

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const editBasefare = (baseId, motId, type) => {
    if (baseId !== 0) {
      dispatch(loadSingleBaseFare(baseId));
      setBasefareId(baseId);
      setEditMotID("");
    } else {
      setBasefareId("");
      setEditMotID(motId);
      baseFareList.forEach((basefare) => {
        if (basefare.motID === motId) {
          dispatch(getSingleBasefare(basefare));
        }
      });
    }
    if (type) {
      setClickType(type);
    } else {
      setClickType("");
    }
    setShow(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "BaseFare" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadMotDrop());
    dispatch(loadDesignationDrop());
    dispatch(loadDepartmentDrop());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBaseFareData(filterArray, PageSize, currentPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterArray, PageSize, currentPage]);

  const fetchBaseFareData = () => {
    dispatch(
      loadBaseFare(filterArray, currentPage, PageSize, setIsLoading, setLoading)
    );
  };

  const [openFilterTab, setOpenFilterTab] = useState(false);
  const clearSerachBySelect = () => {
    setSelectMot({ value: "", label: "" });
    setSelectMotType({ value: "", label: "" });
    setSelectStatus({ value: "", label: "" });
    setSelectDesignation({ value: "", label: "" });
    setSelectDesignation({ value: "", label: "" });
    setFilterArray([]);
    setOpenFilterTab(false);
  };

  const exportExcelBaseFare = () => {
    exportExcel(
      `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
      "BaseFare.xlsx"
    );
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole !== 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">BASEFARE</h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={10}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={motDrop}
                                  placeholder="Select MOT"
                                  value={selectMot.value && selectMot}
                                  onInputChange={motDrop.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "motID")
                                  }
                                  isSearchable={true}
                                  // isClearable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={optionsMOTTypes}
                                  placeholder="Select MOT type"
                                  value={selectMotType.value && selectMotType}
                                  onInputChange={optionsMOTTypes.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "motType")
                                  }
                                  isSearchable={true}
                                  // isClearable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={departmentDrop}
                                  placeholder="Select Department"
                                  value={
                                    selectDepartment.value && selectDepartment
                                  }
                                  onInputChange={departmentDrop.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "Department")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={desigationdrop}
                                  placeholder="Select Designation"
                                  value={
                                    selectDesignation.value && selectDesignation
                                  }
                                  onInputChange={desigationdrop.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "Designation")
                                  }
                                  isSearchable={true}
                                />
                              </div>

                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // defaultValue={optionsRoleList[0].label}
                                  options={optionsMeetStatusList}
                                  placeholder="Select Status"
                                  value={
                                    selectStatus.value !== "" && selectStatus
                                  }
                                  onInputChange={optionsMeetStatusList.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "Status")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>

                {/*TABLE START */}
                <BaseFareTable
                  tableHeader={tableHeader}
                  baseFareList={baseFareList}
                  isLoading={isLoading}
                  editBasefare={editBasefare}
                  loading={loading}
                  setPageSize={setPageSize}
                  PageSize={PageSize}
                  setLoading={setLoading}
                />
                {/*TABLE END */}

                <div className="download-and-pagination">
                  {/* <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  /> */}
                  <SbDownloadBtn onClickEffect={exportExcelBaseFare} />
                </div>

                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && (
                  <AddBaseFareModel
                    show={show}
                    EditmotID={EditmotID}
                    basefareId={basefareId}
                    setBasefareId={setBasefareId}
                    handleClose={handleClose}
                    setEditMotID={setEditMotID}
                    clickType={clickType}
                    setClickType={setClickType}
                    fetchBaseFareData={fetchBaseFareData}
                  />
                )}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default BaseFare;
