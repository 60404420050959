import React, { useState } from "react";
import Select from "react-select";
import { Col, Row, Form, Image, Button, FloatingLabel } from "react-bootstrap";
import { useEffect } from "react";
import EditTextModel from "./EditTextModel";
import EditHeader from "./EditHeaderModel";
import EditParagraph from "./EditParagraph";
import EditRadioModel from "./EditRadioModel";
import EditCheckBox from "./EditCheckBox";
import EditSelect from "./EditSelect";
import EditFileUpload from "./EditFileUpload";
import EditDateField from "./EditDateField";
import EditButton from "./EditButton";

export default function AddedFormFields(props) {
  const [formFieldsData, setFormFieldsData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [editTextField, setEditTextField] = useState(false);
  const [editHeader, setEditHeader] = useState(false);
  const [eitParagraph, setEditParagraph] = useState(false);
  const [eitRadio, setEditRadio] = useState(false);
  const [editCheckBox, setEditCheckBox] = useState(false);
  const [editSelect, setEditSelect] = useState(false);
  const [editFileUpload, setEditFileUpload] = useState(false);
  const [editDateField, setEditDateField] = useState(false);
  const [editButton, setEditButton] = useState(false);

  const [data, setData] = useState("");

  const handleClose = () => {
    setEditTextField(false);
    setEditHeader(false);
    setEditParagraph(false);
    setEditRadio(false);
    setEditCheckBox(false);
    setEditSelect(false);
    setEditFileUpload(false);
    setEditDateField(false);
    setEditButton(false);
  };

  const handleChange = (e) => {
    let addedFormData = [];
    const { name, value } = e.target;
    // addedFormData[name] = value;
    // console.log("NAME-VAL", name, value);
    addedFormData.push({ [name]: value });
    setFormData((prev) => [...prev, addedFormData]);
  };

  const handleSelect = (data) => {
    console.log("NAME-VAL-SELECT", data);
  };

  const removeFromFieldsFromAddedForm = (dataId, name) => {
    props.setAddedFieldNames(
      props.addedFieldNames.filter((fdata) => fdata.id != dataId)
    );
  };

  const editTextFieldFunction = (editId) => {
    // setEditTextField(true);
    let newData = props.addedFieldNames.filter((fdata) => fdata.id == editId);
    setData(newData);
    // console.log("EDITABLE-DATA::", newData);
  };

  console.log("ADDED-DATA::", props.addedFieldNames);

  return (
    <div>
      <div className="dropped-form-fields">
        {props.addedFieldNames.map((data, i) => (
          <Row key={i}>
            {data.name == "Text Field" ? (
              <Col className="p-0">
                <div className="form-field-edit-delete mb-2">
                  <FloatingLabel
                    label={data.inputData.label}
                    className={`${
                      data.inputData?.label
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      id={data.id}
                      type={data.inputData?.type}
                      className={data.inputData?.className}
                      name={data.inputData?.name}
                      // placeholder={data.inputData?.placeHolder}
                      autoComplete={data.inputData?.autoComplete}
                      required={data.inputData?.required}
                    />
                  </FloatingLabel>
                  <div className="fa-edit-delete-icons">
                    <i
                      className="fa fa-pencil"
                      title="Edit"
                      onClick={() => {
                        editTextFieldFunction(data.id);
                        setEditTextField(true);
                      }}
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={() => removeFromFieldsFromAddedForm(data.id)}
                    />
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )}
            {data.name == "Header" ? (
              <>
                <div className="form-field-edit-delete mb-2">
                  {data.inputData.type == "h1" ? (
                    <h1
                      id={data.id}
                      className={data.inputData.className}
                      style={{ textAlign: `${data.inputData.align}` }}
                    >
                      {data.inputData.text}
                    </h1>
                  ) : data.inputData.type == "h2" ? (
                    <h2
                      id={data.id}
                      className={data.inputData.className}
                      style={{ textAlign: `${data.inputData.align}` }}
                    >
                      {data.inputData.text}
                    </h2>
                  ) : data.inputData.type == "h3" ? (
                    <h3
                      id={data.id}
                      className={data.inputData.className}
                      style={{ textAlign: `${data.inputData.align}` }}
                    >
                      {data.inputData.text}
                    </h3>
                  ) : data.inputData.type == "h4" ? (
                    <h4
                      id={data.id}
                      className={data.inputData.className}
                      style={{ textAlign: `${data.inputData.align}` }}
                    >
                      {data.inputData.text}
                    </h4>
                  ) : data.inputData.type == "h5" ? (
                    <h5
                      id={data.id}
                      className={data.inputData.className}
                      style={{ textAlign: `${data.inputData.align}` }}
                    >
                      {data.inputData.text}
                    </h5>
                  ) : data.inputData.type == "h6" ? (
                    <h6
                      id={data.id}
                      className={data.inputData.className}
                      style={{ textAlign: `${data.inputData.align}` }}
                    >
                      {data.inputData.text}
                    </h6>
                  ) : (
                    ""
                  )}
                  <div className="fa-edit-delete-icons">
                    <i
                      className="fa fa-pencil"
                      title="Edit"
                      onClick={() => {
                        editTextFieldFunction(data.id);
                        setEditHeader(true);
                      }}
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={() => removeFromFieldsFromAddedForm(data.id)}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {data.name == "Paragraph" ? (
              <>
                <div className="form-field-edit-delete mb-2">
                  {data.inputData.type == "p" ? (
                    <p
                      id={data.id}
                      className={data.inputData.className}
                      style={{ textAlign: `${data.inputData.align}` }}
                    >
                      {data.inputData.content}
                    </p>
                  ) : (
                    data.inputData.type == "address" && (
                      <address
                        id={data.id}
                        className={data.inputData.className}
                        style={{ textAlign: `${data.inputData.align}` }}
                      >
                        <pre>{data.inputData.content}</pre>
                      </address>
                    )
                  )}
                  <div className="fa-edit-delete-icons">
                    <i
                      className="fa fa-pencil"
                      title="Edit"
                      onClick={() => {
                        editTextFieldFunction(data.id);
                        setEditParagraph(true);
                      }}
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={() => removeFromFieldsFromAddedForm(data.id)}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {data.name == "Radio Buttons" ? (
              <div className="radio-label-n-buttons mb-2">
                <div className="form-field-edit-delete">
                  <Form.Label
                    htmlFor={data.inputData?.name}
                    className="radio-button-label"
                  >
                    {data.inputData.label}
                  </Form.Label>
                  <div className="fa-edit-delete-icons d-flex">
                    <i
                      className="fa fa-pencil"
                      title="Edit"
                      onClick={() => {
                        editTextFieldFunction(data.id);
                        setEditRadio(true);
                      }}
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={() => removeFromFieldsFromAddedForm(data.id)}
                    />
                  </div>
                </div>

                <div
                  className={
                    data.inputData.inline ? "radio-group-inline" : "radio-group"
                  }
                >
                  {data.inputData.values.map((val, index) => {
                    return (
                      <>
                        <Form.Check
                          key={index}
                          type={data.inputData?.type}
                          inline={data.inputData?.inline}
                          label={val.label}
                          name={`${data.inputData?.name}-${data.id}`}
                          className={data.inputData?.className}
                          value={val.value}
                        />
                        {/* <> */}
                        {/* <label
                          className="form-check-label"
                          htmlFor={`radio-${index}`}
                        >
                          <input
                            className={data.inputData?.className}
                            type={data.inputData?.type}
                            name={`${data.inputData?.name}-${data.id}`}
                            id={`radio-${index}`}
                            // checked={enableStatus == 0 ? true : false}
                            // onChange={() => setEnableStatus(0)}
                          />
                          {val.label}
                        </label> */}
                        {/* </> */}
                      </>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {data.name == "Check Box" ? (
              <div className="checkbox-label-n-buttons mb-2">
                <div className="form-field-edit-delete">
                  <Form.Label
                    htmlFor={data.inputData?.name}
                    className="checkbox-button-label"
                  >
                    {data.inputData.label}
                  </Form.Label>
                  <div className="fa-edit-delete-icons d-flex">
                    <i
                      className="fa fa-pencil"
                      title="Edit"
                      onClick={() => {
                        editTextFieldFunction(data.id);
                        setEditCheckBox(true);
                      }}
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={() => removeFromFieldsFromAddedForm(data.id)}
                    />
                  </div>
                </div>
                <div
                  className={
                    data.inputData.inline
                      ? "checkbox-group-inline"
                      : "checkbox-group"
                  }
                >
                  {data.inputData.values.map((val) => {
                    return (
                      <Form.Check
                        type={data.inputData?.type}
                        inline={data.inputData?.inline}
                        label={val.label}
                        name={data.inputData?.name}
                        className={data.inputData?.className}
                        value={val.value}
                        // required={data.inputData?.required}
                        // onChange={(e) => handleChange(e)}
                        // checked={val.selected}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {data.name == "Select" ? (
              <>
                <div className="form-field-edit-delete mb-2">
                  <FloatingLabel
                    label={data.inputData.placeHolder}
                    className={`${
                      data.id
                        ? "float-visible-select"
                        : "float-hidden float-select"
                    }`}
                    // style={{ width: "100%" }}
                  >
                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      defaultValue={data.inputData.values[null]}
                      options={data.inputData.values}
                      required={data.inputData?.required}
                      placeholder={data.inputData.placeHolder}
                      onInputChange={data.inputData.values.label}
                      isSearchable={data.inputData.search}
                      style={{ marginBottom: "20px", width: "100%" }}
                      // value={unitVal}
                      // onChange={(data) => handleSelect(data.value)}
                    />
                  </FloatingLabel>
                  <div className="fa-edit-delete-icons">
                    <i
                      className="fa fa-pencil"
                      title="Edit"
                      onClick={() => {
                        editTextFieldFunction(data.id);
                        setEditSelect(true);
                      }}
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={() => removeFromFieldsFromAddedForm(data.id)}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {data.name == "File Upload" ? (
              <>
                <div className="field-label-n-buttons mb-2">
                  <Form.Label
                    htmlFor={data.inputData?.name}
                    className="field-button-label"
                  >
                    {data.inputData.label}
                  </Form.Label>
                  <div className="form-field-edit-delete">
                    <Form.Control
                      type={data.inputData.type}
                      name={data.inputData.name}
                      multiple={data.inputData?.multiple}
                      className={data.inputData.className}
                      // placeholder={data.inputData.placeHolder}
                      // required={data.inputData.required}
                      // onChange={(e) => handleChange(e)}
                    />
                    <div className="fa-edit-delete-icons">
                      <i
                        className="fa fa-pencil"
                        title="Edit"
                        onClick={() => {
                          editTextFieldFunction(data.id);
                          setEditFileUpload(true);
                        }}
                      />
                      <i
                        className="fa fa-times"
                        title="Remove"
                        onClick={() => removeFromFieldsFromAddedForm(data.id)}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {data.name == "Date Field" ? (
              <>
                <div className="field-label-n-buttons mb-2">
                  <Form.Label
                    htmlFor={data.inputData?.name}
                    className="field-button-label"
                  >
                    {data.inputData.label}
                  </Form.Label>
                  <div className="form-field-edit-delete">
                    <Form.Control
                      type={data.inputData?.type}
                      name={data.inputData?.name}
                      className={data.inputData?.className}
                      // onChange={(e) => handleChange(e)}
                      placeholder={data.inputData?.placeHolder}
                      required={data.inputData?.required}
                    />
                    <div className="fa-edit-delete-icons">
                      <i
                        className="fa fa-pencil"
                        title="Edit"
                        onClick={() => {
                          editTextFieldFunction(data.id);
                          setEditDateField(true);
                        }}
                      />
                      <i
                        className="fa fa-times"
                        title="Remove"
                        onClick={() => removeFromFieldsFromAddedForm(data.id)}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {data.name == "Button" ? (
              <>
                <div className="form-field-edit-delete mb-2">
                  <Button
                    as="input"
                    className={data.inputData.className}
                    variant={data.inputData.variant}
                    type={data.inputData.type}
                    value={data.inputData.label}
                    style={{ width: "100%" }}
                    // onClick={(e) => handleChange(e)}
                  />
                  <div className="fa-edit-delete-icons">
                    <i
                      className="fa fa-pencil"
                      title="Edit"
                      onClick={() => {
                        editTextFieldFunction(data.id);
                        setEditButton(true);
                      }}
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={() => removeFromFieldsFromAddedForm(data.id)}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </Row>
        ))}
      </div>
      <>
        {editTextField ? (
          <EditTextModel
            editTextField={editTextField}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
      <>
        {editHeader ? (
          <EditHeader
            editHeader={editHeader}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
      <>
        {eitParagraph ? (
          <EditParagraph
            eitParagraph={eitParagraph}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
      <>
        {eitRadio ? (
          <EditRadioModel
            eitRadio={eitRadio}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
      <>
        {editCheckBox ? (
          <EditCheckBox
            editCheckBox={editCheckBox}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
      <>
        {editSelect ? (
          <EditSelect
            editSelect={editSelect}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
      <>
        {editFileUpload ? (
          <EditFileUpload
            editFileUpload={editFileUpload}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
      <>
        {editDateField ? (
          <EditDateField
            editDateField={editDateField}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
      <>
        {editButton ? (
          <EditButton
            editButton={editButton}
            handleClose={handleClose}
            data={data[0]}
            setAddedFieldNames={props.setAddedFieldNames}
            addedFieldNames={props.addedFieldNames}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}
