import "../../styles/AddUserDetails.scss";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import "../../styles/AddUserModel.scss";
import { useEffect, useRef, useState } from "react";
import DefaultLogo from "../../images/defalut-logo.png";
import { Link } from "react-router-dom";
import SalesBee from "../../images/MatricLogo.svg";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCityList,
  loadClientCategoryDrop,
  loadClientDropDown,
  loadClientStageGrop,
  loadPostCompanyData,
  loadStateList,
  loadUpdateCompanyData,
  loadUploadSingleImage,
  loadZoneList,
} from "../../redux/action";
import * as HeaderAuthentication from "../HeaderAuthentication";
import Geocode from "react-geocode";
import { baseURL } from "../BaseUrl";
import Skeleton from "react-loading-skeleton";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
// import AlertModel from '../AlertModel';
import "../../styles/Clients.scss";

const AddClientData = (props) => {
  let editClientId = props.editClientId;
  const hiddenFileInput = useRef(null);

  let dispatch = useDispatch();
  const { singleClient } = useSelector((state) => state.data);
  const { clientDropdown } = useSelector((state) => state.data);
  const { categoryDropdown } = useSelector((state) => state.data);
  const { stageDropdown } = useSelector((state) => state.data);
  const { zoneList } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);

  const formattedClientDropdown = clientDropdown.map((option) => ({
    ...option,
    label: `${option.label} - ${option.name}`,
  }));

  const [imgFile, setImgFile] = useState("");
  const [imgFileName, setImgFileName] = useState("");

  const [selectedCategory, setSelectedCategory] = useState({
    value: 0,
    label: "",
  });
  const [selectedStage, setSelectedStage] = useState({ value: 0, label: "" });
  const [country, setCountry] = useState({ value: 0, label: "" });
  const [zoneId, setZoneId] = useState({ value: 0, label: "" });
  const [countryList, setCountryList] = useState([]);
  const [statusFlag, setStatusFlag] = useState(true);
  const cntyList = [{ value: 1, label: "India" }];

  useEffect(() => {
    dispatch(loadClientDropDown());
    dispatch(loadClientCategoryDrop());
    dispatch(loadClientStageGrop());
    props.setIsLoading(true);
    dispatch(loadZoneList(props.setIsLoading));
    setCountryList(cntyList);
    setLocation("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (
          singleClient.countryID !== undefined &&
          singleClient.countryID !== 0
        ) {
          setCountry({ value: 1, label: "India" });
        }
        if (singleClient) {
          setClientData(singleClient);
          if (singleClient.enabledStatus == 1) {
            setStatusFlag(true);
          } else {
            setStatusFlag(false);
          }
          setLatLan({
            lat: Number(singleClient?.clientLoc?.latitude),
            lng: Number(singleClient?.clientLoc?.longitude),
          });
          setLocation(singleClient?.clientLoc?.location);
          setAddress(singleClient?.clientLoc?.location);
        }

        let categoryArray = [...categoryDropdown];
        categoryArray.map((category) => {
          if (category.value === singleClient.categoryID) {
            setSelectedCategory(category);
          }
        });
        let stageArray = [...stageDropdown];
        stageArray.map((stage) => {
          if (stage.value === singleClient.stageID) {
            setSelectedStage(stage);
          }
        });

        let zoneArray = [...zoneList];

        zoneArray.map((zone) => {
          if (zone.value === singleClient.zoneID) {
            setZoneId(zone);
            dispatch(loadStateList(zone.value));
          }
        });
      }
    }
  }, [editClientId, singleClient, zoneList]);

  const [stateId, setStateId] = useState({ value: 0, label: "" });

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (stateList) {
          stateList.map((state) => {
            if (state.value === singleClient.stateID) {
              setStateId(state);
              dispatch(loadCityList(state.value));
            }
          });
        }
      }
    }
  }, [stateList, editClientId]);

  const [cityId, setCityId] = useState({ value: 0, label: "" });

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (cityList) {
          cityList.map((city) => {
            if (city.value === singleClient.cityID) {
              setCityId(city);
            }
          });
        }
      }
    }
  }, [cityList, editClientId]);

  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });

  // const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });
  const [fieldReq, setFieldReq] = useState(false);

  useEffect(() => {
    let list = { ...clientLocation };
    if (latLan.lat == null && latLan.lng == null) {
      getLocation();
    } else {
      list.latitude = JSON.stringify(latLan.lat);
      list.longitude = JSON.stringify(latLan.lng);
    }

    if (location) {
      list.location = location;
    }

    clientData.clientLoc = list;
  }, [latLan, location]);

  const getLocation = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported for your browser");
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLan({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  // const closeAlertModel = () => {
  //     setShowAlert(!showAlert);
  // };

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
    setAddress("");
  };

  const [localImage, setLocalImage] = useState("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  // set state for post client data
  const [clientLocation, setClientLocation] = useState({
    latitude: "",
    longitude: "",
    location: "",
  });

  const [selectBranch, setSelectBranch] = useState({ value: "", label: "" });
  const [clientData, setClientData] = useState({
    clientName: "",
    logo: "",
    mobile: "",
    clientLoc: clientLocation,
    email: "",
    contactPersonName: "",
    landline: "",
    countryID: "",
    zoneID: "",
    stateID: "",
    cityID: "",
    pincode: "",
    categoryID: "",
    clientCategoryName: "",
    stageID: "",
    clientStageName: "",
    enabledStatus: 1,
    city: {
      zoneId: 0,
      zoneName: "",
      stateId: 0,
      stateName: "",
      countryID: 0,
      countryName: "",
      countryCode: "",
      cityId: 0,
      cityName: ""
    },
    customerIds: []
  });
  // set state for post client data

  // Onchange Event
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "mobile") {
  //     const re = /^[0-9\b]+$/;
  //     if (e.target.value === "" || re.test(value)) {
  //       setClientData({ ...clientData, [name]: parseInt(value) });
  //     } else {
  //       e.preventDefault();
  //     }
  //   } else {
  //     setClientData({ ...clientData, [name]: value });
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "customerIds") {
      // Split comma-separated values into an array and trim spaces
      const idsArray = value.split(",").map(id => id.trim());
      setClientData({ ...clientData, [name]: idsArray });
    } else if (name === "mobile") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(value)) {
        setClientData({ ...clientData, [name]: parseInt(value) });
      } else {
        e.preventDefault();
      }
    } else {
      setClientData({ ...clientData, [name]: value });
    }
  };

  const habdleEnableStatus = (e) => {
    if (e === 1) {
      setStatusFlag(true);
      clientData.enabledStatus = 1;
    } else if (e === 0) {
      setStatusFlag(false);
      clientData.enabledStatus = 0;
    }
  };
  // Onchange Event

  // handle select
  const handleSelectChange = (data, type) => {
    if (type === "category") {
      setSelectedCategory(data);
      clientData.categoryID = data.value;
      clientData.clientCategoryName = data.label;
    } else if (type === "stage") {
      setSelectedStage(data);
      clientData.stageID = data.value;
      clientData.clientStageName = data.label;
    } else if (type === "country") {
      setCountry(data);
      clientData.countryID = data.value;
    } else if (type === "zone") {
      setZoneId(data);
      dispatch(loadStateList(data.value));
      clientData.zoneID = data.value;
      setStateId({ value: "", label: "" });
      setCityId({ value: "", label: "" });
      clientData.stateID = "";
      clientData.cityID = "";
    } else if (type === "state") {
      setStateId(data);
      clientData.stateID = data.value;
      dispatch(loadCityList(data.value));
      setCityId({ value: "", label: "" });
      clientData.cityID = "";
    } else if (type === "city") {
      setCityId(data);
      clientData.cityID = data.value;
    }
  };
  // handle select

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (props.companyReportingData.length !== 0 && editClientId) {
      let companyReportingClientsLabels = props.companyReportingData.map(
        (client) => {
          return { label: client.clientName, value: client.clientID, name: "" };
        }
      );
      setSelectedOptions(companyReportingClientsLabels);
    }
  }, [props.companyReportingData, editClientId]);

  const handleSelectOption = (selected) => {
    setSelectedOptions(selected);
  };

  const closeModal = () => {
    props.fetchClientData();
    props.setEditClientId("");
    setSelectedOptions([]);
    setLocation("");
    props.handleClose();
  };

  //  ADDING CLIENT START
  const addClientDetails = (e) => {
    e.preventDefault();
    clientData.city = {
      zoneId: clientData.zoneID,
      zoneName: clientData.zoneName,
      stateId: clientData.stateId,
      stateName: clientData.stateName,
      countryID: clientData.countryID,
      countryName: clientData.countryName,
      countryCode: clientData.countryCode,
      cityId: clientData.cityId,
      cityName: clientData.cityName
    }

    if (
      // imgFile !== "" &&
      clientData.clientName !== "" &&
      clientData.contactPersonName !== "" &&
      Math.abs(clientData?.mobile).toString().length === 10 &&
      clientData.categoryID &&
      clientData.stageID &&
      location !== "" &&
      clientData.countryID &&
      clientData.zoneID &&
      clientData.stateID &&
      clientData.customerIds !== ""
    ) {
      const formData = new FormData();
      formData.append("Files", imgFile);
      formData.append("Directory", "Company");
      formData.append("FileNamePrefix", clientData.clientName);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.salesbeeImgAuth,
        body: formData,
      };
      let actionType;
      if (editClientId) {
        actionType = "update-data";
      } else {
        actionType = "post-data";
      }
      const categoryReportingClientIDsArray = selectedOptions.map((option) =>
        parseInt(option.value)
      );
      clientData.categoryReportingClientIds = categoryReportingClientIDsArray;

      if (imgFile !== "") {
        dispatch(
          loadUploadSingleImage(
            requestOption,
            clientData,
            closeModal,
            actionType
          )
        );
      } else {
        const clientDetails = {
          client: clientData,
        };
        let requestOption = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.postTravelizeAuth,
          body: JSON.stringify(clientDetails),
        };
        if (editClientId) {
          dispatch(loadUpdateCompanyData(requestOption, closeModal));
        } else {
          dispatch(loadPostCompanyData(requestOption, closeModal));
        }
      }
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required...!",
      });
    }
  };
  //  ADDING CLIENT END

  // GOOGLE MAPS LOCATION START

  useEffect(() => {
    if (searchVal) {
      Geocode.fromAddress(searchVal).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;

          setLatLan({
            lat: lat,
            lng: lng,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [searchVal]);

  const onMarkerDragEnd = (e) => {
    setLatLan({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const searchLocation = (event) => {
    setSearchVal(event.target.value);
  };

  useEffect(() => {
    if (latLan.lat !== null && latLan.lat !== "") {
      Geocode.fromLatLng(latLan.lat, latLan.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;

          setAddress(address);
          setLocation(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      addressFromGoogle();
    }
  }, [latLan]);

  const addressFromGoogle = () => {
    if (googleMapsModel) {
      openGoogleMaps();
    }
  };

  // GOOGLE MAPS LOCATION END

  const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
  const [showMachineDetailsHeader, setShowMachineDetailsHeader] =
    useState(true);
  const [activeMenu, setActiveMenu] = useState(1);

  const toggleMachineDetails = () => {
    setShowMachineDetailsForm(true);
    setShowMachineDetailsHeader(true);
    setActiveMenu(1);
  };

  return (
    <div>
      <div show={props.addClientmodelShow} onHide={props.handleClose}>
        <div className="add-user-details-container">
          <div className="add-user-details-first-div">
            <div className="machines-logo-div">
              <div className="logotext">
                <div className="logo profile-mini">
                  <Link className="travelize_logo" to="/dashboard">
                    <img
                      src={SalesBee}
                      alt="product-title-img"
                      className="salesbee-logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="add-user-details-menus"
              onClick={toggleMachineDetails}
            >
              <div
                className={`add-user-details-menu-numbers ${activeMenu === 1 ? "active" : "inactive"
                  }`}
              >
                <p className="add-user-details-menu-number-one">
                  <span>01</span>
                </p>
                <div className="add-user-details-menu-name">
                  {" "}
                  Customer Details
                </div>
              </div>
            </div>
          </div>
          <div className="add-user-details-second-div">
            {showMachineDetailsHeader && (
              <div className="add-user-details-second-div-header">
                <div className="back-btn" onClick={closeModal}>
                  <img
                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                    alt=""
                  />
                </div>
                <div>
                  <h4>Customer Details</h4>
                  <p>
                    Please enter customer infomation and proceed to the next
                    step.
                  </p>
                </div>
              </div>
            )}

            <div style={{ position: " relative" }} className="amc-single-image">
              {showMachineDetailsForm && (
                <form onSubmit={addClientDetails}>
                  <div className="row">
                    <div className="col-lg-3 col">
                      <div className="add-user-main-logo-image">
                        {editClientId && singleClient ? (
                          <img
                            className="add-user-images"
                            src={
                              localImage
                                ? localImage
                                : singleClient.logo
                                  ? `${baseURL}/${singleClient.logo}`
                                  : DefaultLogo
                            }
                            alt=""
                          />
                        ) : (
                          <img
                            className="add-user-images"
                            src={localImage ? localImage : DefaultLogo}
                            alt="Product Images"
                          />
                        )}
                        <div>
                          <button
                            type="button"
                            className="btn upload-logo"
                            onClick={uploadClick}
                          >
                            <i className="bi bi-camera-fill" />
                          </button>
                          <input
                            id="getFile"
                            type="file"
                            name="filetobase64"
                            accept="image/*"
                            className="btn-primary upload"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9 col">
                      <div>
                        <div
                          className="row add-user-image-container-div"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Customer Id"
                                className={`${clientData?.customerIds || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-client-input model-input"
                                  id="exampleFormControlInput1"
                                  placeholder="Customer Id"
                                  name="customerIds"
                                  value={clientData?.customerIds?.join(", ") || ""}
                                  onChange={handleInputChange}
                                />
                              </FloatingLabel>
                              {fieldReq && clientData?.customerIds.length === 0 && (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i className="fa fa-exclamation-circle" />
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Customer Name"
                                className={`${clientData.clientName || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <input
                                  type="text"
                                  className="form-control form add-client-input model-input"
                                  id="exampleFormControlInput1"
                                  placeholder="Customer Name"
                                  name="clientName"
                                  value={clientData?.clientName || ""}
                                  onChange={handleInputChange}
                                  autoComplete="off"
                                />
                              </FloatingLabel>
                              {fieldReq && clientData?.clientName === "" && (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              )}
                            </div>
                          </div>

                        </div>

                        <div
                          className="row add-user-image-container-div"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Contact Person Name"
                                className={`${clientData.contactPersonName || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-client-input model-input"
                                  id="exampleFormControlInput1"
                                  placeholder="Contact Person Name"
                                  name="contactPersonName"
                                  value={clientData?.contactPersonName || ""}
                                  onChange={handleInputChange}
                                />
                              </FloatingLabel>
                              {fieldReq &&
                                clientData?.contactPersonName === "" && (
                                  <small
                                    id={`Error`}
                                    className="form-text text-muted "
                                  >
                                    {alertMessage.message}{" "}
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </small>
                                )}
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Mobile Number"
                                className={`${clientData.mobile || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-client-input model-input"
                                  id="exampleFormControlInput1"
                                  placeholder="Mobile Number"
                                  name="mobile"
                                  value={clientData?.mobile || ""}
                                  maxLength={10}
                                  onChange={handleInputChange}
                                />
                              </FloatingLabel>
                              {fieldReq &&
                                Math.abs(clientData?.mobile).toString().length !==
                                10 ? (
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  className="form-text text-muted "
                                >
                                  {`${alertMessage.message} - 10 Numbers`}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                        </div>

                        <div className="row add-user-image-container-div">
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Email"
                                className={`${clientData.email || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <input
                                  type="email"
                                  autoComplete="off"
                                  className="form-control form add-client-input model-input"
                                  id="exampleFormControlInput1"
                                  placeholder="Email"
                                  name="email"
                                  value={clientData?.email || ""}
                                  onChange={handleInputChange}
                                />
                              </FloatingLabel>
                              {fieldReq && clientData?.email === "" && (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i className="fa fa-exclamation-circle" />
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Landline"
                                className={`${clientData.landline || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <input
                                  type="number"
                                  autoComplete="off"
                                  className="form-control form add-client-input model-input"
                                  id="exampleFormControlInput1"
                                  placeholder="Landline"
                                  name="landline"
                                  value={clientData?.landline || ""}
                                  onChange={handleInputChange}
                                />
                              </FloatingLabel>
                            </div>
                          </div>

                        </div>
                        <div className="row add-user-image-container-div">
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Select Category"
                                className={`${selectedCategory.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                                  }`}
                              >
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={categoryDropdown}
                                  placeholder="Select Category"
                                  value={
                                    selectedCategory.value
                                      ? selectedCategory
                                      : categoryDropdown.value
                                  }
                                  onInputChange={categoryDropdown.label}
                                  onChange={(data) =>
                                    handleSelectChange(data, "category")
                                  }
                                  isSearchable={true}
                                />
                              </FloatingLabel>
                              {fieldReq &&
                                (clientData.categoryID === "" ||
                                  clientData.categoryID === 0) && (
                                  <small
                                    id={`Error${alertMessage.alertType}`}
                                    className="form-text text-muted "
                                  >
                                    {alertMessage.message}{" "}
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </small>
                                )}
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Select Stage"
                                className={`${selectedStage.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                                  }`}
                              >
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={stageDropdown}
                                  placeholder="Select Stage"
                                  value={
                                    selectedStage.value
                                      ? selectedStage
                                      : stageDropdown.value
                                  }
                                  onInputChange={stageDropdown.label}
                                  onChange={(data) =>
                                    handleSelectChange(data, "stage")
                                  }
                                  isSearchable={true}
                                />
                              </FloatingLabel>
                              {fieldReq &&
                                (clientData.stageID === "" ||
                                  clientData.stageID === 0) && (
                                  <small
                                    id={`Error${alertMessage.alertType}`}
                                    className="form-text text-muted "
                                  >
                                    {alertMessage.message}{" "}
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </small>
                                )}
                            </div>
                          </div>

                        </div>
                        <div className="row add-user-image-container-div">
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Select Country"
                                className={`${country.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                                  }`}
                              >
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={countryList}
                                  placeholder="Select Country"
                                  value={
                                    country.value ? country : countryList.value
                                  }
                                  onInputChange={countryList.label}
                                  onChange={(data) =>
                                    handleSelectChange(data, "country")
                                  }
                                  isSearchable={true}
                                />
                              </FloatingLabel>
                              {fieldReq && clientData.countryID === "" ? (
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Select Zone"
                                className={`${zoneId.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                                  }`}
                              >
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={zoneList}
                                  placeholder="Select Zone"
                                  value={zoneId.value ? zoneId : zoneList.value}
                                  onInputChange={zoneList.label}
                                  onChange={(data) =>
                                    handleSelectChange(data, "zone")
                                  }
                                  isSearchable={true}
                                />
                              </FloatingLabel>
                              {fieldReq && clientData.zoneID === "" ? (
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                        </div>
                        <div className="row add-user-image-container-div">
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Select State"
                                className={`${stateId.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                                  }`}
                              >
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={stateList}
                                  placeholder="Select State"
                                  value={
                                    stateId.value && stateId
                                  }
                                  onInputChange={stateList.label}
                                  onChange={(data) =>
                                    handleSelectChange(data, "state")
                                  }
                                  isSearchable={true}
                                />
                              </FloatingLabel>
                              {fieldReq && clientData.stateID === "" ? (
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Select City"
                                className={`${cityId.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                                  }`}
                              >
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={cityList}
                                  placeholder="Select City"
                                  value={cityId.value && cityId}
                                  onInputChange={cityList.label}
                                  onChange={(data) =>
                                    handleSelectChange(data, "city")
                                  }
                                  isSearchable={true}
                                  isDisabled={!stateId && true}
                                />
                              </FloatingLabel>
                              {fieldReq && cityId.value === 0 ? (
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  className="form-text text-muted "
                                >
                                  {alertMessage.message}{" "}
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                        </div>
                        <div className="row add-user-image-container-div">
                          {/* <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="Select Company"
                                className={`${selectedOptions.length !== 0
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                                  }`}
                              >
                                <MultiSelect
                                  options={formattedClientDropdown}
                                  value={selectedOptions}
                                  onChange={handleSelectOption}
                                  labelledBy={"Select"}
                                  overrideStrings={{
                                    selectSomeItems: "Select Company",
                                  }}
                                />
                              </FloatingLabel>
                            </div>
                          </div> */}
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel
                                label="PinCode"
                                className={`${clientData.pincode
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <input
                                  type="number"
                                  autoComplete="off"
                                  className="form-control form add-client-input model-input"
                                  id="exampleFormControlInput1"
                                  placeholder="PinCode"
                                  name="pincode"
                                  value={clientData?.pincode}
                                  onChange={handleInputChange}
                                />
                              </FloatingLabel>
                            </div>
                          </div>
                          <div className="col-lg-6 col">
                            <div className="mb-3">
                              <FloatingLabel label='Branch' className={`${selectBranch.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                <Select
                                  className="react-select-container-list model-select"
                                  classNamePrefix="react-select-list"
                                  options={cityList}
                                  placeholder="Branch"
                                  value={selectBranch.value && selectBranch}
                                  onInputChange={cityList.label}
                                  onChange={(data) => handleSelectChange(data, "branch")}
                                  isSearchable={true}
                                />
                              </FloatingLabel>
                            </div>
                          </div>
                        </div>
                        <div className="row add-user-image-container-div">
                          <div className="col-lg-6 col ">
                            <div className="mb-3 client-location-by-map">
                              <FloatingLabel
                                label="Street Address/House No./Locality"
                                className={`${location
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                                  }`}
                              >
                                <textarea
                                  rows="3"
                                  cols="20"
                                  name="usrtxt"
                                  wrap="hard"
                                  className="form-control form-textarea add-user-input"
                                  id="exampleFormControlInput1"
                                  placeholder="Street Address/House No./Locality"
                                  value={location}
                                  readOnly={true}
                                  onChange={(e) => setLocation(e)}
                                />
                              </FloatingLabel>
                              <i
                                className="bi bi-geo-alt"
                                title="Google Location"
                                onClick={openGoogleMaps}
                              />
                            </div>
                            {fieldReq && location === "" ? (
                              <small
                                id={`Error${alertMessage.alertType}`}
                                className="form-text text-muted "
                              >
                                {alertMessage.message}{" "}
                                <i
                                  className="fa fa-exclamation-circle"
                                  aria-hidden="true"
                                ></i>
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {props.isLoading ? (
                          <Skeleton />
                        ) : (
                          <div className="row add-user-image-container-div">
                            <div className="col-lg-6 col">
                              <div className="mt-3">
                                {editClientId && (
                                  <span className="enable-status d-flex">
                                    <h6>Enable</h6>
                                    <span className="enable-radio d-flex">
                                      <div className="form-check enable-radio-status">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="enableDisable"
                                          id="enableDisable1"
                                          checked={statusFlag}
                                          onChange={() => habdleEnableStatus(1)}
                                        />
                                        <label className="form-check-label">
                                          Yes
                                        </label>
                                      </div>
                                      <div className="form-check enable-radio-status">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="enableDisable"
                                          id="enableDisable2"
                                          checked={!statusFlag}
                                          onChange={() => habdleEnableStatus(0)}
                                        />
                                        <label className="form-check-label">
                                          No
                                        </label>
                                      </div>
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="add-page-save-cancel">
                    <SBSaveUpdateBtn
                      btnName={editClientId ? "Update" : "Save"}
                    />
                    <SbCancelBtn onClickEffect={closeModal} btnName="Cancel" />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      {googleMapsModel === true && (
        // <LoadScript googleMapsApiKey={React_Google_API_Key}>
        <GoogleMapAddressModel
          googleMapsModel={googleMapsModel}
          addressFromGoogle={addressFromGoogle}
          openGoogleMaps={openGoogleMaps}
          latLan={latLan}
          searchLocation={searchLocation}
          searchVal={searchVal}
          onMarkerDragEnd={onMarkerDragEnd}
          address={address}
        />
      )}
      <>
        {/* {showAlert ? (
                    <AlertModel
                        showAlert={showAlert}
                        alertMessage={alertMessage}
                        closeAlertModel={closeAlertModel}
                    />
                ) : (
                    ""
                )} */}
      </>
    </div>
  );
};

export default AddClientData;
