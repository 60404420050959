import React, { useEffect, useState } from "react";
import { Modal, FloatingLabel, Form, Row, Col } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";
import { baseURL } from "../../BaseUrl";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";

export default function EditFormName(props) {
  const [newFormName, setNewFormName] = useState("");
  const [enableStatus, setEnableStatus] = useState(null);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchUpdateFormName();
  }, [props.customPageID]);

  const fetchUpdateFormName = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/CustomePages/Get/${props.customPageID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("CUSFORMS-RES::", res.result);
          setNewFormName(res.result.pageName);
          setEnableStatus(res.result.status);
        } else {
          console.log("CUS-FORM-RES::", `${res.errorMessage}`);
          setNewFormName("");
          setEnableStatus(2);
        }
      });
  };

  const updateFormName = () => {
    let newFormNameData = {
      customePagesID: props.customPageID,
      subscriptionID: resPassData.subscriptionId,
      addedByID: resPassData.userId,
      status: enableStatus,
      pageName: newFormName,
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newFormNameData),
    };
    fetch(`${baseURL}/api/CustomePages/UpdateCustomePages`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          props.handleClose();
          props.fetchCustomFormNames();
          swal.showAlert("Success", `${res.message}`, "success");
        } else {
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      });
  };

  return (
    <div>
      {" "}
      <Modal show={props.editShow} className="main_model add-edit-model">
        <div className="add-edit-heading">
          <h6 className="add-edit-head">EDIT FORM NAME</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>

        <form>
          <div className="container add-edit">
            <div className="change-text-fields-part">
              <div className="model-inputs mb-3 mt-3">
                <FloatingLabel
                  label="Enter Form Name"
                  className={`${
                    newFormName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    id="customformName"
                    type="text"
                    className="custom-form-name"
                    placeholder="Enter Form Name"
                    autoComplete="off"
                    value={newFormName}
                    onChange={(e) => setNewFormName(e.target.value)}
                    required
                  />
                </FloatingLabel>
              </div>
              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={updateFormName} btnName="Update" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
