import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
import { Col, FloatingLabel, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  loadPassbookCardData,
  loadPassbookSummeryData,
  loadUserBankData,
  loadUserReferralsData
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import profileimg from "../../images/profile-1.png";
import "../../styles/PassBook.scss";
import PassBookTable from "./PassBookTable";
import Select from "react-select";
import { FormattedAmount } from "../FormattedAmount";
import BankDetailsModal from "./BankDetailsModal";
import PassBookSummeryTable from "./PassBookSummeryTable";
import Footer from "../Footer";

function PassBook() {
  let dispatch = useDispatch();
  const { gfrefId } = useParams();
  const { userReferralsDataDropDown } = useSelector((state) => state.data);
  const { cardData } = useSelector((state) => state.data);
  const { userBankData } = useSelector((state) => state.data);
  console.log(userBankData, "userBankData");

  const [isLoading, setIsLoading] = useState(true);
  const [filterArray, setFilterArray] = useState([]);
  const [PageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadUserReferralsData());
    dispatch(loadUserBankData());
    dispatch(loadPassbookCardData([],
      1000,
      1));
  }, []);

  const fethPaymentData = () => {
    dispatch(loadPassbookSummeryData(filterArray, PageSize, currentPage, setIsLoading, setLoading));
  };

  useEffect(() => {
    fethPaymentData(filterArray, PageSize, currentPage)
  }, [filterArray, PageSize, currentPage])

  // PAGINATION START
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation
  const [refferalOptions, setRefferalOptions] = useState([])

  useEffect(() => {
    if (userReferralsDataDropDown.length !== 0) {
      const refferalData = userReferralsDataDropDown.map((user) => ({
        value: user.gfRefProfileID,
        label: user.userName,
      }));
      setRefferalOptions(refferalData)
    }
  }, [userReferralsDataDropDown])

  const [selectedUser, setSelectedUser] = useState({ value: "", label: "" });
  useEffect(() => {
    if (gfrefId) {
      refferalOptions.map((user) => {
        if (user.value == gfrefId) {
          setSelectedUser(user);

        }
      });
    }
  }, [gfrefId, refferalOptions]);

  const [openBankDetailsModal, setOpenBankDetailsModal] = useState(false)
  const handleBankDetails = () => {
    setOpenBankDetailsModal(true)
  }

  const handleView = (summaryId) => {
    navigate("/passbookHistory", { state: { summaryId: summaryId } });
  };

  const [activeTab, setActiveTab] = useState('Company');

  const handleTabSelect = (key) => {
    // Add your custom logic here when a tab is clicked
    setActiveTab(key);
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* Open bank details modal */}
          {openBankDetailsModal && (
            <BankDetailsModal
              showModal={openBankDetailsModal}
              setShowModal={setOpenBankDetailsModal}
              selectedUser={selectedUser}
            />
          )}
          {/* Open bank details modal */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToSettingPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">PASSBOOK</h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="passbook-main-container">
                    <div
                      className="users-list-name"
                      style={{ padding: "20px", width: "280px" }}
                    >
                      <div>
                        <img
                          className="table-data-users-imgages"
                          src={profileimg}
                          alt=""
                        />
                      </div>
                      <div style={{ position: "relative", width: "100%" }}>
                        <FloatingLabel
                        >
                          <Select
                            className="react-select-container-list model-select"
                            classNamePrefix="react-select-list"
                            options={refferalOptions}
                            placeholder="Select User"
                            onInputChange={refferalOptions.label}
                            value={selectedUser.value && selectedUser}
                            onChange={(selectedOption) => {
                              setSelectedUser(selectedOption);
                            }}
                            isSearchable={true}
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    {cardData
                      .filter(
                        (card) =>
                          selectedUser &&
                          card.gfRefProfileID === selectedUser.value
                      )
                      .map((filteredCard, index) => (
                        <div className="passbook-container">
                          <div className="card-passbook">
                            <div
                              className="passbook-card-body"
                            >
                              <div className="passbook-card-row">
                                <div className="card-data">
                                  <div className="card-price">
                                    <b>Earned</b>
                                    <span className="price-text">
                                      <i
                                        className="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {FormattedAmount(filteredCard.earned)}
                                    </span>
                                  </div>
                                  <span className="material-symbols-outlined">
                                    wallet
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-passbook">
                            <div
                              className="passbook-card-body"
                            >
                              <div className="passbook-card-row">
                                <div className="card-data">
                                  <div className="card-price">
                                    <b>Claimed</b>
                                    <span className="price-text">
                                      <i
                                        className="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {FormattedAmount(filteredCard.claimed)}
                                    </span>
                                  </div>
                                  <span className="material-symbols-outlined">
                                    credit_score
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-passbook">
                            <div
                              className="passbook-card-body"
                            >
                              <div className="passbook-card-row">
                                <div className="card-data">
                                  <div className="card-price">
                                    <b>Paid</b>
                                    <span className="price-text">
                                      <i
                                        className="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {FormattedAmount(filteredCard.paid)}
                                    </span>
                                  </div>
                                  <span className="material-symbols-outlined">
                                    send_money
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-passbook">
                            <div
                              className="passbook-card-body"
                            >
                              <div className="passbook-card-row">
                                <div className="card-data">
                                  <div className="card-price">
                                    <b>Unclaimed</b>
                                    <span className="price-text">
                                      <i
                                        className="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {FormattedAmount(
                                        filteredCard.closingBalance
                                      )}
                                    </span>
                                  </div>
                                  <span className="material-symbols-outlined">
                                    credit_card_off
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="passbook-third-div">
                    <div className="passbook-line-chart-container">
                      <div className="passbook-line-chart">
                        <div className="claims-table">
                          <Tabs
                            defaultActiveKey="Passbook"
                            id="uncontrolled-tab-example"
                            className="local-claim-nav-tab"
                            onSelect={handleTabSelect}
                          >
                            <Tab eventKey="Passbook" title="Passbook" >
                              <PassBookSummeryTable
                                selectedUser={selectedUser}
                              />
                            </Tab>
                            <Tab eventKey="Transaction" title="Transaction" >
                              {activeTab == "Transaction" && <PassBookTable
                                selectedUser={selectedUser}
                                handleView={handleView}
                                handleBankDetails={handleBankDetails}
                              />}
                            </Tab>
                            <Tab eventKey="Bank Details" title="Bank Details">
                              <div
                                className="client-edit-link"
                                onClick={handleBankDetails}
                                style={{ paddingTop: "20px" }}
                              >
                                <Link>Click here to view Bank-Details</Link>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                    {/* PAGE CONTENT END*/}
                  </div>
                </div>
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default PassBook;
