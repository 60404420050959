import React, { useEffect, useRef, useState } from "react";
import "../src/styles/SignIn.scss";
import "../src/styles/CommonStyle.scss";
import "../src/MediaFiles/Media.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as HeaderAuthentication from "../src/Components/HeaderAuthentication";
import { Button, Col, Image, Row } from "react-bootstrap";
import travelize from "./images/metric-favicon.svg";
import travelizeSalesBeeLogo from "./images/MatricLogo.svg";
import InternetIssue from "./Components/InternetIssue";
import { useDispatch, useSelector } from "react-redux";
import { loadGetOTP } from "./redux/action";
import SignInOtpTimer from "./SignInOtpTimer";
import { baseURL } from "./Components/BaseUrl";

const OtpVerification = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state;

  const [otpError, setOtpError] = useState(false);
  let dispatch = useDispatch();
  const { otpData } = useSelector((state) => state.data);
  console.log(otpData, 'otpData')

  const [expireTime, setExpireTime] = useState(120)
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input
    if (value !== "" && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const result = otp.toString().replace(/,/g, "");

  // useEffect(() => {
  //   if (result && result.length === 6 && otpData) {
  //     if (parseInt(otpData) === parseInt(result)) {
  //       navigate("/dashboard")
  //     } else {
  //       setOtpError(true);
  //     }
  //   }
  // }, [result, otpData]);

  const [showTimer, setShowTimer] = useState(false);
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleTimerExpire = () => {
    setShowTimer(false);
    // Handle what should happen when the timer expires (e.g., request a new OTP).
  };

  const getOtpForSuperAdmin = () => {
    setExpireTime(120)
    setShowTimer(true);
    var request = {
      userName: email + ",",
    };
    let postRequest = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(request),
    };
    dispatch(loadGetOTP(postRequest));
  };

  const [loginStatus, setLoginStatus] = useState("");
  const [loginResponse, setLoginResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigateToDashboard = () => {
    window.location.href = "/";
  };
  let themeColor = localStorage.getItem("theme");

  const [buttonText, setButtonText] = useState("Get OTP");

  const handleClick = (e) => {
    if (buttonText === "Get OTP") {
      handleGetOTP();
    } else {
      otpValidation(e);
    }
  };

  const handleGetOTP = () => {
    console.log("Get OTP button clicked");
    getOtpForSuperAdmin();
    setButtonText("Verify OTP");
    setShowTimer(true);
  };

  const otpValidation = (e) => {
    e.preventDefault();
    if (email && result) {
      if (result && result.length === 6 && otpData) {
        if (parseInt(otpData) === parseInt(result)) {
          const userNamePassword = {
            UserName: email,
            otpPassword: result,
          };
          let requestoption = {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userNamePassword),
          };
          fetch(`${baseURL}/api/Authentication/Login`, requestoption)
            .then((res) => res.json())
            .then((res) => {
              if (res.success) {
                setLoginResponse(res.success);
                setLoginStatus(`Login ${res.message}`);
                sessionStorage.setItem("usdtls", JSON.stringify(res.result));

                //// CHECK SUBSCRIPTION EXPIRY
                let requestOptionS = {
                  method: "GET",
                  mode: "cors",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Travelize_Authentication: res.result,
                  },
                };
                fetch(`${baseURL}/api/DashBoard/GetLoginUserDetails`, requestOptionS)
                  .then((res) => res.json())
                  .then((res) => {
                    const subscriptionExpiry = res?.result?.subscription?.daysleft;
                    if (subscriptionExpiry == 0) {
                      navigate("/subscriptionexpiry");
                    } else {
                      const userLoginDetails = res.result;
                      sessionStorage.setItem("loginId", userLoginDetails.userId);
                      sessionStorage.setItem(
                        "usrlgndtls",
                        JSON.stringify(userLoginDetails)
                      );
                      if (
                        userLoginDetails?.desingation?.role?.roleName == "Admin" &&
                        userLoginDetails?.subscriptionId == "SUB0000000001"
                      ) {
                        sessionStorage.setItem("AdminRole", true);
                      }
                      if (
                        userLoginDetails?.desingation?.role?.roleName == "Admin"
                      ) {
                        sessionStorage.setItem("AdminAccess", true);
                      }
                      if (
                        userLoginDetails?.desingation?.role?.roleName == "Manager"
                      ) {
                        sessionStorage.setItem("ManagerAccess", true);
                      }
                      if (!themeColor) {
                        localStorage.setItem("theme", "light");
                      }
                      navigateToDashboard();
                    }
                  })
                  .catch((error) => {
                    console.log("ERROR :", error);
                    setErrorMessage(error.message);
                  });
              } else {
                console.log(res.errorMessage);
                setLoginResponse(res.success);
                setLoginStatus(res.errorMessage);
              }
            })
            .catch((error) => {
              console.log("ERROR :", error);
              setErrorMessage(error.message);
            });
        }
        else {
          setOtpError(true);
        }
      }
    }
  }

  return (
    <div>
      <>
        <div className="container-fluid">
          <div className="sigin-page-body">
            {/* <Row> */}
            <div className="dflex-flow-row">
              <Col
                lg={5}
                md={5}
                sm={12}
                className="signIn-container-left-div"
              >
                <div className="signIn-container-logo">
                  <Image
                    src={travelizeSalesBeeLogo}
                    alt="MetricInfo"
                    className="signIn-logo"
                  />
                </div>
                <div className="signIn-container-gif">
                  <Image
                    src="../images/sign-animate.gif"
                    alt="MetricInfo Motion Logo"
                    className="signIn-moving-img"
                  />
                </div>
              </Col>
              <Col lg={2} md={2} sm={12}>
                <div className="devider">
                  <div className="v1"></div>
                </div>
              </Col>
              <Col
                lg={5}
                md={5}
                sm={12}
                className="signIn-container-right-div"
              >
                <div className="metricinfo-logo">
                  <Image
                    src={travelize}
                    alt="MetricInfo Logo"
                    className="sb-trv-logo"
                  />
                </div>
                <div className="signin-header login-part">
                  <div className="login-header-part">
                    <h2 className="mb-2">OTP Verification</h2>
                    <h4>Please verify the OTP sent to your email</h4>
                  </div>
                  <form className="login-form">
                    <div className="otp-verification-container">
                      {otp.map((value, index) => (
                        <input
                          key={index}
                          type="password"
                          value={value}
                          maxLength={1}
                          // className="form-control form add-basefare-input"
                          ref={(input) =>
                            (inputRefs.current[index] = input)
                          }
                          onChange={(e) => {
                            handleOtpChange(index, e.target.value);
                            setOtpError("")
                          }
                          }
                          onKeyDown={(e) => handleKeyDown(index, e)}
                        />
                      ))}
                    </div>
                    {otpError && (
                      <small
                        id={`Error`}
                        className="form-text text-muted "
                      >
                        Entered OTP is incorrect
                      </small>
                    )}
                    {/* <div className="forget-pasword-div">
                      <SignInOtpTimer
                        expiryTimeInSeconds={expireTime}
                        setExpireTime={setExpireTime}
                        onTimerExpire={handleTimerExpire}
                        showTimer={showTimer}
                        getOtpForSuperAdmin={getOtpForSuperAdmin}
                      />
                    </div>
                    <div className="signin-button d-flex justify-content-center mt-2">
                      <Button type="button" className="btn btn-signin-button">
                        Verify OTP
                      </Button>
                    </div> */}

                    <div className="d-flex justify-content-between align-items-center mt-5">
                      <div className="signin-button">
                        <Button type="button" className="btn btn-signin-button" style={{ width: "auto" }}
                          // onClick={otpValidation}
                          onClick={handleClick}
                        >
                           {buttonText}
                        </Button>
                      </div>
                      <div >
                        <SignInOtpTimer
                          expiryTimeInSeconds={expireTime}
                          setExpireTime={setExpireTime}
                          onTimerExpire={handleTimerExpire}
                          showTimer={showTimer}
                          getOtpForSuperAdmin={getOtpForSuperAdmin}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
              {/* </Row> */}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default OtpVerification;
