import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import { Col, Form, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import productimg from "../../images/products/headset.png";
import productimg1 from "../../images/products/MouseWired.png";
import { optionsPageSizeList, optionsStatusList } from "../DropdownsData";
import AddProductmodel from "./AddProductmodel";
import SbTableHeader from "../SbTables/SbTableHeader";
import ProductsTable from "./ProductsTable";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn, {
  SbBuyNowBtn,
  SbCheckoutBtn,
  SbCreateListBtn,
} from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useRef } from "react";
import { baseURL } from "../BaseUrl";
import ProductSkelrton from "./ProductSkelrton";
import { FormattedAmount } from "../FormattedAmount";

function CartPage() {
  const divRef = useRef(null);

  let tableHeader = ["Products", "Price", "Quantity", "Total", ""];

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();

  const { cartList } = useSelector((state) => state.data);
  let cartItems = JSON.parse(sessionStorage.getItem("cartItems"));
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);

  // const [selectcartitems, setSelectCartItems] = useState([])
  // useEffect(() => {
  //     if(cartItems.length != 0){
  //       setSelectCartItems(cartItems)
  //     }
  // }, [])

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const [cartData, setCartData] = useState([]);
  useEffect(() => {
    if (cartItems && cartItems.length !== 0) {
      let list = [...cartItems];
      list.forEach((item) => {
        if (!item.qty) {
          item.qty = 1;
        }
      });
      sessionStorage.setItem("cartItems", JSON.stringify(list));
      setCartData(list);
    }
  }, []);

  // Quantity count
  const decreaseCount = (id) => {
    let list = [...cartData];
    list.map((item) => {
      if (item.productID === id) {
        item.qty = parseInt(item.qty) - 1;
      }
    });
    sessionStorage.setItem("cartItems", JSON.stringify(list));
    setCartData(list);
  };

  const increaseCount = (id) => {
    let list = [...cartData];
    list.map((item) => {
      if (item.productID === id) {
        item.qty = parseInt(item.qty) + 1;
      }
    });
    sessionStorage.setItem("cartItems", JSON.stringify(list));
    setCartData(list);
  };
  // Quantity count

  // Function for remove item
  const removeItem = (id) => {
    let list = [...cartData];
    let filterData = list.filter((item) => item.productID !== id);
    sessionStorage.setItem("cartItems", JSON.stringify(filterData));
    setCartData(filterData);
  };
  // Function for remove item

  // Total calculation Item and price
  const [totalItem, setTotalItem] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (cartData.length !== 0) {
      let itemCount = 0;
      let totalCount = 0;
      cartData.map((item) => {
        itemCount = parseInt(itemCount) + parseInt(item.qty);
        let itemPrice = parseFloat(item.price) * parseInt(item.qty);
        totalCount = parseInt(totalCount) + itemPrice;
      });
      setTotalItem(itemCount);
      setTotalPrice(totalCount);
    } else {
      setTotalItem(0);
      setTotalPrice(0);
    }
  }, [cartData]);
  // Total calculation Item and price

  // Page navigation
  let navigate = useNavigate();
  const goToPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-header-div">
            {/* <h6 className='page-header-text'>CART LIST</h6> */}
            <p>
              <span className="breadcome-link" onClick={goToPage}>
                Products
              </span>
              &nbsp;&nbsp;<i className="fa fa-angle-right"></i>&nbsp;&nbsp;
              <span className="breadcome-text">Cart Page</span>
            </p>
          </div>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={2} className="cat-col">
                  <div className="page-header-text-div">
                    <h6 className="page-header-text">CART LIST</h6>
                  </div>
                </Col>
                {/* <Col md={5} className='cat-col'>
                <div className='input-div'>
                  <input type="text" placeholder='Find a Product' className='form-control setting-input'
                      value={searchValue} onChange={(e) => setSearchData(e.target.value)} 
                      />
                  <i className='fa fa-search search-icon' ></i>
                </div>
              </Col> */}
                {/* <Col md={5} className='cat-col'>
                <div className="search-bar-header" style={{float: "right"}}>
                  <div className="page-top-bar-dropdowns">
                    <div className="header-filter-btn" onClick={() => setFilter(!filter)}>
                      <p className="card-head">
                        <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                      </p>
                    </div>
                    <SbCreateListBtn createList={createList} bntName="Create List" clickEffect={createListPage} />
                    <button
                      type="button"
                      className="btn btn-add-product"
                      onClick={() => setShow(!show)}
                    >
                      <span className="btn-inline-item d-flex">
                        <i className="fa fa-plus-square" /> &nbsp;
                        <h6>Add New</h6>
                      </span>
                    </button>
                  </div>
                </div>
              </Col> */}
              </Row>

              {/* Products List */}
              <div className="product-list-div">
                <ProductsTable
                  tableHeader={tableHeader}
                  cartData={cartData}
                  increaseCount={increaseCount}
                  decreaseCount={decreaseCount}
                  removeItem={removeItem}
                />
              </div>
              {cartData.length != 0 && (
                <div className="place-order-div">
                  <div className="summery-div">
                    <div className="summery-details">
                      {/* <h6 className='page-header-text' >Order Summery</h6> */}
                      <div className="total-count">
                        <b>Items - {totalItem}</b>
                      </div>
                      <div className="total-count">
                        <b>
                          Total -{" "}
                          <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                          {FormattedAmount(totalPrice)}
                        </b>
                      </div>
                      <SbCheckoutBtn bntName="Proceed To Checkout" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Products List */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartPage;
