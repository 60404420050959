import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import { useSelector } from "react-redux";
import "../../../styles/CommonStyle.scss";

function ReimbursementLimitsTable(props) {
  const { totalCount } = useSelector((state) => state.data);

  const mergeRows = (accumulator, currentValue) => {
    const existingRow = accumulator.find(
      (row) => row.departmentName === currentValue.departmentName
    );
    if (existingRow) {
      existingRow.designationInfo.push({
        ...currentValue,
      });
    } else {
      accumulator.push({
        departmentName: currentValue.departmentName,
        designationInfo: [{ ...currentValue }],
      });
    }
    return accumulator;
  };

  const mergedRows = props.reimburseMentData
    ? props.reimburseMentData.reduce(mergeRows, [])
    : [];

  console.log("MERGED-DATA:", props.tableHeader);

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.reimburseMentData,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="basefare-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.length > 0 &&
                  props.tableHeader?.map((names, i) => (
                    <>
                      {names.subHeading.length == 0 ? (
                        <TableCell
                          key={i}
                          align={names.align}
                          style={{ minWidth: names.width }}
                          className="border-right"
                        >
                          {names?.name}
                        </TableCell>
                      ) : (
                        <TableCell
                          colSpan={names.subHeading.length ?? 1}
                          align={names.align}
                          style={{
                            minWidth: names.width,
                            padding: "1px",
                          }}
                          className="border-right"
                        >
                          {names?.name}
                          <TableRow className="table_row-inside-tabel-cell">
                            {names.subHeading.length > 0 &&
                              names.subHeading.map((data, ind) => (
                                <TableCell
                                  key={ind}
                                  align={data.align}
                                  style={{
                                    minWidth: data.width,
                                  }}
                                  className="border-right-n-top"
                                >
                                  {data?.name ?? ""}
                                </TableCell>
                              ))}
                          </TableRow>
                        </TableCell>
                      )}
                    </>
                  ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <>
                {props.tableHeader.map((name, i) => (
                  <SkeletonMeetPurpose key={i} />
                ))}
              </>
            ) : (
              <>
                {props.reimburseMentData &&
                props.reimburseMentData.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>--- No Data Found ---</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {mergedRows.length > 0 &&
                      mergedRows.map((data, i) => (
                        <TableRow
                          align="center"
                          key={i}
                          className="client-list-row-data"
                        >
                          <TableCell
                            style={{ width: "150px" }}
                            className="br-1"
                          >
                            <span className="color-green">
                              <b>{data?.departmentName}</b>
                            </span>
                          </TableCell>
                          <TableCell colSpan={11} className="p-0">
                            {data.designationInfo.map((item, i) => (
                              <TableRow
                                key={i}
                                className="table_row-inside-tabel-cell"
                              >
                                <TableCell style={{ width: "200px" }}>
                                  <>
                                    L{item?.designationLevel}-
                                    {item?.designationName}
                                  </>
                                </TableCell>
                                <TableCell style={{ width: "100px" }}>
                                  {item?.foodCliaimLimit}
                                </TableCell>

                                <TableCell style={{ width: "100px" }}>
                                  {item?.hotelCliaimLimit}
                                </TableCell>
                                <TableCell style={{ width: "100px" }}>
                                  <>{item?.othersCliaimLimit}</>
                                </TableCell>
                                <TableCell style={{ width: "100px" }}>
                                  {item?.osFoodCliaimLimit}
                                </TableCell>
                                <TableCell style={{ width: "100px" }}>
                                  {item?.osHotelCliaimLimit}
                                </TableCell>
                                <TableCell style={{ width: "160px" }}>
                                  {item?.osLocalConvinienceCliaimLimit}
                                </TableCell>
                                <TableCell style={{ width: "100px" }}>
                                  {item?.osOthersCliaimLimit}
                                </TableCell>

                                <TableCell style={{ width: "150px" }}>
                                  {item?.addedByUserFullName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: "150px" }}
                                >
                                  {item.status === 1 ? (
                                    <span className="complete-status status-badge">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="pending-status status-badge">
                                      Inactive
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell
                                  className="action-class"
                                  align="center"
                                  style={{ width: "200px" }}
                                >
                                  <button
                                    onClick={() => {
                                      props.editReimburse(
                                        item.customeClaimLimitID
                                      );
                                      props.setEditReimburseID(
                                        item.customeClaimLimitID
                                      );
                                    }}
                                    className="btn client-history-link"
                                  >
                                    <span>
                                      <i className="fa fa-edit"></i> Edit
                                    </span>
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default ReimbursementLimitsTable;
