import React, { useEffect, useState } from "react";
import "../../src/styles/SignIn.scss";
import travelize from "../images/metric-favicon.svg";
import travelizeSalesBeeLogo from "../images/MatricLogo.svg";
import { Col, Row, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as HeaderAuthentication from "../Components/HeaderAuthentication";
import { upDateNewPassword } from "../redux/action";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PasswordChange = () => {
  const location = useLocation();

  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setCinfirmPassword] = useState("")

  const [alertMessages, setAlertMessages] = useState({
    oldPassAlert: "",
    confirmPassAlert: "",
  });
  const [successMessage, setSuccessMessage] = useState("")

  const toggleOldPassword = () => {
    if (oldPasswordType === "password") {
      setOldPasswordType("text");
      return;
    }
    setOldPasswordType("password");
  };
  const toggleNewPassword = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text");
      return;
    }
    setNewPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  let dispatch = useDispatch();
  let navigate = useNavigate();

  const goToLogin = () => {
    navigate("/");
  }
  const changePassword = (e) => {
    e.preventDefault();
    let newAlertMessages = { oldPassAlert: "", confirmPassAlert: "", };
    if (!oldPassword) {
      newAlertMessages.oldPassAlert = "Old password is required!";
    }
    if (!newPassword) {
      newAlertMessages.confirmPassAlert = "New password is required!";
    } else if (newPassword !== confirmPassword) {
      newAlertMessages.confirmPassAlert =
        "New Password & Confirm Password must be the same!";
    }

    setAlertMessages(newAlertMessages);

    if (!newAlertMessages.oldPassAlert && !newAlertMessages.confirmPassAlert) {
      const data = {
        oldCred: oldPassword,
        newCred: newPassword,
      };
      let postPassword = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Travelize_Authentication: location.state.auth_code,
        },
        body: JSON.stringify(data),
      };
      console.log(data, "data")
      dispatch(upDateNewPassword(postPassword, setSuccessMessage, goToLogin));
      setOldPassword("");
      setNewPassword("");
      setCinfirmPassword("");
    }
  }

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <div><>
      <div className="container-fluid">
        <div className="sigin-page-body">
          {/* <Row> */}
          <div className="dflex-flow-row">
            <Col
              lg={5}
              md={5}
              sm={12}
              className="signIn-container-left-div"
            >
              <div className="signIn-container-logo">
                <Image
                  src={travelizeSalesBeeLogo}
                  alt="MetricInfo"
                  className="signIn-logo"
                />
              </div>
              <div className="signIn-container-gif">
                <Image
                  src="../images/sign-animate.gif"
                  alt="MetricInfo Motion Logo"
                  className="signIn-moving-img"
                />
              </div>
            </Col>
            <Col lg={2} md={2} sm={12}>
              <div className="devider">
                <div className="v1"></div>
              </div>
            </Col>
            <Col
              lg={5}
              md={5}
              sm={12}
              className="signIn-container-right-div"
            >
              <div className="metricinfo-logo">
                <Image
                  src={travelize}
                  alt="MetricInfo Logo"
                  className="sb-trv-logo"
                />
              </div>
              <div className="signin-header login-part">
                <div className="login-header-part">
                  <h2 className="mb-2">Change Password!</h2>
                  <h4>Please change your password before login...!</h4>
                </div>
                <form className="login-form">
                  <div className="form-group mb-4">
                    <div className="icon-with-input before">
                      <i className="fa fa-lock"></i>
                      <input
                        type={oldPasswordType}
                        className="form-control signIn-form-control"
                        placeholder=" Old password"
                        value={oldPassword}
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                          setAlertMessages("");
                        }}
                        required
                      />
                      <div className="pass-hide-show">
                        {oldPasswordType === "password" ? (
                          <i
                            className="bi bi-eye-slash pass-hide-show"
                            onClick={toggleOldPassword}
                          />
                        ) : (
                          <i
                            className="bi bi-eye pass-hide-show"
                            onClick={toggleOldPassword}
                          />
                        )}
                      </div>
                    </div>
                    {alertMessages.oldPassAlert && (
                      <small className="form-text text-muted">
                        {alertMessages.oldPassAlert}
                      </small>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    {/* <label>Password</label> */}
                    <div className="icon-with-input before">
                      <i className="fa fa-lock"></i>
                      <input
                        type={newPasswordType}
                        className="form-control signIn-form-control"
                        placeholder="New password"
                        value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          setAlertMessages("");
                        }}
                        required
                      />
                      <div className="pass-hide-show">
                        {newPasswordType === "password" ? (
                          <i
                            className="bi bi-eye-slash pass-hide-show"
                            onClick={toggleNewPassword}
                          />
                        ) : (
                          <i
                            className="bi bi-eye pass-hide-show"
                            onClick={toggleNewPassword}
                          />
                        )}
                      </div>
                    </div>
                    {alertMessages.confirmPassAlert && newPassword === "" && (
                      <small className="form-text text-muted">Required!</small>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    {/* <label>Password</label> */}
                    <div className="icon-with-input before">
                      <i className="fa fa-lock"></i>
                      <input
                        type={confirmPasswordType}
                        className="form-control signIn-form-control"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setCinfirmPassword(e.target.value);
                          setAlertMessages(""); // Clear the error messages
                        }}
                        required
                      />
                      <div className="pass-hide-show">
                        {confirmPasswordType === "password" ? (
                          <i
                            className="bi bi-eye-slash pass-hide-show"
                            onClick={toggleConfirmPassword}
                          />
                        ) : (
                          <i
                            className="bi bi-eye pass-hide-show"
                            onClick={toggleConfirmPassword}
                          />
                        )}
                      </div>
                    </div>
                    {alertMessages.confirmPassAlert && (
                      <small className="form-text text-muted">
                        {confirmPassword === ""
                          ? "Required!"
                          : alertMessages.confirmPassAlert}
                      </small>
                    )}
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <div className="signin-button mt-2">
                      <button type="submit" className="btn btn-signin-button" onClick={changePassword} style={{ width: "auto" }}>
                        Update Password
                      </button>
                    </div>
                    <div className="forget-pasword-div">
                      <Link
                        to="/signin"
                        className="forgot-pass-text color-yellow"
                      >
                        Back to Sign-In
                      </Link>
                    </div>
                    {successMessage !== "" && (
                      <small
                        // id="inputFieldErrorMsgSuccess"
                        className="form-text text-muted"
                      >
                        {successMessage}
                      </small>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            {/* </Row> */}
          </div>
        </div>
      </div>
    </>
    </div>
  );
};

export default PasswordChange;
