import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "../../styles/ViewClient.scss";
import {
  loadGetSingleClient,
  loadOtherContacts,
  loadPostOtherConact,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import * as HeaderData from "../HeaderAuthentication";

function ViewClient(props) {
  let clientId = props.clientId;
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let dispatch = useDispatch();
  const formRef = useRef(null);
  const { singleClient } = useSelector((state) => state.data);
  const { otherContacts } = useSelector((state) => state.data);
  console.log(clientId, "otherContacts");

  const handleCloseModal = () => {
    props.setShowModal(false);
    dispatch(loadGetSingleClient(null));
  };

  const [addContact, setAddContact] = useState(false);
  const openContactForm = () => {
    setAddContact(!addContact);
  };

  const closeContactForm = () => {
    dispatch(loadOtherContacts(clientId));
    setPersonDetails({
      subscriptionID: "",
      clientID: 0,
      contactPersonID: 0,
      name: "",
      email: "",
      contact: 0,
      designation: "",
      addedByID: "",
      addedOn: "",
      addedByUserFullName: "",
      status: 0,
      isPrimary: 0,
      profilePicClient: "",
    });
    setAddContact(!addContact);
  };

  const [personDeatails, setPersonDetails] = useState({
    subscriptionID: resPassData.subscriptionId,
    clientID: "",
    name: "",
    email: "",
    contact: 0,
    contactPersonID: 0,
    designation: "",
    addedByID: resPassData.userId,
    addedOn: "",
    addedByUserFullName: resPassData.fullName,
    status: 1,
    isPrimary: 0,
  });

  useEffect(() => {
    if (clientId) {
      // alert("hi")
      dispatch(loadGetSingleClient(clientId));
      dispatch(loadOtherContacts(clientId));
      personDeatails.clientID = clientId;
    }
  }, [clientId]);

  const { name, email, contact, designation } = personDeatails;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "contact") {
      setPersonDetails({ ...personDeatails, [name]: parseInt(value) });
    } else {
      setPersonDetails({ ...personDeatails, [name]: value });
    }
  };

  const saveOtherConact = (e) => {
    e.preventDefault();
    // if (!name || )
    var postResponse = {
      method: "POST",
      mode: "cors",
      headers: HeaderData.postTravelizeAuth,
      body: JSON.stringify(personDeatails),
    };
    dispatch(loadPostOtherConact(postResponse, closeContactForm));
  };

  return (
    <div>
      {props.showModal && (
        <div className="modal-backdrop">
          <div className="modal-right-fade">
            <Modal.Dialog className="right-fade-modal-dialog">
              {singleClient && (
                <div>
                  <Modal.Header closeButton onHide={handleCloseModal}>
                    <Modal.Title className="client-modal-header card-head">
                      Client Details
                      {/* <span className='view-map-text'>View On Map</span> */}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="details-row">
                      <Col md={4}>
                        <p>Client Name</p>
                        <p>Phone</p>
                        <p>Email</p>
                      </Col>
                      <Col md={8}>
                        <p>
                          <b>{singleClient?.clientName}</b>
                        </p>
                        <p className="detail-text">{singleClient?.mobile}</p>
                        <p className="detail-text">{singleClient?.email}</p>
                      </Col>
                    </Row>
                    <Row className="details-row">
                      <Col md={4}>
                        {singleClient?.city?.zoneName !== "NA" && <p>Zone</p>}
                        {(singleClient?.city?.zoneName !== "NA" ||
                          singleClient?.city?.cityName !== "NA") && (
                          <p>Region</p>
                        )}
                      </Col>
                      <Col md={8}>
                        {singleClient?.city?.zoneName !== "NA" && (
                          <p>{singleClient?.city?.zoneName}</p>
                        )}
                        {(singleClient?.city?.zoneName !== "NA" ||
                          singleClient?.city?.cityName !== "NA") && (
                          <p className="detail-text">
                            {singleClient?.city?.stateName !== "NA" &&
                              singleClient?.city?.stateName + ","}
                            {singleClient?.city?.cityName !== "NA" &&
                              singleClient?.city?.cityName}
                          </p>
                        )}
                      </Col>
                    </Row>
                    <Row className="details-row">
                      <Col md={4}>
                        <p>Address</p>
                      </Col>
                      <Col md={8}>
                        <p className="detail-text">
                          {singleClient?.clientLoc?.location}
                        </p>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer className="contact-footer">
                    <div className="other-contact-section">
                      <div>
                        <h6 className="card-head">Other Contact</h6>
                      </div>
                      <div>
                        <h6
                          className="card-head view-map-text"
                          onClick={openContactForm}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          &nbsp;&nbsp;Add Contact
                        </h6>
                      </div>
                    </div>
                    <div className="other-contacts">
                      {otherContacts &&
                        otherContacts.map((contact, i) => (
                          <div className="conatct-text" key={i}>
                            <p>
                              <span>
                                <i className="fa fa-user"></i>
                              </span>
                              <span style={{ paddingLeft: "5px" }}>
                                {contact.name}({contact.designation})
                              </span>
                            </p>
                            <p>
                              <span>
                                <i className="fa fa-phone"></i>
                              </span>
                              <span
                                style={{ paddingLeft: "5px", color: "#04A96D" }}
                              >
                                {contact.contact}
                              </span>
                            </p>
                          </div>
                        ))}
                    </div>
                  </Modal.Footer>
                </div>
              )}
              {addContact && (
                <div className="other-contact-form">
                  <div className="contact-form-inner">
                    <div>
                      <h6 className="card-head">Other Contact Person</h6>
                    </div>
                    <div className="contact-form-div">
                      <Form ref={formRef} onSubmit={saveOtherConact}>
                        <div className="form-field">
                          <Form.Group
                            className="mb-3_health"
                            controlId="exampleForm.Health"
                          >
                            <Form.Control
                              type="text"
                              value={designation || ""}
                              name="designation"
                              onChange={handleInputChange}
                              placeholder="Designation"
                              autoComplete="off"
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="form-field">
                          <Form.Group
                            className="mb-3_health"
                            controlId="exampleForm.Health"
                          >
                            <Form.Control
                              type="text"
                              value={name || ""}
                              name="name"
                              onChange={handleInputChange}
                              placeholder="Name"
                              autoComplete="off"
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="form-field">
                          <Form.Group
                            className="mb-3_health"
                            controlId="exampleForm.Health"
                          >
                            <Form.Control
                              type="tel"
                              maxLength={10}
                              value={contact || ""}
                              name="contact"
                              onChange={handleInputChange}
                              placeholder="Mobile"
                              autoComplete="off"
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="form-field">
                          <Form.Group
                            className="mb-3_health"
                            controlId="exampleForm.Health"
                          >
                            <Form.Control
                              type="text"
                              value={email || ""}
                              name="email"
                              onChange={handleInputChange}
                              placeholder="Email"
                              autoComplete="off"
                              required
                            />
                          </Form.Group>
                        </div>

                        <hr />
                        <div className="contact-btn-section">
                          <SbAddBtn
                            bntName="Save"
                            // onClickEffect={saveOtherConact}
                          />
                          <SbCancelBtn
                            btnName="Cancel"
                            onClickEffect={closeContactForm}
                          />
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            </Modal.Dialog>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewClient;
