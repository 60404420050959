import React, { useState, useEffect, useLayoutEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { MultiSelect } from "react-multi-select-component";
import "../../styles/AddEditModel.scss";
import moment from "moment";
import * as swal from "../Consturl/SwalAlert";
import { baseURL } from "../BaseUrl";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import LoadingAnimation from "../LoadingAnimation";
import SetDateTimeModel from "./SetDateTimeModel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  loadUserDropdown,
  loadUserRegion,
  loadClientCategoryDrop,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

export default function AddClientToRote(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const clientsListBy = [
    { value: 1, label: "User" },
    { value: 2, label: "State" },
    { value: 3, label: "City" },
  ];

  const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const subscriptionID = loginDetails.subscriptionId;
  let dispatch = useDispatch();

  const { userDropdown } = useSelector((state) => state.data);
  const { userRegionResult } = useSelector((state) => state.data);
  const { categoryDropdown } = useSelector((state) => state.data);

  const [clientList, setClientList] = useState([]);
  const [clientListSelected, setClientListSelected] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [dateTimemodel, setDateTimemodel] = useState(false);
  const [categoryID, setCategoryID] = useState([]);
  const [clientsListByID, setClientsListByID] = useState(null);
  const [userIdForClient, setUserIdForClient] = useState("");
  const [stateIdsForClient, setStateIdsForClient] = useState([]);
  const [cityIdsForClient, setCityIdsForClient] = useState([]);
  const [categoryNames, setCategoryNames] = useState([]);
  const [stateForClient, setStateForClient] = useState([]);
  const [cityForClient, setCityForClient] = useState([]);
  const [clientLoading, setClientLoading] = useState(true);
  const [stateListSingleSelect, setStateListSingleSelect] = useState([]);
  const [cityListSingleSelect, setCityListSingleSelect] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [byClientId, setByClientId] = useState(0);
  const [newdate, setNewDate] = useState("");
  const [meetTime, setMeetTime] = useState({ val: null, time: null });
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useLayoutEffect(() => {
    dispatch(loadUserRegion());
    dispatch(loadUserDropdown());
    dispatch(loadClientCategoryDrop());
  }, []);

  // Input date string in "DD/MM/YYYY" format
  const inputDateFrom = props.fromDate;
  const inputDateTo = props.toDate;

  // Parse the input date string into a JavaScript Date object
  const [dayf, monthf, yearf] = inputDateFrom.split("/").map(Number);
  const [dayt, montht, yeart] = inputDateTo.split("/").map(Number);
  const dateFrom = new Date(yearf, monthf - 1, dayf);
  const dateTo = new Date(yeart, montht - 1, dayt);

  const [fromDate, setFromDate] = useState(dateFrom);
  const [toDate, setToDate] = useState(dateTo);

  //   console.log("FFFTTTT:", fromDate);

  const handleChangeCategoryClient = (selectedList) => {
    setCategoryNames(selectedList);
    setCategoryID(selectedList.map((cat) => cat.value));
  };

  const handleChangeStateForClient = (selectedList) => {
    setStateForClient(selectedList);
    setStateIdsForClient(selectedList.map((s) => s.stateId));
  };

  const handleChangeCityForClient = (selectedList) => {
    setCityForClient(selectedList);
    setCityIdsForClient(selectedList.map((s) => s.cityId));
  };

  useEffect(() => {
    fetchClientDropdown();
  }, [categoryID, stateIdsForClient, cityIdsForClient, userIdForClient]);

  const fetchClientDropdown = () => {
    // setClientLoading(true);
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/Get?PageSize=13664025&CurrentPage=1&EnabledStatus=1&CategoryID=${
        categoryID[0] ?? 0
      }&MultiCategoryFilter=${categoryID?.toString() ?? ""}${
        clientsListByID == 1
          ? `&UserID=${userIdForClient}`
          : clientsListByID == 2
          ? `&Stateid=${stateIdsForClient[0] ?? 0}&MultiStateFilter=${
              stateIdsForClient.toString() ?? ""
            }`
          : clientsListByID == 3
          ? `&CityID=${cityIdsForClient[0] ?? 0}&MultiCityFilter=${
              cityIdsForClient.toString() ?? ""
            }`
          : ""
      }`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("CLIENT-LIST-DROPDOWN::", res.result);
          setClientLoading(false);
          setClientList(
            res.result?.map((client) => {
              return { value: client.clientID, label: client.clientName };
            })
          );
        } else {
          setClientLoading(false);
          setClientList([]);
        }
      });
  };

  useEffect(() => {
    fetchDropdownByZone();
  }, [clientsListByID]);

  const fetchDropdownByZone = () => {
    let stateResponse = userRegionResult.allStates;
    setStateListSingleSelect(stateResponse);
    let cityResponse = userRegionResult.allCities;
    setCityListSingleSelect(cityResponse);
  };

  useEffect(() => {
    if (clientId.length > 0) {
      let requestOption = {
        method: "GET",
        mode: "cors",
        headers: travelizeAuth,
      };
      fetch(
        `${baseURL}/api/Client/Get?PageSize=13664025&CurrentPage=1&EnabledStatus=1&SearchIDS=${clientId}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            console.log("CLIENT-LIST-SELECTED::", res.result);
            setClientListSelected(res.result);
            setClientLoading(false);
          } else {
            setClientListSelected([]);
            setClientLoading(false);
          }
        });
      saveClientsToRoute();
    }
  }, [clientId]);

  // MULTI SELECT DROPDOWN FUNCTION START
  const handleSelectClient = (selectedList) => {
    // console.log("CLNTS:", selectedList);
    setClients(selectedList);
    setClientId(selectedList.map((c) => c.value));
  };
  // MULTI SELECT DROPDOWN FUNCTION END

  useEffect(() => {
    const setInputEle = () => {
      clientsData?.routeClient?.forEach((ele) => {
        ele.inputState = {
          clientName: "",
          contactperson: "",
          clientID: ele.clientID,
          meetingDate: "",
          meetingTime: "",
          purposeID: 0,
          status: 1,
          subscriptionID: ele.subscriptionID,
          userId: ele.userId,
        };
      });
    };
    setInputEle();
  }, [clientListSelected]);

  const handleCloseDateTime = () => {
    setDateTimemodel(false);
  };

  const setVisitDate = (date, clntID) => {
    let clientS = clientsData.routeClient.filter(
      (data) => data.clientID == clntID
    );
    clientS[0].inputState.meetingDate = date;
    setClientsData({ ...clientsData });
    // setSelectedDate(moment(date).format("DD/MM/YYYY"));
  };

  const setVisitTime = (val, clntID) => {
    let clientS = clientsData.routeClient.filter(
      (data) => data.clientID == clntID
    );
    clientS[0].inputState.meetingTime = val;
    setClientsData({ ...clientsData });
    // setSelectedTime(val);
  };

  const saveClientsToRoute = () => {
    if (clientId.length !== 0) {
      let routeClientData = [];
      for (let ids of clientId) {
        routeClientData.push({
          addedByID: props.routePlanData[0]?.userID,
          clientID: ids,
          meetingDate: "",
          meetingTime: "",
          purposeID: 0,
          status: 1,
          subscriptionID: subscriptionID,
          userId: props.routePlanData[0]?.userID,
        });
      }
      const clietsNDates = {
        subscriptionID: subscriptionID,
        name: props.routePlanData[0]?.name,
        routeID: props.routePlanData[0]?.routeID,
        addedByID: props.routePlanData[0]?.userID,
        status: 1,
        routeClient: routeClientData,
      };

      setClientsData(clietsNDates);
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Please select Clients",
      });
    }
  };

  const remmoveClientFromAdded = (clntID) => {
    let updatedClients = clientListSelected?.filter(
      (data) => data.clientID != clntID
    );
    let newIds = clientId.filter((ids) => clntID != ids);
    // console.log("NewIds", newIds);
    setClientId(newIds);
    setClientListSelected(updatedClients);
  };

  const saveRoutePlan = (e) => {
    e.preventDefault();
    let clientsVisitData = [];
    for (let i = 0; i < clientsData.routeClient.length; i++) {
      if (
        clientsData.routeClient[i].inputState.meetingDate !== "" &&
        clientsData.routeClient[i].inputState.meetingTime !== ""
      ) {
        clientsVisitData.push({
          clientID: clientsData.routeClient[i].clientID,
          meetingDate: moment(
            clientsData.routeClient[i].inputState.meetingDate
          ).format("DD/MM/YYYY"),
          meetingTime: clientsData.routeClient[i].inputState.meetingTime,
          purposeID: 0,
          status: 1,
          subscriptionID: clientsData.routeClient[i].subscriptionID,
          userId: clientsData.routeClient[i].userId,
        });
      } else {
        setFieldReq(true);
        setAlertMessage({
          alertType: "fail",
          message: "Required",
        });
      }
    }
    if (clientsVisitData.length == clientsData.routeClient.length) {
      let newVisitData = {
        subscriptionID: clientsData.subscriptionID,
        name: clientsData.name,
        routeID: clientsData.routeID,
        frequency: clientsData.frequency,
        addedByID: clientsData.addedByID,
        status: 1,
        routeClient: clientsVisitData,
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(newVisitData),
      };

      fetch(`${baseURL}/api/Route/UpdateRouteClient`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.addClientToRouteModal();
            props.fetchRoutePlans();
            swal.showAlert(
              "Success",
              `Route Plan Added ${res.message}`,
              "success"
            );
          } else {
            toast.error(`${res.errorMessage}`);
          }
        })
        .catch((error) => {
          // console.log("ERROR :", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };
  //   console.log("CLIENTS-SET-DATA--- (clientListSelected)", clientListSelected);
  //   console.log("CLIENTS-SET-DATA", clientsData);

  return (
    <div>
      {" "}
      <>
        {dateTimemodel ? (
          <SetDateTimeModel
            dateTimemodel={dateTimemodel}
            handleCloseDateTime={handleCloseDateTime}
            clientsData={clientsData}
            setVisitDate={setVisitDate}
            setVisitTime={setVisitTime}
            fromDate={fromDate}
            toDate={toDate}
            clientId={byClientId}
          />
        ) : (
          ""
        )}
      </>
      <Modal
        show={props.addClientRouteModal}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            ADD CLIENT TO - {props.routePlanData[0]?.name ?? ""}
          </h6>
          <i className="fa fa-times" onClick={props.addClientToRouteModal} />
        </div>

        <form>
          <div className="container add-edit mt-3">
            {/* {clients.length > 0 ? ( */}
            <div className="add-route-pan-model">
              <div className="model-inputs mb-3">
                <FloatingLabel
                  label="Categories"
                  className={`${
                    categoryID
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Multiselect
                    className="select-add-user-model zone model-select"
                    options={categoryDropdown} // Options to display in the dropdown
                    selectedValues={categoryNames} // Preselected value to persist in dropdown
                    onSelect={handleChangeCategoryClient} // Function will trigger on select event
                    onRemove={handleChangeCategoryClient} // Function will trigger on remove event
                    showCheckbox={true}
                    displayValue="label" // Property name to display in the dropdown options
                    placeholder={"Select Category"}
                    emptyRecordMsg="No options available"
                    avoidHighlightFirstOption={true}
                    closeIcon="cancel"
                    showArrow={true}
                    closeOnSelect={true}
                    hidePlaceholder={true}
                  />
                </FloatingLabel>
              </div>
              <>
                <div className="radio-label color-green mb-2">
                  Company List By{" "}
                </div>
                <div className="model-inputs dflex-j-start mb-3">
                  {clientsListBy.map((item, i) => (
                    <div key={i}>
                      <Form.Check
                        inline
                        label={item?.label}
                        value={item?.value}
                        name="clientsby"
                        type="radio"
                        id={`inlineradio-clientsby-${i}`}
                        onChange={(e) => setClientsListByID(e.target.value)}
                      />
                    </div>
                  ))}
                </div>
              </>

              <div>
                {clientsListByID == 1 ? (
                  <div className="model-inputs mb-3">
                    <FloatingLabel
                      label="Users"
                      className={`${
                        userIdForClient
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={userDropdown}
                        placeholder="Select User"
                        value={userIdForClient ? userDropdown.value : ""}
                        onInputChange={userDropdown?.label}
                        onChange={(data) => setUserIdForClient(data.value)}
                        isSearchable={true}
                        // required
                      />
                    </FloatingLabel>
                  </div>
                ) : clientsListByID == 2 ? (
                  <div className="model-inputs mb-3">
                    <FloatingLabel
                      label="States"
                      className={`${
                        stateIdsForClient
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <Multiselect
                        className="select-add-user-model zone model-select"
                        options={stateListSingleSelect} // Options to display in the dropdown
                        selectedValues={stateForClient} // Preselected value to persist in dropdown
                        onSelect={handleChangeStateForClient} // Function will trigger on select event
                        onRemove={handleChangeStateForClient} // Function will trigger on remove event
                        showCheckbox={true}
                        displayValue="stateName" // Property name to display in the dropdown options
                        placeholder={"Select States"}
                        emptyRecordMsg="No options available"
                        avoidHighlightFirstOption={true}
                        closeIcon="cancel"
                        groupBy="zoneName"
                        showArrow={true}
                        closeOnSelect={true}
                        hidePlaceholder={true}
                      />
                    </FloatingLabel>
                  </div>
                ) : clientsListByID == 3 ? (
                  <div className="model-inputs mb-3">
                    <FloatingLabel
                      label="Cities"
                      className={`${
                        cityIdsForClient
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <Multiselect
                        className="select-add-user-model zone model-select"
                        options={cityListSingleSelect} // Options to display in the dropdown
                        selectedValues={cityForClient} // Preselected value to persist in dropdown
                        onSelect={handleChangeCityForClient} // Function will trigger on select event
                        onRemove={handleChangeCityForClient} // Function will trigger on remove event
                        showCheckbox={true}
                        displayValue="cityName" // Property name to display in the dropdown options
                        placeholder={"Select City"}
                        emptyRecordMsg="No options available"
                        avoidHighlightFirstOption={true}
                        closeIcon="cancel"
                        groupBy="stateName"
                        showArrow={true}
                        closeOnSelect={true}
                        hidePlaceholder={true}
                      />
                    </FloatingLabel>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="model-inputs mb-3">
                <FloatingLabel
                  label="Select Clients"
                  className={`${
                    clientId.length > 0
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <MultiSelect
                    className="select-add-user-model"
                    options={clientList}
                    value={clients}
                    onChange={handleSelectClient}
                    labelledBy={clientLoading ? "Loading..." : "Select Clients"}
                    isLoading={clientLoading}
                    closeOnChangedValue={false}
                  />
                </FloatingLabel>
                {fieldReq && (clients.length == 0 || clients == "") ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    Please select clients
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="model-inputs client-list-with-date-time edit-part mb-3">
              <div className="display-column-wise">
                <div className="clients-data p-0 ">
                  {!clientLoading && clientListSelected.length > 0 ? (
                    clientListSelected.map((data, i) => (
                      <div className="clientn-datetime-icon">
                        <div className="clients-details" key={i}>
                          <div className="pb-1">
                            <sapn className="tb-td-sh">Name : </sapn>&nbsp;
                            <strong className="color-green">
                              {data?.clientName?.toUpperCase()}
                            </strong>
                          </div>
                          <div className="pb-1">
                            <sapn className="tb-td-sh">Person : </sapn>&nbsp;
                            <span className="color-yellow">
                              {data?.contactPersonName}
                            </span>
                          </div>
                          <div className="pb-1">
                            <sapn className="tb-td-sh">City : </sapn>&nbsp;
                            <span className="color-yellow">
                              {data?.city?.cityName}
                            </span>
                          </div>
                          <div>
                            <sapn className="tb-td-sh">Location : </sapn>
                            &nbsp;
                            <span>{data?.clientLoc?.location}</span>
                          </div>
                        </div>
                        {clientsData?.routeClient
                          ?.filter((item) => item.clientID == data?.clientID)
                          .map((clnt) => (
                            <div className="set-date-time-icon">
                              <div
                                className="remove-icon"
                                onClick={(e) =>
                                  remmoveClientFromAdded(data?.clientID)
                                }
                              >
                                <span className="material-symbols-outlined mb-2">
                                  delete
                                </span>
                              </div>
                              <div
                                className="text-center"
                                onClick={() => {
                                  setDateTimemodel(true);
                                  setByClientId(data?.clientID);
                                }}
                              >
                                <i className="bi bi-clock-fill" />
                              </div>
                              <div>
                                <p className="color-yellow">
                                  {clnt?.inputState?.meetingDate
                                    ? moment(
                                        clnt?.inputState?.meetingDate
                                      ).format("DD/MM/YYYY")
                                    : "Set"}{" "}
                                  <br />
                                  {clnt?.inputState?.meetingTime ?? ""}
                                </p>
                              </div>
                              {fieldReq &&
                              (clnt?.inputState?.meetingTime == "" ||
                                clnt?.inputState?.meetingDate == "") ? (
                                <small
                                  id={`Error${alertMessage.alertType}`}
                                  className="form-text text-muted "
                                >
                                  {`${alertMessage.message}`}
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                      </div>
                    ))
                  ) : (
                    <>
                      <LoadingAnimation />
                      &nbsp; Loading....
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn
              btnName="Update"
              onClickEffect={(event) => saveRoutePlan(event)}
            />
            <SbCancelBtn
              btnName="Cancel"
              onClickEffect={props.addClientToRouteModal}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}
