import { combineReducers } from "redux";
import designReducers from "./reducer";
import whastappConfigReducers from "./ModalReducers/whatsappConfig_Reducer";

const rootReducer = combineReducers({
  data: designReducers,
  configData: whastappConfigReducers,
});

export default rootReducer;
