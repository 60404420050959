import React, { useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Complaints.scss";
import "../../styles/ClientComplaint.scss";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadClientDropDown, loadOrders, loadSearchOrders, loadSubsrDropdown, loadUserDropdown } from "../../redux/action";
import OrdersTable from "./OrdersTable";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Footer from "../Footer";

export default function Orders() {

  let dispatch = useDispatch();
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const { oderDetails } = useSelector(state => state.data);
  const { clientDropdown } = useSelector(state => state.data);
  // let sortedArray;
  // if (oderDetails) {
  //   sortedArray = oderDetails.sort((a, b) => a.orderID - b.orderID);
  // }
  const [isLoading, setIsLoading] = useState(true);

  let tableHeader = [
    { name: "ORDER #", align: "center", width: "100px" },
    { name: "COMPANY", align: "left", width: "150px" },
    { name: "DELIVERY ADDRESS", align: "left", width: "250px" },
    { name: "ORDERED ON", align: "center", width: "150px" },
    { name: "ORDERED QTY", align: "center", width: "150px" },
    { name: "VALUE", align: "center", width: "120px" },
    { name: "STATUS", align: "center", width: "80px" },
    { name: "ACTION", align: "center", width: "80px" },
  ]

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editAmcModel, setEditAmcModel] = useState(false);
  const [viewProductDetail, setViewProductDetail] = useState(false);

  const [showUpdate, setShowUpdate] = useState(false);
  const [orderId, setOrderId] = useState("");

  let navigate = useNavigate()
  const selectOrder = (orderId) => {
    setOrderId(orderId)
    navigate(`/orderDetails/${orderId}`)
  }

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(3);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState("");
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  const [searchValue, setSearchValue] = useState('')
  const [filterArray, setFilterArray] = useState([])
  const [filterOrderStatus, setFilterOrderStatus] = useState('')
  const [filterClientName, setFilterClientName] = useState({ value: "", label: "" })
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
  const [toDate, setToDate] = useState(new Date());
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  useEffect(() => {
    let list = []
    if (fromDate && toDate) {
      list.push({ key: "FromDate", value: moment(new Date(fromDate)).format("DD/MM/YYYY") });
      list.push({ key: "ToDate", value: moment(new Date(toDate)).format("DD/MM/YYYY") });
    }
    setFilterArray(list);
  }, [])

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter(item => item.key !== "Text")
    } else {
      list = []
    }
    if (value) {
      list.push({ key: "Text", value: value })
    } else {
      list = filterArray.filter(item => item.key !== "Text")
    }
    setSearchValue(value)
    setFilterArray(list)
  }

  const setSelectSearchDrop = (data, type) => {
    let list = []
    if (type === "orderStatus") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "OrderStatus");
      }
      list.push({ key: "OrderStatus", value: data.value });
      setFilterOrderStatus(data.value);
    } else if (type === "ClientName") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "VendorClientID");
      }
      list.push({ key: "VendorClientID", value: data.value });
      setFilterClientName(data);
    }
    // else if (type === "From") {
    //   if (filterArray.length !== 0) {
    //     list = filterArray.filter((item) => item.key !== "FromDate");
    //   }
    //   list.push({ key: "FromDate", value: moment(new Date(data)).format("DD/MM/YYYY") });
    //   setFromDate(data);
    // } else if (type === "To") {
    //   if (filterArray.length !== 0) {
    //     list = filterArray.filter((item) => item.key !== "ToDate");
    //   } else {
    //     list = [];
    //   }
    //   list.push({ key: "ToDate", value: moment(new Date(data)).format("DD/MM/YYYY") });
    //   setToDate(data);
    // }
    else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };



  useEffect(() => {
    fetchOrderData(filterArray, PageSize, currentPage)
  }, [filterArray, PageSize, currentPage])

  const fetchOrderData = () => {
    if (filterArray.length === 0) {
      dispatch(loadOrders(resPassData.subscriptionId, currentPage, PageSize, setIsLoading, setLoading))
    } else {
      // setIsLoading(true)
      dispatch(loadSearchOrders(resPassData.subscriptionId, filterArray, currentPage, PageSize, setIsLoading, setLoading))
    }
  }

  useEffect(() => { dispatch(loadClientDropDown()) }, [])

  const clearSerachBySelect = () => {
    setFilterArray([])
    setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
    setToDate(new Date())
    setFilterClientName({ value: "", label: "" })
  }

  const goToPage = () => {
    window.history.back();
  }

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* {showUpdate && <StatusUpdatePage showUpdate={showUpdate} setShowUpdate={setShowUpdate} 
      compaintId={compaintId} />} */}
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className='search-row'>
                <Col md={3} className='cat-col'>
                  <div className='page-header-text-div'>
                    <div
                      className="back-btn"
                      onClick={goToBackPage}
                    >
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className='page-header-text' >Orders</h6>
                  </div>
                </Col>
                <Col md={5} className='cat-col'>
                  <div className='input-div'>
                    <input type="search" placeholder='Search Client By Name/Mobile' className='form-control setting-input'
                      value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                    />
                    <i className='fa fa-search search-icon' ></i>
                  </div>
                </Col>
                <Col md={4} className='cat-col'>
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div className="header-filter-btn" onClick={() => setFilter(!filter)}>
                        <p className="card-head">
                          <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                        </p>
                      </div>
                      {/* <SbAddBtn bntName="Add"
                     onClickEffect={() => setShow(!show)} /> */}
                    </div>
                  </div>
                </Col>
              </Row>

              {filter &&
                <div className="page-filter-section">
                  <div style={{ width: "100%" }}>

                    <Row>
                      <Col md={10}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // options={prodSubcategory}
                              placeholder="Select Order Status"
                              // value={selectSubCategory === "" ? "" : prodSubcategory.value}
                              // onInputChange={prodSubcategory.label}
                              // onChange={(data) => setSelectSearchDrop(data, "subcategory")}
                              isSearchable={true}
                            // isDisabled={!selectCategory}
                            // isClearable={true}
                            />
                          </div>
                          {/* <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                            <span
                              className="input-group-text"
                              id="from-label" >
                              From
                            </span>
                            <span className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate || new Date()}
                                className="select from-date"
                                id="from-datepicker"
                                dateFormat="dd/MM/yyyy"
                                selectsEnd
                                // startDate={fromDate}
                                // endDate={toDate}
                                onChange={(date) => setSelectSearchDrop(date, "From")}
                                maxDate={toDate}
                              />
                              <i className="fa fa-calendar-o calander-icon" />
                            </span>
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                            <span className="input-group-text"
                              id="to-label"  >
                              To
                            </span>
                            <span className="date-picker-with-icon">
                              <DatePicker
                                selected={toDate || new Date()}
                                className="select to-date"
                                id="from-datepicker"
                                dateFormat="dd/MM/yyyy"
                                selectsEnd
                                // startDate={new Date()}
                                // endDate={new Date()}
                                minDate={fromDate}
                                onChange={(date) => setSelectSearchDrop(date, "To")}
                                maxDate={new Date()}
                              />
                              <i className="fa fa-calendar-o calander-icon" />
                            </span>
                          </div> */}
                          <div
                            style={{ width: "100%", padding: "0px 5px" }}
                            className="input-group from-to-date-select"
                          >
                            <span className="input-group-text" id="from-label">
                              From - To
                            </span>
                            <span className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date date-filter"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                selectsRange
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) =>
                                  setSelectSearchDrop(date, "date")
                                }
                                maxDate={new Date()}
                              />
                            </span>
                            <span>
                              <i
                                className="fa fa-calendar-o calander-icon"
                                style={{ right: "15px" }}
                              />
                            </span>
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={clientDropdown}
                              placeholder="Select Company"
                              value={filterClientName.value && filterClientName}
                              onInputChange={clientDropdown.label}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "ClientName")
                              }
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <SbCancelBtn onClickEffect={clearSerachBySelect}
                            btnName="Clear" />
                        </div>
                      </Col>
                    </Row>

                  </div>
                </div>
              }
            </div>
            <OrdersTable tableHeader={tableHeader} oderDetails={oderDetails} PageSize={PageSize}
              isLoading={isLoading} selectOrder={selectOrder} setLoading={setLoading} setPageSize={setPageSize} loading={loading}
            />
          </div></div>
      </div>
      <Footer />
    </div>
  );
}
