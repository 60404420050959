// import {
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import React from "react";
// import { SkeletonClients } from "../Skeleton Loading/SkeletonClients";
// import { baseURL } from "../BaseUrl";
// import { Link } from "react-router-dom";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { getRandomColor } from "../FormattedAmount";
// import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
// import { useSelector } from "react-redux";

// function ClientTable(props) {
//   const { totalCount } = useSelector((state) => state.data);

//   return (
//     <div className="sb-table-div sb-table-setion">
//       <Paper
//         sx={{ width: "100%", overflow: "hidden" }}
//         className="table-main-div"
//       >
//         <TableContainer
//           sx={{ maxHeight: 440 }}
//           onScroll={(e) =>
//             handleScroll(
//               e,
//               props.clientData,
//               totalCount,
//               props.setLoading,
//               props.setPageSize,
//               props.PageSize
//             )
//           }
//         >
//           <Table
//             stickyHeader
//             aria-label="sticky table table-responsive"
//             className="client-table"
//           >
//             <TableHead className="custom-table-header">
//               <TableRow>
//                 {props.tableHeader.map((header, i) => (
//                   <TableCell
//                     key={i}
//                     align={header.align}
//                     style={{ minWidth: `${header.width}` }}
//                   >
//                     {header.name}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             {props.isLoading ? (
//               <>
//                 {props.tableHeader.map((header, i) => (
//                   <SkeletonClients key={i} />
//                 ))}
//               </>
//             ) : (
//               <>
//                 {props.clientData && props.clientData.length === 0 ? (
//                   <TableBody>
//                     <TableRow>
//                       <TableCell
//                         align="center"
//                         colSpan={props.tableHeader.length}
//                       >
//                         --- NO DATA FOUND ---
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 ) : (
//                   <TableBody>
//                     {props.clientData.map((data, i) => (
//                       <TableRow
//                         align="center"
//                         key={i}
//                         className="client-list-row-data"
//                       >
//                         <TableCell>
//                           <div className="users-list-name">
//                             <div>
//                               {data.logo ? (
//                                 <img
//                                   className="table-data-users-imgages company-logo"
//                                   src={`${baseURL}/${data.logo}`}
//                                   alt="client-profile"
//                                 />
//                               ) : (
//                                 <div
//                                   className="table-data-users-imgages default-image"
//                                   style={{ backgroundColor: getRandomColor(i) }}
//                                 >
//                                   {data?.clientName.charAt(0).toUpperCase()}
//                                 </div>
//                               )}
//                             </div>
//                             <div>
//                               <div
//                                 className="table-row-head-Text"
//                                 style={{ cursor: "pointer" }}
//                                 onClick={(e) =>
//                                   props.handleView(data?.clientID)
//                                 }
//                               >
//                                 <strong> {data?.clientName}</strong>
//                               </div>

//                               <div className="table-row-sub-text">
//                                 <i className="fa fa-thumb-tack" /> Re-Tag :{" "}
//                                 <span>{data?.retagCount}</span>
//                               </div>
//                             </div>
//                           </div>
//                         </TableCell>
//                         <TableCell>{data?.contactPersonName}</TableCell>
//                         <TableCell>
//                           <span className="color-green">{data?.mobile}</span>{" "}
//                           <br />
//                           <span>{data?.email}</span>
//                         </TableCell>
//                         <TableCell>
//                           <span>
//                             <strong>
//                               {data?.clientCategoryName
//                                 ? data?.clientCategoryName
//                                 : "-"}{" "}
//                             </strong>
//                           </span>
//                           <br />
//                           {data?.clientStageName ? data?.clientStageName : "-"}
//                         </TableCell>
//                         <TableCell>
//                           {data?.clientLoc?.location}
//                           &nbsp;
//                           <span className="client-tagged">{data?.flag}</span>
//                         </TableCell>
//                         <TableCell>
//                           {data?.userFullName}
//                           <Link
//                             to="#"
//                             onClick={() => {
//                               props.setShowMappedTo(!props.showMappedTo);
//                               props.setClientId(data?.clientID);
//                             }}
//                             className="view-mapped-user"
//                           >
//                             <OverlayTrigger
//                               placement={"top"}
//                               delay={{ show: 200, hide: 250 }}
//                               overlay={
//                                 <Tooltip id={`tooltip-title`}>
//                                   Mapped To
//                                 </Tooltip>
//                               }
//                             >
//                               <span>
//                                 <i className="fa fa-users user-icon" />
//                               </span>
//                             </OverlayTrigger>
//                           </Link>
//                         </TableCell>
//                         <TableCell>
//                           {/* {data?.userFullName} */}
//                           <div
//                             onClick={() => {
//                               // props.setShowReporting(!props.showReportingTo);
//                               // props.setClientId(data?.clientID);
//                               props.handleReportData(data?.clientID)
//                             }}
//                             className="view-mapped-user"
//                           >
//                             <OverlayTrigger
//                               placement={"top"}
//                               delay={{ show: 200, hide: 250 }}
//                               overlay={
//                                 <Tooltip id={`tooltip-title`}>
//                                   Reporting To
//                                 </Tooltip>
//                               }
//                             >
//                               <span>
//                                 <i className="fa fa-users user-icon" />
//                               </span>
//                             </OverlayTrigger>
//                           </div>
//                         </TableCell>
//                         <TableCell align="center">
//                           {data?.enabledStatus === 1 ? (
//                             <span className="status-badge complete-status">
//                               Active
//                             </span>
//                           ) : (
//                             <span className="status-badge cancel-status">
//                               InActive
//                             </span>
//                           )}
//                         </TableCell>
//                         <TableCell className="action-class">
//                           <button
//                             onClick={() =>
//                               props.editClientDetails(data?.clientID)
//                             }
//                             className="btn client-edit-link"
//                           >
//                             <i className="fa fa-edit" /> Edit
//                           </button>
//                           <button
//                             onClick={() => {
//                               props.setHistoryclientmodel(
//                                 !props.historyclientmodel
//                               );
//                               props.setClientId(data?.clientID);
//                             }}
//                             className="btn client-history-link"
//                           >
//                             <i className="fa fa-history" /> History
//                           </button>
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                     {props.loading && (
//                       <OnScrollLoading tableHeader={props.tableHeader} />
//                     )}
//                   </TableBody>
//                 )}
//               </>
//             )}
//           </Table>
//         </TableContainer>
//       </Paper>
//     </div>
//   );
// }

// export default ClientTable;



import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SkeletonClients } from "../Skeleton Loading/SkeletonClients";
import { baseURL } from "../BaseUrl";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getRandomColor } from "../FormattedAmount";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { useSelector } from "react-redux";

function ClientTable(props) {
  const { totalCount } = useSelector((state) => state.data);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    let companies = props.clientData;
    // eslint-disable-next-line no-lone-blocks
    {
      companies.length > 0 &&
        companies.forEach((ele) => {
          ele.inputState = {
            checkSelect: false,
            clientId: ele.clientID,
          };
        });
      setCompanyData(companies);
    }
  }, [props.clientData]);

  // console.log("NEW-COMPANY-DTAP:::", companyData);

  const setReAssignToUser = (state, cId) => {
    if (cId !== 0) {
      let filteredData = companyData.filter((data) => data.clientID == cId);
      filteredData.forEach((item) => {
        item.inputState.checkSelect = state;
      });
      // setSelectAllCheck(false);
    } else {
      companyData.forEach((data) => {
        data.inputState.checkSelect = state;
        data.inputState.clientId = data.clientId;
      });
      // setSelectAllCheck(true);
    }
    setCompanyData([...companyData]);
  };

  useEffect(() => {
    let selectedData = companyData.filter(
      (data) => data.inputState.checkSelect == true
    );

    let checkedAll = companyData.every(
      (data) => data.inputState.checkSelect == true
    );
    setSelectAllCheck(checkedAll);
    props.setReAssignData(selectedData);
  }, [companyData]);

  // console.log("Client IDS::", companyData);

  const selectAllStatus = (event) => {
    if (companyData.map((data) => data?.inputState?.checkSelect == event)) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }
  };


  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.clientData,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="client-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((header, i) => (
                  <TableCell
                    key={i}
                    align={header.align}
                    style={{ minWidth: `${header.width}` }}
                  >
                    {header.name == "COMPANY NAME" ? (
                      <>
                        {props.reAssignEnable && (
                          <Form.Check
                            inline
                            type="checkbox"
                            label="All"
                            value={0}
                            className="selectAll-checkbox"
                            name="reAssignClients"
                            id={"AllSelect"}
                            onChange={(e) => {
                              setReAssignToUser(e.target.checked, 0);
                              selectAllStatus(e.target.checked);
                            }}
                            checked={selectAllCheck}
                          />
                        )}
                        {header.name}
                      </>
                    ) : (
                      header.name
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <>
                {props.tableHeader.map((header, i) => (
                  <SkeletonClients key={i} />
                ))}
              </>
            ) : (
              <>
                {companyData && companyData.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        --- NO DATA FOUND ---
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {companyData.map((data, i) => (
                      <TableRow
                        align="center"
                        key={i}
                        className="client-list-row-data"
                      >
                        <TableCell>
                          <div className="users-list-name">
                            {props.reAssignEnable && (
                              <div>
                                <Form.Check
                                  inline
                                  value={data?.clientID}
                                  className="travelBy-radio-btn"
                                  name="reAssign-clients"
                                  type="checkbox"
                                  id={data?.clientID}
                                  onChange={(e) =>
                                    setReAssignToUser(
                                      e.target.checked,
                                      data?.clientID
                                    )
                                  }
                                  checked={data.inputState.checkSelect}
                                />
                              </div>
                            )}
                            <div>
                              {data.logo ? (
                                <img
                                  className="table-data-users-imgages company-logo"
                                  src={`${baseURL}/${data.logo}`}
                                  alt="client-profile"
                                />
                              ) : (
                                <div
                                  className="table-data-users-imgages default-image"
                                  style={{ backgroundColor: getRandomColor(i) }}
                                >
                                  {data?.clientName.charAt(0).toUpperCase()}
                                </div>
                              )}
                            </div>
                            <div>
                              <div
                                className="table-row-head-Text"
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  props.handleView(data?.clientID)
                                }
                              >
                                <strong> {data?.clientName}</strong>
                              </div>

                              <div className="table-row-sub-text">
                                <i className="fa fa-thumb-tack" /> Re-Tag :{" "}
                                <span>{data?.retagCount}</span>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{data?.contactPersonName}</TableCell>
                        <TableCell>
                          <span className="color-green">{data?.mobile}</span>{" "}
                          <br />
                          <span>{data?.email}</span>
                        </TableCell>
                        <TableCell>
                          <span>
                            <strong>
                              {data?.clientCategoryName
                                ? data?.clientCategoryName
                                : "-"}{" "}
                            </strong>
                          </span>
                          <br />
                          {data?.clientStageName ? data?.clientStageName : "-"}
                        </TableCell>
                        <TableCell>
                          {data?.clientLoc?.location}
                          &nbsp;
                          <span className="client-tagged">{data?.flag}</span>
                        </TableCell>
                        <TableCell>
                          {data?.userFullName}
                          <Link
                            to="#"
                            onClick={() => {
                              props.setShowMappedTo(!props.showMappedTo);
                              props.setClientId(data?.clientID);
                            }}
                            className="view-mapped-user"
                          >
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 200, hide: 250 }}
                              overlay={
                                <Tooltip id={`tooltip-title`}>
                                  Mapped To
                                </Tooltip>
                              }
                            >
                              <span>
                                <i className="fa fa-users user-icon" />
                              </span>
                            </OverlayTrigger>
                          </Link>
                        </TableCell>
                        <TableCell>
                          {/* {data?.userFullName} */}
                          <div
                            onClick={() => {
                              // props.setShowReporting(!props.showReportingTo);
                              // props.setClientId(data?.clientID);
                              props.handleReportData(data?.clientID);
                            }}
                            className="view-mapped-user"
                          >
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 200, hide: 250 }}
                              overlay={
                                <Tooltip id={`tooltip-title`}>
                                  Reporting To
                                </Tooltip>
                              }
                            >
                              <span>
                                <i className="fa fa-users user-icon" />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </TableCell>
                        <TableCell>
                          {/* <ShowCustomeFieldData clientID={data?.clientID} /> */}
                          <div
                            onClick={() => {
                              // props.setShowReporting(!props.showReportingTo);
                              // props.setClientId(data?.clientID);
                              props.handleCustomeFiledsData(data?.clientID);
                            }}
                            className="view-mapped-user"
                          >
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 200, hide: 250 }}
                              overlay={
                                <Tooltip id={`tooltip-title`}>
                                  Custom Fields
                                </Tooltip>
                              }
                            >
                              <span>
                                <i className="fa fa-users user-icon" />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {data?.enabledStatus === 1 ? (
                            <span className="status-badge complete-status">
                              Active
                            </span>
                          ) : (
                            <span className="status-badge cancel-status">
                              InActive
                            </span>
                          )}
                        </TableCell>
                        <TableCell className="action-class">
                          <button
                            onClick={() =>
                              props.editClientDetails(data?.clientID)
                            }
                            className="btn client-edit-link"
                          >
                            <i className="fa fa-edit" /> Edit
                          </button>
                          <button
                            onClick={() => {
                              props.setHistoryclientmodel(
                                !props.historyclientmodel
                              );
                              props.setClientId(data?.clientID);
                            }}
                            className="btn client-history-link"
                          >
                            <i className="fa fa-history" /> History
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {props.loading && (
                      <OnScrollLoading tableHeader={props.tableHeader} />
                    )}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default ClientTable;

