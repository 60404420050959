import Select from "react-select";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import { postCreatedCustomeformData } from "../../../redux/action";
import * as HeaderAuthentication from "../../../Components/HeaderAuthentication";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { baseURL } from "../../BaseUrl";
import productimg1 from "../../../images/DefaultImages/emptyProduct.svg";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const DisplayForm = ({ formItem, fieldId, parsedData, setParsedData, singleMachineData, setSingleMachineData }) => {

    const location = useLocation();
    let navigate = useNavigate();
    const rowId = location.state?.rowId;
    const dispatch = useDispatch();

    const multipleFileInput = useRef(null);
    const uploadMultiple = (e) => {
        e.preventDefault();
        multipleFileInput.current.click();
    };

    const [selectLength, setSelectLength] = useState(false);
    const [localGallery, setLocalGallery] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);

    const handleChangeMultipleImg = (event) => {
        if (event) {
            let images = [...localGallery];
            const fileUploaded = Array.from(event.target.files);
            if (fileUploaded.length + galleryImages.length + images.length > 6) {
                setSelectLength(true);
            } else {
                setSelectLength(false);
                if (images.length === 0) {
                    fileUploaded.forEach((file) => {
                        images.push(file);
                    });
                } else {
                    let filterImages = fileUploaded.filter((file) =>
                        images.every((files) => !files.name.includes(file.name))
                    );
                    if (filterImages.length !== 0) {
                        filterImages.forEach((file) => {
                            images.push(file);
                        });
                    }
                }
            }
            setLocalGallery(images);
            updateParsedDataWithImages(images);
        }
    };

    const updateParsedDataWithImages = (images) => {
        let addFields = parsedData.fields;
        const itemIndex = addFields.findIndex((item) => item.id === fieldId);

        if (itemIndex !== -1) {
            const updatedItem = JSON.parse(JSON.stringify(addFields[itemIndex]));
            const rowIndex = updatedItem.rowData.findIndex((item) => item.id === formItem.id);

            if (rowIndex !== -1) {
                let updatedRow = JSON.parse(JSON.stringify(addFields[itemIndex].rowData[rowIndex]));

                // Prepare the FormData object
                const formData = new FormData();
                images.forEach((imgfile) => {
                    formData.append("Files", imgfile);
                });
                formData.append("Directory", "CustomForm");

                // Prepare request options
                let requestOption = {
                    method: "POST",
                    mode: "cors",
                    headers: HeaderAuthentication.salesbeeImgAuth,
                    body: formData,
                };

                // Fetch the API directly
                fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, requestOption)
                    .then((response) => response.json())
                    .then((responseData) => {
                        if (responseData.success) {
                            const galleryImages = responseData.result;
                            const stringLinks = galleryImages.map((image) => image);
                            // Merge new images with existing ones
                            updatedRow.value = [...formItem.value, ...stringLinks];

                            // Update fields and parsedData
                            addFields[itemIndex].rowData[rowIndex] = updatedRow;
                            parsedData.fields = addFields;
                            setParsedData(parsedData);
                        } else {
                            console.log("Error:", responseData.message);
                        }
                    })
                    .catch((error) => {
                        console.error("Image upload failed:", error);
                    });
            }
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFiles(files);
    };
    const handleFiles = (files) => {
        let images = [...localGallery];
        const fileUploaded = Array.from(files);
        if (fileUploaded.length + galleryImages.length + images.length > 6) {
            setSelectLength(true);
        } else {
            setSelectLength(false);
            if (images.length === 0) {
                fileUploaded.forEach((file) => {
                    images.push(file);
                });
            } else {
                let filterImages = fileUploaded.filter((file) =>
                    images.every((files) => !files.name.includes(file.name))
                );
                if (filterImages.length !== 0) {
                    filterImages.forEach((file) => {
                        images.push(file);
                    });
                }
            }
        }
        setLocalGallery(images);
        updateParsedDataWithImages(images);
    };

    const deleteImage = (name) => {
        setSelectLength(false);
        setLocalGallery(localGallery.filter((data) => data.name !== name.name));
    };

    // const deleteGalImage = (name) => {
    //     setSelectLength(false);
    //     setGalleryImages(galleryImages.filter((data) => data !== name));
    // };

    const deleteGalImage = (name) => {
        // Filter out the image name from galleryImages
        const updatedGalleryImages = galleryImages.filter((data) => data !== name);
        setGalleryImages(updatedGalleryImages);

        // Locate the form field containing the image data
        const updatedFields = parsedData.fields.map((field) => {
            if (field.id === fieldId) {
                // Update the value of the form field to remove the deleted image
                const updatedRowData = field.rowData.map((rowData) => {
                    if (rowData.id === formItem.id) {
                        // Remove the image from the value array
                        const updatedValue = rowData.value.filter((image) => image !== name);
                        // Return the updated row data
                        return { ...rowData, value: updatedValue };
                    }
                    return rowData;
                });
                // Return the updated field with modified row data
                return { ...field, rowData: updatedRowData };
            }
            return field;
        });

        // Update the parsedData with the modified field data
        setParsedData({ ...parsedData, fields: updatedFields });
    };

    const handleInputChange = (e, label) => {
        let list = [...singleMachineData]
        list.forEach(item => {
            if (item.iForm_FieldName === label) {

                item.iForm_FieldValue = e.target.value;
            }
        })
        setSingleMachineData(list)
    }


    const handleSelectChange = (selectedOption, label) => {
        let list = [...singleMachineData]
        list.forEach(item => {
            if (item.iForm_FieldName === label) {
                item.iForm_FieldValue = selectedOption.value;
            }
        })
        setSingleMachineData(list)
    }

    const handleDateChange = (date, label) => {
        let list = [...singleMachineData]
        list.forEach(item => {
            if (item.iForm_FieldName === label) {
                item.iForm_FieldValue = date;
            }
        })
        setSingleMachineData(list)
    }

    const postCustomValues = (e) => {
        e.preventDefault();
        let submitData = [];

        const currentTimeInMilliseconds = new Date().getTime();

        if (!rowId) {
            singleMachineData.forEach(field => {
                field.iForm_FormName = currentTimeInMilliseconds.toString()
            })
        }


        if (rowId) {
            submitData = singleMachineData
        } else {
            submitData = singleMachineData
        }

        const lastIndex = submitData.length - 1;
        submitData.forEach((fields, index) => {
            let lastData = false
            if (lastIndex === index) {
                lastData = true;
            }

            let postCustomeValues = {
                method: "POST",
                mode: 'cors',
                headers: HeaderAuthentication.postTravelizeAuth,
                body: JSON.stringify(fields)
            }

            dispatch(postCreatedCustomeformData(postCustomeValues, rowId, goToBackPage, lastData))

        })
    }

    const goToBackPage = () => {
        navigate(-1);
    };

    const renderInput = () => {
        let valueToDisplay = formItem || {};

        if (singleMachineData.length !== 0) {
            const matchedItem = singleMachineData.find(item => item.iForm_FieldName === formItem.Label);
            if (matchedItem) {
                valueToDisplay = matchedItem;
            }
        }

        return (
            <Form.Group
                key={formItem.id}
                className={`control-form-group ${formItem.alignment}`}
            >
                <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
                <div className="fullname-form-control">
                    <Form.Control
                        type={formItem.type}
                        placeholder={formItem.placeholder || ""}
                        value={valueToDisplay.iForm_FieldValue}
                        onChange={(e) => handleInputChange(e, valueToDisplay.iForm_FieldName)}
                    />
                </div>
            </Form.Group>
        );
    };

    const renderInputGroup = () => {
        let valueToDisplay = formItem || {};

        if (singleMachineData.length !== 0) {
            const matchedItem = singleMachineData.find(item => item.iForm_FieldName === formItem.Label);
            if (matchedItem) {
                valueToDisplay = matchedItem;
            }
        }

        return (
            <Form.Group
                key={formItem.id}
                className={`control-form-group ${formItem.alignment}`}
            >
                <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
                <div className="fullname-form-control">
                    {formItem.subLabels.map((subLabels, i) => (
                        <>
                            {subLabels.active && (
                                <div key={i} className="sublabel-container">
                                    <Form.Control
                                        type={formItem.type}
                                        placeholder={subLabels.placeholder || ""}
                                        // readOnly
                                        value={valueToDisplay.iForm_FieldValue}
                                        onChange={(e) => handleInputChange(e, valueToDisplay.iForm_FieldName)}
                                    />
                                    <div className="form-sublabel">{subLabels.label}</div>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </Form.Group>
        );
    };

    const renderSplitInput = () => {
        let valueToDisplay = formItem || {};

        if (singleMachineData.length !== 0) {
            const matchedItem = singleMachineData.find(item => item.iForm_FieldName === formItem.Label);
            if (matchedItem) {
                valueToDisplay = matchedItem;
            }
        }

        return (
            <Form.Group
                key={formItem.id}
                className={`control-form-group ${formItem.alignment}`}
            >
                <div className="fullname-form-control">
                    {formItem.subLabels.map((subLabels, i) => (
                        <div key={i} className="sublabel-container">
                            <div className={`control-form-group ${formItem.alignment}`}>
                                <Form.Label className="control-form-label">{subLabels.label}</Form.Label>
                                <Form.Control
                                    type={subLabels.type}
                                    placeholder={subLabels.placeholder || ""}
                                    // readOnly
                                    value={valueToDisplay.iForm_FieldValue}
                                    onChange={(e) => handleInputChange(e, valueToDisplay.iForm_FieldName)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Form.Group>
        );
    };

    const renderComposite = () => {
        let valueToDisplay = formItem || {};

        if (singleMachineData.length !== 0) {
            const matchedItem = singleMachineData.find(item => item.iForm_FieldName === formItem.Label);
            if (matchedItem) {
                valueToDisplay = matchedItem;
            }
        }

        return (
            <Form.Group key={formItem.id} className={`control-form-group ${formItem.alignment}`}>
                <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
                <div className="fullname-form-control">
                    {formItem.components.map((component, i) => (
                        <React.Fragment key={i}>
                            {component.type === "select" && component.active && (
                                <div className="country-code">
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={component.options}
                                        placeholder={component.placeholder || ""}
                                        value={component.options.find(option => option.value === valueToDisplay.iForm_FieldValue)}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, valueToDisplay.iForm_FieldName)}
                                    />
                                </div>
                            )}

                            {component.type === "tel" && (
                                <div className="sublabel-container">
                                    <Form.Control
                                        type="tel"
                                        placeholder={component.placeholder || ""}
                                        value={valueToDisplay.iForm_FieldValue}
                                        onChange={(e) => handleInputChange(e, valueToDisplay.iForm_FieldName)}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Form.Group>
        );
    };

    const renderDate = () => {
        // let foundDate = null;
        // const foundDate = selectedDate[formItem.id];
        let valueToDisplay = formItem || {};

        if (singleMachineData.length !== 0) {
            const matchedItem = singleMachineData.find(item => item.iForm_FieldName === formItem.Label);
            if (matchedItem) {
                valueToDisplay = matchedItem;
            }
        }

        return (
            <Form.Group
                key={formItem.id}
                className={`control-form-group ${formItem.alignment}`}
            >
                <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
                <div className="fullname-form-control">
                    <div className="meeting-date-picker-with-icon">
                        <DatePicker
                            // selected={valueToDisplay?.iForm_FieldValue}
                            value={valueToDisplay?.iForm_FieldValue && moment(new Date(valueToDisplay?.iForm_FieldValue)).format(formItem.config.dateFormat)}
                            className="form-control select-add-meeting-model meeting-date model-input"
                            dateFormat={formItem.config.dateFormat}
                            onChange={(date) => handleDateChange(date, valueToDisplay.iForm_FieldName)}
                        />
                        {!valueToDisplay?.iForm_FieldValue && <span className="datepicker-placeholder">
                            {formItem.config.dateFormat}
                        </span>}
                        <i className="fa fa-calendar-o calander-icon" />
                    </div>
                </div>
            </Form.Group>
        );
    };



    const renderTime = () => (
        <Form.Group
            key={formItem.id}
            className={`control-form-group ${formItem.alignment}`}
        >
            <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
            <div className="fullname-form-control">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker
                        // label="Time"
                        value={formItem.config.default ? formItem.config.default : null}
                        className="form-control select-add-meeting-model"
                        renderInput={(params) => <TextField {...params}
                            placeholder={formItem.config.default === "" ? "Select Time" : ""} />}
                        // minTime={
                        //   isCurrentDate
                        //     ? dayjs(`2023-01-30T${isTime}`)
                        //     : dayjs("2023-01-30T09:00")
                        // }
                        readOnly={formItem.validation.readOnly}
                        InputProps={{ readOnly: true }}
                        inputFormat={formItem.config.timeFormat}
                        // maxTime={dayjs("2023-01-31T22:00")}
                        minutesStep={15}
                        onChange={(e) => handleInputChange(e)}
                    />
                </LocalizationProvider>
            </div>
        </Form.Group>
    );

    const rendertTextarea = () => {
        let valueToDisplay = formItem || {};

        if (singleMachineData.length !== 0) {
            const matchedItem = singleMachineData.find(item => item.iForm_FieldName === formItem.Label);
            if (matchedItem) {
                valueToDisplay = matchedItem;
            }
        }

        return (
            <Form.Group
                key={formItem.id}
                className={`control-form-group ${formItem.alignment}`}
            >
                <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
                <div className="fullname-form-control">
                    <textarea
                        rows={formItem.attributes.rows}
                        cols={formItem.attributes.cols}
                        type={formItem.type}
                        className="form-control form-textarea add-product-input"
                        id="descriptionArea"
                        placeholder={formItem.placeholder || ""}
                        // readOnly
                        value={valueToDisplay.iForm_FieldValue}
                        onChange={(e) => handleInputChange(e, valueToDisplay.iForm_FieldName)}
                    />
                </div>
            </Form.Group>
        );
    };

    const rendertFile = () => (
        <Form.Group
            key={formItem.id}
            className={`control-form-group ${formItem.alignment}`}
        >
            <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
            <div className="fullname-form-control d-block">
                <div
                    className="d-flex justify-content-center "
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                >
                    <>
                        {formItem && formItem.value && formItem.value.length !== 0 && (
                            <>
                                {formItem.value.map((data, i) => (
                                    <div className="product-image-box" key={i}>
                                        <img
                                            className="product-images"
                                            src={`${baseURL}/${data}`}
                                            alt="Spare Images"
                                        />
                                        <div
                                            className="delete-image"
                                            onClick={() => deleteGalImage(data)}
                                        >
                                            <i
                                                className="fa fa-times-circle delete-icon"
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                    <>
                        {localGallery && Array.isArray(localGallery) &&
                            localGallery.map((data, i) => (
                                <div className="product-image-box" key={i}>
                                    <img
                                        className="product-images"
                                        src={
                                            localGallery
                                                ? URL.createObjectURL(data)
                                                : productimg1
                                        }
                                        alt="Spare Images"
                                    />
                                    <div
                                        className="delete-image"
                                        onClick={() => deleteImage(data)}
                                    >
                                        <i
                                            className="fa fa-times-circle delete-icon"
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            ))}
                    </>

                </div>
                {((formItem && formItem.value && formItem.value.length !== 0) ||
                    localGallery.length !== 0) &&
                    (formItem.value.length + localGallery.length < 6) && (
                        <div className="drop-btn">
                            <SbCancelBtn
                                btnName="Browse"
                                onClickEffect={uploadMultiple}
                            />
                        </div>
                    )}

                {(!formItem || !formItem.value || formItem.value.length === 0) &&
                    localGallery.length === 0 && (
                        <div className="drop-area"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleDrop}>

                            <img src="../../../images/DefaultImages/UploadImages.svg" alt="" />
                            <p>Drag and drop images here</p>
                            <p>OR</p>
                            <SbCancelBtn
                                btnName="Browse"
                                onClickEffect={uploadMultiple}
                            />

                        </div>)}

                <input
                    id="getFile"
                    type="file"
                    multiple
                    name="filetobase64"
                    accept="image/*"
                    className="btn-primary upload"
                    style={{ display: "none" }}
                    ref={multipleFileInput}
                    onChange={handleChangeMultipleImg}
                />
            </div>
        </Form.Group>
    );

    const renderCheckboxOrRadio = (type) => {
        let valueToDisplay = formItem || {};

        if (singleMachineData.length !== 0) {
            const matchedItem = singleMachineData.find(item => item.iForm_FieldName === formItem.Label);
            if (matchedItem) {
                valueToDisplay = matchedItem;
            }
        }

        return (
            <Form.Group key={formItem.id} className={`control-form-group ${formItem.alignment}`}>
                <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
                <div className="fullname-form-control">
                    <div>
                        {formItem.options.map((opt, i) => (
                            <Form.Check
                                inline
                                key={i}
                                label={opt.label}
                                name={formItem.Label}
                                checked={opt.checked}
                                type={type}
                                id={`${type}-${formItem.id}-${i + 1}`}
                                value={valueToDisplay.iForm_FieldValue}
                                onChange={(e) => handleInputChange(e, valueToDisplay.iForm_FieldName)}
                            />
                        ))}
                    </div>
                </div>
            </Form.Group>
        );
    };

    const renderSelect = () => {
        let valueToDisplay = formItem || {};

        if (singleMachineData.length !== 0) {
            const matchedItem = singleMachineData.find(item => item.iForm_FieldName === formItem.Label);
            if (matchedItem) {
                valueToDisplay = matchedItem;
            }
        }

        return (
            <Form.Group key={formItem.id} className={`control-form-group ${formItem.alignment}`}>
                <Form.Label className="control-form-label">{formItem.Label}</Form.Label>
                <div className="fullname-form-control">
                    <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={formItem.options}
                        placeholder={formItem.placeholder || ""}
                        value={formItem.options.find(option => option.value === valueToDisplay.iForm_FieldValue)}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, valueToDisplay.iForm_FieldName)}
                    />
                </div>
            </Form.Group>
        );
    };


    switch (formItem.field_type) {
        case "input":
            return renderInput();
        case "input_group":
            return renderInputGroup();
        case "input_split":
            return renderSplitInput();
        case "composite":
            return renderComposite();
        case "date_picker":
            return renderDate();
        case "time_picker":
            return renderTime();
        case "textarea":
            return rendertTextarea();
        case "file":
            return rendertFile();
        case "checkbox_group":
            return renderCheckboxOrRadio("checkbox");
        case "radio_group":
            return renderCheckboxOrRadio("radio");
        case "select":
            return renderSelect();
        case "header":
            return (
                <>
                    <Form.Control
                        type="text"
                        value={formItem.Label || ""}
                        style={{ textAlign: `${formItem.alignment}` }}
                        className={`custom-form-control font-${formItem.fontSize}`}
                        readOnly
                    />
                    <p style={{ textAlign: `${formItem.alignment}` }}>
                        {formItem.subheading}
                    </p>
                </>
            );
        case "button":
            return (
                <Form.Group key={formItem.id}
                    className={`control-btn ${formItem.alignment}`}>
                    <Button type={formItem.type} className="custom-form-btn" onClick={(e) => { postCustomValues(e, formItem.id) }}>{formItem.Label}</Button>
                </Form.Group>
            );
        case "devider":
            return (
                <Form.Group key={formItem.id}
                    className={`control-divider ${formItem.alignment}`}>
                    <hr />
                </Form.Group>
            );
        default:
            return null;
    }
};

export default DisplayForm;
