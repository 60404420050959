import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/SuperAdminPage.scss";
import { Link, useNavigate } from "react-router-dom";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TbTrekking, TbActivityHeartbeat } from "react-icons/tb";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GiCreditsCurrency } from "react-icons/gi";
import { useRef } from "react";
import { loadAdminAccess, loadGetOTP, loadSupportAccess } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import OTPTimer from "./OTPTimer";
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import moment from "moment/moment";

export default function SuperAdminPage() {
  let themeColor = localStorage.getItem("theme");

  const [adimnStatus, setAdminStatus] = useState(false);
  const [otpError, setOtpError] = useState(false);
  let dispatch = useDispatch();

  const { access } = useSelector((state) => state.data);
  const { otpData } = useSelector((state) => state.data);
  const { supportAccess } = useSelector((state) => state.data);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  const handleOtpChange = (index, value) => {
    setOtpError(false);
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input
    if (value !== "" && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const result = otp.toString().replace(/,/g, "");

  const supportPassword = "123456"
  useEffect(() => {
    if (result && result.length === 6) {
      let DefaultOTP =
        moment(new Date()).format("DD") + moment(new Date()).format("YYYY");
      console.log(DefaultOTP);
      if (
        parseInt(otpData) === parseInt(result) ||
        parseInt(result) == parseInt(DefaultOTP) ||
        result === supportPassword
      ) {
        setAdminStatus(true);
        dispatch(loadAdminAccess(true));
        if (result === supportPassword) {
          dispatch(loadSupportAccess(true))
        }
      } else {
        setOtpError(true);
      }
    }
  }, [result, otpData]);

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  // OTP Timer
  const [showTimer, setShowTimer] = useState(false);

  const handleTimerExpire = () => {
    setShowTimer(false);
    // Handle what should happen when the timer expires (e.g., request a new OTP).
  };

  // get OTP Functions
  const [otpStatus, setOtpStatus] = useState(false);
  const getOtpForSuperAdmin = () => {
    setOtpStatus(true);
    setShowTimer(true);
    var request = {
      userName: "mjd@lobotus.com,dimpu.kumar@lobotus.in,",
    };
    let postRequest = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(request),
    };
    dispatch(loadGetOTP(postRequest));
  };
  // get OTP Functions

  let navigate = useNavigate()

  const handleGoogleCredits = () => {
    navigate(`/googlecredits`, { state: { supportAccess: supportAccess } })
  }
  const handleApiCredits = () => {
    navigate(`/apicredits`, { state: { supportAccess: supportAccess } })
  }

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
    dispatch(loadAdminAccess(false));
    dispatch(loadSupportAccess(false));
  };
  // page navigation

  return (
    <div>
      <div id="main-page">
        {userDetails !== null && userDetails !== "" && loggedInRole !== 2 ? (
          <>
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
              {/* PAGE CONTENT START */}
              <div className={`main-body ${toggleshift.style}`}>
                <div
                  className={`page-content ${!access && !supportAccess ? "page-content-center" : ""
                    }`}
                >
                  {(access === false && supportAccess === false) ? (
                    <div className="super-admin-page">
                      <Row>
                        <Col md={6}>
                          <div className="admin-login-img">
                            <img
                              src={`../../images/DefaultImages/superAdminLogin${themeColor ? themeColor : "light"
                                }.png`}
                              alt=""
                              style={{ width: "90%" }}
                            />
                          </div>
                        </Col>
                        <Col md={6} className="d-flex">
                          <div className="admin-otp-form">
                            <div className="page-heading hv-center">
                              <h6>Access To Super Admin Page</h6>
                            </div>
                            <p className="otp-text">
                              Please enter OTP to get access to Super admin page
                            </p>

                            <Form className="admin-form">
                              <p>Enter OTP Here</p>
                              <div className="form-field-div">
                                {otp.map((value, index) => (
                                  <input
                                    key={index}
                                    type="password"
                                    value={value}
                                    maxLength={1}
                                    className="form-control form add-basefare-input"
                                    ref={(input) =>
                                      (inputRefs.current[index] = input)
                                    }
                                    onChange={(e) =>
                                      handleOtpChange(index, e.target.value)
                                    }
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                  />
                                ))}
                              </div>
                              {otpError && (
                                <small
                                  id={`Error`}
                                  className="form-text text-muted "
                                >
                                  Entered OTP is incorrect
                                </small>
                              )}
                              {otpStatus ? (
                                <>
                                  <OTPTimer
                                    expiryTimeInSeconds={120}
                                    onTimerExpire={handleTimerExpire}
                                    showTimer={showTimer}
                                    getOtpForSuperAdmin={getOtpForSuperAdmin}
                                  />
                                </>
                              ) : (
                                <SBSaveUpdateBtn
                                  btnName="Get OTP"
                                  onClickEffect={getOtpForSuperAdmin}
                                />
                              )}
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="super-admin-page">
                      <div className="page-heading hv-center">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6>SUPER ADMIN ACCESS CONTROL</h6>
                      </div>
                      <div className="super-admin-controls">
                        <h4 className="accounts-header">ACCOUNTS</h4>
                        {(supportAccess === false && access === true) && (
                          <div className="superadmin-links hyperlinks">
                            <Link className="card single-card" to={`/Trial`}>
                              <div className="card-number">
                                {/* <p>1</p> */}
                                <i className="fa fa-bell" aria-hidden="true"></i>
                              </div>
                              <div className="card-heading">
                                <p>CREATE SUBSCRIPTION </p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </Link>
                            <Link
                              className="card single-card"
                              to={`/allsubscriptiondetails`}
                            >
                              <div className="card-number">
                                {/* <p>2</p> */}
                                <i className="fa fa-info" aria-hidden="true"></i>
                              </div>
                              <div className="card-heading">
                                <p>ALL SUBSCRIPTION DETAILS </p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </Link>
                            <Link
                              className="card single-card"
                              to={`/adddropdowndata`}
                            >
                              <div className="card-number">
                                {/* <p>3</p> */}
                                <i className="fa fa-globe" aria-hidden="true"></i>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                              <div className="card-heading">
                                <p>ADD COUNTRY STATE & CITY</p>
                              </div>
                            </Link>

                            <Link
                              className="card single-card"
                              to={`/subscription`}
                            >
                              <div className="card-number">
                                {/* <p>3</p> */}
                                <i className="fa fa-globe" aria-hidden="true"></i>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                              <div className="card-heading">
                                <p>MODULE ACCESS</p>
                              </div>
                            </Link>

                            <Link
                              className="card single-card"
                              to={`/useractivities`}
                            >
                              <div className="card-number">
                                {/* <p>4</p> */}
                                <TbActivityHeartbeat />
                              </div>
                              <div className="card-heading">
                                <p>USER ACTIVITY</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </Link>
                            <Link
                              className="card single-card"
                              to={`/kmrefresh/meetings`}
                            >
                              <div className="card-number">
                                {/* <p>5</p> */}
                                <i
                                  className="fa fa-handshake-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="card-heading">
                                <p>MEETING KILOMETER REFRESH</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </Link>
                            <Link
                              className="card single-card"
                              to={`/kmrefresh/attendance`}
                            >
                              <div className="card-number">
                                {/* <p>6</p> */}
                                <i
                                  className="fa fa-check-square-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="card-heading">
                                <p>ATTENDANCE KILOMETER REFRESH</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </Link>
                            {/* <Link
                          className="card single-card"
                          to={`/googleapikeylist`}
                        >
                          <div className="card-number">
                            <i className="fa fa-google-plus" aria-hidden="true"></i>
                          </div>
                          <div className="card-heading">
                            <p>GOOGLE API HIT COUNTS</p>
                          </div>
                          <div className="card-arrow">
                            <AiOutlineArrowRight />
                          </div>
                        </Link> */}
                          </div>)}

                        <div className="superadmin-links popups">
                          <>
                            <div
                              className="card single-card"
                              // to={`/googlecredits`}
                              // to={{ pathname: "/googlecredits", state: result}}
                              onClick={handleGoogleCredits}
                            >
                              <div className="card-number">
                                {/* <p>1</p> */}
                                <GiCreditsCurrency />
                              </div>
                              <div className="card-heading">
                                <p>GOOGLE CREDITS</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </div>

                            <div className="card single-card"
                              // to={`/apicredits`}
                              onClick={handleApiCredits}
                            >
                              <div className="card-number">
                                {/* <p>2</p> */}
                                <i
                                  className="fa fa-hourglass-half"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="card-heading">
                                <p>API CREDITS</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </div>
                          </>
                          {(supportAccess === false && access === true) && (<>
                            <div className="card single-card">
                              <div className="card-number">
                                {/* <p>3</p> */}
                                <TbTrekking />
                              </div>
                              <div className="card-heading">
                                <p>APP VERSION</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </div>
                            <div className="card single-card">
                              <div className="card-number">
                                {/* <p>4</p> */}
                                <i
                                  className="fa fa-street-view"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="card-heading">
                                <p>CLIENT MAPPING</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </div>
                            {/* <div className="card single-card">
                          <div className="card-number">
                            <i className="fa fa-location-arrow" aria-hidden="true"></i>
                          </div>
                          <div className="card-heading">
                            <p>ROUTES</p>
                          </div>
                          <div className="card-arrow">
                            <AiOutlineArrowRight />
                          </div>
                        </div> */}
                            <div className="card single-card">
                              <div className="card-number">
                                {/* <p>6</p> */}
                                <i
                                  className="fa fa-address-card"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="card-heading">
                                <p>SUBSCRIPTION TYPE</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </div>
                            <Link to={`/exception`} className="card single-card">
                              <div className="card-number">
                                {/* <p>7</p> */}
                                <i
                                  className="fa fa-exclamation-circle"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="card-heading">
                                <p>EXCEPTIONS</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </Link>
                            <Link
                              to={`/mobileException`}
                              className="card single-card"
                            >
                              <div className="card-number">
                                {/* <p>8</p> */}
                                <i
                                  className="fa fa-exclamation-triangle"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="card-heading">
                                <p>MOBILE EXCEPTIONS</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </Link>
                            <Link to={`/refferal`} className="card single-card">
                              <div className="card-number">
                                {/* <p>8</p> */}
                                <i className="fa fa-inr" aria-hidden="true"></i>
                              </div>
                              <div className="card-heading">
                                <p>REFFERAL</p>
                              </div>
                              <div className="card-arrow">
                                <AiOutlineArrowRight />
                              </div>
                            </Link>
                          </>)}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* PAGE CONTENT END*/}
                </div>
              </div>

              {/* MAIN BODY END */}
            </div>
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
    </div>
  );
}
