import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";

function LeaveHierarchyTable(props) {
  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.designationData,
              props.totalData,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table stickyHeader aria-label="sticky table table-responsive">
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    className={`thead-cell-products`}
                    style={{ minWidth: `${name.width}` }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <TableBody>
                {props.tableHeader.map((name, i) => (
                  <TableRow align="center" key={i}>
                    {props.tableHeader.map((name, index) => (
                      <TableCell key={index}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <>
                {props.designationData && props.designationData.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/no-data-found.png"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>---- No Data Found ----</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {/* {props.designationData &&
                      props.designationData.map((data, i) => ( */}
                    <TableRow
                      align="center"
                      // key={i}
                      className="client-list-row-data"
                    >
                      <TableCell align="center">1</TableCell>
                      <TableCell>
                        <b>Yuvaraj B</b>
                      </TableCell>
                      <TableCell align="left">Manager-1</TableCell>
                      <TableCell>Manager-2</TableCell>
                      <TableCell>Manager-3</TableCell>
                      <TableCell>Manager-4</TableCell>
                      <TableCell>Manager-5</TableCell>
                      <TableCell>Admin</TableCell>
                      <TableCell align="center">
                        <span className="status-badge complete-status">
                          Active
                        </span>
                      </TableCell>
                      <TableCell className="action-class">
                        <button
                          // onClick={() => {
                          //   props.setEditDesignation(!props.editDesignation);
                          //   props.setEditDesignationID(data.designationID);
                          // }}
                          className="btn client-edit-link"
                        >
                          <span>
                            <i className="bi bi-pencil-fill" />
                            &nbsp;Edit
                          </span>
                        </button>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default LeaveHierarchyTable;
