// import {
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import React from "react";
// import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
// import moment from "moment";

// function AnnualContractTable(props) {
//   return (
//     <div className="sb-table-div sb-table-setion">
//       <Paper
//         sx={{ width: "100%", overflow: "hidden" }}
//         className="table-main-div"
//       >
//         <TableContainer
//           sx={{ maxHeight: 440 }}
//         // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
//         >
//           <Table
//             stickyHeader
//             aria-label="sticky table table-responsive"
//             className="basefare-table"
//           >
//             <TableHead className="custom-table-header">
//               <TableRow>
//                 {props.tableHeader.map((name, i) => (
//                   <TableCell
//                     key={i}
//                     align={name.align}
//                     className={`thead-cell-products`}
//                     style={{ minWidth: name.width }}
//                   >
//                     {name.name}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             {props.isLoading ? (
//               <TableBody>
//                 {props.tableHeader.map((name, i) => (
//                   <SkeletonTableData key={i} />
//                 ))}
//               </TableBody>
//             ) : (
//               <>
//                 {props.getAmcData && props.getAmcData.length === 0 ? (
//                   <TableBody>
//                     <TableRow align="center">
//                       <TableCell
//                         align="center"
//                         colSpan={props.tableHeader.length}
//                       >
//                         <div style={{ padding: "20px 20px" }}>
//                           <img
//                             src="../../../../images/DefaultImages/NodataFound.jpg"
//                             alt=""
//                             style={{ width: "10%" }}
//                           />
//                           <br />
//                           <span>No Data Found...!</span>
//                         </div>
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 ) : (
//                   <TableBody>
//                     {props.getAmcData &&
//                       props.getAmcData.map((data, i) => (
//                         <TableRow
//                           align="center"
//                           key={i}
//                           className="client-list-row-data"
//                         >
//                           <TableCell>
//                             Number : <b> {data.amcNumber} </b>
//                             <br />
//                             Type : <b> {data.amcType} </b>
//                             <br />
//                             Invoice Date : <b>
//                               {/* {moment(data.invoiceDate, "DD/MM/YYYY").format("DD MMM YYYY")} */}
//                               {data.invoiceDate}
//                             </b>
//                             <br />
//                             Start Date : <b>
//                               {/* {moment(data.amcStartDate, "DD/MM/YYYY").format("DD MMM YYYY")} */}
//                               {data.amcStartDate}
//                             </b>
//                             <br />
//                             End Date : <b>
//                               {/* {moment(data.amcEndDate, "DD/MM/YYYY").format("DD MMM YYYY")} */}
//                               {data.amcEndDate}
//                             </b>
//                             <br />
//                             No.of days : <b> {data.numberOfDays} </b>
//                           </TableCell>
//                           <TableCell>
//                             Product Name : <b>{data.productName}</b>
//                             <br />
//                             Code : <b>{data.productCode}</b>
//                             <br />
//                             Serial Number : <b>{data.companySerialNumber}</b>
//                             <br />
//                             Installed by : <b>{data.installedBy}</b>
//                             <br />
//                             Installation Date : <b>
//                               {/* {moment(data.installationDate, "DD/MM/YYYY").format("DD MMM YYYY")} */}
//                               {data.installationDate}
//                             </b>
//                             <br />
//                           </TableCell>
//                           <TableCell>
//                             <span className="client-meet ">
//                               <b className="color-green">{data?.companyName}</b>
//                             </span>
//                             <br />
//                             <i className="fa fa-user mtdtls-icon" />
//                             &nbsp;
//                             {data.companyEmail}
//                             <br />
//                             <i className="fa fa-mobile mtdtls-icon" /> &nbsp;
//                             {data.phoneNumber}
//                             <br />
//                             <i className="fa fa-thumb-tack mtdtls-icon" />{" "}
//                             &nbsp;
//                             {data.address}
//                             {/* <span className="client-meet-location">
//                               <p className="client-meet-lat">
//                                 <span className="color-yellow">
//                                   <i className="bi bi-geo-alt" />
//                                 </span>
//                                 {data.lat}
//                               </p>
//                               <p className="client-meet-lan">
//                                 <span className="color-yellow">
//                                   <i className="bi bi-geo-alt" />
//                                 </span>
//                                 {data.long}
//                               </p>
//                             </span> */}
//                           </TableCell>
//                           <TableCell>
//                             <button className="btn client-edit-link" onClick={() => { props.EditAmc(data.amcNumber) }}>
//                               <i class="bi bi-pencil-fill"></i>&nbsp; Edit
//                             </button>
//                             <button
//                               className="btn client-history-link"
//                               onClick={() => props.handleViewAttachments(data.amcNumber)}
//                             >
//                               <i className="fa fa-eye"></i>&nbsp; View
//                             </button>
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     {/* {props.loading &&
//                                             <OnScrollLoading />
//                                         } */}
//                   </TableBody>
//                 )}
//               </>
//             )}
//           </Table>
//         </TableContainer>
//       </Paper>
//     </div>
//   );
// }

// export default AnnualContractTable;



import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import moment from "moment";

function AnnualContractTable(props) {

  const [formFields, setFormfields] = useState([])
  useEffect(() => {
    let fieldRows = []
    if(props.parsedData.length !== 0){
      props.parsedData.forEach(item => {
        item.iForm_FieldValue.fields.forEach(fields => {
          fields.rowData.forEach(rowData => {
            fieldRows.push(rowData)
          })
          
        })
      })
    }
    setFormfields(fieldRows)
  }, [props.parsedData])
    
  console.log(formFields, "formFields")

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
        // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="basefare-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
              {formFields.length !== 0 && formFields.map((item, i) => (
                        <TableCell key={i}>
                          {item.Label}
                        </TableCell>
              ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <TableBody>
                {props.tableHeader.map((name, i) => (
                  <SkeletonTableData key={i} />
                ))}
              </TableBody>
            ) : (
              <>
                {formFields.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>No Data Found...!</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (


                  <TableBody>
                    <TableRow>
              {formFields.length !== 0 && formFields.map((item, i) => (
                        <TableCell key={i}>
                          {item.value}
                        </TableCell>
              ))}
              </TableRow>
                  </TableBody>


                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default AnnualContractTable;

