import uuid from "react-uuid";
import { format } from 'date-fns';
import moment from "moment";

export const formItems = [
  {
    id: uuid(),
    content: "Heading",
    Label: "Heading",
    subheading: "Sub heading",
    field_type: "header",
    name: "heading",
    imgname: "header",
    alignment: "center",
    fontSize: "default",
    validation: {
      required: true,
    }
  },
  // {
  //   id: uuid(),
  //   content: "Full Name",
  //   Label: "Name",
  //   name: "Full Name",
  //   subLabels: [
  //     {
  //       id: uuid(),
  //       label: "First Name",
  //       name: "fname",
  //       active: true,
  //       placeholder: "",
  //     },
  //     {
  //       id: uuid(),
  //       label: "Middle Name",
  //       name: "mname",
  //       active: false,
  //       placeholder: "",
  //     },
  //     {
  //       id: uuid(),
  //       label: "Last Name",
  //       name: "lname",
  //       active: true,
  //       placeholder: "",
  //     },
  //   ],
  //   field_type: "input_group",
  //   type: "text",
  //   imgname: "fullname",
  //   alignment: "top",
  //   fontSize: "default",
  //   validation: {
  //     readOnly: false,
  //     required: false,
  //   }
  // },
  {
    id: uuid(),
    content: "Single Text",
    Label: "Name",
    name: "Text",
    field_type: "input",
    type: "text",
    imgname: "singleText",
    alignment: "top",
    fontSize: "default",
    placeholder: '',
    validation: {
      readOnly: false,
      required: false,
    }
  },
  {
    id: uuid(),
    content: "Split Input",
    Label: "Name",
    name: "Full Name",
    subLabels: [
      {
        id: uuid(),
        type: "Text",
        label: "Text1",
        name: "text1",
        placeholder: "",
      },
      {
        id: uuid(),
        type: "Text",
        label: "Text2",
        name: "text2",
        placeholder: "",
      }
    ],
    field_type: "input_split",
    type: "text",
    imgname: "splitImage",
    alignment: "top",
    fontSize: "default",
    validation: {
      readOnly: false,
      required: false,
    }
  },
  {
    id: uuid(),
    content: "Email",
    Label: "Email",
    name: "email",
    field_type: "input",
    type: "email",
    imgname: "email",
    placeholder: "",
    alignment: "top",
    fontSize: "default",
    validation: {
      readOnly: false,
      required: false,
      pattern: "[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+"
    }
  },
  {
    id: uuid(),
    content: "Phone",
    Label: "Phone",
    name: "phone",
    field_type: "composite",
    components: [
      {
        id: uuid(),
        type: "select",
        name: "countryCode",
        label: "Country Code",
        options: [
          { id: uuid(), label: "+91", value: "India" },
        ],
        required: true,
        active: false,
        placeholder: "country"
      },
      {
        id: uuid(),
        type: "tel",
        name: "phone",
        label: "Phone",
        placeholder: "Enter your phone number",
        validation: {
          required: true,
          pattern: "^\\d{10}$" // Example pattern for a 10-digit phone number
        },
        active: true,
      }
    ],
    type: "tel",
    imgname: "phone",
    alignment: "top",
    fontSize: "default",
    validation: {
      readOnly: false,
      required: false,
      maxLength: 10,
      minLength: 10,
      pattern: "^[0-9]{10}$"
    }
  },
  {
    id: uuid(),
    content: "Date Picker",
    Label: "Select Date",
    name: "date",
    field_type: "date_picker",
    type: "date",
    imgname: "date",
    alignment: "top",
    fontSize: "default",
    validation: {
      readOnly: false,
      required: false,
    },
    config: {
      dateFormat: "yyyy/MM/dd",
      minDate: "",
      maxDate: "",
      defaultDate: ''
    }
  },
  {
    id: uuid(),
    content: "Time Picker",
    Label: "Select Time",
    name: "time",
    field_type: "time_picker",
    type: "date",
    imgname: "clock",
    alignment: "top",
    fontSize: "default",
    validation: {
      readOnly: false,
      required: false,
    },
    config: {
      timeFormat: "hh:mm A",
      interval: 5,
      defaultTime: "",
      startTime: "",
      endTime: ""
    }
  },
  {
    id: uuid(),
    content: "Paragraph",
    Label: "Paragraph",
    name: "paragraph",
    field_type: "textarea",
    placeholder: "",
    type: "textarea",
    imgname: "paragraph",
    alignment: "top",
    fontSize: "default",
    validation: {
      required: true,
      minLength: 20,
      maxLength: 500
    },
    attributes: {
      rows: 3,
      cols: 40,
      wrap: "soft"
    },
    helpText: "Please keep your message between 20 to 500 characters."
  },
  {
    id: uuid(),
    content: "File Upload",
    Label: "Upload Document",
    name: "documentUpload",
    field_type: "file",
    type: "file",
    imgname: "upload",
    alignment: "top",
    fontSize: "default",
    validation: {
      required: true,
      accept: '',
      limitFile: false,
      maxFile: '',
      maxSize: 1048576
    },
  },
  {
    id: uuid(),
    content: "Checkbox",
    Label: "Check Box",
    name: "chk",
    field_type: "checkbox_group",
    imgname: "checkbox",
    alignment: "top",
    fontSize: "default",
    placeholder: "",
    defaultvalue: "",
    sublabel: "",
    options: [
      {
        id: uuid(),
        label: "Option 1",
        name: "Option 1",
        value: "Option 1",
        checked: false
      },
      {
        id: uuid(),
        label: "Option 2",
        name: "Option 2",
        value: "Option 2",
        checked: false
      },
      {
        id: uuid(),
        label: "Option 3",
        name: "Option 3",
        value: "Option 3",
        checked: false
      }
    ],
    validation: {
      readOnly: false,
      required: false,
    }
  },
  {
    id: uuid(),
    content: "Radio Button",
    Label: "Radio Button",
    name: "rdo",
    field_type: "radio_group",
    imgname: "radio",
    alignment: "top",
    fontSize: "default",
    placeholder: "",
    defaultvalue: "",
    sublabel: "",
    options: [
      {
        id: uuid(),
        label: "Option 1",
        name: "Option 1",
        value: "Option 1",
        checked: false
      },
      {
        id: uuid(),
        label: "Option 2",
        name: "Option 2",
        value: "Option 2",
        checked: false
      },
      {
        id: uuid(),
        label: "Option 3",
        name: "Option 3",
        value: "Option 3",
        checked: false
      }
    ],
    validation: {
      readOnly: false,
      required: false,
    }
  },
  {
    id: uuid(),
    content: "Dropdown Select",
    Label: "Select",
    name: "select",
    field_type: "select",
    imgname: "select",
    alignment: "top",
    fontSize: "default",
    placeholder: "",
    defaultvalue: "",
    sublabel: "",
    options: [
      {
        id: uuid(),
        label: "Option 1",
        name: "Option 1",
        value: "Option 1",
        checked: false
      },
      {
        id: uuid(),
        label: "Option 2",
        name: "Option 2",
        value: "Option 2",
        checked: false
      },
      {
        id: uuid(),
        label: "Option 3",
        name: "Option 3",
        value: "Option 3",
        checked: false
      }
    ],
    validation: {
      readOnly: false,
      required: false,
    }
  },
  {
    id: uuid(),
    content: "Button",
    Label: "Submit",
    name: "btn",
    field_type: "button",
    type: "submit",
    imgname: "submit",
    alignment: "top",
    fontSize: "default",
    backGround: '',
    validation: {
      readOnly: false,
      required: false,
    }
  },
  {
    id: uuid(),
    content: "divider",
    Label: "devider",
    name: "devider",
    field_type: "devider",
    type: "devider",
    imgname: "divider",
    alignment: "top",
    fontSize: "default",
    backGround: '',
  },
];
