import '../../styles/AddUserDetails.scss'
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import '../../styles/AddUserModel.scss'
import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import SalesBee from "../../images/MatricLogo.svg";
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import SbCancelBtn from '../SbButtons/SbCancelBtn';
import { useDispatch, useSelector } from 'react-redux';
import { addEscalationData, loadDesignationDrop, loadRolesDropDown, loadUserDropdown } from '../../redux/action';
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";

const triggers = [
    // { value: "No AMC,No Warranty", label: "No AMC,No Warranty" },
    { value: "Non Warranty/ASC", label: "Non Warranty/ASC" },
    { value: "Hours", label: "Hours" }
]

const levelOptions = [
    { value: "1", label: "Escalation Level-1" },
    { value: "2", label: "EscalationLevel-2" },
    { value: "3", label: "Escalation Level-3" },
    { value: "4", label: "Escalation Level-4" },
    { value: "5", label: "Escalation Level-5" },
]

const actionOptions = [
    { value: "Mail", label: "Mail" },
    // { value: "Accepted", label: "Accepted" },
    // { value: "On the way", label: "On the way" },
    // { value: "CheckIn", label: "CheckIn" },
    // { value: "Closed", label: "Closed" },
];

const AddEscalationMatrix = (props) => {

    const location = useLocation();
    const { esId } = location.state

    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
    console.log(resPassData, 'resPassData')

    let dispatch = useDispatch();
    const { userDropdown } = useSelector((state) => state.data);
    const { desigationdrop } = useSelector((state) => state.data);
    const { rolesDropdown } = useSelector((state) => state.data);

    const [moduleName, setModuleName] = useState({ value: "Service Request", label: "Service Request" });
    const [level, setLevel] = useState({ value: "", label: "" });
    const [action, setAction] = useState({ value: "", label: "" });
    const [role, setRole] = useState({ value: "", label: "" });
    const [designation, setDesignation] = useState({ value: "", label: "" });
    const [userName, setUserName] = useState({ value: "", label: "" });
    const [triggerWhen, setTriggerWhe] = useState({ value: "", label: "" });
    const [value, setValue] = useState("");
    const [branch, setBranch] = useState("");
    const [status, setStatus] = useState("1");

    const [activeKey, setActiveKey] = useState('');

    const [activeMenu, setActiveMenu] = useState(1);

    const toggleMachineDetails = () => {
        setActiveMenu(1);
    }

    useEffect(() => {
        dispatch(loadUserDropdown());
        dispatch(loadDesignationDrop());
        dispatch(loadRolesDropDown());
    }, []);

    const addEscalation = (e) => {
        e.preventDefault();
        const data = {
            "subscriptionID": resPassData.subscriptionId,
            // "userID": esId,
            "userID": resPassData.userId,
            "moduleName": "sevice",
            "role": role.label,
            "designation": designation.label,
            "userName": userName.label,
            "trigger": triggerWhen.label,
            "value": value,
            "active": status,
            "level": level.value,
            "assingnedTo": esId,
            "clientComplaints_AssingnedTo": esId,
            "action": action.label,
            "branch": branch,
            "status": status,
        }
        let postEscalation = {
            method: "POST",
            mode: 'cors',
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data)
        }
        dispatch(addEscalationData(postEscalation, closeModel))
    }

    const closeModel = () => {
        props.handleClose();
    };

    const handleSelectLevel = (data) => {
        setLevel(data);
        switch (data.label) {
            case "Escalation Level-1":
                setTriggerWhe({ value: "Hours", label: "Hours" })
                setValue("4");
                break;
            case "EscalationLevel-2":
                setTriggerWhe({ value: "Hours", label: "Hours" })
                setValue("24");
                break;
            case "Escalation Level-3":
                setTriggerWhe({ value: "Hours", label: "Hours" })
                setValue("36");
                break;
            case "Escalation Level-4":
                setTriggerWhe({ value: "Hours", label: "Hours" })
                setValue("48");
                break;
            case "Escalation Level-5":
                setTriggerWhe({ value: "Non Warranty/ASC", label: "Non Warranty/ASC" })
                setValue("Non Warranty/ASC");
                break;
            default:
                setValue("");
        }
    };


    return (
        <div
            show={props.show}
            onHide={props.handleClose}
        >
            <div className="add-user-details-container">
                <div className="add-user-details-first-div">
                    <div className='machines-logo-div'>
                        <div className="logotext">
                            <div className="logo profile-mini">
                                <Link className="travelize_logo" to="/dashboard">
                                    <img
                                        src={SalesBee}
                                        alt="product-title-img"
                                        className="salesbee-logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-details-menus" onClick={toggleMachineDetails}>
                        <div className={`add-user-details-menu-numbers ${activeMenu === 1 ? 'active' : 'inactive'}`} >
                            <p className="add-user-details-menu-number-one">
                                <span>01</span>
                            </p>
                            <div className="add-user-details-menu-name">Escalation Matrix</div>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-second-div">
                    <div className="add-user-details-second-div-header">
                        <div className="back-btn" onClick={closeModel} ><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <div>
                            <h4>Escalation Matrix Details</h4>
                            <p>Please enter escalation matrix infomation and proceed to the next step.</p>
                        </div>
                    </div>
                    <div style={{ position: " relative" }} className="amc-single-image">
                        <form>
                            {/* <div className="row"> */}
                            <div className="col-lg-10 col">
                                <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Module Name' className={`${moduleName.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Module Name"
                                                    isSearchable={true}
                                                    value={moduleName}
                                                    isDisabled
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Action' className={`${action.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Action"
                                                    isSearchable={true}
                                                    options={actionOptions}
                                                    value={action.value && action}
                                                    onChange={(data) => { setAction(data) }}
                                                    onInputChange={actionOptions.label}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                </div>

                                <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Level' className={`${level.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Level"
                                                    isSearchable={true}
                                                    options={levelOptions}
                                                    value={level.value && level}
                                                    // onChange={(data) => { setLevel(data) }}
                                                    onChange={handleSelectLevel}
                                                    onInputChange={levelOptions.label}
                                                />
                                            </FloatingLabel>                                       </div>
                                    </div>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Role' className={`${role.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Role"
                                                    isSearchable={true}
                                                    options={rolesDropdown}
                                                    value={role.value && role}
                                                    onChange={(data) => { setRole(data) }}
                                                    onInputChange={rolesDropdown.label}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>

                                </div>

                                <div className="row add-user-image-container-div">
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Designation' className={`${designation.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="Designation"
                                                    isSearchable={true}
                                                    options={desigationdrop}
                                                    value={designation.value && designation}
                                                    onChange={(data) => { setDesignation(data) }}
                                                    onInputChange={desigationdrop.label}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='User Name' className={`${userName.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    placeholder="User Name"
                                                    options={userDropdown}
                                                    value={userName.value && userName}
                                                    onChange={(data) => { setUserName(data) }}
                                                    onInputChange={userDropdown.label}
                                                    isSearchable={true}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Branch' className={`${branch !== "" ? "float-input-visible" : "float-hidden float-input"}`}>
                                                <input
                                                    type="text"
                                                    name="clientEmail"
                                                    className="form-control add-user-input"
                                                    placeholder="Branch"
                                                    value={branch}
                                                    onChange={(e) => { setBranch(e.target.value) }}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                </div>
                                <div className="row add-user-image-container-div">
                                    <div className='d-flex col-lg-6 col'>
                                        <div >
                                            <div className="mb-3">
                                                <FloatingLabel label='Trigger When' className={`${triggerWhen.value !== "" ? "float-visible-select" : "float-hidden float-select"}`}>
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={triggers}
                                                        placeholder="Trigger When"
                                                        value={triggerWhen.value && triggerWhen}
                                                        // onChange={(data) => { setTriggerWhe(data) }}
                                                        onInputChange={triggers.label}
                                                        isSearchable={true}
                                                        isDisabled
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                        <div className='c0l-lg-4 col'>
                                            <div className="mb-3">
                                                <FloatingLabel label='Value' className={`${value !== "" ? "float-input-visible" : "float-hidden float-input"}`}>
                                                    <input
                                                        type="text"
                                                        name="clientEmail"
                                                        className="form-control add-user-input"
                                                        placeholder="Value"
                                                        value={value}
                                                        // onChange={(e) => { setValue(e.target.value) }}
                                                        isDisabled
                                                    />
                                                </FloatingLabel>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel label='Branch' className={`${branch !== "" ? "float-input-visible" : "float-hidden float-input"}`}>
                                                <input
                                                    type="text"
                                                    name="clientEmail"
                                                    className="form-control add-user-input"
                                                    placeholder="Branch"
                                                    value={branch}
                                                    onChange={(e) => { setBranch(e.target.value) }}
                                                />
                                            </FloatingLabel>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="add-page-save-cancel">
                                    <SBSaveUpdateBtn btnName="Save" onClickEffect={addEscalation} />
                                    <SbCancelBtn
                                        btnName="Cancel"
                                    // onClickEffect={() => {
                                    //     props.handleClose();
                                    // }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div >

            </div >
        </div >
    );
}

export default AddEscalationMatrix;