import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Image, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import '../../styles/ProductsViewPage.scss';
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { loadPageBreadCome, loadSingleProduct } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../BaseUrl";
import { FormattedAmount } from "../FormattedAmount";
import Skeleton from "react-loading-skeleton";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function ProductsViewPage() {

  let navigate = useNavigate();
  let { prodId } = useParams();
  let dispatch = useDispatch();
  const { singleProduct } = useSelector(state => state.data);

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    let breadCome = { mainpage: "Products" }
    dispatch(loadPageBreadCome(breadCome))
    if (prodId) {
      dispatch(loadSingleProduct(prodId, setIsLoading))
    }
  }, [prodId])

  const [galleryImages, setGalleryImages] = useState([])
  useEffect(() => {
    if (Object.keys(singleProduct).length !== 0) {
      let spareImgs = [];
      if (singleProduct.galaryImage) {
        spareImgs.push(singleProduct.galaryImage.split(","));
        spareImgs.unshift(singleProduct.image);
      } else if (!singleProduct.galaryImage && singleProduct.image) {
        spareImgs.push(singleProduct.image);
      }
      console.log(spareImgs.flat(), "spareImgs.flat()")
      setGalleryImages(spareImgs.flat())
    }
  }, [singleProduct])

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (

    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>

          <div className="page-content">

            <div className="clients-table">
              <Row className='search-row'>
                <Col md={3} className='cat-col'>
                  <div className='page-header-text-div'>
                    <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                    <h6 className='page-header-text' >PRODUCT DETAILS</h6>
                  </div>
                </Col>
                <Col md={5} className='cat-col'>
                </Col>
              </Row>
            </div>

            <div className="product-details-model">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="product-detail-left-part">
                    <div className="product-big-img">
                      {isLoading ?
                        <div style={{ padding: "20px 0px" }}>
                          <Skeleton />
                        </div>
                        :
                        <Swiper
                          spaceBetween={10}
                          navigation={false}
                          thumbs={{ swiper: thumbsSwiper }}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper2"
                        >
                          {galleryImages.length != 0 && galleryImages.map((data, i) => (
                            <SwiperSlide>
                              <Image
                                src={`${baseURL}/${data}`}
                                alt="attachment-photo"
                                className="product-img"
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      }

                    </div>
                    {isLoading ?
                      <Skeleton />
                      :
                      <div className="carousel-sliding-part">
                        <Swiper
                          style={{
                            "--swiper-navigation-color": "#000000",
                            "--swiper-pagination-color": "#000000",
                            "--swiper-navigation-size": "13px",
                            "--swiper-navigation-sides-offset": "0px",
                          }}
                          onSwiper={setThumbsSwiper}
                          spaceBetween={5}
                          slidesPerView={4}
                          // slidesPerGroup={1}
                          loadSingleProduct
                          navigation={true}
                          freeMode={true}
                          watchSlidesProgress={true}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiper"
                        >
                          {galleryImages.length != 0 && galleryImages.map((data, i) => (
                            <SwiperSlide key={i} >
                              <div className="product-small-images-row">
                                <div className="product-small-image">
                                  <Image
                                    src={`${baseURL}/${data}`}
                                    alt="attachment-photo"
                                    className="product-img"
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>

                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-8 col-sm-12">
                  {isLoading ?
                    <div className="product-detail-right-part">
                      <div className='product-name'>
                        <Skeleton />
                      </div>
                      <div className='product-price'>
                        <Skeleton />
                      </div>
                      <div className='description-cont'>
                        <Skeleton />
                      </div>
                      <div className='description-cont'>
                        <Skeleton />
                      </div>
                      <div className='specifications'>
                        <Skeleton />
                        <div className='products-specifications'>
                          <Skeleton />
                        </div>
                        <div className='products-specifications'>
                          <Skeleton />
                        </div>
                        <div className='products-specifications'>
                          <Skeleton />
                        </div>
                        <div className='products-specifications'>
                          <Skeleton />
                        </div>
                        <div className='products-specifications'>
                          <Skeleton />
                        </div>
                        <div className='products-specifications'>
                          <Skeleton />
                        </div>
                        <div className='products-specifications'>
                          <Skeleton />
                        </div>
                        <div className='products-specifications'>
                          <Skeleton />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="product-detail-right-part">
                      <div className='product-name'>
                        <h1><span>{singleProduct?.productName}</span></h1>
                      </div>
                      <div className='product-price'>
                        <div className='product-discount-price'>₹ {FormattedAmount(singleProduct?.price)}</div>
                        <div className='product-mrp-price'>₹ {FormattedAmount(singleProduct?.mrp)}</div>
                      </div>
                      <div className='specifications'>
                        <div className="sub-header">Description </div>
                        <p className='description-about-product'>{singleProduct?.description} </p>
                      </div>
                      <div className='specifications'>
                        <div className="sub-header">Instruction </div>
                        <p className='description-about-product'>{singleProduct?.instruction} </p>
                      </div>
                      <div className='specifications'>
                        <div className="sub-header">Specifications</div>
                        {singleProduct?.productName &&
                          <div className='products-specifications'>
                            <span>Product Name </span>
                            <b>:</b>
                            <b>{singleProduct?.productName}</b>
                          </div>
                        }
                        {singleProduct?.productCode &&
                          <div className='products-specifications'>
                            <span>Product Code </span>
                            <b>:</b>
                            <b>{singleProduct?.productCode}</b>
                          </div>
                        }
                        {singleProduct?.category?.name &&
                          <div className='products-specifications'>
                            <span>Category </span>
                            <b>:</b>
                            <b>{singleProduct?.category?.name}</b>
                          </div>
                        }
                        {singleProduct?.subCategory?.name &&
                          <div className='products-specifications'>
                            <span>Sub-Category </span>
                            <b>:</b>
                            <b>{singleProduct?.subCategory?.name}</b>
                          </div>
                        }
                        {singleProduct?.brand &&
                          <div className='products-specifications'>
                            <span>Brand </span>
                            <b>:</b>
                            <b>{singleProduct?.brand}</b>
                          </div>
                        }
                        {singleProduct?.shape &&
                          <div className='products-specifications'>
                            <span>Shape </span>
                            <b>:</b>
                            <b>{singleProduct?.shape}</b>
                          </div>
                        }
                        {singleProduct?.color &&
                          <div className='products-specifications'>
                            <span>Color </span>
                            <b>:</b>
                            <b>{singleProduct?.color}</b>
                          </div>
                        }
                        {singleProduct?.size &&
                          <div className='products-specifications'>
                            <span>Size </span>
                            <b>:</b>
                            <b>{singleProduct?.size}</b>
                          </div>
                        }
                        {((singleProduct?.height * singleProduct?.width * singleProduct?.length) !== 0) &&
                          <div className='products-specifications'>
                            <span>Dimension(H*W*L) </span>
                            <b>:</b>
                            <b>{singleProduct?.height} * {singleProduct?.width} * {singleProduct?.length}</b>
                          </div>
                        }
                        {singleProduct?.weight &&
                          <div className='products-specifications'>
                            <span>Weight </span>
                            <b>:</b>
                            <b>{singleProduct?.weight}</b>
                          </div>
                        }
                        {singleProduct?.mrp &&
                          <div className='products-specifications'>
                            <span>MRP </span>
                            <b>:</b>
                            <b><i className="fa fa-inr" /> {singleProduct?.mrp}</b>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


