import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import * as HeaderData from "../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleClientCompalint, loadUserDropdown, updateComplaintStatus } from "../../redux/action";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";

export default function AssignToServiceRequest(props) {

  let dispatch = useDispatch();
  const { userDropdown } = useSelector(state => state.data);
  const { singleClientCompaint } = useSelector((state) => state.data);

  const [selectedUser, setSelectedUser] = useState({})

  const closeAssignModal = () => {
    props.setShowAssign(false);
    // props.setCompaintId("")
    props.fetchComplaintdata()
  };

  const [updateData, setUpdateData] = useState({
    clientComplaintsID: 0, subscriptionID: "", userID: "", userFullName: "",
    addedByID: "", addedByUserFullName: "", addedOn: "", status: 0,
    clientIDFk: 0, date: "", productIDFK: 0, clientEmail: "", clientPhone: 0,
    issue: "", description: "", issueImage: "", inWarranty: 0, address: "",
    priority: 0, assingnedTo: "", adttenedByID: "", adttenedOn: "", solution: "",
    solutionImage: "",
  });

  useEffect(() => {
    if (singleClientCompaint) {
      setUpdateData(singleClientCompaint);
    }
    dispatch(loadUserDropdown())
  }, [singleClientCompaint])

  useEffect(() => {
    if (singleClientCompaint) {
      userDropdown.map(drop => {
        if (drop.value === singleClientCompaint.assingnedTo) {
          setSelectedUser(drop)
        }
      })
    }
  }, [singleClientCompaint, userDropdown])


  const setUserToAssign = (data) => {
    setSelectedUser(data);
    setUpdateData({ ...updateData, assingnedTo: data.value })
  }

  const updateComplaints = (e) => {
    e.preventDefault();
    // let date = moment(new Date()).format("DD/MM/YYYY");
    // updateData.date = date;
    var postRequest = {
      method: "POST",
      mode: "cors",
      headers: HeaderData.postTravelizeAuth,
      body: JSON.stringify(updateData),
    };
    // console.log(updateData, "updateData")
    dispatch(updateComplaintStatus(postRequest, closeAssignModal, "assign"));
  };

  console.log(selectedUser, "updateData")

  return (
    <div>
      <div>
        <Modal
          show={props.showAssign}
          onHide={closeAssignModal}
          className="category-modal-div"
        >
          <Row className="modale-header-div">
            <Col md={6} xs={9} className="cat-col">
              <h6 className="page-header-text">Re-Assign</h6>
            </Col>
            <Col md={6} xs={3} className="cat-col" align="right">
              <button
                onClick={closeAssignModal}
                className="bi bi-x close-popup"
              ></button>
            </Col>
          </Row>
          <hr />
          <div className="cat-col">
            <Form>
              <div className="mb-3">
                {/* <Form.Label>Select User To Re-Assign</Form.Label> */}
                <Select
                  className="select-add-meeting-model"
                  classNamePrefix="model-select-salesbee"
                  options={userDropdown}
                  placeholder="Select User"
                  value={selectedUser.value ? selectedUser : userDropdown.value}
                  onInputChange={userDropdown.label}
                  onChange={(data) => setUserToAssign(data)}
                  isSearchable={true}
                // styles={colourStyles}
                />
              </div>
              <div className="sb-btn-section">
                <SBSaveUpdateBtn onClickEffect={updateComplaints} btnName="Re-Assign" />
                <SbCancelBtn btnName="Cancel" onClickEffect={closeAssignModal} />
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
}
