export const optionsRoleList = [
  { value: "", label: "Select Role" },
  { value: "Manager", label: "Manager" },
  { value: "User", label: "User" },
];

export const optionsRoleListModel = [
  { value: "", label: "Select Role" },
  { value: 2, label: "Manager" },
  { value: 3, label: "User" },
];

export const optionsLeaveStatusUpdate = [
  { value: "", label: "Select Status" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
];

export const optionsMapByListModel = [
  { value: 0, label: "Added By User" },
  { value: 1, label: "Map By Country" },
  { value: 2, label: "Map By Zone" },
  { value: 3, label: "Map By State" },
  { value: 4, label: "Map By City" },
  { value: 5, label: "Map By Subscription" },
];

export const optionsStatusList = [
  { value: 2, label: "Select Status" },
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const warrantyStatus = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
export const priorityStatus = [
  { value: 3, label: "High" },
  { value: 2, label: "Medium" },
  { value: 1, label: "Low" },
];

export const optionsPausePlayList = [
  { value: 2, label: "Select Status" },
  { value: 1, label: "Play" },
  { value: 0, label: "Pause" },
];

export const optionsRoutPlanStatus = [
  { value: "", label: "Select Status" },
  { value: "Planned", label: "Planned" },
  { value: "Pending", label: "Pending" },
  { value: "Completed", label: "Completed" },
];

export const priceRange = [
  { value: "1,500", label: "Below 500" },
  { value: "500,1000", label: "500 to 1000" },
  { value: "1000,10000", label: "1000 to 10000" },
  { value: "10000,50000", label: "10000 to 50000" },
  { value: "Other", label: "Other Range" },
];

export const optionsMeetStatusList = [
  { value: 2, label: "Select Status" },
  { value: 1, label: "Active" },
  { value: 0, label: "InActive" },
];

export const optionsLeaveTypes = [
  { value: 1, label: "Sick Leave" },
  { value: 2, label: "Casual Leave" },
  { value: 3, label: "Earned Leave" },
  { value: 4, label: "Rest" },
];

export const optionsLeaveStatusList = [
  { value: "Requested", label: "Requested" },
  { value: "Approved", label: "Approved" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Rejected", label: "Rejected" },
];

export const optionsMOTTypes = [
  { value: "Local", label: "Local MOT" },
  { value: "OS", label: "Outstation MOT" },
];

export const optionsTravelTypes = [
  { value: "Local", label: "Local" },
  { value: "OS", label: "Outstation" },
];

export const optionsReimburseTypes = [
  { value: 1, label: "Lodging" },
  { value: 2, label: "Food" },
  { value: 3, label: "Local Conveyance" },
  { value: 4, label: "Others" },
];

export const optionsMeetingStatusList = [
  { value: "", label: "Select Type" },
  { value: "Completed", label: "Completed" },
  { value: "Follow up", label: "Follow-Up" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "PCancelled", label: "Pre-Cancelled" },
  { value: "ReSchedule", label: "Re-Scheduled" },
  { value: "Pending", label: "Pending" },
];

export const optionsMeetingTypeList = [
  { value: "Scheduled", label: "Scheduled" },
  { value: "Direct Visit", label: "Direct Visit" },
];

export const optionsUnitList = [
  { value: "Per KM", label: "Kilometer" },
  { value: "1", label: "Ticket" },
];

export const optionsPageSizeList = [
  { value: 20, label: "Show Per Page" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
];

export const optionsDistanceList = [
  { value: 1, label: "1" },
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 300, label: "300" },
  { value: 400, label: "400" },
  { value: 500, label: "500" },
  { value: 1000, label: "1000" },
];

export const UOMList = [
  { value: 1, label: "Kilometer (km)" },
  { value: 2, label: "Inch (in)" },
  { value: 3, label: "Liter (L)" },
  { value: 4, label: "Kilogram (kg)" },
  { value: 5, label: "Others" },
];

// for complaints page
// export const compStatus = [
//   { value: 0, label: "Select Status" },
//   { value: 1, label: "Pending" },
//   { value: 2, label: "Follow Up" },
//   { value: 3, label: "Resolved" },
//   { value: 4, label: "Closed" },
//   { value: 5, label: "Hold" },
//   { value: 6, label: "No AMC" },
// ];

export const compStatus = [
  { value: 0, label: "Select Status" },
  { value: 1, label: "Pending" },
  { value: 2, label: "Follow Up" },
  { value: 3, label: "Resolved" },
  { value: 4, label: "Closed" },
  { value: 5, label: "No AMC, No Warranty" },
  { value: 6, label: "On the Way" },
  { value: 7, label: "CheckIn" },
  { value: 8, label: "Escalation" },
  { value: 9, label: "Feedback" },
  { value: 10, label: "Submitted" },
  { value: 11, label: "Accepted" },
];
// for complaints page

// whatsapp configuration header type option
export const headerType = [
  { value: 'none', label: "none" },
  { value: 'Header Text', label: "Header Text" },
  { value: 'Header Image', label: "Image" },
];
export const recieverType = [
  { value: 'All Device', label: "All Device" },
  { value: 'Particular', label: "Particular" },
];
export const notificationFrequency = [
  { value: 'One Time', label: "One Time" },
  { value: 'Daily', label: "Daily" },
  { value: 'Weekly', label: "Weekly" },
  { value: 'Monthly', label: "Monthly" },
];
export const attachmentTypes = [
  { value: 'Count Data', label: "Count Data" },
  { value: 'Detailed Data', label: "Detailed Data" },
  // { value: 'Locations', label: "Locations" },
  // { value: 'Contacts', label: "MonContactsthly" },
];
// whatsapp configuration header type option 
