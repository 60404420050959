import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import "../../styles/AddPage.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import Footer from "../Footer";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import CustomerFeedBackTable from "./CustomerFeedBackTable";
import FeedbackImages from "./FeedbackImages";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loadCustomerFeedbackData, loadCustomerFeedbackMachineList } from "../../redux/action";

function CustomerFeedBack() {
    let tableHeader = [
        { name: "CUSTOMER DETAILS", align: "left", width: "200px" },
        { name: "DATE", align: "left", width: "100px" },
        { name: "QUERY TYPE", align: "left", width: "100px" },
        { name: "PRODUCT/SERVICE", align: "left", width: "150px" },
        { name: "QUERY", align: "left", width: "200px" },
    ];

    let dispatch = useDispatch();
    const { getCustomerFeedback } = useSelector((state) => state.data);
    const { getCustomerFeedbackMachineList } = useSelector((state) => state.data);

    let navigate = useNavigate();
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [show, setShow] = useState(false);

    const [menuCollapse, setMenuCollapse] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [PageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [filterArray, setFilterArray] = useState([]);
    const [fromDate, setFromDate] = useState(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );
    const [toDate, setToDate] = useState(new Date());
    const [filterMachine, setFilterMachine] = useState({ value: "", label: "" })

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    // PAGINATION START
    const [pageDataSize, setPageDataSize] = useState(0);
    const pageDataFrom = currentPage * PageSize - PageSize + 1;
    const pageDataTill = pageDataFrom + pageDataSize - 1;
    // PAGINATION END

    const [feedBackImages, setFeedBackImages] = useState(false)
    const handleViewAttachments = () => {
        setFeedBackImages(true);
    }
    const handleClose = () => {
        setFeedBackImages(false);
    };

    // Page navigation
    const goToBackPage = () => {
        navigate(-1);
    };
    // page navigation

    // Open Filter div
    const [filter, setFilter] = useState(false);
    // Open Filter div

    //* EXPORT FILE START *//

    const [showEdit, setShowEdit] = useState(false)
    const EditAmc = () => {
        // setShowEdit(!showEdit)
        setShow(!show);
    }
    const handleCloseEdit = () => {
        setShowEdit(false)
    }

    const viewFeedback = () => {
        navigate('/viewFeedback')
    }

    useEffect(() => {
        if (getCustomerFeedbackMachineList.length > 0) {
            const defaultValue = getCustomerFeedbackMachineList.find(option => option.value === "MetricInfo-Essae Client App");
            setFilterMachine(defaultValue);
        }
    }, [getCustomerFeedbackMachineList]);

    useEffect(() => {
        const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
        const formattedToDate = toDate ? moment(toDate).format("YYYY-MM-DD") : '';
        const machineName = filterMachine?.value;
        dispatch(loadCustomerFeedbackData(formattedFromDate, formattedToDate, machineName));
    }, [dispatch, fromDate, toDate, filterMachine]);

    const setSelectSearchDrop = (data, type) => {
        if (type === 'date') {
            const [start, end] = data;
            setFromDate(start);
            setToDate(end);
        }
        else if (type === 'machineName') {
            setFilterMachine(data)
        }
    };

    useEffect(() => {
        dispatch(loadCustomerFeedbackMachineList())
    }, [])

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const filterFeedbackData = () => {
        return getCustomerFeedback.filter((feedback) => {
            return (
                (feedback.mobile_user_name ?? "").toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                (feedback.Phone_number ?? "").toString().includes(searchValue) ||
                (feedback.issue ?? "").toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                (feedback.date ?? "").toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                (feedback.product ?? "").toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                (feedback.issuetype ?? "").toString().toLowerCase().includes(searchValue.toLowerCase())
            );
        });
    };

    const filteredFeedbackData = filterFeedbackData();

    const clearSerachBySelect = () => {
        setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
        setToDate(new Date())
        // setFilterMachine({ value: "", label: "" })
    }

    return (
        <div id="main-page">
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        <div className="clients-table">
                            <Row className="search-row">
                                <Col md={3} className="cat-col">
                                    <div className="page-header-text-div">
                                        <div className="back-btn" onClick={goToBackPage}>
                                            <img
                                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="page-header-text">
                                            CUSTOMER QUERIES
                                        </h6>
                                    </div>
                                </Col>
                                <Col md={5} className="cat-col">
                                    <div className="input-div">
                                        <input
                                            type="search"
                                            id="metricInfo_search_bar"
                                            placeholder="Search"
                                            className="form-control setting-input"
                                            value={searchValue}
                                            onChange={handleSearchChange}
                                        />
                                        <i className="fa fa-search search-icon" />
                                    </div>
                                </Col>
                                <Col md={4} className="cat-col">
                                    <div
                                        className="search-bar-header"
                                        style={{ float: "right" }}
                                    >
                                        <div className="page-top-bar-dropdowns page-top-Filters">
                                            <div
                                                className="header-filter-btn"
                                                onClick={() => setFilter(!filter)}
                                            >
                                                <p className="card-head">
                                                    <i className="bi bi-sliders" />
                                                    &nbsp;&nbsp;Filters
                                                </p>
                                            </div>
                                            {/* <SbAddBtn onClickEffect={() => setShow(!show)} /> */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {filter && (
                                <div className="page-filter-section">
                                    <div style={{ width: "100%" }}>
                                        <Row>
                                            <Col md={12}>
                                                <div className="clients-filter-input-boxs filter-box-div">
                                                    <div style={{ width: "100%", padding: "0px 5px" }}>
                                                        <Select
                                                            className="react-select-container-list"
                                                            classNamePrefix="react-select-list"
                                                            options={getCustomerFeedbackMachineList}
                                                            placeholder="Machine Name"
                                                            value={filterMachine.value && filterMachine}
                                                            onInputChange={filterMachine.label}
                                                            // onChange={(data) =>
                                                            //     setSelectSearchDrop(data, "machineName")
                                                            // }
                                                            isSearchable={true}
                                                            isDisabled
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ width: "100%", padding: "0px 5px" }}
                                                        className="input-group from-to-date-select"
                                                    >
                                                        <span className="input-group-text" id="from-label">
                                                            From - To
                                                        </span>
                                                        <span className="date-picker-with-icon">
                                                            <DatePicker
                                                                selected={fromDate}
                                                                className="select from-date date-filter"
                                                                id="date-range-picker"
                                                                dateFormat="dd/MM/yyyy"
                                                                selectsRange
                                                                startDate={fromDate}
                                                                endDate={toDate}
                                                                onChange={(date) =>
                                                                    setSelectSearchDrop(date, "date")
                                                                }
                                                                maxDate={new Date()}
                                                            />
                                                        </span>
                                                        <span>
                                                            <i
                                                                className="fa fa-calendar-o calander-icon"
                                                                style={{ right: "15px" }}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{ width: "100%", padding: "0px 5px" }}
                                                        className="input-group from-to-date-select"
                                                    >
                                                        <SbCancelBtn
                                                            onClickEffect={clearSerachBySelect}
                                                            btnName="Clear"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )}
                        </div>
                        <CustomerFeedBackTable
                            tableHeader={tableHeader}
                            handleViewAttachments={handleViewAttachments}
                            // viewFeedback={viewFeedback}
                            getCustomerFeedback={filteredFeedbackData}
                        />
                        {feedBackImages && <FeedbackImages
                            feedBackImages={feedBackImages}
                            handleClose={handleClose}
                            setFeedBackImages={setFeedBackImages}
                        />}
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
}

export default CustomerFeedBack;
