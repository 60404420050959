import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import uuid from "react-uuid";
import Topbar from "../../Topbar";
import "../../../styles/dragdropForm.scss";
import DraggableItem from "./DraggableItem";
import Footer from "../../Footer";
import ElementSetting from "./ElementSetting";
import { formItems } from "./FormItems";
import SettingsTab from "./SettingsTab";
import PreviewForm from "./PreviewForm";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import { useDispatch } from "react-redux";
import { loadCreatedCustomeForms, postCreatedCustomeform, postCreatedCustomeformData } from "../../../redux/action";
import '../../../styles/Sidenavbar.scss'
import DisplayForm from "../DisplayForm/DisplayForm";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

// Example form items to drag from

function DragCustomForm() {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [mainHeight, setMainHeight] = useState(200);
  const getHeightOfDiv = () => {
    const element = document.querySelector(".main-without-sidebar");

    // Get the height including padding
    const heightWithPadding = element.getBoundingClientRect().height;

    // Extract the top and bottom padding values
    const style = window.getComputedStyle(element);
    const paddingTop = parseFloat(style.paddingTop);
    const paddingBottom = parseFloat(style.paddingBottom);

    // Calculate the height excluding padding
    const heightWithoutPadding = heightWithPadding - paddingTop - paddingBottom;
    setMainHeight(heightWithoutPadding);
  };

  useEffect(() => {
    getHeightOfDiv();
  }, []);

  const [items, setItems] = useState(formItems);
  const [addFields, setAddFields] = useState([]);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [focusIndex, setFocusIndex] = useState(null);
  const [selectField, setSelectField] = useState("");
  const [openSettingsSection, setOpenSettingsSection] = useState(false);
  const [selectedField, setSelectedField] = useState({});
  const [selectedFieldID, setSelectedFieldID] = useState("");
  const [customTab, setCustomTab] = useState(false);

  // const setMakeSelectField = (rowData) => {
  //   if (selectField !== rowData && openSettingsSection) {
  //     setOpenSettingsSection(false)
  //   }
  //   setSelectField(rowData)
  // }

  const [formName, setFormName] = useState({
    id: uuid(),
    content: "Form Name",
    Label: "Form Name",
    alignment: "left",
    fontSize: "default",
    subheading: "Sub heading",
    field_type: "header",
    type: "Heading",
    moduleName: '',
    modulePrimaryKey: '',
    subModuleName: '',
    foreignKey: '',
    validation: {
      readOnly: false,
      active: true
    }
  });

  // let dispatch = useDispatch();
  // const [submitError, setSubmitError] = useState(false);
  // const publishForm = () => {
  //   formName.fields = addFields
  //   console.log(formName, "formName")
  //   console.log(addFields, "addFields")
  //   if (!formName.moduleName) {
  //     setSubmitError(true);
  //   }
  //   else {
  //     const data = {
  //       "dForm_ID": 0,
  //       "dForm_ModuleName": formName.moduleName,
  //       "dForm_ModulePrimaryKey": "",
  //       "dForm_FormName": formName.Label,
  //       "dForm_JsonUI": JSON.stringify(formName),
  //       "dForm_Status": 1,
  //       "subscriptionID": resPassData.subscriptionID,
  //       "dFormVariableDetail": addFields.map(field => ({
  //         dForm_ID: 0,
  //         dForm_LabelName: field.rowData[0].Label,
  //         dForm_PlaceHolder: field.rowData[0].placeholder,
  //         // dForm_Required: field.rowData[0].validation.required,
  //         // dForm_Readonly: field.rowData[0].validation.readOnly,
  //         dForm_Options: JSON.stringify(field.rowData[0].options),
  //         dForm_DefaultSelected: "",
  //         dForm_Contains: "",
  //         dForm_FieldType: field.rowData[0].type
  //       }))
  //     }
  //     let postForm = {
  //       method: "POST",
  //       mode: 'cors',
  //       headers: HeaderAuthentication.postTravelizeAuth,
  //       body: JSON.stringify(data)
  //     }
  //     dispatch(postCreatedCustomeform(postForm,dFormId))
  //     console.log(data, 'data')
  //   }
  // }

  const setElementEditSection = (data, fieldId) => {
    setSelectedField(data);
    setSelectedFieldID(fieldId)
    setOpenSettingsSection(true);
  };

  const deleteFieldFromForm = (fieldId, rowId) => {
    let newAddFields = JSON.parse(JSON.stringify(addFields));

    const rowIndex = newAddFields.findIndex(field => field.id === fieldId);
    if (rowIndex !== -1) {
      newAddFields[rowIndex].rowData = newAddFields[rowIndex].rowData.filter(row => row.id !== rowId);
      setAddFields(newAddFields);
      setOpenSettingsSection(false);
      setSelectedField({});
      setSelectedFieldID('')
    }
  };

  // #region update Custom form settting 
  const updateFormSettings = (val, type) => {
    if (type === 'boolian') {
      setFormName(prevFormName => ({
        ...prevFormName,
        validation: {
          ...prevFormName.validation,
          active: val
        }
      }));
    } else {
      setFormName(prevFormName => ({
        ...prevFormName,
        [type]: val
      }));
    }

  }

  // #endregion

  // #region updating Custom AddedFieldsData 
  const updateAddedFieldsData = (itemIndex, updatedItem, updatedRow) => {
    const newAddFields = [
      ...addFields.slice(0, itemIndex),
      updatedItem,
      ...addFields.slice(itemIndex + 1),
    ];

    setAddFields(newAddFields);
    setSelectedField(updatedRow);
  }
  // #endregion

  // #region updating field values by element setting window of form fields 
  const updateFieldValue = (e, data, option, subProperty) => {
    const { id, name, value } = e.target;
    if (data.content === "Form Name") {
      let updatedData = { ...formName, [name]: value };
      setFormName({ ...formName, [name]: value });
      setSelectedField(updatedData);
    } else {
      const itemIndex = addFields.findIndex((item) => item.id === selectedFieldID);
      if (itemIndex !== -1) {
        const updatedItem = JSON.parse(JSON.stringify(addFields[itemIndex]));
        const rowIndex = updatedItem.rowData.findIndex((item) => item.id === data.id);
        if (rowIndex !== -1) {
          const updatedRow = JSON.parse(JSON.stringify(addFields[itemIndex].rowData[rowIndex]));
          if (option === "sublabel") {
            const subLabelIndex = updatedRow.subLabels.findIndex(
              (subLabel) => subLabel.id === id
            );

            if (subLabelIndex !== -1) {
              if (subProperty) {
                updatedRow.subLabels[subLabelIndex][subProperty] = value;
              } else {
                updatedRow.subLabels[subLabelIndex].label = value;
              }
            }
          } else if (option === "options") {
            const subLabelIndex = updatedRow.options.findIndex(
              (option) => option.id === id
            );

            if (subLabelIndex !== -1) {
              updatedRow.options[subLabelIndex].label = value
              updatedRow.options[subLabelIndex].value = value
            }
          } else if (option === "components") {
            const subLabelIndex = updatedRow.components.findIndex(
              (component) => component.id === id
            );

            if (subLabelIndex !== -1) {
              updatedRow.components[subLabelIndex].placeholder = value
            }
          } else if (!option) {
            if (id === 'validation') {
              updatedRow.validation[name] = value;
            } else if (id !== 'validation') {
              updatedRow[name] = value;
            }
          }

          updatedItem.rowData[rowIndex] = updatedRow;
          updateAddedFieldsData(itemIndex, updatedItem, updatedRow)
        }
      }
    }
  };
  // #endregion

  // #region updating size and alignmemt by element setting window of form fields 
  const updateSizeAlignValue = (val, data, property, id) => {
    if (data.content === "Form Name") {
      let updatedData = { ...formName, [property]: val };
      setFormName({ ...formName, [property]: val });
      setSelectedField(updatedData);
    } else {
      const itemIndex = addFields.findIndex((item) => item.id === selectedFieldID);
      if (itemIndex !== -1) {
        const updatedItem = JSON.parse(JSON.stringify(addFields[itemIndex]));

        const rowIndex = updatedItem.rowData.findIndex((item) => item.id === data.id);

        if (rowIndex !== -1) {
          const updatedRow = JSON.parse(JSON.stringify(addFields[itemIndex].rowData[rowIndex]));
          if (property === "sublabels") {
            const subLabelIndex = updatedRow.subLabels.findIndex(
              (subLabel) => subLabel.id === id
            );
            if (subLabelIndex !== -1) {
              updatedRow.subLabels[subLabelIndex].active = val;
            }
          } else if (property === "composite") {
            const subLabelIndex = updatedRow.components.findIndex(
              (component) => component.id === id
            );
            if (subLabelIndex !== -1) {
              updatedRow.components[subLabelIndex].active = val;
            }
          } else if (!id) {
            if (property === "alignment") {
              updatedRow[property] = val;
            } else if (property === "dateFormat" || property === "defaultDate") {
              if (property === "dateFormat") {
                updatedRow.config.defaultDate = '';
                updatedRow.config[property] = val;
              } else {
                if (val === 'custom') {
                  setCustomTab(true)
                  return;
                }
                setCustomTab(false)
                updatedRow.config[property] = val;
              }
            } else {
              updatedRow.validation[property] = val;
            }
          }

          updatedItem.rowData[rowIndex] = updatedRow;

          updateAddedFieldsData(itemIndex, updatedItem, updatedRow)
        }
      }
    }
  };
  // #endregion

  // #region setting custom date for date field in element setting window of form fields 
  const setCustomDate = (val, data) => {
    const itemIndex = addFields.findIndex((item) => item.id === selectedFieldID);
    if (itemIndex !== -1) {
      const updatedItem = JSON.parse(JSON.stringify(addFields[itemIndex]));
      const rowIndex = updatedItem.rowData.findIndex((item) => item.id === data.id);

      if (rowIndex !== -1) {
        const updatedRow = JSON.parse(JSON.stringify(addFields[itemIndex].rowData[rowIndex]));
        updatedRow.config.defaultDate = val;

        updatedItem.rowData[rowIndex] = updatedRow;
        updateAddedFieldsData(itemIndex, updatedItem, updatedRow)
      }
    }
  };
  // #endregion

  // #region adding options for checkbox, radio box and select dropdown in element setting window of form fields
  const addOptions = (data) => {
    const itemIndex = addFields.findIndex((item) => item.id === selectedFieldID);
    if (itemIndex !== -1) {
      const updatedItem = JSON.parse(JSON.stringify(addFields[itemIndex]));

      const rowIndex = updatedItem.rowData.findIndex((item) => item.id === data.id);

      if (rowIndex !== -1) {
        const updatedRow = JSON.parse(JSON.stringify(addFields[itemIndex].rowData[rowIndex]));

        updatedRow.options.push({
          id: uuid(),
          label: `Option ${updatedRow.options.length + 1}`,
          name: `Option ${updatedRow.options.length + 1}`,
          value: `Option ${updatedRow.options.length + 1}`,
          checked: false
        },);

        updatedItem.rowData[rowIndex] = updatedRow;

        updateAddedFieldsData(itemIndex, updatedItem, updatedRow)
      }
    }
  }
  // #endregion

  // #region removing options for checkbox, radio box and select dropdown in element setting window of form fields
  const removeOptions = (data, id) => {
    const itemIndex = addFields.findIndex((item) => item.id === selectedFieldID);
    if (itemIndex !== -1) {
      const updatedItem = JSON.parse(JSON.stringify(addFields[itemIndex]));
      const rowIndex = updatedItem.rowData.findIndex((item) => item.id === data.id);

      if (rowIndex !== -1) {
        const updatedRow = JSON.parse(JSON.stringify(addFields[itemIndex].rowData[rowIndex]));
        const filteredOptions = updatedRow.options.filter(option => option.id !== id);

        updatedRow.options = filteredOptions;

        updatedItem.rowData[rowIndex] = updatedRow;
        updateAddedFieldsData(itemIndex, updatedItem, updatedRow)
      }
    }
  }
  // #endregion

  // #region making default select of options for checkbox, radio box  in element setting window of form fields
  const makeSelected = (e, data, id) => {
    const itemIndex = addFields.findIndex((item) => item.id === selectedFieldID);
    if (itemIndex !== -1) {
      const updatedItem = JSON.parse(JSON.stringify(addFields[itemIndex]));

      const rowIndex = updatedItem.rowData.findIndex((item) => item.id === data.id);

      if (rowIndex !== -1) {
        const updatedRow = JSON.parse(JSON.stringify(addFields[itemIndex].rowData[rowIndex]));
        if (data.field_type === "radio_group" && e.target.checked) {
          const isSelected = updatedRow.options.some(option => option.checked === true)
          if (isSelected) {
            alert('you cant make morethan one option as default selected')
            return;
          }
        }
        const subLabelIndex = updatedRow.options.findIndex(
          (option) => option.id === id
        );
        if (subLabelIndex !== -1) {
          updatedRow.options[subLabelIndex].checked = e.target.checked;
        }

        updatedItem.rowData[rowIndex] = updatedRow;

        updateAddedFieldsData(itemIndex, updatedItem, updatedRow)
      }
    }
  }

  // #endRegon

  // #region dublicate the field
  const duplicateItem = (dublicatingData) => {
    const sourceRowIndex = addFields.findIndex(row => row.id === selectedFieldID);
    if (sourceRowIndex === -1) return;

    const rowDataToDuplicate = addFields[sourceRowIndex].rowData.find(rowData => rowData.id === dublicatingData.id);
    if (!rowDataToDuplicate) return;

    const duplicatedRowData = { ...rowDataToDuplicate, id: uuid() };

    addFields[sourceRowIndex].rowData.push(duplicatedRowData);
    setAddFields([...addFields]);
  }

  const duplicateItemNewRow = (dublicatingData) => {
    const sourceRowIndex = addFields.findIndex(row => row.id === selectedFieldID);
    if (sourceRowIndex === -1) return;

    const rowDataToDuplicate = addFields[sourceRowIndex].rowData.find(rowData => rowData.id === dublicatingData.id);
    if (!rowDataToDuplicate) return;

    const duplicatedRowData = { ...rowDataToDuplicate, id: uuid() };

    const newRowData = { rowData: [duplicatedRowData], col: 1 };
    const newItem = { ...newRowData, id: uuid() };
    const newAddFields = [
      ...addFields.slice(0, sourceRowIndex + 1),
      newItem,
      ...addFields.slice(sourceRowIndex + 1),
    ];
    setAddFields(newAddFields);
  }
  // #endregion

  // #region Option Tab functions
  const addMiddlaName = (val, data, property) => { };
  // #endregion

  const formHeaderRef = useRef(null);
  const formRowRef = useRef(null);
  const editSectionRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        formHeaderRef.current &&
        !formHeaderRef.current.contains(event.target) &&
        formRowRef.current &&
        !formRowRef.current.contains(event.target) &&
        editSectionRef.current &&
        !editSectionRef.current.contains(event.target)
      ) {
        setSelectField("");
        setOpenSettingsSection(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formHeaderRef, editSectionRef, formRowRef]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    // Ensure we're dragging from the external list to the form preview
    if (source.droppableId === "droppable" && destination.droppableId === "formPreview") {
      // Create a copy of the item being dragged
      const itemToAdd = { ...formItems[source.index], id: uuid() };

      // Copy the current state to manipulate
      let newAddFields = [...addFields];

      // Find the target row in formPreview or create a new one if necessary
      const targetRowIndex = destination.index; // Assuming each row is a Droppable and has a unique index
      let targetRow = newAddFields[targetRowIndex];

      // If there's no target row, we create a new one
      if (!targetRow) {
        targetRow = { id: uuid(), rowData: [], col: 1 }; // Adjust 'col' as necessary
        newAddFields.push(targetRow); // Add the new row to the state array
      }

      // Add the new item to the rowData array of the target row
      targetRow.rowData.push(itemToAdd);

      // Update the state with the new structure
      setAddFields(newAddFields);
    } else if (
      destination.droppableId === "formPreview" &&
      source.droppableId === "formPreview"
    ) {
      // Handle reordering within the formPreview
      const newAddFields = reorder(addFields, source.index, destination.index);
      setAddFields(newAddFields);
    }
    // Handle reordering within formPreview if needed...
  };

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const { getCustomeFrom } = useSelector((state) => state.data);
  const location = useLocation();
  const dFormId = location.state?.dFormId;
  useEffect(() => {
    if (dFormId) {
      dispatch(loadCreatedCustomeForms(dFormId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dFormId])
  const [parsedData, setParsedData] = useState({})
  useEffect(() => {
    if (getCustomeFrom && Object.keys(getCustomeFrom).length !== 0) {
      let parsed_json = printFieldNames(getCustomeFrom.dForm_JsonUI)
      setParsedData(parsed_json)
    }
  }, [getCustomeFrom])

  const printFieldNames = (jsonData) => {
    try {
      const parsedData = JSON.parse(jsonData);
      return parsedData;
    } catch (error) {
      console.error("Error parsing JSON data:", error);
    }
  };

  // useEffect(() => {
  //   if (parsedData && parsedData.fields) {
  //     const rowDataArray = parsedData.fields.map(field => field);
  //     setAddFields(rowDataArray);
  //   }
  //   else {
  //     setAddFields([])
  //   }
  //   setFormName(prevFormName => ({
  //     ...prevFormName,
  //     Label: parsedData.Label,
  //     subheading: parsedData.subheading,
  //     moduleName: parsedData.moduleName,
  //   }));
  // }, [parsedData]);

  useEffect(() => {
    if (parsedData && parsedData.fields) {
      const rowDataArray = parsedData.fields.map(field => {
        if (field.rowData) {
          field.rowData.map(data => {
            data.editable = true;
            return data;
          })
        }
        return field;
      });

      setAddFields(rowDataArray);
    } else {
      setAddFields([]);
    }
    setFormName(prevFormName => ({
      ...prevFormName,
      Label: parsedData.Label,
      subheading: parsedData.subheading,
      moduleName: parsedData.moduleName,
    }));
  }, [parsedData]);

  // const setMakeSelectField = (rowDataId, rowData) => {
  //   console.log(rowData,'rowData')
  //   if (rowData && rowData.editable) {
  //     // toast.warning("Field is not supporter for edit");
  //     toast.warning("Can't edit the fields", {
  //       autoClose: 3000, // Auto close after 3 seconds
  //     });
  //     return;
  //   }
  //   setSelectField(rowDataId);
  // };

  const setMakeSelectField = (rowDataId, rowData) => {
    console.log(rowData, 'rowData');
    if (rowData && rowData.editable && rowData.content !== "Dropdown Select") {
      toast.warning("Can't edit the fields", {
        autoClose: 3000, // Auto close after 3 seconds
      });
      return;
    }
    setSelectField(rowDataId);
  };


  const extractFields = (data) => {
    const extractedFields = [];

    data.forEach(item => {
      item.rowData.forEach(field => {
        // if (field.field_type === "input" && (field.type === "text" || field.type === "email")) {
        extractedFields.push({
          "row_ID": "",
          "dForm_LabelName": field.Label,
          "dForm_PlaceHolder": field.placeholder ? field.placeholder : "",
          "dForm_Options": field.options ? JSON.stringify(field.options) : "",
          "dForm_FieldType": field.field_type
        });
        // }
      });
    });

    return extractedFields;
  };

  let dispatch = useDispatch();
  const [submitError, setSubmitError] = useState(false);
  const publishForm = () => {
    const extractedFields = extractFields(addFields);

    formName.fields = addFields
    if (!formName.moduleName) {
      setSubmitError(true);
    }
    else {
      const data = {
        "dForm_ID": 0,
        "dForm_ModuleName": formName.moduleName,
        "dForm_ModulePrimaryKey": "",
        "dForm_FormName": formName.Label,
        "dForm_JsonUI": JSON.stringify(formName),
        "dForm_Status": 1,
        "subscriptionID": resPassData.subscriptionID,
        "dForm_SubModule": formName.subModuleName,
        "dForm_SubModuleFK": formName.foreignKey,
        "dFormVariableDetail": extractedFields
      }
      let postForm = {
        method: "POST",
        mode: 'cors',
        headers: HeaderAuthentication.postTravelizeAuth,
        body: JSON.stringify(data)
      }
      dispatch(postCreatedCustomeform(postForm, dFormId))
      console.log(data, 'data')
    }
  }


  return (
    <div>
      <div id="main-page">
        <Topbar
          toggleMenu={toggleMenu}
          menuCollapse={menuCollapse}
          toggleshift={toggleshift}
          type="onlyheade"
        />
        <div className="page-wrapper">
          <div className={`main-without-sidebar ${toggleshift.style}`}>
            <div className="main-tab-div" style={{ height: mainHeight }}>
              <Tabs
                defaultActiveKey="build"
                id="justify-tab-example"
                className="custom-form-tab"
                justify
              >
                <Tab eventKey="build" className="form-main-tab" title="Build">
                  <DragDropContext
                    onDragEnd={onDragEnd}
                  >
                    <Row className="custom-form-row">
                      <Col md={2} className="form-element-col">
                        <div className="form-elements-header">
                          <h5>Form Elements</h5>
                        </div>
                        <div className="form-elements-box">
                          <Droppable droppableId="droppable">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {items.map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <>
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <div className="form-field-item-hover-div">
                                            <div className="form-field-item">
                                              <div className="item-icons">
                                                <img
                                                  src={`../../../../images/customForm/${item.imgname}.png`}
                                                  alt=""
                                                />
                                              </div>
                                              <div className="item-text">
                                                {item.content}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {snapshot.isDragging && (
                                          <div
                                            style={{
                                              opacity: 0.5,
                                            }}
                                          >
                                            <div className="form-field-item">
                                              <div className="item-icons">
                                                <i className="fa fa-edit"></i>
                                              </div>
                                              <div className="item-text">
                                                {item.content}
                                              </div>
                                            </div>{" "}
                                            {/* Show a clone of the item being dragged */}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </Col>
                      <Col md={openSettingsSection ? 7 : 10} className="form-edit-col">
                        <div className="form-box">
                          <div className="form-header-card">
                            <div
                              ref={formHeaderRef}
                              className="form-header"
                              onClick={() => setMakeSelectField(formName.id)}
                            >
                              <div
                                className={`header-item `}
                              > <div className={`item-col-data ${selectField === formName.id && "item-select"
                                }`}>
                                  <Form.Control
                                    type="text"
                                    value={formName.Label}
                                    style={{ textAlign: `${formName.alignment}` }}
                                    className={`custom-form-control font-${formName.fontSize}`}
                                    // onChange={(e) =>
                                    //   setElementEditSection(formName)
                                    // }
                                    readOnly
                                  />
                                  <p
                                    style={{ textAlign: `${formName.alignment}` }}
                                  >
                                    {formName.subheading}
                                  </p>
                                  {selectField === formName.id && (
                                    <div className="action-btn-section">
                                      <div
                                        className="icon-div"
                                        onClick={() =>
                                          setElementEditSection(formName)
                                        }
                                      >
                                        <i className="fa fa-gear"></i>
                                      </div>
                                      {/* <div className="icon-div"><i className="fa fa-trash-o"></i></div> */}
                                    </div>
                                  )}
                                </div>
                              </div>

                            </div>
                          </div>
                          <Droppable droppableId="formPreview">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  padding: "5px",
                                  height: "80%",
                                  overflow: "auto",
                                }}
                              >
                                {addFields.length === 0 ? (
                                  <div className="drag-drop-box">
                                    {" "}
                                    Drag and drop the fields here
                                  </div>
                                ) : (
                                  addFields.map((field, index) => (
                                    <Draggable
                                      key={field.id}
                                      draggableId={field.id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onMouseEnter={() =>
                                            setHoverIndex(index)
                                          }
                                          onMouseLeave={() =>
                                            setHoverIndex(null)
                                          }
                                          onFocus={() => setFocusIndex(index)}
                                          onBlur={() => setFocusIndex(null)}
                                          className="form-header"
                                          style={{
                                            ...provided.draggableProps.style,
                                            userSelect: "none",
                                            outline: "none",
                                          }}
                                          tabIndex={0}
                                        >
                                          <Row className="header-item field-item-card" ref={formRowRef}>
                                            {field.rowData.map((rowData, row_i) => (
                                              <Col key={row_i} className={`item-col-data ${selectField === rowData.id &&
                                                "item-select"
                                                }`}
                                                // ref={formRowRef}
                                                onClick={() =>
                                                  setMakeSelectField(rowData.id, rowData)
                                                }
                                              >
                                                <DraggableItem
                                                  formItem={rowData}
                                                  formName={formName}
                                                />
                                                {selectField === rowData.id && (
                                                  <div className="action-btn-section">
                                                    <div
                                                      className="icon-div"
                                                      onClick={() =>
                                                        setElementEditSection(rowData, field.id)
                                                      }
                                                    >
                                                      <i className="fa fa-gear"></i>
                                                    </div>
                                                    <div
                                                      className="icon-div"
                                                      onClick={() =>
                                                        deleteFieldFromForm(field.id, rowData.id)
                                                      }
                                                      disabled={rowData.editable === true}
                                                    >
                                                      <i className="fa fa-trash-o"></i>
                                                    </div>
                                                  </div>
                                                )}
                                              </Col>
                                            ))}
                                          </Row>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </Col>
                      {openSettingsSection && (
                        <Col
                          md={3}
                          ref={editSectionRef}
                          className="form-element-col right"
                        >
                          <ElementSetting
                            selectedField={selectedField}
                            updateFieldValue={updateFieldValue}
                            updateSizeAlignValue={updateSizeAlignValue}
                            duplicateItem={duplicateItem}
                            duplicateItemNewRow={duplicateItemNewRow}
                            addMiddlaName={addMiddlaName}
                            customTab={customTab} setCustomDate={setCustomDate}
                            addOptions={addOptions} makeSelected={makeSelected}
                            removeOptions={removeOptions}
                          />
                        </Col>
                      )}
                    </Row>
                  </DragDropContext>
                </Tab>
                <Tab
                  eventKey="settings"
                  className="form-main-tab"
                  title="Settings"
                >
                  <SettingsTab formName={formName} updateFormSettings={updateFormSettings} submitError={submitError} />
                </Tab>
                <Tab
                  eventKey="preview"
                  className="form-main-tab"
                  title="Preview and Publish"
                >
                  <PreviewForm formName={formName} addFields={addFields} publishForm={publishForm} />
                </Tab>
              </Tabs>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {/* <DisplayForm formItem={{ field_type: formName.field_type }} handleInputChange={handleInputChange} /> */}
    </div>
  );
}

export default DragCustomForm;
