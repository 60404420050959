import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DraggableItem from "./DraggableItem";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

function PreviewForm({ formName, addFields, publishForm }) {
  // const formName = props.formName;
  // const addFields = props.addFields;
  const [showModal, setShowModal] = useState(false)
  const handleShowModal = () => {
    publishForm();
    setShowModal(false);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  }
  
  return (
    <Row className="custom-form-row">
      <Col sm={2} className="form-element-col"></Col>
      <Col sm={8} className="form-edit-col">
        <div className="form-box">
          <div className="form-header-card">
            <div className="form-header">
              <div className={`header-item`}>
                <Form.Control
                  type="text"
                  value={formName.Label}
                  style={{ textAlign: `${formName.alignment}` }}
                  className={`custom-form-control font-${formName.fontSize}`}
                  readOnly
                />
                <p style={{ textAlign: `${formName.alignment}` }}>
                  {formName.subheading}
                </p>
              </div>
            </div>
            {/* <Button variant="primary" onClick={publishForm}>Publish</Button> */}
            <SBSaveUpdateBtn
              btnName="Publish"
              // onClickEffect={publishForm}
              onClickEffect={() => setShowModal(true)}
            />
          </div>
          <div
            style={{
              padding: "5px",
              height: "80%",
              overflow: "auto",
            }}
          >
            {addFields.map((field, index) => (
              <div className="form-header" tabIndex={0}>
                <Row className="header-item field-item-card">
                  {field?.rowData.map((rowData, row_i) => (
                    <Col key={row_i} className={`item-col-data`} >
                      <DraggableItem formItem={rowData} />
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </div>
        </div>
      </Col>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="logout-modal-div"
      >
        <div className="cat-col">
          <div>
            <div className="log-out-div">
              <h6>Are you sure want to save the form?</h6>
            </div>
            <div className="sb-logout-btn">
              <SBSaveUpdateBtn btnName="Yes" onClickEffect={handleShowModal} />
              <SbCancelBtn btnName="No" onClickEffect={handleCloseModal} />
            </div>
          </div>
        </div>
      </Modal>
    </Row>
  );
}

export default PreviewForm;
