import React, { useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Complaints.scss";
import "../../styles/ClientComplaint.scss";
import "../../styles/AddPage.scss";
import { Col, Row, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import {
    compStatus,
    priorityStatus,
} from "../DropdownsData";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    loadClientComplaint,
    loadClientDropDown,
    loadOtherContacts,
    loadPageBreadCome,
    loadSingleClientCompalint,
    loadUserDropdown,
} from "../../redux/action";
import SbAddBtn, {
    SbDownloadBtn,
} from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import DatePicker from "react-datepicker";
import moment from "moment";
import { exportExcel } from "../DownloadFile";
import { baseURL } from "../BaseUrl";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import AnnualContractStatus from "./AnnualContractStatus";
import AddServiceRequestData from "./AddServiceRequestData";
import ProductSkelrton from "../Products/ProductSkelrton";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import AssignToServiceRequest from "./AssignToServiceRequest";

const machineStatus = [
    { label: 'Under Warranty', value: 'Under Warranty' },
    { label: 'Out Of Warranty', value: 'Non Warranty/ASC' },
    { label: 'Under AMC', value: 'Under AMC' },
    { label: 'Out of AMC', value: 'Non Warranty/ASC' },
    { label: 'In Active', value: 'Non Warranty/ASC' },
    { label: 'Privilege Contract', value: 'Privilege Contract' },
];


export default function ServiceRequest() {
    let dispatch = useDispatch();
    const { clientComplaints } = useSelector((state) => state.data);
    const { clientDropdown } = useSelector((state) => state.data);
    const { contactByCompanyDrop } = useSelector((state) => state.data);
    const { totalCount } = useSelector((state) => state.data);

    const [isLoading, setIsLoading] = useState(true);

    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [show, setShow] = useState(false);
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [viewProductDetail, setViewProductDetail] = useState(false);

    const [showAssign, setShowAssign] = useState(false);

    const handleAssign = (id) => {
        dispatch(loadSingleClientCompalint(id));
        setShowAssign(true);
    };

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // PAGINATION START
    const [PageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);

    const [searchValue, setSearchValue] = useState("");
    const [filterArray, setFilterArray] = useState([]);
    const [filterCompStatus, setFilterCompStatus] = useState({
        value: "",
        label: "",
    });
    const [filterUser, setFilterUser] = useState({ value: "", label: "" });
    const [filterClientName, setFilterClientName] = useState({
        value: "",
        label: "",
    });
    const [filterMachineStatus, setFilterFilterMachineStatus] = useState({
        value: "",
        label: "",
    });
    const [selectPriority, setSelectPriority] = useState();
    const [fromDate, setFromDate] = useState(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );
    const [toDate, setToDate] = useState(new Date());
    // PAGINATION END

    useEffect(() => {
        let breadCome = { mainpage: "Complaints" };
        dispatch(loadPageBreadCome(breadCome));
        let list = [];
        if (fromDate && toDate) {
            list.push({
                key: "FromDate",
                value: moment(new Date(fromDate)).format("DD/MM/YYYY"),
            });
            list.push({
                key: "ToDate",
                value: moment(new Date(toDate)).format("DD/MM/YYYY"),
            });
        }
        setFilterArray(list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate]);

    const setSearchData = (value) => {
        let list = [];
        if (filterArray.length !== 0) {
            list = filterArray.filter((item) => item.key !== "Text");
        }
        if (value) {
            list.push({ key: "Text", value: value });
        }
        setSearchValue(value);
        setFilterArray(list);
    };

    const setSelectSearchDrop = (data, type) => {
        let list = [];
        if (type === "Reported") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "ContactPersonID");
            }
            list.push({ key: "ContactPersonID", value: data.value });
            setFilterUser(data);
        } else if (type === "status") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "Status");
            }
            list.push({ key: "Status", value: data.value });
            setFilterCompStatus(data);
        } else if (type === "MachineStatus") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "AMC");
            }
            list.push({ key: "AMC", value: data.value });
            setFilterFilterMachineStatus(data);
        } else if (type === "ClientName") {
            setFilterUser({ value: "", label: "" });
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "ClientID");
            }
            list.push({ key: "ClientID", value: data.value });
            dispatch(loadOtherContacts(data.value));
            setFilterClientName(data);
        } else if (type === "date") {
            const [start, end] = data;
            if (filterArray.length !== 0) {
                if (start) {
                    list = filterArray.filter((item) => item.key !== "FromDate");
                }
                if (end) {
                    list = filterArray.filter((item) => item.key !== "ToDate");
                }
            }
            if (start && end == null) {
                list.push({
                    key: "FromDate",
                    value: moment(new Date(start)).format("DD/MM/YYYY"),
                });
            }
            setFromDate(start);
            if (end) {
                list.push({
                    key: "ToDate",
                    value: moment(new Date(end)).format("DD/MM/YYYY"),
                });
            }
            setToDate(end);
        } else if (type === "priority") {
            if (filterArray.length !== 0) {
                list = filterArray.filter((item) => item.key !== "Priority");
            } else {
                list = [];
            }
            list.push({ key: "Priority", value: data.value });
            setSelectPriority(data);
        }
        setFilterArray(list);
    };

    const handlePrioritySearch = (e) => {
        // alert(e.target.value)
        let list = [];
        if (filterArray.length !== 0) {
            list = filterArray.filter((item) => item.key !== "Priority");
        } else {
            list = [];
        }
        list.push({ key: "Priority", value: +e.target.value });
        setSelectPriority(+e.target.value);
        setFilterArray(list);
    }

    const handleClose = () => {
        setShow(false);
        setViewProductDetail(false);
    };

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch(loadClientDropDown());
        dispatch(loadUserDropdown());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        fetchComplaintdata(filterArray, PageSize, currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterArray, PageSize, currentPage]);

    const fetchComplaintdata = () => {
        if (filterArray.length !== 0) {
            dispatch(
                loadClientComplaint(
                    filterArray,
                    currentPage,
                    PageSize,
                    setIsLoading,
                    setLoading
                )
            );
        }
    };

    //* CLEAR SELECT START *//
    const clearSerachBySelect = () => {
        setFilterUser({ value: "", label: "" });
        setFilterFilterMachineStatus({ value: "", label: "" });
        setFilterClientName({ value: "", label: "" });
        setFilterCompStatus({ value: "", label: "" });
        setSelectPriority({ value: "", label: "" });
        setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
        setToDate(new Date());
        let list = [];
        // if(fromDate && toDate){
        list.push({
            key: "FromDate",
            value: moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format(
                "DD/MM/YYYY"
            ),
        });
        list.push({
            key: "ToDate",
            value: moment(new Date(toDate)).format("DD/MM/YYYY"),
        });
        // }
        setFilterArray(list);
    };
    //* CLEAR SELECT END *//

    // Open Filter div
    const [filter, setFilter] = useState(false);
    // Open Filter div

    //* EXPORT FILE START *//
    const exportComplaintDetails = () => {
        let param = "";
        if (filterArray.length !== 0) {
            filterArray.map((item, i) => {
                param = param + "&" + item.key + "=" + item.value;
            });
        }
        exportExcel(
            `${baseURL}/api/Export/ClientComplaintExcelExport?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
            "Complaints.xlsx"
        );
    };
    //* EXPORT FILE END *//

    // Page navigation
    let navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };
    // page navigation

    const [showStatus, setShowStatus] = useState(false)

    const handleShowStatus = () => {
        setShowStatus(!showStatus)
    }


    const viewServiceRequest = (id) => {
        dispatch(loadSingleClientCompalint(id));
        navigate(`/viewSeviceRequest/${id}`);
    }

    let currentTime = moment(new Date()).format("DD/MM/YYYY");

    const handleEscalationsData = () => {
        navigate('/escalations')
    }

    return (
        <div id="main-page">
            {showAssign && (
                <AssignToServiceRequest
                    showAssign={showAssign}
                    setShowAssign={setShowAssign}
                    fetchComplaintdata={fetchComplaintdata}
                />
            )}
            {/* SIDEBAR START */}
            {!showStatus && !show && <>
                <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                {/* SIDEBAR END */}

                {/* TOPBAR START */}
                <Topbar
                    toggleMenu={toggleMenu}
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />
                {/* TOPBAR END */}
                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content">
                            {/* Filter and Header Section */}
                            <div className="user-table">
                                <Row className="search-row request-page-header">
                                    <Col md={3} className="cat-col">
                                        <div className="page-header-text-div">
                                            <div className="back-btn" onClick={goToBackPage}>
                                                <img
                                                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                    alt=""
                                                />
                                            </div>
                                            <h6 className="page-header-text">SERVICE REQUEST</h6>
                                        </div>
                                    </Col>
                                    <Col md={9} className="cat-col">
                                        <div className="search-bar-header" style={{ float: "right" }}>
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                <div className="route-deviation-link" onClick={handleEscalationsData}>
                                                    <Link>
                                                        <i className="fa fa-location-arrow"></i>&nbsp;
                                                        SR Escalations
                                                    </Link>
                                                </div>
                                                {!filter && <div
                                                    className="header-filter-btn"
                                                    onClick={() => setFilter(!filter)}
                                                >
                                                    <p className="card-head">
                                                        <i className="bi bi-sliders" />
                                                        &nbsp;&nbsp;Filters
                                                    </p>
                                                </div>}

                                                <SbAddBtn
                                                    onClickEffect={() => setShow(!show)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                            {/* Filter and Header Section */}
                            <div
                                className="request-list-div"
                            // onScroll={(e) =>
                            //   handleScroll(
                            //     e,
                            //     productData,
                            //     totalCount,
                            //     setLoading,
                            //     setPageSize,
                            //     PageSize
                            //   )
                            // }
                            >
                                <Row className="request-list-div-row">
                                    <Col lg={3} md={2} className={`side-filter-col ${filter ? "resonsive-filter" : "desktop-filter"}`}>
                                        <div className="side-filter-div">
                                            <div className="side-filter-header">
                                                <p className="card-head">
                                                    <i className="bi bi-sliders" />
                                                    &nbsp;&nbsp;Filters
                                                </p>
                                                {filter && <p className="card-head close" onClick={() => setFilter(!filter)}>
                                                    <i class="fa fa-times-circle"></i>
                                                </p>}
                                            </div>
                                            <div className="filters-section" >
                                                <div className="slt-input-div">
                                                    <div className="filter-input">
                                                        <Select
                                                            className="react-select-container-list"
                                                            classNamePrefix="react-select-list"
                                                            options={clientDropdown}
                                                            placeholder="Select Customer"
                                                            value={filterClientName.value && filterClientName}
                                                            onInputChange={clientDropdown?.label}
                                                            onChange={(data) =>
                                                                setSelectSearchDrop(data, "ClientName")
                                                            }
                                                            isSearchable={true}
                                                        />
                                                    </div>
                                                    {filterClientName.value &&
                                                        <div className="filter-input">
                                                            <Select
                                                                className="react-select-container-list"
                                                                classNamePrefix="react-select-list"
                                                                options={contactByCompanyDrop}
                                                                placeholder="Reported BY"
                                                                value={filterUser.value && filterUser}
                                                                onInputChange={contactByCompanyDrop?.label}
                                                                onChange={(data) =>
                                                                    setSelectSearchDrop(data, "Reported")
                                                                }
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                    }
                                                    <div className="filter-input">
                                                        <Select
                                                            className="react-select-container-list"
                                                            classNamePrefix="react-select-list"
                                                            options={compStatus}
                                                            placeholder="Select SR Status"
                                                            value={filterCompStatus.value && filterCompStatus}
                                                            onInputChange={compStatus?.label}
                                                            onChange={(data) =>
                                                                setSelectSearchDrop(data, "status")
                                                            }
                                                            isSearchable={true}
                                                        />
                                                    </div>
                                                    {/* <div className="filter-input">
                                                        <Select
                                                            className="react-select-container-list"
                                                            classNamePrefix="react-select-list"
                                                            options={machineStatus}
                                                            placeholder="Select Machine Status"
                                                            value={filterMachineStatus.value && filterMachineStatus}
                                                            onInputChange={machineStatus.label}
                                                            onChange={(data) =>
                                                                setSelectSearchDrop(data, "MachineStatus")
                                                            }
                                                            isSearchable={true}
                                                        />
                                                    </div> */}
                                                </div>
                                                <div className="sub-filter-header">
                                                    <h6>Filter By Priority</h6>
                                                </div>
                                                <div className="slt-input-div">
                                                    <div className="filter-input">
                                                        <div className="radio-group">
                                                            {priorityStatus.map((radio, idx) => (
                                                                <Form.Check
                                                                    custom
                                                                    type="radio"
                                                                    id={`custom-radio-${idx}`}
                                                                    label={radio.label}
                                                                    value={radio.value}
                                                                    checked={selectPriority === radio.value}
                                                                    onChange={handlePrioritySearch}
                                                                    className={`customRadio ${radio.label}`}
                                                                    key={idx}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sub-filter-header">
                                                    <h6>Filter By Date</h6>
                                                </div>
                                                <div className="slt-input-div">
                                                    <div className="filter-input">
                                                        <div className="date-range">
                                                            <Form.Label className="control-form-label">From - To</Form.Label>
                                                            <div
                                                                style={{ width: "100%" }}
                                                                className="input-group from-to-date-select"
                                                            >
                                                                <span className="date-picker-with-icon">
                                                                    <DatePicker
                                                                        selected={fromDate}
                                                                        className="select from-date date-filter"
                                                                        id="date-range-picker"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        selectsRange
                                                                        startDate={fromDate}
                                                                        endDate={toDate}
                                                                        onChange={(date) =>
                                                                            setSelectSearchDrop(date, "date")
                                                                        }
                                                                        maxDate={new Date()}
                                                                    />
                                                                </span>
                                                                <span>
                                                                    <i
                                                                        className="fa fa-calendar-o calander-icon"
                                                                        style={{ right: "15px" }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="request-download-section">
                                                <SbCancelBtn
                                                    onClickEffect={clearSerachBySelect}
                                                    btnName="Clear"
                                                />
                                                <SbDownloadBtn
                                                    btnName="Download"
                                                    onClickEffect={exportComplaintDetails}
                                                />
                                            </div>
                                        </div>

                                    </Col>
                                    <Col md={10} lg={9} className="request-data-div"
                                        onScroll={(e) =>
                                            handleScroll(
                                                e,
                                                clientComplaints,
                                                totalCount,
                                                setLoading,
                                                setPageSize,
                                                PageSize
                                            )
                                        }
                                    >
                                        <Row>
                                            {isLoading ? (
                                                <ProductSkelrton />
                                            ) : (
                                                <>
                                                    {clientComplaints && clientComplaints.length === 0 ? (
                                                        <>
                                                            <Col md={12}>
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <div className="sb-product-details-div default-img">
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <img
                                                                                src="../../../images/DefaultImages/emptyProduct.svg"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <br />
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <h4>Requests not found</h4>
                                                                        </div>
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {clientComplaints &&
                                                                clientComplaints.map((data, i) => (
                                                                    <Col md={12} key={i}>
                                                                        <div className="request-box-div">
                                                                            <Row className="request-details">
                                                                                <Col
                                                                                    sm={2} className="request-id-col">
                                                                                    <div className="mi-request-id-section">
                                                                                        <h6 className="prod-name">#{data.clientComplaintsID}</h6>
                                                                                        <div className="priority-btn">
                                                                                            {data.status === 1 ? (
                                                                                                <span className="pending-status status-badge">
                                                                                                    Pending
                                                                                                </span>
                                                                                            ) : data.status === 2 ? (
                                                                                                <span className="follow-status status-badge">
                                                                                                    Follow Up
                                                                                                </span>
                                                                                            ) : data.status === 3 ? (
                                                                                                <span className="follow-status status-badge">
                                                                                                    Resolved
                                                                                                </span>
                                                                                            ) : data.status === 4 ? (
                                                                                                <span className="follow-status status-badge">
                                                                                                    Closed
                                                                                                </span>
                                                                                            ) : data.status === 5 ? (
                                                                                                <span className="follow-status status-badge">
                                                                                                    No AMC, No Warranty
                                                                                                </span>
                                                                                            ) : data.status === 6 ? (
                                                                                                <span className="follow-status status-badge">
                                                                                                    On the Way
                                                                                                </span>
                                                                                            ) : data.status === 7 ? (
                                                                                                <span className="follow-status status-badge">
                                                                                                    CheckIn
                                                                                                </span>
                                                                                            ) : data.status === 8 ? (
                                                                                                <span className="follow-status status-badge">
                                                                                                    Escalation
                                                                                                </span>
                                                                                            ) : data.status === 9 ? (
                                                                                                <span className="follow-status status-badge">
                                                                                                    Feedback
                                                                                                </span>
                                                                                            ) : data.status === 10 ? (
                                                                                                <span className="complete-status status-badge">
                                                                                                    Submitted
                                                                                                </span>
                                                                                            ) : (
                                                                                                data.status === 11 && (
                                                                                                    <span className="complete-status status-badge">
                                                                                                        Accepted
                                                                                                    </span>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={10} className="p-0" >
                                                                                    <div className="request-service-div">
                                                                                        <div style={{ width: "85%" }}>
                                                                                            <Row>
                                                                                                <Col md={6} className="p-0">
                                                                                                    <div className="details-sub-header">Customer:  <span className="prod-name">{data.clientName}</span></div>
                                                                                                    <div className="d-flex">
                                                                                                        <div className="details-sub-header">Issue:</div> &nbsp;&nbsp;
                                                                                                        <p>{data.issue}</p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col md={6} className="p-0">
                                                                                                    <div className="d-flex">
                                                                                                        <div className="details-sub-header">Machine:</div> &nbsp;&nbsp;
                                                                                                        <p>{data.productName}</p>
                                                                                                    </div>
                                                                                                    <div className="d-flex">
                                                                                                        <div className="details-sub-header">Remark:</div> &nbsp;&nbsp;
                                                                                                        <p>
                                                                                                            {data.remark ? (
                                                                                                                data.remark.length > 50
                                                                                                                    ? data.remark.substring(0, 50) + "..."
                                                                                                                    : data.remark
                                                                                                            ) : (
                                                                                                                "NA"
                                                                                                            )}
                                                                                                        </p>
                                                                                                    </div>

                                                                                                    <div className="d-flex">
                                                                                                        <div className="details-sub-header">Warrenty:</div> &nbsp;&nbsp;
                                                                                                        <b>
                                                                                                            {data.inWarranty == 1 ? (
                                                                                                                <span className="color-green">Yes</span>
                                                                                                            ) : (
                                                                                                                <span className="color-yellow">No</span>
                                                                                                            )}
                                                                                                        </b>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                        <div className="date-section-div">
                                                                                            <p>Raised on{" "}
                                                                                                {moment(currentTime, "DD/MM/YYYY").diff(
                                                                                                    moment(data.date, "DD/MM/YYYY"),
                                                                                                    "days"
                                                                                                )}{" "}
                                                                                                day(s) ago
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="service-details-div">
                                                                                <Col sm={1} className="br-right action-icons">
                                                                                    <div className="request-service-icons">
                                                                                        <OverlayTrigger
                                                                                            placement={"right"}
                                                                                            delay={{ show: 250, hide: 300 }}
                                                                                            overlay={
                                                                                                <Tooltip id={`tooltip-dashboard`}>
                                                                                                    View Request
                                                                                                </Tooltip>
                                                                                            } >
                                                                                            <div className="mi-request-action" onClick={() => viewServiceRequest(data?.clientComplaintsID)}><i className="fa fa-eye"></i></div>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={3} className="br-right">
                                                                                    <div className="request-service-icons">
                                                                                        <div className="mi-service-request-action">
                                                                                            <div className="details-sub-header">Assigned to</div>
                                                                                            <div className="details-sub-value">{data.assingnedToName}</div>
                                                                                        </div>
                                                                                        <OverlayTrigger
                                                                                            placement={"right"}
                                                                                            delay={{ show: 250, hide: 300 }}
                                                                                            overlay={
                                                                                                <Tooltip id={`tooltip-dashboard`}>
                                                                                                    Re Assign
                                                                                                </Tooltip>
                                                                                            } >
                                                                                            <div className="mi-request-action" onClick={() => handleAssign(data?.clientComplaintsID)}><span class="material-symbols-outlined">quick_phrases</span></div>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={2} className="br-right">
                                                                                    <div className="request-service-icons">
                                                                                        <div className="mi-service-request-action">
                                                                                            <div className="details-sub-header">Request date</div>

                                                                                            <div className="details-sub-value">{moment(data.date, "DD/MM/YYYY").format(
                                                                                                "DD MMM YYYY"
                                                                                            )}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={2} className="br-right">
                                                                                    <div className="request-service-icons">
                                                                                        <div className="mi-service-request-action">
                                                                                            <div className="details-sub-header">Reported By</div>
                                                                                            <div className="details-sub-value">{data.adttenedByName === " "
                                                                                                ? "-"
                                                                                                : data.adttenedByName}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={2} className="br-right">
                                                                                    <div className="request-service-icons">
                                                                                        <div className="mi-service-request-action">
                                                                                            <div className="details-sub-header">Priority</div>
                                                                                            <div className="details-sub-value">{data.priority === 3 ? (
                                                                                                <span className="pending-request">
                                                                                                    High
                                                                                                </span>
                                                                                            ) : data.priority === 2 ? (
                                                                                                <span className="follow-request">
                                                                                                    Medium
                                                                                                </span>
                                                                                            ) : (
                                                                                                data.priority === 1 && (
                                                                                                    <span className="complete-request">
                                                                                                        Low
                                                                                                    </span>
                                                                                                )
                                                                                            )}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={2} style={{ padding: "0" }}>
                                                                                    <div className="request-service-icons">
                                                                                        <div className="mi-service-request-action">
                                                                                            <div className="details-sub-header"><em>Due date</em></div>

                                                                                            <div className="due-date-details-sub-value">{moment(data.date, "DD/MM/YYYY").format(
                                                                                                "DD MMM YYYY"
                                                                                            )}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>

                                                                    </Col>
                                                                ))}
                                                            {loading && <OnScrollLoading />}

                                                            
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>}
            {showStatus && (
                <AnnualContractStatus
                    showStatus={showStatus}
                    handleShowStatus={handleShowStatus}
                />
            )}
            {show && (
                <AddServiceRequestData
                    show={show}
                    handleClose={handleClose}
                    fetchComplaintdata={fetchComplaintdata}
                />
            )}
        </div>
    );
}
