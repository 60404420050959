import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Image, Modal } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Skeleton from 'react-loading-skeleton';
import { baseURL } from '../../BaseUrl';

SwiperCore.use([FreeMode, Navigation, Thumbs]);

const DisplayFormAttachments = ({ isModalOpen, closeModal, selectedRowFields }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadDocuments, setUploadDocuments] = useState([]);

    useEffect(() => {
        const filteredUploadDocuments = selectedRowFields.filter(row => row.row.some(item => item.Label === 'Upload Document'));
        setUploadDocuments(filteredUploadDocuments);
    }, [selectedRowFields]);

    return (
        <Modal show={isModalOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Attachments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="product-details-model">
                    <div className="row">
                        <div>
                            {uploadDocuments.length !== 0 ? (
                                uploadDocuments.map((document, docIndex) => (
                                    <div key={`doc-${docIndex}`} className="product-detail-left-part">
                                        <div className="product-big-img">
                                            {isLoading ? (
                                                <div style={{ padding: "20px 0px" }}>
                                                    <Skeleton />
                                                </div>
                                            ) : (
                                                <Swiper
                                                    spaceBetween={10}
                                                    navigation={false}
                                                    thumbs={{ swiper: thumbsSwiper }}
                                                    modules={[FreeMode, Navigation, Thumbs]}
                                                    className="mySwiper2"
                                                >
                                                    {document.row.map((item, itemIndex) => (
                                                        item.Label === 'Upload Document' && Array.isArray(item.value) ? (
                                                            item.value.map((url, urlIndex) => (
                                                                <SwiperSlide key={`${item.id}-${urlIndex}`}>
                                                                    <Image
                                                                        src={`${baseURL}/${url}`}
                                                                        alt="attachment-photo"
                                                                        className="product-img"
                                                                    />
                                                                </SwiperSlide>
                                                            ))
                                                        ) : null
                                                    ))}
                                                </Swiper>
                                            )}
                                        </div>
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : (
                                            <div className="carousel-sliding-part">
                                                <Swiper
                                                    style={{
                                                        "--swiper-navigation-color": "#000000",
                                                        "--swiper-pagination-color": "#000000",
                                                        "--swiper-navigation-size": "13px",
                                                        "--swiper-navigation-sides-offset": "0px",
                                                    }}
                                                    onSwiper={setThumbsSwiper}
                                                    spaceBetween={5}
                                                    slidesPerView={4}
                                                    navigation={true}
                                                    freeMode={true}
                                                    watchSlidesProgress={true}
                                                    modules={[FreeMode, Navigation, Thumbs]}
                                                    className="mySwiper"
                                                >
                                                    {document.row.map((item, itemIndex) => (
                                                        item.Label === 'Upload Document' && Array.isArray(item.value) ? (
                                                            item.value.map((url, urlIndex) => (
                                                                <SwiperSlide key={`${item.id}-${urlIndex}`}>
                                                                    <div className="product-small-images-row">
                                                                        <div className="product-small-image">
                                                                            <Image
                                                                                src={`${baseURL}/${url}`}
                                                                                alt="attachment-photo"
                                                                                className="product-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))
                                                        ) : null
                                                    ))}
                                                </Swiper>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div style={{ padding: "20px 20px" }}>
                                    <img
                                        src="../../../../images/DefaultImages/NodataFound.jpg"
                                        alt="No data found"
                                        style={{ width: "10%" }}
                                    />
                                    <br />
                                    <span>No Attachments Found...!</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DisplayFormAttachments;
