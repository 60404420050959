import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Leaves.scss";
import { FloatingLabel, Form } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { useDispatch, useSelector } from "react-redux";
import { loadUserDropdown } from "../../redux/action";
import { toast } from "react-toastify";
import { travelizeAuth } from "../HeaderAuthentication";

function AssignToRouteModel(props) {
  const loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const subscriptionID = loginDetails.subscriptionId;

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);

  const [routeName, setRouteName] = useState("");
  const [filterUserId, setFilterUserId] = useState("");
  const [routeType, setRouteType] = useState(null);
  const [frequency, setFrequency] = useState("None");
  const [ownText, setOwnText] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [routeTypeDetails, setRouteTypeDetails] = useState("");
  const [assignedUserval, setAssignedUserval] = useState("");

  useLayoutEffect(() => {
    dispatch(loadUserDropdown());
  }, []);

  useEffect(() => {
    if (props.viewRouteID !== null) {
      let requestOption = {
        method: "GET",
        mode: "cors",
        headers: travelizeAuth,
      };

      fetch(`${baseURL}/api/Route/Get/${props.viewRouteID}`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            console.log("ROUTE-DETAILS::", res.result);
            setRouteName(res.result.name);
            setFrequency(res.result.frequency);
            setOwnText(
              res.result.routeType == "Other" ? res.result.routeTypeDetails : ""
            );
            setFromDate(res.result.fromDate);
            setToDate(res.result.toDate);
            setFilterUserId(res.result.addedByID);
            setRouteType(res.result.routeType);
            setRouteTypeDetails(res.result.routeTypeDetails);
            // Parse the input date string into a JavaScript Date object
          } else {
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
        });
    }
  }, [props.viewRouteID]);

  useEffect(() => {
    if (filterUserId !== "" && filterUserId !== null) {
      userDropdown.map((data) => {
        if (filterUserId == data.value) {
          setAssignedUserval(data);
        }
      });
    }
  }, [filterUserId]);

  // UPDATE LEAVE STATUS START
  const updateReAssignToUser = (e) => {
    e.preventDefault();
    let newVisitData = {
      routeID: props.viewRouteID,
      subscriptionID: subscriptionID,
      name: routeName,
      frequency: frequency,
      addedByID: filterUserId,
      status: 1,
      fromDate: fromDate,
      toDate: toDate,
      routeClient: [],
      routeType: routeType,
      routeTypeDetails: routeTypeDetails,
    };
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newVisitData),
    };
    fetch(`${baseURL}/api/Route/UpdateRoute`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          props.handleClose();
          props.fetchRoutePlans();
          swal.showAlert("Success", `Route Assigned ${res.message}`, "success");
        } else {
          toast.error(`${res.errorMessage}`);
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
      });
  };
  // UPDATE LEAVE STATUS END

  return (
    <div>
      <Modal
        show={props.assignToRouteModel}
        // onHide={props.handleClose}
        className="main_model"
      >
        <div className="update-leavestatus-heading">
          <h6 className="page-header-text">ASSIGN TO</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="update-leave-status">
              <div className="select-status mb-2">
                <FloatingLabel
                  label="Select User"
                  className={`${
                    assignedUserval
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={userDropdown}
                    placeholder="Select User"
                    value={assignedUserval}
                    onInputChange={userDropdown.label}
                    onChange={(data) => setFilterUserId(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn
            onClickEffect={updateReAssignToUser}
            btnName="Update"
          />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}

export default AssignToRouteModel;
