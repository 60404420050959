import React, { useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Complaints.scss";
import "../../styles/ClientComplaint.scss";
import { Col, Form, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import {
  compStatus,
  optionsPageSizeList,
  priorityStatus,
} from "../DropdownsData";
import AddComplaint from "./AddComplaint";
import EditComplaint from "./EditComplaint";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadClientComplaint,
  loadClientDropDown,
  loadOtherContacts,
  loadPageBreadCome,
  loadSingleClientCompalint,
  loadUserDropdown,
} from "../../redux/action";
import ComplaintTable from "./ComplaintTable";
import StatusUpdatePage from "./StatusUpdatePage";
import AssignToComplaint from "./AssignToComplaint";
import SbAddBtn, {
  SBSaveUpdateBtn,
  SbDownloadBtn,
} from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import DatePicker from "react-datepicker";
import moment from "moment";
import { exportExcel } from "../DownloadFile";
import { baseURL } from "../BaseUrl";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";

// import ViewProductDetails from "./ViewProductDetails";

export default function Complaints() {
  let dispatch = useDispatch();
  const { clientComplaints } = useSelector((state) => state.data);
  const { clientDropdown } = useSelector((state) => state.data);
  const { contactByCompanyDrop } = useSelector((state) => state.data);

  const [isLoading, setIsLoading] = useState(true);

  let tableHeader = [
    { name: "Complaint #", align: "center", width: "100px" },
    { name: "Date", align: "left", width: "100px" },
    { name: "Company / Reported By", align: "left", width: "200px" },
    // { name: "Reported By", align: "left", width: "180px" },
    { name: "Contact Details", align: "left", width: "200px" },
    { name: "Product", align: "left", width: "200px" },
    { name: "Issue", align: "left", width: "200px" },
    { name: "Priority", align: "center", width: "100px" },
    { name: "InWarranty", align: "center", width: "100px" },
    { name: "Status", align: "center", width: "120px" },
    { name: "Assigned To", align: "left", width: "150px" },
    { name: "Addressed By", align: "left", width: "150px" },
    { name: "TAT", align: "center", width: "80px" },
    { name: "Remarks", align: "left", width: "200px" },
    { name: "Action", align: "center", width: "120px" },
  ];

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [viewProductDetail, setViewProductDetail] = useState(false);

  const [showUpdate, setShowUpdate] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [compaintId, setCompaintId] = useState("");

  const handleStatus = (id) => {
    setCompaintId(id);
    dispatch(loadSingleClientCompalint(id));
    setShowUpdate(true);
  };

  const handleAssign = (id) => {
    setCompaintId(id);
    dispatch(loadSingleClientCompalint(id));
    setShowAssign(true);
  };

  const handleEditCompleint = (id) => {
    setCompaintId(id);
    dispatch(loadSingleClientCompalint(id));
    setShow(true);
  };

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

  const [searchValue, setSearchValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const [filterCompStatus, setFilterCompStatus] = useState({
    value: "",
    label: "",
  });
  const [filterUser, setFilterUser] = useState({ value: "", label: "" });
  const [filterClientName, setFilterClientName] = useState({
    value: "",
    label: "",
  });
  const [selectPriority, setSelectPriority] = useState({
    value: "",
    label: "",
  });
  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());
  // PAGINATION END

  useEffect(() => {
    let breadCome = { mainpage: "Complaints" };
    dispatch(loadPageBreadCome(breadCome));
    let list = [];
    if (fromDate && toDate) {
      list.push({
        key: "FromDate",
        value: moment(new Date(fromDate)).format("DD/MM/YYYY"),
      });
      list.push({
        key: "ToDate",
        value: moment(new Date(toDate)).format("DD/MM/YYYY"),
      });
    }
    setFilterArray(list);
  }, [fromDate, toDate]);

  const setSearchData = (value) => {
    let list = [];
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Text");
    }
    if (value) {
      list.push({ key: "Text", value: value });
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "Reported") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ContactPersonID");
      }
      list.push({ key: "ContactPersonID", value: data.value });
      setFilterUser(data);
    } else if (type === "status") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "Status");
      }
      list.push({ key: "Status", value: data.value });
      setFilterCompStatus(data);
    } else if (type === "ClientName") {
      setFilterUser({ value: "", label: "" });
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ClientID");
      }
      list.push({ key: "ClientID", value: data.value });
      dispatch(loadOtherContacts(data.value));
      setFilterClientName(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    } else if (type === "priority") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "Priority");
      } else {
        list = [];
      }
      list.push({ key: "Priority", value: data.value });
      setSelectPriority(data);
    }
    setFilterArray(list);
  };

  const handleClose = () => {
    setShow(false);
    setViewProductDetail(false);
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(loadClientDropDown());
    dispatch(loadUserDropdown());
  }, []);
  useEffect(() => {
    fetchComplaintdata(filterArray, PageSize, currentPage);
  }, [filterArray, PageSize, currentPage]);

  const fetchComplaintdata = () => {
    if (filterArray.length !== 0) {
      dispatch(
        loadClientComplaint(
          filterArray,
          currentPage,
          PageSize,
          setIsLoading,
          setLoading
        )
      );
    }
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUser({ value: "", label: "" });
    setFilterClientName({ value: "", label: "" });
    setFilterCompStatus({ value: "", label: "" });
    setSelectPriority({ value: "", label: "" });
    setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    setToDate(new Date());
    let list = [];
    // if(fromDate && toDate){
    list.push({
      key: "FromDate",
      value: moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format(
        "DD/MM/YYYY"
      ),
    });
    list.push({
      key: "ToDate",
      value: moment(new Date(toDate)).format("DD/MM/YYYY"),
    });
    // }
    setFilterArray(list);
  };
  //* CLEAR SELECT END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  //* EXPORT FILE START *//
  const exportComplaintDetails = () => {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        param = param + "&" + item.key + "=" + item.value;
      });
    }
    exportExcel(
      `${baseURL}/api/Export/ClientComplaintExcelExport?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      "Complaints.xlsx"
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {show && (
        <AddComplaint
          show={show}
          handleClose={handleClose}
          fetchComplaintdata={fetchComplaintdata}
          compaintId={compaintId}
          setCompaintId={setCompaintId}
        />
      )}
      {showUpdate && (
        <StatusUpdatePage
          showUpdate={showUpdate}
          setShowUpdate={setShowUpdate}
          setCompaintId={setCompaintId}
          compaintId={compaintId}
          fetchComplaintdata={fetchComplaintdata}
        />
      )}

      {showAssign && (
        <AssignToComplaint
          showAssign={showAssign}
          setShowAssign={setShowAssign}
          setCompaintId={setCompaintId}
          fetchComplaintdata={fetchComplaintdata}
        />
      )}
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            {/* Filter and Header Section */}
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">COMPLAINTS</h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div
                        className="header-filter-btn"
                        onClick={() => setFilter(!filter)}
                      >
                        <p className="card-head">
                          <i className="bi bi-sliders" />
                          &nbsp;&nbsp;Filters
                        </p>
                      </div>
                      <SbAddBtn onClickEffect={() => setShow(!show)} />
                    </div>
                  </div>
                </Col>
              </Row>

              {filter && (
                <div className="page-filter-section">
                  <div style={{ width: "100%" }}>
                    <Row>
                      <Col md={12}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={clientDropdown}
                              placeholder="Select Company"
                              value={filterClientName.value && filterClientName}
                              onInputChange={clientDropdown.label}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "ClientName")
                              }
                              isSearchable={true}
                            />
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={contactByCompanyDrop}
                              placeholder="Reported BY"
                              value={filterUser.value && filterUser}
                              onInputChange={contactByCompanyDrop.label}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "Reported")
                              }
                              isSearchable={true}
                            />
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={priorityStatus}
                              placeholder="Select Priority"
                              value={selectPriority.value && selectPriority}
                              onInputChange={priorityStatus.label}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "priority")
                              }
                              isSearchable={true}
                            />
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            {/* {loggedInRole != 2 && ( */}
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={compStatus}
                              placeholder="Select Status"
                              value={filterCompStatus.value && filterCompStatus}
                              onInputChange={compStatus.label}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "status")
                              }
                              isSearchable={true}
                            // isClearable={true}
                            />
                            {/* )} */}
                          </div>
                          <div
                            style={{ width: "100%", padding: "0px 5px" }}
                            className="input-group from-to-date-select"
                          >
                            <span className="input-group-text" id="from-label">
                              From - To
                            </span>
                            <span className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date date-filter"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                selectsRange
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) =>
                                  setSelectSearchDrop(date, "date")
                                }
                                maxDate={new Date()}
                              />
                            </span>
                            <span>
                              <i
                                className="fa fa-calendar-o calander-icon"
                                style={{ right: "15px" }}
                              />
                            </span>
                          </div>
                          <div
                            style={{ width: "100%", padding: "0px 5px" }}
                            className="input-group from-to-date-select"
                          >
                            <SbCancelBtn
                              onClickEffect={clearSerachBySelect}
                              btnName="Clear"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
            {/* Filter and Header Section */}

            <ComplaintTable
              tableHeader={tableHeader}
              clientComplaints={clientComplaints}
              isLoading={isLoading}
              handleStatus={handleStatus}
              handleAssign={handleAssign}
              handleEditCompleint={handleEditCompleint}
              loading={loading}
              setPageSize={setPageSize}
              PageSize={PageSize}
              setLoading={setLoading}
            />
            {/* PAGINATION START */}
            <div className="download-and-pagination">
              <SbDownloadBtn
                btnName="Download"
                onClickEffect={exportComplaintDetails}
              />
            </div>
            {/* PAGINATION END */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
