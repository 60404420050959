import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { baseURL } from "../../BaseUrl";
import "../../../styles/CommonStyle.scss";
import "../../../styles/CustomForms.scss";
import { Col, Row, Form, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AccessDenied from "../../../AccessDenied";
import CreateForm from "./CreateForm";
import CreatedForms from "./CreatedForms";
import { Tabs, Tab } from "react-bootstrap";

export default function CustomForm() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div>
      <div id="main-page">
        {userDetails !== null && userDetails !== "" ? (
          <>
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
              <div className={`main-body ${toggleshift.style}`}>
                {/* PAGE CONTENT START */}
                <div className="page-content">
                  {/* <Row className="search-row"> */}

                  <Tabs
                    defaultActiveKey="createown"
                    id="uncontrolled-tab-example"
                    className="local-claim-nav-tab"
                  >
                    <Tab
                      eventKey="back"
                      title={
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                      }
                    >
                      Back
                    </Tab>
                    <Tab title="CUSTOM FORM" className="local-claim-head">
                      CUSTOM FORM
                    </Tab>
                    <Tab eventKey="createown" title="Create Form">
                      <CreateForm />
                    </Tab>

                    <Tab eventKey="createdforms" title="Created Forms">
                      <CreatedForms />
                    </Tab>
                  </Tabs>

                  {/* </Row> */}
                </div>
                {/* PAGE CONTENT END*/}
              </div>
              {/* MAIN BODY END */}
            </div>
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
    </div>
  );
}
