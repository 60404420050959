import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import productimg from "../../images/DefaultImages/emptyProduct.svg";
import { priceRange } from "../DropdownsData";
import AddProductmodel from "./AddProductmodel";
import {
  loadPageBreadCome,
  loadProdCategoryDrop,
  loadProdSubCategory,
  loadProductsData,
  loadProductsDropDown,
  loadSearchProducts,
  loadSingleProduct,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn, {
  SBSaveUpdateBtn,
  SbDownloadBtn,
} from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import { useRef } from "react";
import { baseURL } from "../BaseUrl";
import ProductSkelrton from "./ProductSkelrton";
import { FormattedAmount } from "../FormattedAmount";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import Footer from "../Footer";
import { exportExcel } from "../DownloadFile";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import AddBulkProducts from "./AddBulkProducts";

function Products() {
  const divRef = useRef(null);

  let cartItems = JSON.parse(sessionStorage.getItem("cartItems"));
  const AdminAccess = sessionStorage.getItem("AdminAccess");
  let dispatch = useDispatch();

  const { prodCategoryDrop } = useSelector((state) => state.data);
  const { prodSubcategory } = useSelector((state) => state.data);
  const { productData } = useSelector((state) => state.data);
  const { totalCount } = useSelector((state) => state.data);

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [addBulkUserModel, setAddBulkUserModel] = useState(false);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [viewProductDetail, setViewProductDetail] = useState(false);

  const [selectCategory, setSelectCategory] = useState("");
  const [selectSubCategory, setSelectSubCategory] = useState("");
  const [selectPrice, setSelectPrice] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [PageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [pageDataSize, setPageDataSize] = useState(0);
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // console.table(
  //   productData
  //     .filter((data) => data.image == "")
  //     .map((item) => item.productName)
  // );

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Text");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "Text", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "Text");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const [priceOther, setPriceOther] = useState(false);
  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "category") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "CategoryID");
      } else {
        list = [];
      }
      list.push({ key: "CategoryID", value: data.value });
      dispatch(loadProdSubCategory(data.value));
      setSelectCategory(data.value);
    } else if (type === "subcategory") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "SubCateogoryID");
      } else {
        list = [];
      }
      list.push({ key: "SubCateogoryID", value: data.value });
      setSelectSubCategory(data.value);
    } else if (type === "price") {
      console.log(data);
      if (data.value === "Other") {
        if (filterArray.length !== 0) {
          list = filterArray.filter(
            (item) => item.key !== "StartPrie" && item.key !== "EndPrice"
          );
        } else {
          list = [];
        }
        setSelectPrice(data.value);
        setPriceOther(true);
      } else {
        let prices = data.value.split(",");
        if (filterArray.length !== 0) {
          list = filterArray.filter(
            (item) => item.key !== "StartPrie" && item.key !== "EndPrice"
          );
        } else {
          list = [];
        }
        list.push({ key: "StartPrie", value: parseInt(prices[0]) });
        list.push({ key: "EndPrice", value: parseInt(prices[1]) });
        setSelectPrice(data.value);
      }
    }
    setFilterArray(list);
  };

  const handleProductInput = (e, type) => {
    let list = [];
    if (type === "StartPrie") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "StartPrie");
      } else {
        list = [];
      }

      if (e.target.value) {
        list.push({ key: "StartPrie", value: parseInt(e.target.value) });
      }
    } else if (type === "EndPrice") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "EndPrice");
      } else {
        list = [];
      }

      if (e.target.value) {
        list.push({ key: "EndPrice", value: parseInt(e.target.value) });
      }
    }
    setFilterArray(list);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "Products" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadProductsDropDown());
    dispatch(loadProdCategoryDrop());
  }, []);

  useEffect(() => {
    fetchProductData(filterArray, PageSize, currentPage);
  }, [filterArray, PageSize, currentPage]);

  const fetchProductData = () => {
    if (filterArray.length === 0) {
      dispatch(
        loadProductsData(currentPage, PageSize, setIsLoading, setLoading)
      );
    } else {
      setIsLoading(true);
      dispatch(
        loadSearchProducts(
          filterArray,
          currentPage,
          PageSize,
          setIsLoading,
          setLoading
        )
      );
    }
  };

  const handleClose = () => {
    setShow(false);
    setViewProductDetail(false);
    dispatch(loadSingleProduct(null));
  };

  const handleCloseBulkUserModel = () => {
    setAddBulkUserModel(!addBulkUserModel);
  };

  let navigate = useNavigate();
  const openViewPage = (prodId) => {
    navigate(`/products/${prodId}`);
    dispatch(loadSingleProduct(prodId));
    setViewProductDetail(true);
  };

  // edit product button function
  const [productId, setProductId] = useState("");
  const editProducts = (prodId) => {
    dispatch(loadSingleProduct(prodId));
    setProductId(prodId);
    setShow(!show);
  };
  // edit product button function

  // Add to Wish List Function
  const addToWishList = () => {
    // alert("add to wist list")
  };
  // Add to Wish List Function

  // Select Products for create List
  const [createList, setCreateList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedIds([...selectedIds, id]);
      productData.map((item) => {
        if (item.productID === id) {
          // item.ckeckstatus = true
          setCreateList([...createList, item]);
        }
      });
    } else {
      setSelectedIds(selectedIds.filter((item) => item !== id));
      setCreateList(createList.filter((item) => item.productID !== id));
    }
  };

  useEffect(() => {
    if (createList.length !== 0) {
      sessionStorage.setItem("cartItems", JSON.stringify(createList));
    } else {
      sessionStorage.removeItem("cartItems");
    }
  }, [createList]);

  useEffect(() => {
    if (cartItems && cartItems.length !== 0) {
      let sessiondata = [];
      let sessionId = [];
      cartItems.map((item) => {
        sessionId.push(item.productID);
        sessiondata.push(item);
      });
      setCreateList(sessiondata);
      setSelectedIds(sessionId);
    }
  }, []);

  // Select Products for create List

  // Navigate to Cart Page
  const createListPage = () => {
    navigate("/Cartpage");
  };
  // Navigate to Cart Page

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  // Clear btn
  const clearSerachBySelect = () => {
    setSearchValue("");
    setSelectCategory("");
    setSelectSubCategory("");
    setFilterArray("");
    setFilter(false);
    setPriceOther(false);
  };
  // Clear btn

  //* EXPORT FILE START *//
  const exportComplaintDetails = () => {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        param = param + "&" + item.key + "=" + item.value;
      });
    }
    exportExcel(
      `${baseURL}/api/Export/ProductExcelExport?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      "Products.xlsx"
    );
  };
  //* EXPORT FILE END *//

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      PRODUCTS ({totalCount})
                    </h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col">
                  <div className="input-div">
                    <input
                      type="search"
                      id="metricInfo_search_bar"
                      placeholder="Find a Product"
                      className="form-control setting-input"
                      value={searchValue}
                      onChange={(e) => setSearchData(e.target.value)}
                    />
                    <i className="fa fa-search search-icon" />
                  </div>
                </Col>
                <Col md={4} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div
                        className="header-filter-btn"
                        onClick={() => setFilter(!filter)}
                      >
                        <p className="card-head">
                          <i className="bi bi-sliders" />
                          &nbsp;&nbsp;Filters
                        </p>
                      </div>
                      {/* <SbAddBtn onClickEffect={() => setShow(!show)} /> */}

                      <SbDropDownBtn
                        openAddModal={() => setShow(!show)}
                        pageName="Product"
                        addBulkModel={handleCloseBulkUserModel}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {filter && (
                <div className="page-filter-section">
                  <div style={{ width: "100%" }}>
                    <Row>
                      <Col md={priceOther ? 10 : 8}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={prodCategoryDrop}
                              placeholder="Select Category"
                              value={
                                selectCategory === ""
                                  ? ""
                                  : prodCategoryDrop.value
                              }
                              onInputChange={prodCategoryDrop.label}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "category")
                              }
                              isSearchable={true}
                            />
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={prodSubcategory}
                              placeholder="Select SubCategory"
                              value={
                                selectSubCategory === ""
                                  ? ""
                                  : prodSubcategory.value
                              }
                              onInputChange={prodSubcategory.label}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "subcategory")
                              }
                              isSearchable={true}
                              isDisabled={!selectCategory}
                              // isClearable={true}
                            />
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={priceRange}
                              placeholder="Select Price Range"
                              value={selectPrice === "" ? "" : priceRange.value}
                              onInputChange={priceRange.label}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "price")
                              }
                              isSearchable={true}
                              // isClearable={true}
                            />
                          </div>
                          {priceOther && (
                            <>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                                className="input-group from-to-date-select"
                              >
                                {/* <span
                                        className="input-group-text"
                                        id="from-label"
                                      >
                                        From
                                      </span> */}
                                <span className="date-picker-with-icon">
                                  <input
                                    type="text"
                                    className="form-control form add-product-input"
                                    placeholder="Enter Start Price"
                                    name="startPrice"
                                    // readOnly={true}
                                    // value={productName || ''}
                                    onChange={(e) =>
                                      handleProductInput(e, "StartPrie")
                                    }
                                  />
                                </span>
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                                className="input-group from-to-date-select"
                              >
                                {/* <span
                                        className="input-group-text"
                                        id="from-label" >
                                        To
                                      </span> */}
                                <span className="date-picker-with-icon">
                                  <input
                                    type="text"
                                    className="form-control form add-product-input"
                                    placeholder="Enter End Price"
                                    name="endPrice"
                                    // readOnly={true}
                                    // value={productName || ''}
                                    onChange={(e) =>
                                      handleProductInput(e, "EndPrice")
                                    }
                                  />
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <SbCancelBtn
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

              {/* Products List */}
              <div
                className="product-list-div"
                onScroll={(e) =>
                  handleScroll(
                    e,
                    productData,
                    totalCount,
                    setLoading,
                    setPageSize,
                    PageSize
                  )
                }
              >
                <Row>
                  {isLoading ? (
                    <ProductSkelrton />
                  ) : (
                    <>
                      {productData && productData.length === 0 ? (
                        <>
                          <Col md={12}>
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="sb-product-details-div default-img">
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src="../../../images/DefaultImages/emptyProduct.svg"
                                    alt=""
                                  />
                                </div>
                                <br />
                                <div className="d-flex align-items-center justify-content-center">
                                  <h4>Products not found</h4>
                                </div>
                                <br />
                                <div className="d-flex align-items-center justify-content-center">
                                  <SBSaveUpdateBtn
                                    btnName="Add Product"
                                    onClickEffect={() => setShow(!show)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <>
                          {productData &&
                            productData.map((item, i) => (
                              <Col md={6} key={i}>
                                <div className="product-box-div">
                                  <Row className="product-details">
                                    <Col
                                      md={3}
                                      style={{
                                        maxHeight: "160px",
                                        minHeight: "100px",
                                      }}
                                    >
                                      <div
                                        onClick={() =>
                                          openViewPage(item.productID)
                                        }
                                        className="product-display-img"
                                      >
                                        <img
                                          src={
                                            item.image
                                              ? `${baseURL}/${item.image}`
                                              : productimg
                                          }
                                          className="product-img"
                                          alt=""
                                        />
                                      </div>
                                    </Col>
                                    <Col md={7}>
                                      <div>
                                        {/* <h4 className='prod-name' onClick={() => openView(item.productID)}>{item.productName}</h4> */}
                                        <h4
                                          className="prod-name"
                                          onClick={() =>
                                            openViewPage(item.productID)
                                          }
                                        >
                                          {item.productName}
                                        </h4>
                                        <p>
                                          {item.description.length > 80
                                            ? item.description.substring(
                                                0,
                                                80
                                              ) + "..."
                                            : item.description}
                                        </p>
                                      </div>
                                      <div className="price-text-div">
                                        <div className="price-div">
                                          Price:{" "}
                                          <span className="price-text">
                                            <i
                                              className="fa fa-inr"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            {FormattedAmount(item.price)}
                                          </span>
                                        </div>
                                        <div style={{ fontSize: "11px" }}>
                                          <i
                                            className="fa fa-inr"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {FormattedAmount(item.mrp)}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="sb-product-details-div">
                                        <div
                                          ref={divRef}
                                          className="product-details-section"
                                        >
                                          <h4>Details</h4>
                                          <p>{item.category?.name}</p>
                                          <p>{item.subCategory?.name}</p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col
                                      md={2}
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                      }}
                                    >
                                      {/* <div className="select-section">
                            <div className="icon-div-section"><input
                            type="checkbox"
                            className="select-check"
                            id={item.productID}
                            value={item.productName}
                            checked={selectedIds.includes(item.productID)}
                            onChange={(e) => handleCheckboxChange(e, item.productID)}
                          /> &nbsp;&nbsp;Add to cart
                          </div>
                          <div className="buy-btn-div">
                            <SbBuyNowBtn bntName="Buy Now" />
                          </div>
                        </div> */}
                                    </Col>

                                    {/* <div className="wish-list-section">
                          <div className="icon-div-section" onClick={addToWishList}><i style={{float: "right", cursor: "pointer"}} className="fa fa-heart-o" aria-hidden="true"></i></div>
                      </div> */}
                                  </Row>
                                  {AdminAccess && (
                                    <div className="hover-section">
                                      <div>
                                        <div
                                          className="edit-btn hover-badge"
                                          onClick={() =>
                                            editProducts(item.productID)
                                          }
                                        >
                                          <i className="fa fa-edit"></i>{" "}
                                          &nbsp;&nbsp;Edit
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            ))}
                          {loading && <OnScrollLoading />}
                        </>
                      )}
                    </>
                  )}
                </Row>
              </div>
            </div>
            {/* Products List */}

            {/* ADD PRODUCT MODEL START */}
            {show && (
              <AddProductmodel
                show={show}
                handleClose={handleClose}
                productId={productId}
                setProductId={setProductId}
                fetchProductData={fetchProductData}
              />
            )}
            {/* ADD PRODUCT MODEL END */}
            {/* ADD BULK  MODEL START */}
            {addBulkUserModel ? (
              <AddBulkProducts
                addBulkUserModel={addBulkUserModel}
                handleCloseBulkUserModel={handleCloseBulkUserModel}
              />
            ) : (
              ""
            )}
            {/* ADD BULK MODEL END */}
          </div>
          <div className="download-and-pagination">
            <SbDownloadBtn
              btnName="Download"
              onClickEffect={exportComplaintDetails}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Products;
