import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, FloatingLabel, Form, ModalFooter, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { loadClientStageGrop, loadReferredByDropdowndata, loadSubscriptionDropdown, loadUpdateSubscription } from '../../redux/action'
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import SbCancelBtn from '../SbButtons/SbCancelBtn';
import DatePicker from "react-datepicker";

const UpGradeSubscriptionModal = (props) => {

  let dispatch = useDispatch();
  const { subscriptionTypeDropdown } = useSelector((state) => state.data);
  const { getReferredByDropdownData } = useSelector((state) => state.data);
  const { stageDropdown } = useSelector((state) => state.data);
  const [updatedSub, setUpdatedSub] = useState({ value: "", Label: "" });
  const [subScriptionTypeID, setSubscriptionTypeID] = useState()
  const [userCount, setUserCount] = useState(0)
  const [expiryDate, setExpiryDate] = useState("")
  const [leadStage, setLeadStage] = useState({ value: "", label: "" });
  const [BDEName, setBDEName] = useState({ value: "", label: "" });
  const [paymentReceived, setpaymentReceived] = useState();
  const [showSubscriptionSelect, setShowSubscriptionSelect] = useState(false);
  const [showUserCountInput, setShowUserCountInput] = useState(false);
  const [showExpiryDatePicker, setShowExpiryDatePicker] = useState(false);

  const handleUpgradeSubscription = () => {
    setShowSubscriptionSelect(!showSubscriptionSelect)
  }
  const handleUpgradeUserCount = () => {
    setShowUserCountInput(!showUserCountInput)
  }
  const handleUpgradeExpiryData = () => {
    setShowExpiryDatePicker(!showExpiryDatePicker)
  }
  const closeModal = () => {
    props.setSubScribeId();
    props.handleClose(); // Close the modal
  };

  useEffect(() => {
    dispatch(loadSubscriptionDropdown());
    dispatch(loadReferredByDropdowndata());
    dispatch(loadClientStageGrop());
  }, []);

  const handleSelectChange = (data) => {
    setUpdatedSub(data)
    setSubscriptionTypeID(data.value)
  }

  const [showAlert, setShowAlert] = useState(false)
  // const postUpdateSub = (e) => {
  //   e.preventDefault();
  //   if (subScriptionTypeID && !props.subscriptionId) {
  //     let updateData = {
  //       subscriptionTypeID: parseInt(subScriptionTypeID),
  //       subscriptionId: props.subscriptionId,
  //       endDate: expiryDate,
  //       maxUserCount: parseInt(userCount)
  //     }
  //     console.log(updateData, "updateData")
  //     let postSubscription = {
  //       method: "POST",
  //       mode: "cors",
  //       headers: HeaderAuthentication.postTravelizeAuth,
  //       body: JSON.stringify(updateData),
  //     };
  //     dispatch(loadUpdateSubscription(postSubscription, closeModal))
  //   } else {
  //     setShowAlert(true)
  //   }
  // }

  const postUpdateSub = (e) => {
    e.preventDefault();
    if (
      (showSubscriptionSelect && !subScriptionTypeID) ||
      (showUserCountInput && !userCount) ||
      (showExpiryDatePicker && !expiryDate) ||
      (!showSubscriptionSelect && !showUserCountInput && !showExpiryDatePicker)
    ) {
      setShowAlert(true);
    } else {
      let updateData = {
        subscriptionTypeID: parseInt(subScriptionTypeID),
        subscriptionId: props.subscriptionId,
        endDate: expiryDate,
        maxUserCount: parseInt(userCount)
      };
      let postSubscription = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.postTravelizeAuth,
        body: JSON.stringify(updateData)
      };
      dispatch(loadUpdateSubscription(postSubscription, closeModal));
      setShowAlert(false);
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} className="category-modal-div">
      <Row className='modale-header-div'>
        <Col md={6} xs={9} className='cat-col'>
          <h6 className='page-header-text'>Edit Subscription</h6>
        </Col>
        <Col md={6} xs={3} className='cat-col' align="right">

          <button onClick={closeModal} className='bi bi-x close-popup'></button>
        </Col>
      </Row>
      <hr />
      <div className='cat-col'>
        <Form onSubmit={postUpdateSub}>
          <div className='form-field'>
            <div className='subscription-check-box-div'>
              <input type="checkbox" onClick={handleUpgradeSubscription} />
              <span>Upgrade Subscription</span>
            </div>
            {showSubscriptionSelect && (
              <FloatingLabel label='Select Subscription' className={`${updatedSub.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  // value={!updatedSub.value ? "" : subscriptionTypeDropdown.value}
                  value={updatedSub.value && updatedSub}
                  // onInputChange={subscriptionTypeDropdown.label}
                  onChange={(data) => { handleSelectChange(data) }}
                  options={subscriptionTypeDropdown}
                  placeholder="Select Subscription"
                  isSearchable={true}
                />
              </FloatingLabel>)}
            {(showAlert === true && showSubscriptionSelect && !subScriptionTypeID) && (
              <small
                id={`Error`}
                className="form-text text-muted "
              >Please Select Subscription
              </small>
            )}
          </div >

          <div className='form-field'>
            <div className='subscription-check-box-div'>
              <input type="checkbox" onClick={handleUpgradeUserCount} />
              <span>Upgrade User count</span>
            </div>
            {showUserCountInput && (
              <FloatingLabel label='Enter Number' className={`${userCount !== "" ? "float-input-visible" : "float-hidden float-input"}`}>
                <input
                  type="number"
                  name="usercount"
                  className="form-control add-user-input"
                  placeholder="Enter Number"
                  value={userCount}
                  onChange={(e) => { setUserCount(e.target.value) }}
                />
              </FloatingLabel>)}
            {(showAlert === true && showUserCountInput && !userCount) && (
              <small
                id={`Error`}
                className="form-text text-muted "
              >Please Enter User Count
              </small>
            )}
          </div >
          <div className='form-field'>
            <div className='subscription-check-box-div'>
              <input type="checkbox" onClick={handleUpgradeExpiryData} />
              <span>Upgrade Expiry date</span>
            </div>
            {showExpiryDatePicker && (
              <FloatingLabel label='Service Request Date' className={`${expiryDate ? "float-visible-select" : "float-hidden float-select"}`}>
                <DatePicker
                  selected={expiryDate}
                  className={`form-control form add-input meeting-date`}
                  dateFormat="dd/MM/yyyy"
                  placeholder="Service Request Date"
                  onChange={(date) => setExpiryDate(date)}
                  minDate={new Date()}
                />
              </FloatingLabel>)}
            {(showAlert === true && showExpiryDatePicker && !expiryDate) && (
              <small
                id={`Error`}
                className="form-text text-muted "
              >Please Select Expiry Date
              </small>
            )}
          </div >
          <div className='form-field'>
            <FloatingLabel label='Lead Stage' className={`${leadStage.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
              <Select
                className="react-select-container-list model-select"
                classNamePrefix="react-select-list"
                // value={!updatedSub.value ? "" : subscriptionTypeDropdown.value}
                // onInputChange={subscriptionTypeDropdown.label}
                // onChange={(data) => { handleSelectChange(data) }}
                options={stageDropdown}
                placeholder="Lead Stage"
                isSearchable={true}
              />
            </FloatingLabel>
          </div >
          <div className='form-field'>
            <FloatingLabel label='BDE Name' className={`${BDEName.value !== '' ? "float-visible-select" : "float-hidden float-select"}`}>
              <Select
                className="react-select-container-list model-select"
                classNamePrefix="react-select-list"
                // value={!updatedSub.value ? "" : subscriptionTypeDropdown.value}
                // onInputChange={subscriptionTypeDropdown.label}
                // onChange={(data) => { handleSelectChange(data) }}
                options={getReferredByDropdownData}
                placeholder="BDE Name"
                isSearchable={true}
              />
            </FloatingLabel>
          </div >
          <div className='form-field'>
            <FloatingLabel
              label="Payment Received"
              className={`${paymentReceived
                ? "float-input-visible"
                : "float-hidden float-input"
                }`}
            >
              <input
                type="text"
                className="form-control add-user-input"
                placeholder="Payment Received"
                name="productCode"
              // value={machineCode}
              // onChange={(e) => { setMachineCode(e.target.value) }}
              />
            </FloatingLabel>
          </div >
          <hr />
          <div className='sb-btn-section'>
            <SBSaveUpdateBtn btnName="Update" />
            <SbCancelBtn btnName="Cancel" onClickEffect={closeModal} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpGradeSubscriptionModal;




