import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Leaves.scss";
import { FloatingLabel, Form } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import AlertModel from "../AlertModel";
import { optionsLeaveStatusUpdate } from "../DropdownsData";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

function Leaveupdatemodel(props) {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [status, setStatus] = useState("");
  // const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  // UPDATE LEAVE STATUS START
  const updateLeaveStatus = () => {
    const leaveStatus = {
      leaveID: props.leaveId,
      status: status,
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(leaveStatus),
    };
    fetch(`${baseURL}/api/Leave/UpdateLeaveStatus`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          props.handleClose();
          props.fetchLeaveDetails();
          swal.showAlert("Success", `${res.message}`, "success");
        } else {
          props.handleClose();
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
      });
  };
  // UPDATE LEAVE STATUS END

  return (
    <div>
      <Modal
        show={props.leaveUpdatestatus}
        // onHide={props.handleClose}
        className="main_model"
      >
        <div className="update-leavestatus-heading">
          <h6 className="page-header-text">UPDATE STATUS</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="update-leave-status">
              <div className="select-status mb-2">
              <FloatingLabel
                      label="Select Status"
                      className={`${
                        status
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={optionsLeaveStatusUpdate}
                    placeholder="Select Status"
                    value={optionsLeaveStatusUpdate.value}
                    onInputChange={optionsLeaveStatusUpdate.label}
                    onChange={(data) => setStatus(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
                <SBSaveUpdateBtn onClickEffect={updateLeaveStatus} btnName="Update" />
                <SbCancelBtn
                  btnName="Cancel"
                  onClickEffect={props.handleClose}
                />
              </div>
      </Modal>
    </div>
  );
}

export default Leaveupdatemodel;
