import { useEffect, useState } from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadSingleClientCompalint, loadSREscalationData } from "../../redux/action";
import ProductSkelrton from "../Products/ProductSkelrton";
import { useNavigate } from "react-router-dom";
import AssignToServiceRequest from "./AssignToServiceRequest";

function SrOverDue({ escalationLevel }) {

    let dispatch = useDispatch();
    const { SRescalationData } = useSelector((state) => state.data);
    const { totalCount } = useSelector((state) => state.data);

    const [PageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterArray, setFilterArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredComplaints, setFilteredComplaints] = useState([]);



    useEffect(() => {
        fetchComplaintdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PageSize, currentPage]);

    const fetchComplaintdata = () => {
        dispatch(
            loadSREscalationData(
                escalationLevel,
                currentPage,
                PageSize,
                setIsLoading,
                setLoading
            )
        );
    };

    // useEffect(() => {
    //     console.log(clientComplaints, "clientComplaints")
    //     if(clientComplaints.length !== 0){
    //         const filteredComplaint = clientComplaints.filter(
    //             complaint => complaint.clientComplaints_Service_Escalation === escalationLevel
    //         );
    //         setFilteredComplaints(filteredComplaint)
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[clientComplaints])

    let currentTime = moment(new Date()).format("DD/MM/YYYY");

    let navigate = useNavigate();
    const viewServiceRequest = (id) => {
        dispatch(loadSingleClientCompalint(id));
        navigate(`/viewSeviceRequest/${id}`);
    }

    const [showAssign, setShowAssign] = useState(false);
    const handleAssign = (id) => {
        dispatch(loadSingleClientCompalint(id));
        setShowAssign(true);
    };

    return (
        <Row>
            <Col className="request-data-div mt-4"
                onScroll={(e) =>
                    handleScroll(
                        e,
                        SRescalationData,
                        totalCount,
                        setLoading,
                        setPageSize,
                        PageSize
                    )
                }
            >
                <Row>
                    {isLoading ? (
                        <ProductSkelrton />
                    ) : (
                        <>
                            {SRescalationData && SRescalationData.length === 0 ? (
                                <>
                                    <Col md={12}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="sb-product-details-div default-img">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <img
                                                        src="../../../images/DefaultImages/emptyProduct.svg"
                                                        alt=""
                                                    />
                                                </div>
                                                <br />
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <h4>Requests not found</h4>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    {SRescalationData &&
                                        SRescalationData.map((data, i) => (
                                            <Col md={12} key={i}>
                                                <div className="request-box-div">
                                                    <Row className="request-details">
                                                        <Col
                                                            sm={2} className="request-id-col">
                                                            <div className="mi-request-id-section">
                                                                <h6 className="prod-name">#{data.clientComplaintsID}</h6>
                                                                <div className="priority-btn">
                                                                    {data.status === 1 ? (
                                                                        <span className="pending-status status-badge">
                                                                            Pending
                                                                        </span>
                                                                    ) : data.status === 2 ? (
                                                                        <span className="follow-status status-badge">
                                                                            Follow Up
                                                                        </span>
                                                                    ) : data.status === 3 ? (
                                                                        <span className="follow-status status-badge">
                                                                            Resolved
                                                                        </span>
                                                                    ) : data.status === 4 ? (
                                                                        <span className="follow-status status-badge">
                                                                            Closed
                                                                        </span>
                                                                    ) : data.status === 5 ? (
                                                                        <span className="follow-status status-badge">
                                                                            No AMC, No Warranty
                                                                        </span>
                                                                    ) : data.status === 6 ? (
                                                                        <span className="follow-status status-badge">
                                                                            On the Way
                                                                        </span>
                                                                    ) : data.status === 7 ? (
                                                                        <span className="follow-status status-badge">
                                                                            CheckIn
                                                                        </span>
                                                                    ) : data.status === 8 ? (
                                                                        <span className="follow-status status-badge">
                                                                            Escalation
                                                                        </span>
                                                                    ) : data.status === 9 ? (
                                                                        <span className="follow-status status-badge">
                                                                            Feedback
                                                                        </span>
                                                                    ) : data.status === 10 ? (
                                                                        <span className="complete-status status-badge">
                                                                            Submitted
                                                                        </span>
                                                                    ) : (
                                                                        data.status === 11 && (
                                                                            <span className="complete-status status-badge">
                                                                                Accepted
                                                                            </span>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={10} className="p-0" >
                                                            <div className="request-service-div">
                                                                <div style={{ width: "85%" }}>
                                                                    <Row>
                                                                        <Col md={6} className="p-0">
                                                                            <div className="details-sub-header">Customer:  <span className="prod-name">{data.clientName}</span></div>
                                                                            <div className="d-flex">
                                                                                <div className="details-sub-header">Issue:</div> &nbsp;&nbsp;
                                                                                <p>{data.issue}</p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} className="p-0">
                                                                            <div className="d-flex">
                                                                                <div className="details-sub-header">Machine:</div> &nbsp;&nbsp;
                                                                                <p>{data.productName}</p>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <div className="details-sub-header">Remark:</div> &nbsp;&nbsp;
                                                                                <p>
                                                                                    {data.remark ? (
                                                                                        data.remark.length > 200
                                                                                            ? data.remark.substring(0, 50) + "..."
                                                                                            : data.remark
                                                                                    ) : (
                                                                                        "NA"
                                                                                    )}
                                                                                </p>
                                                                            </div>

                                                                            <div className="d-flex">
                                                                                <div className="details-sub-header">Warrenty:</div> &nbsp;&nbsp;
                                                                                <b>
                                                                                    {data.inWarranty == 1 ? (
                                                                                        <span className="color-green">Yes</span>
                                                                                    ) : (
                                                                                        <span className="color-yellow">No</span>
                                                                                    )}
                                                                                </b>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div className="date-section-div">
                                                                    <p>Raised on{" "}
                                                                        {moment(currentTime, "DD/MM/YYYY").diff(
                                                                            moment(data.date, "DD/MM/YYYY"),
                                                                            "days"
                                                                        )}{" "}
                                                                        day(s) ago
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="service-details-div">
                                                        <Col sm={1} className="br-right action-icons">
                                                            <div className="request-service-icons">
                                                                <OverlayTrigger
                                                                    placement={"right"}
                                                                    delay={{ show: 250, hide: 300 }}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-dashboard`}>
                                                                            View Request
                                                                        </Tooltip>
                                                                    } >
                                                                    <div className="mi-request-action" onClick={() => viewServiceRequest(data?.clientComplaintsID)}><i className="fa fa-eye"></i></div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col sm={3} className="br-right">
                                                            <div className="request-service-icons">
                                                                <div className="mi-service-request-action">
                                                                    <div className="details-sub-header">Assigned to</div>
                                                                    <div className="details-sub-value">{data.assingnedToName}</div>
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement={"right"}
                                                                    delay={{ show: 250, hide: 300 }}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-dashboard`}>
                                                                            Re Assign
                                                                        </Tooltip>
                                                                    } >
                                                                    <div className="mi-request-action" onClick={() => handleAssign(data?.clientComplaintsID)}><span class="material-symbols-outlined">quick_phrases</span></div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2} className="br-right">
                                                            <div className="request-service-icons">
                                                                <div className="mi-service-request-action">
                                                                    <div className="details-sub-header">Request date</div>

                                                                    <div className="details-sub-value">{moment(data.date, "DD/MM/YYYY").format(
                                                                        "DD MMM YYYY"
                                                                    )}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2} className="br-right">
                                                            <div className="request-service-icons">
                                                                <div className="mi-service-request-action">
                                                                    <div className="details-sub-header">Reported By</div>
                                                                    <div className="details-sub-value">{data.adttenedByName === " "
                                                                        ? "-"
                                                                        : data.adttenedByName}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2} className="br-right">
                                                            <div className="request-service-icons">
                                                                <div className="mi-service-request-action">
                                                                    <div className="details-sub-header">Priority</div>
                                                                    <div className="details-sub-value">{data.priority === 3 ? (
                                                                        <span className="pending-request">
                                                                            High
                                                                        </span>
                                                                    ) : data.priority === 2 ? (
                                                                        <span className="follow-request">
                                                                            Medium
                                                                        </span>
                                                                    ) : (
                                                                        data.priority === 1 && (
                                                                            <span className="complete-request">
                                                                                Low
                                                                            </span>
                                                                        )
                                                                    )}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2} style={{ padding: "0" }}>
                                                            <div className="request-service-icons">
                                                                <div className="mi-service-request-action">
                                                                    <div className="details-sub-header"><em>Due date</em></div>

                                                                    <div className="due-date-details-sub-value">{moment(data.date, "DD/MM/YYYY").format(
                                                                        "DD MMM YYYY"
                                                                    )}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </Col>
                                        ))}
                                    {loading && <OnScrollLoading />}
                                </>
                            )}
                        </>
                    )}
                </Row>
            </Col>
            {showAssign && (
                <AssignToServiceRequest
                    showAssign={showAssign}
                    setShowAssign={setShowAssign}
                    fetchComplaintdata={fetchComplaintdata}
                />
            )}
        </Row>
    );
}

export default SrOverDue;
