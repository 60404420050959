import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Spares.scss";
import { Col, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import AddSpare from "./AddSpare";
import EditSpare from "./EditSpare";
import ViewSpareDetails from "./ViewSpareDetails";
import { baseURL } from "../BaseUrl";
import SbAddBtn, { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useNavigate, useParams } from "react-router-dom";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import SpareDefaultImg from "../../images/SpareDefaultImg.png";
import Footer from "../Footer";

export default function Spares() {

  const { dForm_ID } = useParams();
  console.log('dForm_ID:', dForm_ID);
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [show, setShow] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [editSpareModel, setEditSpareModel] = useState(false);
  const [viewProductDetail, setViewProductDetail] = useState(false);
  const [productList, setProductList] = useState([]);
  const [spareList, setSpareList] = useState([]);
  const [spareDetails, setSpareDetails] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [productID, setProductID] = useState(0);
  const [sparePartId, setSparePartId] = useState(0);
  const [spareID, setSpareID] = useState(0);
  const [status, setStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // FETCH SELECT DROPDOWN DATA
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Product/BindDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setProductList(
            res.result.map((data) => {
              return { value: data.productID, label: data.productName };
            })
          );
        } else {
          setProductList([]);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/SpareParts/BindSparePartsDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setSpareList(
            res.result.map((data) => {
              return { value: data.sparePartsID, label: data.name };
            })
          );
        } else {
          setSpareList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH SELECT DROPDOWN DATA

  useEffect(() => {
    fetchSpareDetails();
  }, [productID, spareID, PageSize, currentPage]);

  const fetchSpareDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/SpareParts/Get?UserId=${filterUserId}&SubscriptionID=${resPassData.subscriptionId}&SparePartsID=${spareID}&ProductID=${productID}&Status=${status}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("SPARES", res.result);
        if (res.success) {
          setSpareDetails(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setSpareDetails([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };

  const handleClose = () => {
    setShow(false);
    setEditSpareModel(false);
    setViewProductDetail(false);
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setSpareID(0);
    setProductID(0);
  };
  //* CLEAR SELECT END *//

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const handleOpenAddForm = () => {
    navigate(`/createdform/${dForm_ID}`)
  }


  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            {/* Filter and Header Section */}
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text"> SPARES </h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div
                        className="header-filter-btn"
                        onClick={() => setFilter(!filter)}
                      >
                        <p className="card-head">
                          <i className="bi bi-sliders" />
                          &nbsp;&nbsp;Filters
                        </p>
                      </div>
                      {/* <SbAddBtn onClickEffect={() => setShow(!show)} /> */}
                      {dForm_ID ? (
                        <SbAddBtn onClickEffect={() => handleOpenAddForm()} />
                      ) : (
                        <SbAddBtn onClickEffect={() => setShow(!show)} />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              {filter && (
                <div className="page-filter-section">
                  <div style={{ width: "100%" }}>
                    <Row>
                      <Col md={6}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={productList}
                              placeholder="Select Product"
                              value={productID === 0 ? "" : productList.value}
                              onInputChange={productList.label}
                              onChange={(data) => setProductID(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={spareList}
                              placeholder="Select Spare"
                              value={spareID == 0 ? "" : spareList.value}
                              onInputChange={spareList.label}
                              onChange={(data) => setSpareID(data.value)}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <SbCancelBtn
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
            {/* Filter and Header Section */}

            {/* USER TABLE START */}
            <div className="spares-table">
              <div className="table-responsive spares-scrollbar">
                <table className="table spares-table-data">
                  <thead className="thead-light spares-thead">
                    <tr>
                      <th>SPARE NAME</th>
                      <th>CODE</th>
                      <th>PRODUCT NAME</th>
                      <th>WARRANTY</th>
                      <th>
                        DIMENSION <br />
                        (H x W x L)
                      </th>
                      <th>WEIGHT</th>
                      <th>
                        (
                        <i className="fa fa-inr" style={{ fontSize: "12px" }} />
                        ) MRP
                      </th>
                      <th>PRICE</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {spareDetails.length > 0 ? (
                      spareDetails.map((data, i) => (
                        <tr className="spare-table-row-data" key={i}>
                          <td>
                            <p
                              className="row-img-spare"
                              onClick={() => {
                                setViewProductDetail(!viewProductDetail);
                                setSparePartId(data.sparePartsID);
                              }}
                            >
                              <span className="spare-img-box">
                                {data.image !== "" ? (
                                  <img
                                    className="row-spare-images"
                                    src={
                                      `${baseURL}/${data.image}` ??
                                      SpareDefaultImg
                                    }
                                    alt="Spare Images"
                                  />
                                ) : (
                                  <img
                                    className="row-spare-images"
                                    src={SpareDefaultImg}
                                    alt="Spare Images"
                                  />
                                )}
                              </span>
                              &nbsp; &nbsp;
                              <div>
                                <div className="table-row-head-Text">
                                  {data?.name ?? ""}
                                </div>
                                <div className="table-row-sub-text">
                                  SN : {data?.slNo ?? ""}
                                </div>
                              </div>
                            </p>
                          </td>
                          <td>{data?.code ?? ""}</td>
                          <td>{data?.productNames ?? ""}</td>
                          <td>{data?.inWarranty == 1 ? "YES" : "NO"}</td>
                          <td>
                            {data?.height && data?.width && data?.length && (
                              <>
                                {`${data?.height ?? 0} x ${data?.width ?? 0} x
                            ${data?.length ?? 0}`}
                              </>
                            )}
                          </td>
                          <td>{data?.weight ?? 0}</td>
                          <td>{data?.mrp ?? 0}</td>
                          <td>{data?.price ?? 0}</td>

                          <td>
                            <button
                              onClick={() => {
                                setEditSpareModel(!editSpareModel);
                                setSparePartId(data.sparePartsID);
                              }}
                              className="btn spare-edit-link"
                            >
                              <i className="bi bi-pencil-fill"></i> Edit
                            </button>
                            {/* <button
                              onClick={() => {
                                setViewProductDetail(!viewProductDetail);
                                setSparePartId(data.sparePartsID);
                              }}
                              className="btn spare-edit-link"
                            >
                              <i className="fa fa-eye" /> View
                            </button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center">
                          ----- NO DATA FOUND ------
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* USER TABLE END */}

            {/* PAGINATION START */}
            <div className="download-and-pagination">
              <SbDownloadBtn
                btnName="Download"
              // onClickEffect={exportComplaintDetails}
              />
            </div>
            {/* PAGINATION END */}

            {/* ADD PRODUCT MODEL START */}
            <AddSpare
              show={show}
              handleClose={handleClose}
              fetchSpareDetails={fetchSpareDetails}
            />
            {/* ADD PRODUCT MODEL END */}

            {/* EDIT PRODUCT MODEL START */}
            {editSpareModel ? (
              <EditSpare
                editSpareModel={editSpareModel}
                handleClose={handleClose}
                sparePartId={sparePartId}
                fetchSpareDetails={fetchSpareDetails}
              />
            ) : (
              ""
            )}
            {/* EDIT PRODUCT MODEL END */}

            {/* VIEW PRODUCT MODEL START */}
            {viewProductDetail ? (
              <ViewSpareDetails
                viewProductDetail={viewProductDetail}
                handleClose={handleClose}
                sparePartId={sparePartId}
              />
            ) : (
              ""
            )}
            {/* VIEW PRODUCT MODEL END */}

            {/* PAGE CONTENT END*/}
          </div>
        </div>

        {/* MAIN BODY END */}
      </div>
      <Footer />
    </div>
  );
}
