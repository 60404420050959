// import {
//     Paper,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { loadFormData, loadMachineData } from "../../../redux/action";
// import Footer from "../../Footer";
// import SbAddBtn from "../../SbButtons/SbAddBtn";
// import Sidenavbar from "../../Sidenavbar";
// import Topbar from "../../Topbar";
// import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
// import { useNavigate, useParams } from "react-router-dom";
// import '../../../styles/CustomForms.scss'
// import DisplayFormAttachments from "./DisplayFormAttachments";

// const DisplayTableData = () => {
//     let dispatch = useDispatch();
//     const { dForm_ModuleName, dForm_ID } = useParams();
//     const { getFromData } = useSelector((state) => state.data);
//     const { getParticularModuleTableData } = useSelector((state) => state.data);
//     const { getMachineData } = useSelector((state) => state.data);
//     console.log(getMachineData, 'getMachineData')

//     const [isLoading, setIsLoading] = useState(false);
//     const [toggleshift, setToggleshift] = useState({ style: "" });
//     const [menuCollapse, setMenuCollapse] = useState(false);

//     const toggleMenu = () => {
//         if (menuCollapse) {
//             setMenuCollapse(false);
//             setToggleshift({ style: "" });
//         } else {
//             setMenuCollapse(true);
//             setToggleshift({ style: "toggle-shift-left" });
//         }
//     };

//     const [filterArray, setFilterArray] = useState([])
//     const [searchValue, setSearchValue] = useState('')
//     const [parsedTableData, setParsedTableData] = useState([])

//     const searchByLabel = (value) => {
//         const filtered = getMachineData
//             .flat()
//             .filter(item =>
//                 item.data.some(field =>
//                     field.iForm_FieldName.toLowerCase().includes(value.toLowerCase())
//                 )
//             );
//         setFilterArray(filtered);
//     };

//     const setSearchData = (e) => {
//         const { value } = e.target;
//         setSearchValue(value);
//         searchByLabel(value);
//     };

//     useEffect(() => {
//         // dispatch(loadFormData());
//         dispatch(loadMachineData());
//         // dispatch(loadParticularModuleDataInTable(dForm_ModuleName));
//     }, [dispatch])

//     // useEffect(() => {
//     //     if (getFromData && getFromData.length !== 0) {
//     //         let parsedDataArray = getFromData.map(item => {
//     //             if (item.iForm_FieldValue) {
//     //                 if (typeof item.iForm_FieldValue === 'string') {
//     //                     let parsedArray = JSON.parse(item.iForm_FieldValue);
//     //                     item.iForm_FieldValue = parsedArray;
//     //                 }
//     //             }
//     //             return item;
//     //         });
//     //         setParsedTableData(parsedDataArray);
//     //     }
//     // }, [getFromData]);

//     const [formHeaders, setFormHeaders] = useState([])
//     const [formFields, setFormfields] = useState([])
//     console.log(formFields, 'form')
//     // useEffect(() => {
//     //     let fieldRows = [];
//     //     let headerRows = [];
//     //     if (parsedTableData.length !== 0) {
//     //         parsedTableData.forEach(item => {
//     //             let fieldItem = []
//     //             item.iForm_FieldValue.fields.forEach(fields => {
//     //                 fields.rowData.forEach(rowData => {
//     //                     if (rowData.field_type !== "button") {
//     //                         fieldItem.push(rowData)
//     //                     }
//     //                 })
//     //             })
//     //             fieldRows.push({ row: fieldItem, row_ID: item.row_ID })
//     //         })
//     //     }
//     //     if (getFromData.length !== 0) {
//     //         let lastItem = getFromData[getFromData.length - 1];
//     //         lastItem.iForm_FieldValue.fields.forEach(fields => {
//     //             fields.rowData.forEach(rowData => {
//     //                 if (rowData.field_type !== "button") {
//     //                     headerRows.push(rowData);
//     //                 }
//     //             });
//     //         });
//     //     }

//     //     setFormHeaders(headerRows)
//     //     setFormfields(fieldRows)
//     // }, [parsedTableData])

//     let navigate = useNavigate();

//     const [rowId, setRowId] = useState("")

//     const handleOpenAddForm = () => {
//         setRowId("")
//         navigate(`/createdform/${dForm_ID}`)
//     }

//     const handleEditForm = (id) => {
//         setRowId(id)
//         navigate(`/createdform/${dForm_ID}`, { state: { rowId: id } })
//     }

//     const goToBackPage = () => {
//         setRowId("")
//         navigate(-1);
//     };

//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [selectedRowFields, setSelectedRowFields] = useState([]);

//     const handleViewDocuments = (id) => {
//         setRowId(id)
//         const filteredFields = formFields.filter(field => field.row_ID === id);
//         setSelectedRowFields(filteredFields);
//         setIsModalOpen(true);
//     }
//     const closeModal = () => {
//         setIsModalOpen(false);
//     };

//     const [lastFieldNames, setLastFieldNames] = useState([]);

//     useEffect(() => {
//         if (getMachineData && Array.isArray(getMachineData) && getMachineData.length > 0) {
//             const lastArray = getMachineData[getMachineData.length - 1]; // Get the last array in getMachineData
//             if (Array.isArray(lastArray) && lastArray.length > 0) {
//                 const lastItem = lastArray[lastArray.length - 1]; // Get the last object in the last array
//                 if (lastItem && Array.isArray(lastItem.data) && lastItem.data.length > 0) {
//                     const fieldNames = lastItem.data
//                         .map(item => item.iForm_FieldName)
//                         .filter(fieldName => fieldName && fieldName.toLowerCase() !== 'submit'); // Filter out 'submit'

//                     setLastFieldNames(fieldNames); // Set the filtered iForm_FieldName values to state
//                 } else {
//                     console.error("Last item does not contain a valid 'data' array");
//                 }
//             } else {
//                 console.error("Last array is empty or not an array");
//             }
//         } else {
//             console.error("getMachineData is empty or not an array");
//         }
//     }, [getMachineData]);

//     return (
//         <div id="main-page">
//             {/* SIDEBAR START */}
//             <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
//             {/* SIDEBAR END */}

//             {/* TOPBAR START */}
//             <Topbar
//                 toggleMenu={toggleMenu}
//                 menuCollapse={menuCollapse}
//                 toggleshift={toggleshift}
//             />
//             {/* TOPBAR END */}

//             {/* MAIN BODY START */}
//             <div className="page-wrapper">
//                 <div className={`main-body ${toggleshift.style}`}>
//                     <div className="page-content">
//                         <div className="clients-table">
//                             <Row className="search-row">
//                                 <Col md={3} className="cat-col">
//                                     <div className="page-header-text-div">
//                                         <div className="back-btn" onClick={goToBackPage}>
//                                             <img
//                                                 src="../../../../images/DefaultImages/back-btn-arrow.svg"
//                                                 alt=""
//                                             />
//                                         </div>
//                                         <h6 className="page-header-text">
//                                             {dForm_ModuleName}
//                                         </h6>
//                                     </div>
//                                 </Col>
//                                 <Col md={5} className="cat-col">
//                                     <div className="input-div">
//                                         <input
//                                             type="search"
//                                             id="metricInfo_search_bar"
//                                             placeholder="Search..."
//                                             className="form-control setting-input"
//                                             value={searchValue} onChange={(e) => setSearchData(e)}
//                                         />
//                                         <i className="fa fa-search search-icon" />
//                                     </div>
//                                 </Col>
//                                 <Col md={4} className="cat-col">
//                                     <div
//                                         className="search-bar-header"
//                                         style={{ float: "right" }}
//                                     >
//                                         <div className="page-top-bar-dropdowns page-top-Filters">
//                                             <SbAddBtn onClickEffect={handleOpenAddForm} />
//                                         </div>
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <div className="sb-table-div sb-table-setion">
//                                 <Paper
//                                     sx={{ width: "100%", overflow: "hidden" }}
//                                     className="table-main-div"
//                                 >
//                                     <TableContainer
//                                         sx={{ maxHeight: 440 }}
//                                     >
//                                         <Table
//                                             stickyHeader
//                                             aria-label="sticky table table-responsive"
//                                             className="basefare-table custom-form-table"
//                                         >
//                                             <TableHead className="custom-table-header">
//                                                 <TableRow>
//                                                     {lastFieldNames.map((fieldName, index) => (
//                                                         <TableCell key={index} align="left">
//                                                             {fieldName || "-"}
//                                                         </TableCell>
//                                                     ))}
//                                                     <TableCell align="center">
//                                                         Action
//                                                     </TableCell>
//                                                 </TableRow>
//                                             </TableHead>
//                                             {isLoading ? (
//                                                 <TableBody>
//                                                 </TableBody>
//                                             ) : (
//                                                 <>
//                                                     {getMachineData.length === 0 ? (
//                                                         <TableBody>
//                                                             <TableRow align="center">
//                                                                 <TableCell align="center">
//                                                                     <div style={{ padding: "20px 20px" }}>
//                                                                         <img
//                                                                             src="../../../../images/DefaultImages/NodataFound.jpg"
//                                                                             alt=""
//                                                                             style={{ width: "10%" }}
//                                                                         />
//                                                                         <br />
//                                                                         <span>No Data Found...!</span>
//                                                                     </div>
//                                                                 </TableCell>
//                                                             </TableRow>
//                                                         </TableBody>
//                                                     ) : (
//                                                         <TableBody>
//                                                             {getMachineData.map((outerItem, outerIndex) =>
//                                                                 outerItem.map((innerItem, innerIndex) => (
//                                                                     <TableRow key={innerIndex}>
//                                                                         {lastFieldNames.map((fieldName, index) => (
//                                                                             <TableCell key={index}>
//                                                                                 {fieldName === 'Upload Document' ? (
//                                                                                     <div style={{ cursor: "pointer", color: "#5842bc" }}>
//                                                                                         <i className="fa fa-eye" onClick={() => handleViewDocuments(innerItem?.iForm_FormName)}> View Attachments</i>
//                                                                                     </div>
//                                                                                 ) : (
//                                                                                     innerItem.data.find(d => d.iForm_FieldName === fieldName)?.iForm_FieldValue && innerItem.data.find(d => d.iForm_FieldName === fieldName)?.iForm_FieldValue.length > 150 ? (
//                                                                                         <OverlayTrigger
//                                                                                             placement="top"
//                                                                                             overlay={<Tooltip>{innerItem.data.find(d => d.iForm_FieldName === fieldName)?.iForm_FieldValue}</Tooltip>}
//                                                                                         >
//                                                                                             <span>{innerItem.data.find(d => d.iForm_FieldName === fieldName)?.iForm_FieldValue.substring(0, 50)}...</span>
//                                                                                         </OverlayTrigger>
//                                                                                     ) : (
//                                                                                         innerItem.data.find(d => d.iForm_FieldName === fieldName)?.iForm_FieldValue || "NA"
//                                                                                     )
//                                                                                 )}
//                                                                             </TableCell>
//                                                                         ))}
//                                                                         <TableCell>
//                                                                             <button className="btn client-edit-link"
//                                                                                 onClick={() => handleEditForm(innerItem?.iForm_FormName)}
//                                                                             >
//                                                                                 <i className="bi bi-pencil-fill"></i>&nbsp; Edit
//                                                                             </button>
//                                                                         </TableCell>
//                                                                     </TableRow>
//                                                                 ))
//                                                             )}
//                                                         </TableBody>
//                                                     )}
//                                                 </>
//                                             )}
//                                         </Table>
//                                     </TableContainer>
//                                 </Paper>
//                             </div>
//                         </div>
//                     </div >
//                 </div >
//                 <Footer />
//             </div >
//             {isModalOpen && (
//                 <DisplayFormAttachments
//                     isModalOpen={isModalOpen}
//                     closeModal={closeModal}
//                     formFields={formFields}
//                     selectedRowFields={selectedRowFields}
//                 />
//             )}
//         </div>
//     );
// }

// export default DisplayTableData;



import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loadMachineData } from "../../../redux/action";
import Footer from "../../Footer";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import '../../../styles/CustomForms.scss'
import DisplayFormAttachments from "./DisplayFormAttachments";
import OnScrollLoading from "../../OnScrollLoading";

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { SkeletonClients } from "../../Skeleton Loading/SkeletonClients";

const DisplayTableData = () => {
    let dispatch = useDispatch();
    const { dForm_ModuleName, dForm_ID } = useParams();
    const { getMachineData } = useSelector((state) => state.data);

    const [isLoading, setIsLoading] = useState(true);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [filterArray, setFilterArray] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [formFields, setFormfields] = useState([])

    const toggleMenu = () => {
        setMenuCollapse(!menuCollapse);
        setToggleshift({ style: menuCollapse ? "" : "toggle-shift-left" });
    };

    const searchByLabel = (value) => {
        const filtered = getMachineData.flatMap(items =>
            items.flatMap(data =>
                data.data.filter(field =>
                    field.iForm_FieldValue && // Ensure iForm_FieldValue exists
                    field.iForm_FieldValue.toLowerCase().includes(value.toLowerCase()) // Case insensitive search
                ).length > 0 ? data : null
            ).filter(item => item !== null)
        );
        setFilterArray(filtered);
    };

    const setSearchData = (e) => {
        const { value } = e.target;
        setSearchValue(value);
        searchByLabel(value);
    };

    useEffect(() => {
        dispatch(loadMachineData(setIsLoading));
    }, [setIsLoading, dispatch]);

    useEffect(() => {
        // Initialize filterArray with getMachineData
        if (getMachineData && getMachineData.length > 0) {
            setFilterArray(getMachineData.flat());
        }
    }, [getMachineData]);

    let navigate = useNavigate();

    const handleOpenAddForm = () => {
        navigate(`/createdform/${dForm_ID}`)
    };

    const handleEditForm = (id) => {
        navigate(`/createdform/${dForm_ID}`, { state: { rowId: id } })
    };

    const goToBackPage = () => {
        navigate(-1);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowFields, setSelectedRowFields] = useState([]);

    const handleViewDocuments = (id) => {
        const filteredFields = formFields.filter(field => field.row_ID === id);
        setSelectedRowFields(filteredFields);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [lastFieldNames, setLastFieldNames] = useState([]);

    useEffect(() => {
        if (getMachineData && Array.isArray(getMachineData) && getMachineData.length > 0) {
            const lastArray = getMachineData[getMachineData.length - 1]; // Get the last array in getMachineData
            if (Array.isArray(lastArray) && lastArray.length > 0) {
                const lastItem = lastArray[lastArray.length - 1]; // Get the last object in the last array
                if (lastItem && Array.isArray(lastItem.data) && lastItem.data.length > 0) {
                    const fieldNames = lastItem.data
                        .map(item => item.iForm_FieldName)
                        .filter(fieldName => fieldName && fieldName.toLowerCase() !== 'submit'); // Filter out 'submit'

                    setLastFieldNames(fieldNames); // Set the filtered iForm_FieldName values to state
                } else {
                    console.error("Last item does not contain a valid 'data' array");
                }
            } else {
                console.error("Last array is empty or not an array");
            }
        } else {
            console.error("getMachineData is empty or not an array");
        }
    }, [getMachineData]);

    return (
        <div id="main-page">
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        <div className="clients-table">
                            <Row className="search-row">
                                <Col md={3} className="cat-col">
                                    <div className="page-header-text-div">
                                        <div className="back-btn" onClick={goToBackPage}>
                                            <img
                                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="page-header-text">
                                            {dForm_ModuleName}
                                        </h6>
                                    </div>
                                </Col>
                                <Col md={5} className="cat-col">
                                    <div className="input-div">
                                        <input
                                            type="search"
                                            id="metricInfo_search_bar"
                                            placeholder="Search..."
                                            className="form-control setting-input"
                                            value={searchValue} onChange={(e) => setSearchData(e)}
                                        />
                                        <i className="fa fa-search search-icon" />
                                    </div>
                                </Col>
                                <Col md={4} className="cat-col">
                                    <div
                                        className="search-bar-header"
                                        style={{ float: "right" }}
                                    >
                                        <div className="page-top-bar-dropdowns page-top-Filters">
                                            <SbAddBtn onClickEffect={handleOpenAddForm} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="sb-table-div sb-table-setion">
                                <Paper
                                    sx={{ width: "100%", overflow: "hidden" }}
                                    className="table-main-div"
                                >
                                    <TableContainer
                                        sx={{ maxHeight: 440 }}
                                    >
                                        <Table
                                            stickyHeader
                                            aria-label="sticky table table-responsive"
                                            className="basefare-table custom-form-table"
                                        >
                                            <TableHead className="custom-table-header">
                                                <TableRow>
                                                    {lastFieldNames.map((fieldName, index) => (
                                                        <TableCell key={index} align="left">
                                                            {fieldName || "-"}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell align="center">
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {isLoading ? (
                                                <>
                                                    {/* {filterArray.map((header, i) => ( */}
                                                    {/* <SkeletonClients key={i} /> */}
                                                    {/* ))} */}
                                                    <OnScrollLoading />
                                                </>
                                            ) : (
                                                <>
                                                    <TableBody>
                                                        {filterArray.length === 0 ? (
                                                            <TableRow align="center">
                                                                <TableCell colSpan={lastFieldNames.length + 1}>
                                                                    <div style={{ padding: "20px 20px" }}>
                                                                        <img
                                                                            src="../../../../images/DefaultImages/NodataFound.jpg"
                                                                            alt=""
                                                                            style={{ width: "10%" }}
                                                                        />
                                                                        <br />
                                                                        <span>No Data Found...!</span>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            filterArray.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    {lastFieldNames.map((fieldName, innerIndex) => (
                                                                        <TableCell key={innerIndex}>
                                                                            {fieldName === 'Upload Document' ? (
                                                                                <div style={{ cursor: "pointer", color: "#5842bc" }}>
                                                                                    <i className="fa fa-eye" onClick={() => handleViewDocuments(item?.iForm_FormName)}> View Attachments</i>
                                                                                </div>
                                                                            ) : (
                                                                                item.data.find(field => field.iForm_FieldName === fieldName)?.iForm_FieldValue || "NA"
                                                                            )}
                                                                        </TableCell>
                                                                    ))}
                                                                    <TableCell>
                                                                        <button className="btn client-edit-link"
                                                                            onClick={() => handleEditForm(item?.iForm_FormName)}
                                                                        >
                                                                            <i className="bi bi-pencil-fill"></i>&nbsp; Edit
                                                                        </button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        )}
                                                    </TableBody>
                                                </>)}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </div>
                    </div >
                </div >
                <Footer />
            </div >
            {isModalOpen && (
                <DisplayFormAttachments
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    formFields={formFields}
                    selectedRowFields={selectedRowFields}
                />
            )}
        </div>
    );
}
export default DisplayTableData;