import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import SalesBee from "../../../images/MatricLogo.svg";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as HeaderData from "../../HeaderAuthentication";
import { loadPostCustomfields, loadSingleField, loadUpdateCustomfields } from "../../../redux/action";

const fieldTypes = [
    { value: "Text Box", label: "Text Box" },
    { value: "Drop Down", label: "Drop Down" },
    { value: "Check Box", label: "Check Box" },
    { value: "Radio", label: "Radio" },
];

const AddCustomerQuestionaires = (props) => {
    let dispatch = useDispatch();
    let editId = props.editId;
    const { singleField } = useSelector((state) => state.data);

    const [fieldName, setFieldName] = useState("");
    const [placeHolder, setPlaceHolder] = useState("");
    const [fieldType, setFieldType] = useState("");
    const [optionCount, setOptionCount] = useState([]);
    const [isRequired, setIsRequired] = useState(0);
    const [status, setStatus] = useState(1)

    const [showMachineDetailsForm, setShowMachineDetailsForm] = useState(true);
    const [activeMenu, setActiveMenu] = useState(1);

    const toggleMachineDetails = () => {
        setShowMachineDetailsForm(true);
        setActiveMenu(1);
    };

    const selectedOption = (data) => {
        setFieldType(data.value); // Fix: set fieldType state
    };

    const removeOption = (i) => {
        const updatedOptions = optionCount.filter((_, index) => index !== i);
        setOptionCount(updatedOptions);
    };

    const OptionCount = () => {
        setOptionCount([...optionCount, { value: "text", label: "Text" }]);
    };

    const setOptionsVal = (e, index) => {
        const updatedOptions = optionCount.map((option, i) => {
            if (i === index) {
                return { ...option, value: e.target.value, label: e.target.value };
            }
            return option;
        });
        setOptionCount(updatedOptions);
    };

    const cancelModale = () => {
        props.addFieldModuleClose();
    };

    useEffect(() => {
        if (editId) {
            dispatch(loadSingleField(editId));
        }
    }, [editId]);

    const fieldStatusType = () => {
        setIsRequired(isRequired ? 0 : 1);
    };

    const statusFlag = () => {
        setStatus(status ? 0 : 1)
    };

    useEffect(() => {
        if (editId) {
            if (singleField && Object.keys(singleField).length) {
                setFieldName(singleField.fieldName)
                setPlaceHolder(singleField.placeHolder)
                setFieldType(singleField.fieldType)
                // setOptionCount(singleField.fieldOptions)
                // const parsedOptions = JSON.parse(singleField.fieldOptions);
                // setOptionCount(parsedOptions);
                setOptionCount(singleField.fieldOptions.split(',').map(option => ({ value: option, label: option })));
                setIsRequired(singleField.isRequired)
                setStatus(singleField.status)
            }

        }
    }, [editId, singleField])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            !fieldName ||
            !fieldType ||
            !placeHolder ||
            ((fieldType === "Drop Down" ||
                fieldType === "Check Box" ||
                fieldType === "Radio") &&
                !optionCount)
        ) {
            setFieldReq(true);
        } else {
            const data = {
                "status": status,
                "customePageID": 29,
                "customePageName": "QUESTIONNAIRES FOR C",
                "fieldName": fieldName,
                "fieldType": fieldType,
                // "fieldOptions": JSON.stringify(optionCount),
                "fieldOptions": optionCount.map(option => option.value).join(','),
                "placeHolder": placeHolder,
                "isRequired": isRequired
            }
            var postRequest = {
                method: "POST",
                mode: "cors",
                headers: HeaderData.postTravelizeAuth,
                body: JSON.stringify(data),
            };
            dispatch(loadPostCustomfields(postRequest, cancelModale));
            props.fetchCustomerQuesData()
        }
    }

    const [fieldReq, setFieldReq] = useState("");
    const handleUpdate = (e) => {
        e.preventDefault();
        if (
            !fieldName ||
            !fieldType ||
            !placeHolder ||
            ((fieldType === "Drop Down" ||
                fieldType === "Check Box" ||
                fieldType === "Radio") &&
                !optionCount)
        ) {
            setFieldReq(true);
        } else {
            const data = {
                "customeFieldID": editId,
                "status": status,
                "customePageID": 29,
                "customePageName": "QUESTIONNAIRES FOR C",
                "fieldName": fieldName,
                "fieldType": fieldType,
                // "fieldOptions": JSON.stringify(optionCount),
                "fieldOptions": optionCount.map(option => option.value).join(','),
                "placeHolder": placeHolder,
                "isRequired": isRequired
            }
            let updateRequest = {
                method: "POST",
                mode: "cors",
                headers: HeaderData.postTravelizeAuth,
                body: JSON.stringify(data),
            };
            dispatch(loadUpdateCustomfields(updateRequest, cancelModale, singleField.customeFieldID));
            props.fetchCustomerQuesData()
            console.log(data,'data')
        }
    };


    return (
        <div className="add-user-details-container">
            <div className="add-user-details-first-div">
                <div className="machines-logo-div">
                    <div className="logotext">
                        <div className="logo profile-mini">
                            <Link className="travelize_logo" to="/dashboard">
                                <img
                                    src={SalesBee}
                                    alt="product-title-img"
                                    className="salesbee-logo"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="add-user-details-menus" onClick={toggleMachineDetails}>
                    <div
                        className={`add-user-details-menu-numbers ${activeMenu === 1 ? "active" : "inactive"
                            }`}
                    >
                        <p className="add-user-details-menu-number-one">
                            <span>01</span>
                        </p>
                        <div className="add-user-details-menu-name">
                            {/* Questionnaires For Service Engineer */}
                            Customer
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-user-details-second-div">
                <div className="add-user-details-second-div-header">
                    <div className="back-btn" onClick={cancelModale}>
                        <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                        />
                    </div>
                    <div>
                        <h4>Questionnaires For Customer</h4>
                        <p>Please enter information and proceed to the next step.</p>
                    </div>
                </div>
                <div className="amc-single-image">
                    {showMachineDetailsForm && (
                        <form onSubmit={editId ? handleUpdate : handleSubmit}>
                            <div className="col-lg-10 col">
                                <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel
                                                label="Enter Questions"
                                                className={`${fieldName || ""
                                                    ? "float-input-visible"
                                                    : "float-hidden float-input"
                                                    }`}
                                            >
                                                <input
                                                    className="form-control add-user-input"
                                                    type="text"
                                                    value={fieldName || ""}
                                                    name="fieldName"
                                                    placeholder="Enter Questions"
                                                    autoComplete="off"
                                                    required
                                                    onChange={(e) => { setFieldName(e.target.value) }}
                                                />
                                            </FloatingLabel>
                                            {fieldReq && !fieldName && (
                                                <small id={`Error`} className="form-text text-muted ">
                                                    Please Enter Question
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel
                                                label="Place Holder"
                                                className={`${placeHolder
                                                    ? "float-input-visible"
                                                    : "float-hidden float-input"
                                                    }`}
                                            >
                                                <input
                                                    className="form-control add-user-input"
                                                    type="text"
                                                    value={placeHolder || ""}
                                                    name="placeHolder"
                                                    placeholder="Enter Place Holder"
                                                    autoComplete="off"
                                                    required
                                                    onChange={(e) => { setPlaceHolder(e.target.value) }}
                                                />
                                            </FloatingLabel>
                                            {fieldReq && !placeHolder && (
                                                <small id={`Error`} className="form-text text-muted ">
                                                    Please Enter Field Name
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row add-user-image-container-div" style={{ marginTop: "10px" }}>
                                    <div className="col-lg-6 col">
                                        <div className="mb-3">
                                            <FloatingLabel
                                                label="Field Type"
                                                className={`${fieldType !== ""
                                                    ? "float-visible-select"
                                                    : "float-hidden float-select"
                                                    }`}
                                            >
                                                <Select
                                                    className="react-select-container-list model-select"
                                                    classNamePrefix="react-select-list"
                                                    options={fieldTypes}
                                                    name="fieldType"
                                                    placeholder="Select Field Type"
                                                    value={fieldTypes.find(option => option.value === fieldType) || null}
                                                    onChange={selectedOption}
                                                    isSearchable={true}
                                                    required
                                                />
                                            </FloatingLabel>
                                            {fieldReq && !fieldTypes && (
                                                <small id={`Error`} className="form-text text-muted ">
                                                    Please Enter Field Name
                                                </small>
                                            )}
                                        </div>
                                        {(fieldType === "Drop Down" ||
                                            fieldType === "Check Box" ||
                                            fieldType === "Radio") && (
                                                <div className="form-field options-part">
                                                    <div
                                                        className="mb-3_health"
                                                        controlId="exampleForm.Health"
                                                    >
                                                        {optionCount.map((item, i) => (
                                                            <div
                                                                key={i}
                                                                className="model-inputs dflex-align-center mb-3"
                                                            >
                                                                <div className="option-label">
                                                                    <FloatingLabel
                                                                        label={`Option-${i + 1}`}
                                                                        className={`${item.value
                                                                            ? "float-input-visible"
                                                                            : "float-hidden float-input"
                                                                            }`}
                                                                    >
                                                                        <input
                                                                            className="form-control add-user-input"
                                                                            type="text"
                                                                            value={item.value}
                                                                            name="fieldOptions"
                                                                            onChange={(event) =>
                                                                                setOptionsVal(event, i)
                                                                            }
                                                                            placeholder=""
                                                                            autoComplete="off"
                                                                            required
                                                                        />
                                                                    </FloatingLabel>
                                                                </div>
                                                                <div className="remove-option">
                                                                    <i
                                                                        className="fa fa-times"
                                                                        onClick={() => removeOption(i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {fieldType &&
                                                            (fieldType === "Drop Down" ||
                                                                fieldType === "Check Box" ||
                                                                fieldType === "Radio") &&
                                                            !optionCount.length && (
                                                                <small
                                                                    id={`Error`}
                                                                    className="form-text text-muted "
                                                                >
                                                                    Please Enter Field Options
                                                                </small>
                                                            )}
                                                        <div
                                                            className="add-option-icon"
                                                            onClick={OptionCount}
                                                        >
                                                            <span className="plus-icon">
                                                                <i className="fa fa-plus" />
                                                            </span>
                                                            <span className="add-option-text">
                                                                Add Option
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        {editId && (
                                            <div className="mt-3">
                                                <div className="form-field form-status-section">
                                                    <Row style={{ width: "100%" }}>
                                                        <Col md={6} className="cat-col">
                                                            <div>
                                                                <Form.Label className="require">Status</Form.Label>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="cat-col">
                                                            <div className="btn-status">
                                                                <Form.Group className="facility-details">
                                                                    <div
                                                                        className={`checkbox1 ${status == 1 ? "checkbox--on" : ""
                                                                            }`}
                                                                        name="status"
                                                                        onClick={(e) => statusFlag(status)}
                                                                    >
                                                                        <div className="checkbox__ball">
                                                                            <span
                                                                                className={
                                                                                    status == 1 ? "enable-text" : "disable-text"
                                                                                }
                                                                            >
                                                                                {status == 1 ? "Enable" : "Disable"}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mt-3">
                                            <div className="form-field form-status-section">
                                                <Row style={{ width: "100%" }}>
                                                    <Col md={6} className="cat-col">
                                                        <div>
                                                            <Form.Label className="require">IsRequired</Form.Label>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className="cat-col">
                                                        <div className="btn-status">
                                                            <Form.Group className="facility-details">
                                                                <div
                                                                    className={`checkbox1 ${isRequired == 1 ? "checkbox--on" : ""
                                                                        }`}
                                                                    name="status"
                                                                    onClick={(e) => fieldStatusType(isRequired)}
                                                                >
                                                                    <div className="checkbox__ball">
                                                                        <span
                                                                            className={
                                                                                isRequired == 1 ? "enable-text" : "disable-text"
                                                                            }
                                                                        >
                                                                            {isRequired == 1 ? "Mandatory" : "Optional"}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-page-save-cancel">
                                    <SBSaveUpdateBtn btnName={editId ? "Update" : "Save"} />
                                    <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddCustomerQuestionaires;
