import React, { useEffect, useLayoutEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPostHoliday,
  loadPostShift,
  loadUpdateHoliday,
} from "../../../redux/action";
import moment from "moment";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function AddShift(props) {
  let shiftId = props.shiftId;
  let dispatch = useDispatch();
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [statusValue, setStatusValue] = useState(false);
  const [validateRequired, setValidateRequired] = useState(false);
  const [dataInHrOrMin, setDataInHrOrMin] = useState("Min");
  const [checkIn, setCheckIn] = useState({ val: null, time: null });
  const [checkOut, setCheckOut] = useState({ val: null, time: null });

  const [shiftData, setShiftData] = useState({
    shiftName: "",
    shiftCheckInTime: "",
    shiftCheckOutTime: "",
    isNightShift: 0,
    shifts_CheckIn_Grace_Period_Before: 0,
    shifts_CheckIn_Grace_Period_After: 0,
    shifts_AutoCheckOut_Period: 0,
    shifts_MinutesToWork_FullDay: 0,
    shifts_MinutesToWork_HalfDay: 0,
    shifts_HRS_Min: "",
    shifts_Validate: 0,
  });

  // console.log(shiftData, "shiftData")
  const {
    shiftName,
    shiftCheckInTime,
    shiftCheckOutTime,
    isNightShift,
    shifts_CheckIn_Grace_Period_Before,
    shifts_CheckIn_Grace_Period_After,
    shifts_AutoCheckOut_Period,
    shifts_MinutesToWork_FullDay,
    shifts_MinutesToWork_HalfDay,
    shifts_HRS_Min,
    shifts_Validate,
  } = shiftData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.type === "number") {
      setShiftData({ ...shiftData, [name]: Math?.round(+value) });
      return;
    }
    setShiftData({ ...shiftData, [name]: value });
  };

  useEffect(() => {
    if (shiftId) {
      if (props.shiftData) {
        props.shiftData.map((item) => {
          if (item.shiftID === shiftId) {
            setShiftData({
              shiftID: item.shiftID,
              shiftName: item.shiftName,
              shiftCheckInTime: item.shiftCheckInTime,
              shiftCheckOutTime: item.shiftCheckOutTime,
              isNightShift: item.isNightShift,
              shifts_CheckIn_Grace_Period_Before:
                item.shifts_CheckIn_Grace_Period_Before,
              shifts_CheckIn_Grace_Period_After:
                item.shifts_CheckIn_Grace_Period_After,
              shifts_AutoCheckOut_Period: item.shifts_AutoCheckOut_Period,
              shifts_MinutesToWork_FullDay: item.shifts_MinutesToWork_FullDay,
              shifts_MinutesToWork_HalfDay: item.shifts_MinutesToWork_HalfDay,
              shifts_HRS_Min: item.shifts_HRS_Min,
              shifts_Validate: item.shifts_Validate,
            });
            const formatString = "h:mm A";
            const checkIndateTimeString = moment(
              item.shiftCheckInTime,
              formatString
            ).format();
            const checkInObject = new Date(checkIndateTimeString);
            setCheckIn({
              val: checkInObject,
              time: `${checkInObject.$H}:${checkInObject.$m}`,
            });

            const checkOutdateObject = moment(
              item.shiftCheckOutTime,
              formatString
            ).format();
            const checkOutObject = new Date(checkOutdateObject);
            setCheckOut({
              val: checkOutObject,
              time: `${checkOutObject.$H}:${checkOutObject.$m}`,
            });
            if (item.isNightShift === 1) {
              setStatusValue(true);
            } else {
              setStatusValue(false);
            }
            if (item.shifts_Validate === 1) {
              setValidateRequired(true);
            } else {
              setValidateRequired(false);
            }
          }
        });
      }
    } else {
      setShiftData({
        shiftName: "",
        shiftCheckInTime: "",
        shiftCheckOutTime: "",
        isNightShift: 0,
        shifts_CheckIn_Grace_Period_Before: 0,
        shifts_CheckIn_Grace_Period_After: 0,
        shifts_AutoCheckOut_Period: 0,
        shifts_MinutesToWork_FullDay: 0,
        shifts_MinutesToWork_HalfDay: 0,
        shifts_HRS_Min: "",
        shifts_Validate: 0,
      });
    }
  }, [shiftId, props.shiftData]);

  const cancelModale = () => {
    props.shiftModuleClose();
    props.setShiftId("");
  };

  const statusFlag = (statusValue) => {
    setStatusValue(!statusValue);
    if (!statusValue === true) {
      setShiftData({ ...shiftData, isNightShift: 1 });
    } else if (!statusValue === false) {
      setShiftData({ ...shiftData, isNightShift: 0 });
    }
  };

  const validateStatus = (validateRequired) => {
    setValidateRequired(!validateRequired);
    if (!validateRequired === true) {
      setShiftData({ ...shiftData, shifts_Validate: 1 });
    } else if (!validateRequired === false) {
      setShiftData({ ...shiftData, shifts_Validate: 0 });
    }
  };

  const selectCheckInTime = (time) => {
    setCheckIn({ val: time, time: `${time.$H}:${time.$m}` });
    let timeS = time.$d.toTimeString().slice(0, 5);

    const currentTime = timeS.slice(0, 2);

    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        // console.log("0" + exactTime + " " + "PM", "timeStimeStimeS")
        shiftData.shiftCheckInTime = "0" + exactTime + " " + "PM";
        // setCheckInTime("0" + exactTime + " " + "PM");
      } else {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        shiftData.shiftCheckInTime = exactTime + " " + "PM";
        // setCheckInTime(exactTime + " " + "PM");
      }
    } else if (currentTime === 12) {
      // setCheckInTime(timeS + " " + "PM");
      shiftData.shiftCheckInTime = timeS + " " + "PM";
    } else if (currentTime === 0) {
      let str = timeS.split("");
      str.splice(0, 2, 12);
      let exactTime = str.join("");
      shiftData.shiftCheckInTime = exactTime + " " + "AM";
      // setCheckInTime(exactTime + " " + "AM");
    } else {
      shiftData.shiftCheckInTime = timeS + " " + "AM";
      // setCheckInTime(timeS + " " + "AM");
    }
  };

  const selectCheckOutTime = (time) => {
    // console.log(`${time.$H}:${time.$m}`, "time")
    setCheckOut({ val: time, time: `${time.$H}:${time.$m}` });
    let timeS = time.$d.toTimeString().slice(0, 5);

    const currentTime = timeS.slice(0, 2);

    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        // console.log("0" + exactTime + " " + "PM", "timeStimeStimeS")
        // setCheckOutTime("0" + exactTime + " " + "PM");
        shiftData.shiftCheckOutTime = "0" + exactTime + " " + "PM";
      } else {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        shiftData.shiftCheckOutTime = exactTime + " " + "PM";
        // setCheckOutTime(exactTime + " " + "PM");
      }
    } else if (currentTime === 12) {
      shiftData.shiftCheckOutTime = timeS + " " + "PM";
      // setCheckOutTime(timeS + " " + "PM");
    } else if (currentTime == 0) {
      let str = timeS.split("");
      str.splice(0, 2, 12);
      let exactTime = str.join("");
      shiftData.shiftCheckOutTime = exactTime + " " + "AM";
      // setCheckOutTime(exactTime + " " + "AM");
    } else {
      shiftData.shiftCheckOutTime = timeS + " " + "AM";
      // setCheckOutTime(timeS + " " + "AM");
    }
  };

  const [fieldReq, setFieldReq] = useState(false);

  const saveShiftData = (e) => {
    e.preventDefault();
    if (
      shiftName == "" ||
      shiftCheckInTime == "" ||
      shiftCheckOutTime == "" ||
      shifts_AutoCheckOut_Period == 0 ||
      (shifts_Validate == 1
        ? shifts_CheckIn_Grace_Period_Before == 0 &&
          shifts_CheckIn_Grace_Period_After == 0
        : shifts_CheckIn_Grace_Period_Before > 0 &&
          shifts_CheckIn_Grace_Period_After > 0)
    ) {
      setFieldReq(true);
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify([shiftData]),
      };
      dispatch(loadPostShift(postRequest, cancelModale, shiftId));
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.shiftModuleClose}
        className="main_model add-edit-model "
      >
        {/* category-modal-div */}
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            {shiftId ? "Edit Shift" : "Add Shift"}
          </h6>
          <i className="fa fa-times" onClick={cancelModale} />
        </div>
        <Form onSubmit={saveShiftData}>
          <div className="container add-edit mt-3">
            <div className="model-inputs mb-3 form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                <FloatingLabel
                  label="Shift Name"
                  className={`${
                    shiftName || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    value={shiftName || ""}
                    name="shiftName"
                    onChange={handleInputChange}
                    placeholder="Shift Name"
                    autoComplete="off"
                  />
                </FloatingLabel>
                {fieldReq && !shiftName && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Shift Name
                  </small>
                )}
              </Form.Group>
            </div>
            <div className=" model-inputs mb-3 form-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <Form.Label className="require">Check In Time</Form.Label> */}
                <FloatingLabel
                  label="Check In Time"
                  className={`${
                    checkIn.val !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <DesktopTimePicker
                    // label="Time"
                    name="shiftCheckInTime"
                    className="select-time-input"
                    value={checkIn.val}
                    onChange={(time) => {
                      selectCheckInTime(time);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    // minTime={dayjs("2023-01-30T09:00")}
                    // maxTime={dayjs("2023-01-31T22:00")}
                    // minutesStep={15}
                  />
                </FloatingLabel>
                {fieldReq && !checkIn.val && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select CheckIn Time
                  </small>
                )}
              </LocalizationProvider>
            </div>
            <div className="model-inputs mb-3 form-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FloatingLabel
                  label="Check Out Time"
                  className={`${
                    checkOut.val !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <DesktopTimePicker
                    name="shiftCheckOutTime"
                    className="select-time-input"
                    value={checkOut.val}
                    onChange={(time) => {
                      selectCheckOutTime(time);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FloatingLabel>
                {fieldReq && !checkOut.val && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select CheckIn Time
                  </small>
                )}
              </LocalizationProvider>
            </div>

            <div className="model-inputs mb-3 form-field form-status-section">
              <div>
                <Form.Label className="require">Is NightShift</Form.Label>
              </div>
              <div className="btn-status">
                <Form.Group className="facility-details">
                  <div
                    className={`checkbox ${statusValue ? "checkbox--on" : ""}`}
                    name="status"
                    onClick={(e) => statusFlag(statusValue)}
                  >
                    <div className="checkbox__ball">
                      <span
                        className={statusValue ? "enable-text" : "disable-text"}
                      >
                        {statusValue ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="dflex-j-between mb-3">
              <FloatingLabel
                label="Early Check-In By Minutes"
                className={`${
                  shifts_CheckIn_Grace_Period_Before || ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
                style={{ width: "100%", paddingRight: "10px" }}
              >
                <Form.Control
                  type="number"
                  value={shifts_CheckIn_Grace_Period_Before || ""}
                  name="shifts_CheckIn_Grace_Period_Before"
                  onChange={handleInputChange}
                  placeholder="Early Check-In By Minutes"
                  autoComplete="off"
                />
              </FloatingLabel>

              <FloatingLabel
                label="Late Check-In By Minutes"
                className={`${
                  shifts_CheckIn_Grace_Period_After || ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
                style={{ width: "100%", paddingRight: "10px" }}
              >
                <Form.Control
                  type="number"
                  value={shifts_CheckIn_Grace_Period_After || ""}
                  name="shifts_CheckIn_Grace_Period_After"
                  onChange={handleInputChange}
                  placeholder="Late Check-In By Minutes"
                  autoComplete="off"
                />
              </FloatingLabel>
            </div>
            {fieldReq &&
              validateRequired == true &&
              (shifts_CheckIn_Grace_Period_Before == 0 ||
                shifts_CheckIn_Grace_Period_After == 0) && (
                <small id={`Error`} className="form-text text-muted ">
                  Please enter Early or Maximum Late Check-In Minutes properly
                </small>
              )}
            {fieldReq &&
              validateRequired == false &&
              (shifts_CheckIn_Grace_Period_Before !== 0 ||
                shifts_CheckIn_Grace_Period_After !== 0) && (
                <small id={`Error`} className="form-text text-muted ">
                  Early or Maximum Late Check-In Minutes should be 0
                </small>
              )}

            <div className="model-inputs mb-3 form-field form-status-section">
              <div>
                <Form.Label className="require">
                  Validation Required for Check-In
                </Form.Label>
              </div>
              <div className="btn-status">
                <Form.Group className="facility-details">
                  <div
                    className={`checkbox ${
                      validateRequired ? "checkbox--on" : ""
                    }`}
                    name="status"
                    onClick={(e) => validateStatus(validateRequired)}
                  >
                    <div className="checkbox__ball">
                      <span
                        className={
                          validateRequired ? "enable-text" : "disable-text"
                        }
                      >
                        {validateRequired ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="radio-labels">
              <div className="radio-label color-green mb-2">
                Enter below data in{" "}
              </div>
              <div className="model-inputs hv-center mb-3">
                {["Hrs", "Min"].map((item, i) => (
                  <div key={i}>
                    <Form.Check
                      inline
                      label={item}
                      value={item}
                      name="shifts_HRS_Min"
                      type="radio"
                      id={`inlineradio-hrsmin-${i}`}
                      // onChange={(e) => setDataInHrOrMin(e.target.value)}
                      onChange={(e) => {
                        handleInputChange(e);
                        setDataInHrOrMin(e.target.value);
                      }}
                      checked={dataInHrOrMin == item ? true : false}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="model-inputs mb-3">
              <FloatingLabel
                label={
                  dataInHrOrMin === "Hrs"
                    ? "Auto Check Out Hours"
                    : "Auto Check Out Minutes"
                }
                className={`${
                  shifts_AutoCheckOut_Period || ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
              >
                <Form.Control
                  type="number"
                  value={shifts_AutoCheckOut_Period || ""}
                  name="shifts_AutoCheckOut_Period"
                  onChange={handleInputChange}
                  placeholder={
                    dataInHrOrMin === "Hrs"
                      ? "Auto Check Out Hours"
                      : "Auto Check Out Minutes"
                  }
                  autoComplete="off"
                />
              </FloatingLabel>
              {fieldReq && !shifts_AutoCheckOut_Period && (
                <small id={`Error`} className="form-text text-muted ">
                  Please Enter Auto Check Out Minutes
                </small>
              )}
            </div>
            <div className="model-inputs mb-3 form-field">
              <FloatingLabel
                label={
                  dataInHrOrMin === "Hrs"
                    ? "Hours to work Full Day"
                    : "Minutes to work Full Day"
                }
                className={`${
                  shifts_MinutesToWork_FullDay || ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
              >
                <Form.Control
                  type="number"
                  value={shifts_MinutesToWork_FullDay || ""}
                  name="shifts_MinutesToWork_FullDay"
                  onChange={handleInputChange}
                  placeholder={
                    dataInHrOrMin === "Hrs"
                      ? "Hours to work Full Day"
                      : "Minutes to work Full Day"
                  }
                  autoComplete="off"
                />
              </FloatingLabel>
            </div>
            <div className="model-inputs mb-3 form-field">
              <FloatingLabel
                label={
                  dataInHrOrMin === "Hrs"
                    ? "Hours to work Half Day"
                    : "Minutes to work Half Day"
                }
                className={`${
                  shifts_MinutesToWork_HalfDay || ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
              >
                <Form.Control
                  type="number"
                  value={shifts_MinutesToWork_HalfDay || ""}
                  name="shifts_MinutesToWork_HalfDay"
                  onChange={handleInputChange}
                  placeholder={
                    dataInHrOrMin === "Hrs"
                      ? "Hours to work Half Day"
                      : "Minutes to work Half Day"
                  }
                  autoComplete="off"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn btnName={shiftId ? "Update" : "Save"} />
            <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default AddShift;
