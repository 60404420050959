import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";

function PassBookHistoryTable(props) {
  let tableHeader = [
    { name: "Referal Code", align: "left", width: "100px" },
    { name: "Transaction Id", align: "left", width: "150px" },
    { name: "PID", align: "left", width: "150px" },
    { name: "Product Value", align: "left", width: "100px" },
    { name: "TAX", align: "left", width: "100px" },
    { name: "Total Product Value", align: "left", width: "150px" },
    { name: "Discount", align: "left", width: "100px" },
    { name: "Commission", align: "left", width: "100px" },
    { name: "Total Commission", align: "left", width: "100px" },
  ];

  return (
    <>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="basefare-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {tableHeader.map((name, i) => (
                    <TableCell
                      key={i}
                      align={name.align}
                      className={`thead-cell-products`}
                      style={{ minWidth: name.width }}
                    >
                      {name.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {props.isLoading ? (
                <>
                  {tableHeader.map((name, i) => (
                    <SkeletonMeetPurpose key={i} />
                  ))}
                </>
              ) : (
                <>
                  {props.userTransactionDetails && props.userTransactionDetails.length === 0 ? (
                    <TableBody>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={tableHeader.length}>
                          <div style={{ padding: "20px 20px" }}>
                            <img
                              src="../../../../images/DefaultImages/NodataFound.jpg"
                              alt=""
                              style={{ width: "10%" }}
                            />
                            <br />
                            <span>No Data Found...!</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {props.userTransactionDetails?.[0] && (
                        <TableRow align="center" className="client-list-row-data">
                          <TableCell>{props.userTransactionDetails[0]?.referelCode}</TableCell>
                          <TableCell>{props.userTransactionDetails[0]?.transactionID}</TableCell>
                          <TableCell>{props.userTransactionDetails[0]?.pid}</TableCell>
                          <TableCell>{props.userTransactionDetails[0]?.prodValue}</TableCell>
                          <TableCell>{props.userTransactionDetails[0]?.tax}</TableCell>
                          <TableCell>{props.userTransactionDetails[0]?.totalProdValue}</TableCell>
                          <TableCell>{props.userTransactionDetails[0]?.discount}</TableCell>
                          <TableCell>{props.userTransactionDetails[0]?.commValueUnit}</TableCell>
                          <TableCell>{props.userTransactionDetails[0]?.totalCommValue}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
}

export default PassBookHistoryTable;
