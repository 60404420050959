import React, { useEffect, useRef, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import "../../styles/AddPage.scss";
import { Col, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { loadClientComplaint, loadComplaintLog, loadCustomeFieldsForCustomerQuestionnair, loadCustomeFieldsForServiceEnggQuestionnair, loadServiceRepairReport, loadSingleClientCompalint, loadSingleProduct, loadSingleRowFormData } from "../../redux/action";
import ServiceRequestStatusUpdatePage from "./ServiceRequestStatusUpdatePage";
import AssignToServiceRequest from "./AssignToServiceRequest";
import AddServiceRequestData from "./AddServiceRequestData";
import CustomerQs from "./CustomerQs";
import ServiceEngineerQs from "./ServiceEngineerQs";
import AnnualContractStatus from "./AnnualContractStatus";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useReactToPrint } from "react-to-print";
import UpdatePriority from "./UpdatePriority";
import ViewIssueImages from "./ViewIssueImages";
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

function ViewServiceRequest(props) {

    let tableHeader = [
        { name: "LOG DATE", align: "left", width: "180px" },
        { name: "ACTION", align: "left", width: "180px" },
        { name: "ACTION BY", align: "left", width: "220px" },
    ];

    const escalationHistoryData = [
        {
            on: "05/05/2024",
            to: "  Manager",
            remark: "Urgent issue requiring immediate attention.",
        },
        {
            on: "12/05/2024",
            to: "Supervisor",
            remark: "Customer escalation due to service outage.",
        },
        {
            on: "18/05/2024",
            to: "Team Lead",
            remark: "Escalated for further investigation.",
        },
        {
            on: "25/05/2024",
            to: "Director",
            remark: "  Requesting higher-level review and intervention.",
        },
        {
            on: "02/06/2024",
            to: "Senior Manager",
            remark: " Escalation due to unresolved customer complaint.",
        },
        {
            on: " 10/06/2024",
            to: "Senior Executive",
            remark: " Escalated due to repeated customer complaints.",
        },
        {
            on: "17/06/2024",
            to: " Department Head",
            remark: " Issue requires specialized technical expertise.",
        },
        {
            on: " 24/06/2024",
            to: "Operations Manager",
            remark: " Requesting expedited resolution.",
        },
        {
            on: " 01/07/2024",
            to: "Senior Analyst",
            remark: " Escalation triggered by service level agreement breach.",
        },
        {
            on: " 08/07/2024",
            to: "Support Specialist",
            remark: " Seeking senior management intervention to address customer dissatisfaction.",
        },
    ]

    let navigate = useNavigate();
    let dispatch = useDispatch();
    const { id } = useParams();
    const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
    const { singleClientCompaint } = useSelector((state) => state.data)
    const { clientComplaints } = useSelector((state) => state.data);
    const { getSingleRowFormData } = useSelector((state) => state.data);
    console.log(getSingleRowFormData, 'getSingleRowFormData')
    const { getCustomeFieldsForCustomerQuestion } = useSelector((state) => state.data);
    const { getCustomeFieldsForServiceEnggQuestion } = useSelector((state) => state.data);
    const { getComplaintLog } = useSelector((state) => state.data);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (id) {
            dispatch(loadSingleClientCompalint(id));
            dispatch(loadCustomeFieldsForCustomerQuestionnair(id));
            dispatch(loadComplaintLog(id));
        }
    }, [id, dispatch])

    const [menuCollapse, setMenuCollapse] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [PageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [filterArray, setFilterArray] = useState([]);
    const [fromDate, setFromDate] = useState(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    );
    const [toDate, setToDate] = useState(new Date());

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    // PAGINATION START
    const [pageDataSize, setPageDataSize] = useState(0);
    const pageDataFrom = currentPage * PageSize - PageSize + 1;
    const pageDataTill = pageDataFrom + pageDataSize - 1;
    // PAGINATION END

    const [feedBackImages, setFeedBackImages] = useState(false)
    const handleViewAttachments = () => {
        setFeedBackImages(true);
    }


    const [viewProductDetail, setViewProductDetail] = useState(false);
    const openViewPage = (prodId) => {
        navigate(`/machines/${prodId}`);
        dispatch(loadSingleProduct(prodId));
        setViewProductDetail(true);
    };

    // Page navigation
    const goToBackPage = () => {
        navigate(-1);
    };
    // page navigation

    // Open Filter div
    const [filter, setFilter] = useState(false);
    // Open Filter div

    const setSelectSearchDrop = (data, type) => {
        let list = [];
        if (type === "date") {
            const [start, end] = data;
            if (filterArray.length !== 0) {
                if (start) {
                    list = filterArray.filter((item) => item.key !== "FromDate");
                }
                if (end) {
                    list = filterArray.filter((item) => item.key !== "ToDate");
                }
            }
            if (start && end == null) {
                list.push({
                    key: "FromDate",
                    value: moment(new Date(start)).format("DD/MM/YYYY"),
                });
            }
            setFromDate(start);
            if (end) {
                list.push({
                    key: "ToDate",
                    value: moment(new Date(end)).format("DD/MM/YYYY"),
                });
            }
            setToDate(end);
        }
        setFilterArray(list);
    };

    const [escalationId, setEscalationId] = useState()
    const openServiceRequestEscalationPage = (esId) => {
        setEscalationId(esId)
        navigate('/serviceRequestEscalation', { state: { esId: esId } });
    }

    let currentTime = moment(new Date()).format("DD/MM/YYYY");
    useEffect(() => {
        fetchComplaintdata(filterArray, PageSize, currentPage);
    }, [filterArray, PageSize, currentPage]);

    const [compaintId, setCompaintId] = useState("");

    const [showAssign, setShowAssign] = useState(false);
    const handleAssign = (id) => {
        setCompaintId(id);
        dispatch(loadSingleClientCompalint(id));
        setShowAssign(true);
    };

    const handleEditCompleint = (id) => {
        setCompaintId(id);
        dispatch(loadSingleClientCompalint(id));
        setShow(true);
    };

    const [showUpdate, setShowUpdate] = useState(false);
    const handleStatus = (id) => {
        setCompaintId(id);
        dispatch(loadSingleClientCompalint(id));
        setShowUpdate(true);
    };

    const [showPriority, setShowPriority] = useState(false);
    const handlePriority = (id) => {
        setCompaintId(id);
        dispatch(loadSingleClientCompalint(id));
        setShowPriority(true);
    };


    const fetchComplaintdata = () => {
        if (filterArray.length !== 0) {
            dispatch(
                loadClientComplaint(
                    filterArray,
                    currentPage,
                    PageSize,
                    setIsLoading,
                    setLoading
                )
            );
        }
    };

    const handleClose = () => {
        setShow(false);
        setViewProductDetail(false);
    };

    const [activeTab, setActiveTab] = useState('Customer Questions');

    const handleTabSelect = (key) => {
        // Add your custom logic here when a tab is clicked
        setActiveTab(key);
        if (key === "Service Engineer Questions") {
            dispatch(loadCustomeFieldsForServiceEnggQuestionnair(id));
        }
    };

    const [showStatus, setShowStatus] = useState(false)

    // const handleShowStatus = () => {
    //     setShowStatus(!showStatus)
    // }
    const handleShowStatus = (machId) => {
        dispatch(loadSingleRowFormData(machId));
        setShowStatus(!showStatus)
    }


    const componentRef = useRef();
    const pdfDownload = useReactToPrint({
        content: () => componentRef.current,
    });

    const decodeBase64Image = (base64Image) => {
        return `data:image/jpeg;base64,${base64Image}`;
    };

    const [showImageModal, setShowImageModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const [modalTitle, setModalTitle] = useState('');

    const handleOpenModal = (image, title) => {
        setModalImage(image);
        setModalTitle(title);
        setShowImageModal(true);
    };

    const handleCloseModal = () => {
        setShowImageModal(false);
        setModalImage(null);
        setModalTitle('');
    };

    const serviceReportDownload = () => {
        dispatch(loadServiceRepairReport(id, navigateToReport))
    }

    const navigateToReport = () => {
        navigate('/servicereport', { state: { id: id } })
    }

    const buttonRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tootTipId, setTooltipId] = useState(null);

    const handleClick = (id) => {
        setShowTooltip(!showTooltip);
        setTooltipId(id);
    };

    const renderTooltip = (dynamicValue) => (
        <Tooltip id={`tooltip-serviceRequest`}>{dynamicValue}</Tooltip>
    );

    const statusMap = {
        1: { message: "Pending", className: "pending-status" },
        2: { message: "Follow Up", className: "follow-status" },
        3: { message: "Resolved", className: "follow-status" },
        4: { message: "Closed", className: "follow-status" },
        5: { message: "No AMC, No Warranty", className: "follow-status" },
        6: { message: "On the Way", className: "follow-status" },
        7: { message: "CheckIn", className: "follow-status" },
        8: { message: "Escalation", className: "follow-status" },
        9: { message: "Feedback", className: "follow-status" },
        10: { message: "Submitted", className: "complete-status" },
        11: { message: "Accepted", className: "complete-status" },
    };

    const StatusBadge = ({ action }) => {
        const status = statusMap[action];

        return status ? (
            <span className={`${status.className} status-badge`}>
                {status.message}
            </span>
        ) : null;
    };

    const renderStars = (rating) => {
        const maxRating = 5; // Maximum number of stars
        return Array.from({ length: maxRating }, (_, index) => {
            const starRating = rating - index;
            if (starRating >= 1) {
                return <FaStar key={index} color="gold" fontSize="20px" />;
            }
            if (starRating >= 0.5) {
                return <FaStarHalfAlt key={index} color="gold" fontSize="20px" />;
            }
            return <FaRegStar key={index} color="gold" fontSize="20px" />;
        });
    };

    return (
        <div id="main-page">
            {/* SIDEBAR START */}
            {!showStatus && !show && <>
                <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                {/* SIDEBAR END */}

                {/* TOPBAR START */}
                <Topbar
                    toggleMenu={toggleMenu}
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />
                {/* TOPBAR END */}

                {/* MAIN BODY START */}
                <div className="page-wrapper">
                    <div className={`main-body ${toggleshift.style}`}>
                        <div className="page-content" >
                            <div className="user-table">
                                <Row className='search-row'>
                                    <Col md={11} className='cat-col'>
                                        <div className='page-header-text-div'>
                                            <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                            <h6 className='page-header-text' >VIEW SERVICE REQUEST</h6>
                                        </div>
                                    </Col>
                                    <Col md={1} className='cat-col'>
                                        {/* {(singleClientCompaint?.status === 1 || singleClientCompaint?.status === 2) && ( */}
                                        <OverlayTrigger
                                            placement={"top"}
                                            delay={{ show: 250, hide: 300 }}
                                            overlay={
                                                <Tooltip id={`tooltip-dashboard`}>
                                                    Edit Complaint
                                                </Tooltip>
                                            }
                                        >
                                            <button
                                                onClick={(e) =>
                                                    handleEditCompleint(
                                                        singleClientCompaint?.clientComplaintsID
                                                    )
                                                }
                                                className="btn client-history-link"
                                            >
                                                <i className="fa fa-edit" />
                                            </button>
                                        </OverlayTrigger>
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </div>
                            <div ref={componentRef}>
                                <Row>
                                    <Col md={8}>
                                        <div className="clients-table p-3">
                                            <div className="mi-subform-header">
                                                <div className='form-header-sub'>
                                                    <h6>Service Details<span></span></h6>
                                                </div>
                                                {/* <div><p>Please enter company information and proceed to the next step.</p></div> */}
                                            </div>
                                            <Row className='order-details-row'>
                                                <Col md={6} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li><div className="span1">Service Id</div> &nbsp;&nbsp;<div className="span2"><b className="color-green" style={{ fontSize: "16px" }}>#{singleClientCompaint?.clientComplaintsID}</b></div></li>
                                                        <li><div className="span1">Date</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.date}</b></div></li>
                                                        <li><div className="span1">Reported By</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.reportedBy ? singleClientCompaint?.reportedBy : "NA"}</b></div></li>
                                                        <li><div className="span1">Assigned To</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.assingnedToName}
                                                            <OverlayTrigger
                                                                placement={"top"}
                                                                delay={{ show: 250, hide: 300 }}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-dashboard`}>
                                                                        Re Assign
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handleAssign(singleClientCompaint?.clientComplaintsID)
                                                                    }
                                                                    // onClick={handleAssign}
                                                                    className="btn client-asign-btn"
                                                                    disabled={
                                                                        !(
                                                                            resPassData &&
                                                                            resPassData?.desingation?.role?.roleName &&
                                                                            (resPassData?.desingation?.role?.roleName === "Admin" ||
                                                                                resPassData?.desingation?.role?.roleName === "Manager")
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa fa-edit" />
                                                                </button>
                                                            </OverlayTrigger></b></div></li>
                                                    </ul>
                                                </Col>
                                                <Col md={6} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li
                                                            style={{ cursor: "pointer" }}
                                                            onClick={(e) =>
                                                                openViewPage(singleClientCompaint?.productIDFK)
                                                            }>
                                                            {/* <div className="span1">Product:</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint.productName}</b></div> */}
                                                        </li>
                                                        <li><div className="span1">Priority</div> &nbsp;&nbsp;<div className="span2">
                                                            <b>{singleClientCompaint?.priority === 3 ? (
                                                                <span className="pending-status status-badge">
                                                                    High
                                                                </span>
                                                            ) : singleClientCompaint?.priority === 2 ? (
                                                                <span className="follow-status status-badge">
                                                                    Medium
                                                                </span>
                                                            ) : (
                                                                singleClientCompaint?.priority === 1 && (
                                                                    <span className="complete-status status-badge">
                                                                        Low
                                                                    </span>
                                                                )
                                                            )}
                                                            </b>
                                                            <OverlayTrigger
                                                                placement={"top"}
                                                                delay={{ show: 250, hide: 300 }}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-dashboard`}>
                                                                        Update Priority
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        handlePriority(singleClientCompaint?.clientComplaintsID)
                                                                    }
                                                                    // onClick={handleAssign}
                                                                    className="btn client-asign-btn"
                                                                    disabled={
                                                                        !(
                                                                            resPassData &&
                                                                            resPassData?.desingation?.role?.roleName &&
                                                                            (resPassData?.desingation?.role?.roleName === "Admin" ||
                                                                                resPassData?.desingation?.role?.roleName === "Manager")
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa fa-edit" />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        </li>


                                                        <li><div className="span1">TAT</div> &nbsp;&nbsp;<div className="span2"><b className="color-green">
                                                            {moment(currentTime, "DD/MM/YYYY").diff(
                                                                moment(singleClientCompaint?.date, "DD/MM/YYYY"),
                                                                "days"
                                                            )}{" "}
                                                            days</b></div></li>

                                                        <li><div className="span1">Escalation Manager</div> &nbsp;&nbsp;<div className="span2"><b>
                                                            <OverlayTrigger
                                                                placement={"top"}
                                                                delay={{ show: 250, hide: 300 }}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-dashboard`}>
                                                                        Escalation
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button
                                                                    onClick={() => { openServiceRequestEscalationPage(singleClientCompaint.assingnedTo) }}
                                                                    className="btn client-history-link"
                                                                    disabled={
                                                                        !(
                                                                            resPassData &&
                                                                            resPassData?.desingation?.role?.roleName &&
                                                                            (resPassData?.desingation?.role?.roleName === "Admin" ||
                                                                                resPassData?.desingation?.role?.roleName === "Manager")
                                                                        )
                                                                    }
                                                                >
                                                                    <img src="../../images/escalation.png" alt="" style={{ width: "30px", height: "30px", color: "#5842bc" }} />
                                                                </button>
                                                            </OverlayTrigger></b></div></li>
                                                    </ul>
                                                </Col>
                                                <Col md={12}>
                                                    <ul className="order-detail-list">
                                                        <li><div className="span1" style={{ width: "14%" }}>Issue</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.issue}</b></div></li>
                                                        {/* <li><div className="span1" style={{ width: "14%" }}>Remarks</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.description}</b></div></li> */}
                                                        {/* <li>
                                                            <div className="span1" style={{ width: "14%" }}>Remarks</div>
                                                            <div className="span2">
                                                                <b>
                                                                    {singleClientCompaint?.remark ? (
                                                                        singleClientCompaint?.remark.length > 150
                                                                            ? singleClientCompaint?.remark.substring(0, 150) + "..."
                                                                            : singleClientCompaint?.remark
                                                                    ) : (
                                                                        "NA"
                                                                    )}
                                                                    &nbsp;&nbsp;
                                                                    {singleClientCompaint?.remark?.length > 150 && (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={renderTooltip(singleClientCompaint?.remark)}
                                                                            show={showTooltip && tootTipId === singleClientCompaint?.clientComplaintsID}
                                                                        >
                                                                            <i
                                                                                ref={buttonRef}
                                                                                id="info"
                                                                                className="fa fa-info-circle details-icon"
                                                                                onClick={(e) => handleClick(singleClientCompaint?.clientComplaintsID)}
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </OverlayTrigger>
                                                                    )}
                                                                </b>

                                                            </div>
                                                        </li> */}
                                                        <li>
                                                            <div className="span1" style={{ width: "14%" }}>Description</div> &nbsp;&nbsp;
                                                            <div className="span2">
                                                                <b>
                                                                    {singleClientCompaint?.description ? (
                                                                        singleClientCompaint?.description.length > 150
                                                                            ? singleClientCompaint?.description.substring(0, 150) + "..."
                                                                            : singleClientCompaint?.description
                                                                    ) : (
                                                                        "NA"
                                                                    )}
                                                                    &nbsp;&nbsp;
                                                                    {singleClientCompaint?.description?.length > 150 && (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={renderTooltip(singleClientCompaint?.description)}
                                                                            show={showTooltip && tootTipId === singleClientCompaint?.clientComplaintsID}
                                                                        >
                                                                            <i
                                                                                ref={buttonRef}
                                                                                id="info"
                                                                                className="fa fa-info-circle details-icon"
                                                                                onClick={(e) => handleClick(singleClientCompaint?.clientComplaintsID)}
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </OverlayTrigger>
                                                                    )}
                                                                </b>

                                                            </div>
                                                        </li>
                                                        <li><div className="span1" style={{ width: "14%" }}>Service Scheduled Time</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.expected_Reach_Time ? singleClientCompaint?.expected_Reach_Time : "NA"}</b></div></li>
                                                        {singleClientCompaint?.clientComplaints_Complaint_Reopen && (
                                                            <li>
                                                                <div className="span1" style={{ width: "14%" }}>Reopen</div>
                                                                &nbsp;&nbsp;
                                                                <div className="pending-status status-badge">
                                                                    <b>{singleClientCompaint.clientComplaints_Complaint_Reopen}</b>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr />
                                        <div className="clients-table p-2">
                                            <div className="mi-subform-header">
                                                <div className='form-header-sub'>
                                                    <h6>Customer Details<span></span></h6>
                                                </div>
                                                {/* <div><p>Please enter company information and proceed to the next step.</p></div> */}
                                            </div>
                                            <Row className=' order-details-row'>
                                                <Col md={6} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li><div className="span1">Customer Id</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.clientIDFk}</b></div></li>
                                                        <li><div className="span1">Contact Person</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.addedByUserFullName}</b></div></li>
                                                        <li><div className="span1">Email</div> &nbsp;&nbsp;<div className="span2"><b className="color-green">{singleClientCompaint?.clientEmail}</b></div></li>
                                                    </ul>
                                                </Col>
                                                <Col md={6} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li><div className="span1">Customer</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.clientName}</b></div></li>
                                                        <li><div className="span1">Phone</div> &nbsp;&nbsp;<div className="span2"><b className="color-green">{singleClientCompaint?.clientPhone}</b></div></li>
                                                        {/* <li><div className="span1">Location:</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint.address}</b></div></li> */}
                                                        <li><div className="span1">Branch</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.branch ?? "NA"}</b></div></li>
                                                    </ul>
                                                </Col>
                                                <Col md={12} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li><div className="span1" style={{ width: "14%" }}>Location</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.address}</b></div></li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr />
                                        <div className="clients-table p-2">
                                            <div className="mi-subform-header">
                                                <div className='form-header-sub'>
                                                    <h6>Machine Details<span></span></h6>
                                                </div>
                                                {/* <div><p>Please enter company information and proceed to the next step.</p></div> */}
                                            </div>
                                            <Row className=' order-details-row'>
                                                <Col md={6} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li><div className="span1">Machine Code</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.productCode}</b></div></li>
                                                        <li><div className="span1">Machine Name</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.productName}</b></div></li>
                                                        {/* <li><div className="span1">Description</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.description}</b></div></li> */}
                                                        {/* <li>
                                                            <div className="span1">Description</div>
                                                            <div className="span2">
                                                                <b>
                                                                    {singleClientCompaint?.description ? (
                                                                        singleClientCompaint?.description.length > 150
                                                                            ? singleClientCompaint?.description.substring(0, 150) + "..."
                                                                            : singleClientCompaint?.description
                                                                    ) : (
                                                                        "NA"
                                                                    )}
                                                                    &nbsp;&nbsp;
                                                                    {singleClientCompaint?.description?.length > 150 && (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={renderTooltip(singleClientCompaint?.description)}
                                                                            show={showTooltip && tootTipId === singleClientCompaint?.clientComplaintsID}
                                                                        >
                                                                            <i
                                                                                ref={buttonRef}
                                                                                id="info"
                                                                                className="fa fa-info-circle details-icon"
                                                                                onClick={(e) => handleClick(singleClientCompaint?.clientComplaintsID)}
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </OverlayTrigger>
                                                                    )}
                                                                </b>

                                                            </div>
                                                        </li> */}
                                                    </ul>
                                                </Col>
                                                <Col md={6} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li><div className="span1">AMC</div> &nbsp;&nbsp;
                                                            <div className="span2"><b>
                                                                {singleClientCompaint?.amC_app_rej_ID ?? "NA"}
                                                            </b></div>
                                                            <div className="span2">
                                                                <button
                                                                    onClick={() => handleShowStatus(JSON.stringify(singleClientCompaint.productIDFK))}
                                                                    className="btn client-asign-btn"
                                                                    disabled={
                                                                        !(
                                                                            resPassData &&
                                                                            resPassData?.desingation?.role?.roleName &&
                                                                            (resPassData?.desingation?.designationName === "General Manager" ||
                                                                                resPassData?.desingation?.role?.roleName === "Manager" ||
                                                                                resPassData?.desingation?.role?.roleName === "Admin")
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa fa-edit" />
                                                                </button>
                                                            </div>
                                                        </li>
                                                        <li><div className="span1">Warrenty Till</div> &nbsp;&nbsp;<div className="span2"><b>
                                                            {singleClientCompaint?.clientComplaints_Warranty_Till ?? "NA"}
                                                        </b></div></li>
                                                    </ul>
                                                </Col>
                                                <Col md={12} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li>
                                                            <div className="span1">Machine Issue Images</div>
                                                            &nbsp;&nbsp;
                                                            <div className="span2">
                                                                <b>
                                                                    {singleClientCompaint?.issueImage ?
                                                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                                                        <img src={decodeBase64Image(singleClientCompaint?.issueImage)} alt="issue image" style={{ width: "100px", height: "100px", cursor: "pointer" }}
                                                                            onClick={() => handleOpenModal(singleClientCompaint.issueImage, 'Machine Issue Image')}
                                                                        />
                                                                        :
                                                                        <span>No image found</span>
                                                                    }
                                                                </b>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col md={12} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li>
                                                            <div className="span1">Machine Solution Images</div>
                                                            &nbsp;&nbsp;
                                                            <div className="span2">
                                                                <b>
                                                                    {singleClientCompaint?.solutionImage ?
                                                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                                                        <img src={decodeBase64Image(singleClientCompaint?.solutionImage)} alt="issue image" style={{ width: "100px", height: "100px", cursor: "pointer" }}
                                                                            onClick={() => handleOpenModal(singleClientCompaint.solutionImage, 'Machine Solution Image')}
                                                                        />
                                                                        :
                                                                        <span>No image found</span>
                                                                    }
                                                                </b>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr />
                                        {/* <div className="clients-table p-2">
                                        <div className="mi-subform-header">
                                            <div className='form-header-sub'>
                                                <h6>Service History<span></span></h6>
                                            </div>
                                        </div>
                                        <Row className='search-row order-details-row'>
                                            <Col md={6} className='cat-col'>
                                                <ul className="order-detail-list">
                                                    <li><div className="span1">Service Date and Time:</div> &nbsp;&nbsp;<div className="span2"><b>15/04/2024 11:59 AM</b></div></li>
                                                    <li><div className="span1">Service Type:</div> &nbsp;&nbsp;<div className="span2"><b>routine maintenance</b></div></li>
                                                    <li><div className="span1">Technician:</div> &nbsp;&nbsp;<div className="span2"><b>Dummy</b></div></li>
                                                    <li><div className="span1">Machine ID:</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint.productCode}</b></div></li>
                                                    <li><div className="span1">Description:</div> &nbsp;&nbsp;
                                                        <div className="span2">
                                                            <b>Parts Replaced,Adjustments Made,Issues Identified</b>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col md={6} className='cat-col'>
                                                <ul className="order-detail-list">
                                                    <li><div className="span1">Service Duration:</div> &nbsp;&nbsp;<div className="span2"><b>4 hours</b></div></li>
                                                    <li><div className="span1">Service Location:</div> &nbsp;&nbsp;<div className="span2"><b>Race-coarse road bangalore</b></div></li>
                                                    <li><div className="span1">Service Status:</div> &nbsp;&nbsp;<div className="span2"><b>Complated</b></div></li>
                                                    <li><div className="span1">Service Comments:</div> &nbsp;&nbsp;<div className="span2"><b></b></div></li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div> */}
                                        <div className="clients-table p-2">
                                            <div className="mi-subform-header">
                                                <div className='form-header-sub'>
                                                    <h6>Customer Feedback<span></span></h6>
                                                </div>
                                            </div>
                                            <Row className=' order-details-row'>
                                                <Col md={6} className='cat-col'>
                                                    <ul className="order-detail-list">
                                                        <li><div className="span1">Rating</div> &nbsp;&nbsp;<div className="span2"><b>{renderStars(singleClientCompaint.rating)}</b></div></li>
                                                        <li><div className="span1">Feedback</div> &nbsp;&nbsp;<div className="span2"><b>{singleClientCompaint?.clientComplaints_CustomerFeedback ? singleClientCompaint?.clientComplaints_CustomerFeedback : "NA"}</b></div></li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mi-subform-header p-3">
                                            <div className='form-header-sub' style={{ marginLeft: "10px" }}>
                                                <h6>Service Tracking<span></span></h6>
                                            </div>
                                            {/* <div><p>Please enter company information and proceed to the next step.</p></div> */}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {/* <ul className="order-detail-list"> */}
                                            {/* <li> */}
                                            <div className="span1"></div> &nbsp;&nbsp;<div className="span2"><b>
                                                {singleClientCompaint?.status === 1 ? (
                                                    <span className="pending-status status-badge update-status-badge">
                                                        Pending
                                                    </span>
                                                ) : singleClientCompaint?.status === 2 ? (
                                                    <span className="follow-status status-badge update-status-badge">
                                                        Follow Up
                                                    </span>
                                                ) : singleClientCompaint?.status === 3 ? (
                                                    <span className="follow-status status-badge update-status-badge">
                                                        Resolved
                                                    </span>
                                                ) : singleClientCompaint?.status === 4 ? (
                                                    <span className="follow-status status-badge update-status-badge">
                                                        Closed
                                                    </span>
                                                ) : singleClientCompaint?.status === 5 ? (
                                                    <span className="follow-status status-badge update-status-badge">
                                                        No AMC, No Warranty
                                                    </span>
                                                ) : singleClientCompaint?.status === 6 ? (
                                                    <span className="follow-status status-badge update-status-badge">
                                                        On the Way
                                                    </span>
                                                ) : singleClientCompaint?.status === 7 ? (
                                                    <span className="follow-status status-badge update-status-badge">
                                                        CheckIn
                                                    </span>
                                                ) : singleClientCompaint?.status === 8 ? (
                                                    <span className="follow-status status-badge update-status-badge">
                                                        Escalation
                                                    </span>
                                                ) : singleClientCompaint?.status === 9 ? (
                                                    <span className="follow-status status-badge update-status-badge">
                                                        Feedback
                                                    </span>
                                                ) : singleClientCompaint?.status === 10 ? (
                                                    <span className="complete-status status-badge update-status-badge">
                                                        Submitted
                                                    </span>
                                                ) : (
                                                    singleClientCompaint?.status === 11 && (
                                                        <span className="complete-status status-badge update-status-badge">
                                                            Accepted
                                                        </span>
                                                    )
                                                )}</b>
                                                <OverlayTrigger
                                                    placement={"top"}
                                                    delay={{ show: 250, hide: 300 }}
                                                    overlay={
                                                        <Tooltip id={`tooltip-dashboard`}>
                                                            Update Status
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) =>
                                                            handleStatus(singleClientCompaint?.clientComplaintsID)
                                                        }
                                                        className="btn client-history-link"
                                                        disabled={!(resPassData && resPassData?.desingation?.role?.roleName === "Admin")}
                                                    >
                                                        {/* <i className="fa fa-tasks" /> */}
                                                        <i className="fa fa-edit" />
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                            {/* </li> */}
                                            {/* </ul> */}
                                        </div>

                                        {/* <div class="order-track">
                                            <div class="order-track-step is-complete">
                                                <div class="order-track-status">
                                                    <span class="order-track-status-dot"></span>
                                                    <span class="order-track-status-line"></span>
                                                </div>
                                                <div class="order-track-text">
                                                    <p class="order-track-text-stat"><b>Registered</b></p>
                                                    <span class="order-track-text-sub">16/04/2014</span>
                                                </div>
                                            </div>
                                            <div class="order-track-step is-complete">
                                                <div class="order-track-status">
                                                    <span class="order-track-status-dot"></span>
                                                    <span class="order-track-status-line"></span>
                                                </div>
                                                <div class="order-track-text">
                                                    <p class="order-track-text-stat"><b>Assigned</b></p>
                                                    <span class="order-track-text-sub">16/04/2014</span>
                                                </div>
                                            </div>
                                            <div class="order-track-step is-complete">
                                                <div class="order-track-status">
                                                    <span class="order-track-status-dot"></span>
                                                    <span class="order-track-status-line"></span>
                                                </div>
                                                <div class="order-track-text">
                                                    <p class="order-track-text-stat"><b>On the way</b></p>
                                                    <span class="order-track-text-sub">16/04/2014</span>
                                                </div>
                                            </div>
                                            <div class="order-track-step">
                                                <div class="order-track-status">
                                                    <span class="order-track-status-dot"></span>
                                                    <span class="order-track-status-line"></span>
                                                </div>
                                                <div class="order-track-text">
                                                    <p class="order-track-text-stat"><b>CheckIn</b></p>
                                                    <span class="order-track-text-sub">16/04/2014</span>
                                                </div>
                                            </div>
                                            <div class="order-track-step">
                                                <div class="order-track-status">
                                                    <span class="order-track-status-dot"></span>
                                                    <span class="order-track-status-line"></span>
                                                </div>
                                                <div class="order-track-text">
                                                    <p class="order-track-text-stat"><b>Closed</b></p>
                                                    <span class="order-track-text-sub">16/04/2014</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="order-track" >
                                            {getComplaintLog && getComplaintLog.length > 0 ? (
                                                getComplaintLog.map((data, index) => (
                                                    <div className="order-track-step is-complete" key={index}>
                                                        <div className="order-track-status">
                                                            <span className="order-track-status-dot"></span>
                                                            <span className="order-track-status-line"></span>
                                                        </div>
                                                        <div className="order-track-text">
                                                            <p className="order-track-text-stat">
                                                                <b>{data.action}</b>
                                                                {/* <b><StatusBadge action={data.action} /></b> */}
                                                            </p>
                                                            <span className="order-track-text-sub">{data.log_Date}</span><br />
                                                            <span className="color-green"><b>{data.action_By ?? "NA"}</b></span>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <b>No tracking record found</b>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="sb-table-div p-4 pt-0">
                                    {/* <div className="mi-subform-header">
                                        <div className='form-header-sub'>
                                            <h6>Customer Feedback<span></span></h6>
                                        </div>
                                    </div> */}
                                    <div className="claims-table ">

                                        {/* <Tabs
                                            defaultActiveKey="Customer Questions"
                                            id="uncontrolled-tab-example"
                                            className="local-claim-nav-tab"
                                            onSelect={handleTabSelect}
                                        >
                                            <Tab eventKey="Customer Questions" title="Customer Questions" >
                                                <CustomerQs getCustomeFieldsForCustomerQuestion={getCustomeFieldsForCustomerQuestion} />
                                            </Tab>
                                            <Tab eventKey="Service Engineer Questions" title="Service Engineer Questions" >
                                                {activeTab == "Service Engineer Questions" && <ServiceEngineerQs getCustomeFieldsForServiceEnggQuestion={getCustomeFieldsForServiceEnggQuestion} />}
                                            </Tab>
                                        </Tabs> */}
                                        {/* <CustomerQs getCustomeFieldsForCustomerQuestion={getCustomeFieldsForCustomerQuestion} /> */}
                                    </div>
                                </div>
                            </div>
                            {/* <Row>
                                <Col md={12}>
                                    <div className="mi-subform-header p-3 pb-0">
                                        <div className='form-header-sub' style={{ marginLeft: "10px" }}>
                                            <h6>Complaint Log<span></span></h6>
                                        </div>
                                    </div>
                                    <div className="passbook-third-div">
                                        <div className="passbook-line-chart-container pt-1 pb-2 pl-0 pr-0">
                                            <div className="passbook-line-chart">
                                                <div className="claims-table">
                                                    <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                                                        <TableContainer sx={{ maxHeight: 440 }}
                                                        >
                                                            <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                                                                <TableHead className='custom-table-header'>
                                                                    <TableRow>
                                                                        {tableHeader.map((name, i) => (
                                                                            < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                                                                style={{ minWidth: name.width }}>
                                                                                {name.name}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                {isLoading ? (
                                                                    <TableBody>
                                                                        {tableHeader.map((name, i) => (
                                                                            <SkeletonTableData key={i} />
                                                                        ))}
                                                                    </TableBody>
                                                                ) : (
                                                                    <>
                                                                        {getComplaintLog && getComplaintLog.length === 0 ?
                                                                            <TableBody>
                                                                                <TableRow align="center" >
                                                                                    <TableCell align="center" colSpan={tableHeader.length}>
                                                                                        <div style={{ padding: "20px 20px" }}>
                                                                                            <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                                                            <span>No Data Found...!</span>
                                                                                        </div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                            :
                                                                            <TableBody>
                                                                                {getComplaintLog && getComplaintLog.map((data, i) => (
                                                                                    <TableRow align="center" key={i} className='client-list-row-data'>
                                                                                        <TableCell>
                                                                                            {data.log_Date}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {data.action}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {data.action_By}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        }
                                                                    </>
                                                                )}
                                                            </Table>
                                                        </TableContainer>
                                                    </Paper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
                        </div>
                        <div className="download-and-pagination">
                            {singleClientCompaint?.status === 4 && <SbDownloadBtn btnName="Download Reports" onClickEffect={() => serviceReportDownload(singleClientCompaint?.clientComplaintsID)} />}
                            <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
                        </div>
                    </div>
                </div>
                {showAssign && (
                    <AssignToServiceRequest
                        showAssign={showAssign}
                        setShowAssign={setShowAssign}
                        setCompaintId={setCompaintId}
                        compaintId={compaintId}
                        fetchComplaintdata={fetchComplaintdata}
                        loadSingleClientCompalint={loadSingleClientCompalint}
                    />
                )}
                {showUpdate && (
                    <ServiceRequestStatusUpdatePage
                        showUpdate={showUpdate}
                        setShowUpdate={setShowUpdate}
                        setCompaintId={setCompaintId}
                        compaintId={compaintId}
                        fetchComplaintdata={fetchComplaintdata}
                    />
                )}
            </>}
            {showStatus && (
                <AnnualContractStatus
                    showStatus={showStatus}
                    handleShowStatus={handleShowStatus}
                    singleClientCompaint={singleClientCompaint}
                    getSingleRowFormData={getSingleRowFormData}
                />
            )}
            {show && (
                <AddServiceRequestData
                    show={show}
                    handleClose={handleClose}
                    fetchComplaintdata={fetchComplaintdata}
                    compaintId={compaintId}
                    setCompaintId={setCompaintId}
                />
            )}
            {showPriority && (
                <UpdatePriority
                    showPriority={showPriority}
                    setShowPriority={setShowPriority}
                    setCompaintId={setCompaintId}
                    compaintId={compaintId}
                    fetchComplaintdata={fetchComplaintdata}
                    loadSingleClientCompalint={loadSingleClientCompalint}
                />
            )}

            <ViewIssueImages showImageModal={showImageModal} handleCloseModal={handleCloseModal} singleClientCompaint={singleClientCompaint} decodeBase64Image={decodeBase64Image} modalImage={modalImage} modalTitle={modalTitle} />
        </div >

    );
}

export default ViewServiceRequest;
